import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ReactElement } from 'react'
import { Mars } from './Mars'

export type MarsContent = {
    primary: ReactElement
    secondary: ReactElement
}

export const MarsRegistration = createRegisterableComposition<
    keyof MarsContent
>()('mars', (contentAreas, props: { sectionHeader: string }) => (
    <Mars sectionHeader={props.sectionHeader} content={contentAreas} />
))
