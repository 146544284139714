import styled from '@emotion/styled'
import { createPropFilter } from '@news-mono/web-common'
import { breakpoint, calcRem, metrics } from '../../__styling'

interface PublicationListSkeletonProps {
    overrideWidth?: number
    overrideHeight?: number
}

export const StyledPublicationListSkeleton = styled('div')(() => ({
    width: '100%',
}))

export const StyledPublicationItemSkeleton = styled('div', {
    shouldForwardProp: createPropFilter()(['gridAreaName']),
})<PublicationListSkeletonProps>(({ overrideWidth, overrideHeight }) => [
    {
        width: overrideWidth ? calcRem(overrideWidth) : 'auto',
        minHeight: calcRem(100),
        height: overrideHeight ? calcRem(overrideHeight) : undefined,
        display: 'grid',
        gridTemplateColumns: '0fr 2fr',
        columnGap: calcRem(13),
        '& [class*="StyledSkeleton"]': {
            borderRadius: calcRem(4),
        },
        background: '#fff',
        margin: calcRem(
            0,
            metrics.thenightly.margins.m2l,
            metrics.thenightly.margins.md,
            metrics.thenightly.margins.m2l,
        ),

        [breakpoint('lg')]: {
            marginLeft: 0,
        },
    },
])

export const StyledSkeletonText = styled('div')(() => ({
    position: 'relative',
    width: '100%',
    '& [class*="StyledSkeleton"]': {
        height: calcRem(20),
    },
}))

export const StyledSkeletonMedia = styled('div')(() => ({
    width: calcRem(96),
    height: '100%',
    '& [class*="StyledSkeleton"]': {
        height: '95%',
    },
}))

export const StyledSkeletonByLine = styled('div')({
    left: 0,
    '& [class*="StyledSkeleton"]': {
        height: calcRem(15),
        width: calcRem(55),
    },
})
