import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TNToastComponent } from './TNToastNotification'

export type AlertType = 'info' | 'success' | 'warning' | 'error'

export interface TNToastProps {
    type: AlertType
}
export interface TNToastComponentProps {
    text: string
    type: AlertType
    useMaxWidth: boolean
    forceMaxWidth?: boolean
}

export const TNToastNotificationRegistration = createRegisterableComponent(
    'tn-toast-notification',
    (props: TNToastComponentProps) => {
        return (
            <TNToastComponent
                type={props.type}
                text={props.text}
                useMaxWidth={props.useMaxWidth}
                forceMaxWidth={props.forceMaxWidth}
            />
        )
    },
)
