import { NavEvent, NavItem } from '@news-mono/web-common'
import { createRenderTarget } from '../../render-target'
import { TNQuickLinksWeb } from './TNQuickLinks.web'

export interface TNQuickLinksProps {
    links: NavItem[]
    onEvent: (event: NavEvent) => void
    hasBackgroundFill?: boolean
}

export const TNQuickLinks = createRenderTarget('TNQuickLinks', {
    web: TNQuickLinksWeb,
    amp: TNQuickLinksWeb,
    rss: TNQuickLinksWeb,
    preview: TNQuickLinksWeb,
    app: null,
})
TNQuickLinks.displayName = 'TNQuickLinks'
