import styled from '@emotion/styled'
import { getComponentTheme } from '../../__styling'

export const StyledNotificationCentreSectionList = styled.div(({ theme }) => {
    const sectionListTheme = getComponentTheme(
        theme,
        'notificationCentre',
    ).sectionList

    return {
        display: sectionListTheme.display,
        flexDirection: sectionListTheme.flexDirection,
        gap: sectionListTheme.gap,
    }
})
