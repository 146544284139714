import {
    Issue,
    createRegisterableComponentWithData,
    ContentDataDefinitionLoader,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { NightlyPreviousEditions } from './PreviousEditions/NightlyPreviousEditions'
import { CollectionDataContext } from '../../contexts/CollectionDataContext/CollectionDataContext'

export interface NightlyPreviousEditionsRouteProps {
    latestIssues?: Issue[]
    title: string
    type: 'homepage' | 'editions'
    lazyloadImages?: boolean
}

export const NightlyPreviousEditionsRegistration =
    createRegisterableComponentWithData(
        'nightly-previous-editions',
        ContentDataDefinitionLoader,
        (
            { type, title, lazyloadImages }: NightlyPreviousEditionsRouteProps,
            { dataDefinitionArgs, ...initialData },
            { config, onEvent },
        ) => {
            const contextData = useContext(CollectionDataContext)

            let data = initialData

            if (dataDefinitionArgs.type === 'context') {
                data = contextData.forId(
                    dataDefinitionArgs.contextId,
                ).collectionData
            }
            return (
                <NightlyPreviousEditions
                    editions={
                        data.loaded && data.result.kind === 'edition'
                            ? {
                                  loaded: true,
                                  result: data.result.editions,
                              }
                            : { loaded: false }
                    }
                    onEvent={onEvent}
                    title={title}
                    type={type}
                    config={config}
                    lazyloadImages={lazyloadImages}
                />
            )
        },
    )
