import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, getComponentTheme } from '@news-mono/component-library'

export const StyledNavigationMenuContainer = styled.nav(({ theme }) => {
    const sectionContentTheme = getComponentTheme(theme, 'sectionContent')

    // 195 is a magic number margin
    const largeScreenMargin = calcRem(195).replace('rem', '')
    const maxWidth = sectionContentTheme.maxWidth.replace('rem', '')
    const largeWidth = `${+maxWidth - +largeScreenMargin}rem`

    return {
        display: 'flex',
        flexDirection: 'row',
        padding: calcRem(8),
        backgroundColor: tokens.thenightly.colors.palette.white,
        overflow: 'hidden',
        width: '100%',
        height: calcRem(44),
        borderBottom: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
        borderTop: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,

        [breakpoint('sm')]: {
            width: 'fit-content',
            maxWidth: largeWidth,
            margin: '0 auto',
            boxShadow: '0px 8px 20px 8px #0000000D',
            borderRadius: calcRem(22),
        },
    }
})

export const StyledNavigationMenuItemContainer = styled.div({
    listStyleType: 'none',
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden',
    width: '100%',
    scrollbarWidth: 'none',
    overscrollBehavior: 'none',
    cursor: 'pointer',

    '&::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important',
        height: '0 !important',
        '-webkit-appearance': 'none',
        background: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
        display: 'none',
        width: '0 !important',
        height: '0 !important',
        '-webkit-appearance': 'none',
        background: 'transparent',
    },
})
