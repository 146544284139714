import {
    ContentDataDefinitionLoader,
    createRegisterableCompositionWithData,
} from '@news-mono/web-common'
import React from 'react'
import { CollectionDataContextProvider } from './CollectionDataContext'

export const CollectionDataContextRegistration =
    createRegisterableCompositionWithData<'children'>()(
        'collection-data-context',
        ContentDataDefinitionLoader,
        (
            { children },
            { contextId }: { contextId: string },
            { dataDefinitionArgs, ...data },
        ) => (
            <CollectionDataContextProvider
                contextId={contextId}
                initialData={data}
                initialDataDefinition={dataDefinitionArgs}
            >
                {children}
            </CollectionDataContextProvider>
        ),
    )
