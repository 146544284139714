import { TheNightlyHeaderNavExternalState } from '@news-mono/component-library'
import { useRef, useState } from 'react'
import { DataLayerEventName, NavEvent } from '@news-mono/web-common'
import { isContentNavOpen } from '../util'

export interface UseAnalyticsProps {
    navState: TheNightlyHeaderNavExternalState
    onEvent: (event: NavEvent) => void
}

export interface UseAnalyticsEvents {
    fireScrollToTopEvent: () => void
    fireOpenFlyoutNavEvent: () => void
    fireContentNavMenuItemClickedEvent: (index: number) => void
}

export const useAnalytics = ({
    navState,
    onEvent,
}: UseAnalyticsProps): UseAnalyticsEvents => {
    const prevRef = useRef(navState)

    const eventProps = {
        navName: getContentNavName(navState.isMobile),
        navState,
        onEvent,
    }

    const prev = prevRef.current

    // Fire when content nav values changes due to user scroll
    if (
        navState.activeContentArea > 0 &&
        !navState.isSystemScroll &&
        navState.activeContentArea !== prev.activeContentArea
    ) {
        fireContentNavMenuItemFocusedEvent(eventProps)
    }

    // Fire event when content nav opens
    if (
        !isContentNavOpen(prev.navDisplayState) &&
        isContentNavOpen(navState.navDisplayState)
    ) {
        fireContentNavOpenedEvent(eventProps)
    }

    // Fire event when content nav closes
    if (
        isContentNavOpen(prev.navDisplayState) &&
        !isContentNavOpen(navState.navDisplayState)
    ) {
        fireContentNavClosedEvent(eventProps)
    }

    prevRef.current = navState

    return {
        fireOpenFlyoutNavEvent: () => fireOpenFlyoutNavEvent(eventProps),
        fireScrollToTopEvent: () => fireScrollToTopEvent(eventProps),
        fireContentNavMenuItemClickedEvent: (index: number) =>
            fireContentNavMenuItemClickedEvent(index, eventProps),
    }
}

const getContentNavName = (isMobile: boolean) => {
    return isMobile
        ? 'ContentNav.Mobile.TheNightly'
        : 'ContentNav.Desktop.TheNightly'
}

interface FireEventProps extends UseAnalyticsProps {
    navName: string
}

const fireContentNavMenuItemClickedEvent = (
    index: number,
    { navName, navState, onEvent }: FireEventProps,
): void => {
    const navText = getActiveHeaderText(navState, index)

    onEvent({
        type: DataLayerEventName.navClicked,
        originator: 'ContentNavMenuItem',
        payload: {
            navName,
            navLocation: 'Header.Sticky',
            navText,
            navPos: index,
            linkType: 'scroll',
        },
    })
}

const fireOpenFlyoutNavEvent = ({ navName, onEvent }: FireEventProps): void => {
    onEvent({
        type: DataLayerEventName.navClicked,
        originator: 'ContentNavMenuItem',
        payload: {
            navName: navName,
            navLocation: 'Header.Sticky',
            navText: 'Hamburger',
            linkType: 'menu',
            navPos: 0,
        },
    })
}

const fireScrollToTopEvent = ({ navName, onEvent }: FireEventProps): void => {
    onEvent({
        type: DataLayerEventName.navClicked,
        originator: 'ContentNavMenuItem',
        payload: {
            navName,
            navLocation: 'Header.Sticky',
            navText: 'Home',
            linkType: 'scroll',
            navPos: 0,
        },
    })
}

const fireContentNavMenuItemFocusedEvent = ({
    navName,
    navState,
    onEvent,
}: FireEventProps): void => {
    const navText = getActiveHeaderText(navState)
    onEvent({
        type: DataLayerEventName.navFocused,
        originator: 'UseContentNav',
        payload: {
            navName,
            navText,
            navPos: navState.activeContentArea,
            linkType: 'scroll',
        },
    })
}

const fireContentNavOpenedEvent = ({
    navName,
    onEvent,
}: FireEventProps): void => {
    onEvent({
        type: DataLayerEventName.navAvailable,
        originator: 'ContentNav',
        payload: {
            navName,
        },
    })
}

const fireContentNavClosedEvent = ({
    navName,
    onEvent,
}: FireEventProps): void => {
    onEvent({
        type: DataLayerEventName.navHidden,
        originator: 'ContentNav',
        payload: {
            navName,
        },
    })
}

const getActiveHeaderText = (
    navState: TheNightlyHeaderNavExternalState,
    index?: number,
) => {
    const targetIndex = index ?? navState.activeContentArea

    const activeMenuItem = navState.contentNavMenuItems.find(
        (menuItem) => menuItem.index === targetIndex,
    )

    return activeMenuItem?.heading ?? ''
}
