import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import {
    getComponentTheme,
    HighlightDirection,
    TheNightlyTheme,
} from '../../../../component-library/src/'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

interface StyledMenuItemProps {
    textOverride?: string
    backgroundOverride?: string
    isSelected: boolean
    highlightDirection: HighlightDirection
    transitionTime: number
}

const animationDistance = 20
const transitionRatio = 2

export const StyledMenuItem = styled.div<StyledMenuItemProps>(
    ({
        theme,
        textOverride,
        backgroundOverride,
        isSelected,
        highlightDirection,
        transitionTime,
    }) => {
        const sectionHeaderTheme = getComponentTheme(theme, 'sectionHeader')
        const textColour = textOverride ?? sectionHeaderTheme.heading?.textColor
        const backgroundColour =
            backgroundOverride ?? sectionHeaderTheme.background
        const translateDistance =
            highlightDirection === 'left'
                ? animationDistance
                : -animationDistance
        const transition = `all ${transitionTime}ms ease-in`
        const transitionOut = `all ${
            transitionTime / transitionRatio
        }ms ease-in`

        return [
            {
                color: isSelected
                    ? textColour
                    : tokens.thenightly.colors.palette.neutral[50],
                background: 'transparent',
                padding: `${calcRem(2)} ${calcRem(8)}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textWrap: 'nowrap',
                whiteSpace: 'nowrap',
                position: 'relative',
                zIndex: 1,
                userSelect: 'none',

                // Only hover on devices that support hovering to prevent touchscreen
                // taps from sticking
                '@media (hover: hover)': {
                    '&:hover': {
                        color: textColour,
                        background: backgroundColour,
                        borderRadius: calcRem(22),
                        transition,
                    },
                },

                // The after element handles the coloured background
                // allowing it to be moved separate from the text
                '&::after': {
                    content: '""',
                    width: '100%',
                    height: '100%',
                    borderRadius: calcRem(22),
                    padding: `${calcRem(2)} ${calcRem(8)}`,
                    background: isSelected ? backgroundColour : 'transparent',
                    position: 'absolute',
                    zIndex: -1,
                },

                // Animate slide in from previous menu item
                '&.selected-enter::after': {
                    transform: `translate(${translateDistance}%)`,
                },
                '&.selected-enter-active::after': {
                    background: backgroundColour,
                    color: textColour,
                    transform: 'translate(0%)',
                    transition,
                },

                // Selected state of the menu item
                '&.selected-enter-done::after': {
                    background: backgroundColour,
                    color: textColour,
                },
                '&.selected-appear-done::after': {
                    background: backgroundColour,
                    color: textColour,
                },

                // Slide towards the next menu item and fade out to help
                // blend the transition.
                '&.selected-exit::after': {
                    background: backgroundColour,
                    color: textColour,
                    transform: 'translate(0%)',
                },
                '&.selected-exit-active::after': {
                    transform: `translate(${-translateDistance}%)`,
                    color: tokens.thenightly.colors.palette.neutral[50],
                    background: 'transparent',
                    zIndex: -2,
                    transition: transitionOut,
                },
            },
            textMixin(theme as TheNightlyTheme, 'body-alt'),
        ]
    },
)

export const StyledMenuItemFirstOption = styled.div(({ theme }) => {
    return [
        {
            color: tokens.thenightly.colors.palette.neutral[50],
            background: 'transparent',
            padding: `${calcRem(2)} ${calcRem(8)}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textWrap: 'nowrap',
            whiteSpace: 'nowrap',
            position: 'relative',
            zIndex: 1,
            cursor: 'pointer',
            userSelect: 'none',

            // Only hover on devices that support hovering to prevent touchscreen
            // taps from sticking
            '@media (hover: hover)': {
                '&:hover': {
                    cursor: 'pointer',
                    background: tokens.thenightly.colors.palette.neutral[30],
                    borderRadius: calcRem(22),
                },
            },
        },
        textMixin(theme as TheNightlyTheme, 'body-alt'),
    ]
})

export const StyledDivider = styled.div({
    width: calcRem(0),
    margin: calcRem(2, 4, 0),
    borderRight: `1px solid ${tokens.thenightly.colors.palette.neutral[30]}`,
    height: calcRem(24),
})

export const StyledFixedMenuItem = styled.div({
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    left: 0,
    backgroundColor: tokens.thenightly.colors.palette.white,
})

export const StyledHamburgerIcon = styled.div({
    display: 'flex',
    verticalAlign: 'middle',
    width: calcRem(18),
    height: calcRem(2),
    background: tokens.thenightly.colors.palette.neutral[50],
    transform: `translateY(-1px)`,
    borderRadius: calcRem(1),
    marginTop: calcRem(2),

    '&::before, &::after': {
        content: `''`,
        position: 'absolute',
        transformOrigin: 'center',
        right: 0,
        left: 0,
        height: calcRem(2),
        background: tokens.thenightly.colors.palette.neutral[50],
        borderRadius: calcRem(1),
    },

    '&::before': {
        top: calcRem(5),
    },

    '&::after': {
        bottom: calcRem(5),
    },
})
