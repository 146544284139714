import { NotificationCentreListSection } from '../types'
import React, { FC } from 'react'
import {
    StyledNotificationCentreSectionContainer,
    StyledNotificationCentreSectionTitle,
} from './NotificationCentreSection.styled'
import { NotificationCentreCard } from './NotificationCentreCard'
import { NotificationCentreItemV4DTO } from '@west-australian-newspapers/publication-types'

interface NotificationCentreSectionProps {
    notificationSection: NotificationCentreListSection
    onViewItemEvent: (
        index: number,
        notification: NotificationCentreItemV4DTO,
    ) => void
}

export const NotificationCentreSection: FC<NotificationCentreSectionProps> = ({
    notificationSection,
    onViewItemEvent,
}) => {
    return (
        <StyledNotificationCentreSectionContainer>
            <StyledNotificationCentreSectionTitle>
                {notificationSection.title}
            </StyledNotificationCentreSectionTitle>
            {notificationSection.notifications.map((notification, index) => (
                <NotificationCentreCard
                    notification={notification}
                    index={index}
                    onViewItemEvent={onViewItemEvent}
                    key={`${notification.publicationId}-${notification.dateSent}`}
                />
            ))}
        </StyledNotificationCentreSectionContainer>
    )
}
