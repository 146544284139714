import React from 'react'
import {
    StyledEmptyResultContainer,
    StyledSubTitle,
    StyledTitle,
} from './PublicationListEmptyResult.styled'
import { IconInfoLarge } from '../../icons/IconInfoLarge/IconInfoLarge'

export const PublicationListEmptyResult = () => {
    return (
        <StyledEmptyResultContainer>
            <IconInfoLarge dimension={48} />
            <StyledTitle>You haven’t saved any articles yet.</StyledTitle>
            <StyledSubTitle>
                Any articles you save will be stored here for later.
            </StyledSubTitle>
        </StyledEmptyResultContainer>
    )
}
