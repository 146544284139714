import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheNightlySection } from '@news-mono/common'
import { calcRem, metrics } from '@news-mono/component-library'
import { StaticRoute, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'

const socialImage = require('./assets/crossword-social-image.png')

export function getPuzzleRoute(
    topic: Topic,
    gameTitle: string,
    gameUrl: string,
): StaticRoute<TheNightlySection> {
    return ({ getAdTargeting, store, config }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'puzzles',
            )
        ) {
            return null
        }

        const canonicalUrl = config.publicUrl + `/${topic.id}`
        const renditionType = store.getState().render.renditionType

        return {
            kind: 'page',
            heading: 'Puzzles',
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: [
                    {
                        property: 'og:image',
                        content: socialImage,
                    },
                    {
                        name: 'twitter:image',
                        content: socialImage,
                    },
                    {
                        property: 'og:image:height',
                        content: '630',
                    },
                    {
                        property: 'og:image:width',
                        content: '1200',
                    },
                    {
                        property: 'og:description',
                        content: topic.seoDescription,
                    },
                    {
                        property: 'twitter:description',
                        content: topic.seoDescription,
                    },
                ],
            },
            adTargeting: getAdTargeting(
                'home',
                'default',
                topic.parent ?? topic,
            ),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        flex: {
                            default: {
                                flexDirection: 'column',
                                gap: calcRem(40),
                                alignItems: 'center',
                            },
                        },
                    },
                    contentAreas: {
                        main:
                            renditionType === 'app'
                                ? [
                                      layout.component({
                                          type: 'puzzleIframe',
                                          props: {
                                              link: gameUrl,
                                              title: gameTitle,
                                              backLinkDestination: '/puzzles',
                                          },
                                      }),
                                  ]
                                : [
                                      layout.component({
                                          type: 'puzzleIframe',
                                          props: {
                                              link: gameUrl,
                                              title: gameTitle,
                                              backLinkDestination: '/puzzles',
                                          },
                                      }),
                                      layout.component({
                                          type: 'ad-unit',
                                          props: {
                                              adType: 'inline',
                                              noticePosition: 'above-center',
                                              slot: {
                                                  id: 'below-page-content',
                                                  size: 'leaderboardAbove768MrecBelow',
                                              },
                                          },
                                      }),
                                  ],
                    },
                }),
            ],
        }
    }
}
