import { CollectionDataLoaded, ContentDataTypes } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, {
    createContext,
    PropsWithChildren,
    SetStateAction,
    Dispatch,
    useCallback,
    useContext,
    useState,
} from 'react'
import { isEqual } from 'lodash'

type CollectionDataContextType = {
    forId: (contextId: string) => {
        initialDataDefinition: ContentDataTypes
        collectionData: MaybeLoaded<CollectionDataLoaded>
        setCollectionData: Dispatch<
            SetStateAction<MaybeLoaded<CollectionDataLoaded>>
        >
    }
}

export const CollectionDataContext = createContext<CollectionDataContextType>({
    // Default to an error if no contexts match. Better to catch this error during
    forId: (contextId: string) => {
        throw new Error(
            `No CollectionDataContext with the id ${contextId} was found!`,
        )
    },
})

/** Props exposed to external registration */
export type CollectionDataContextRegistrationProps = {
    contextId: string
}

type CollectionDataContextProviderProps = {
    initialData: MaybeLoaded<CollectionDataLoaded>
    initialDataDefinition: ContentDataTypes
} & CollectionDataContextRegistrationProps

/**
 * A context designed to pass collection information between components at different levels.
 * Also allows modification of shared collection data for Load-More or Pagination type components.
 */
export const CollectionDataContextProvider = ({
    contextId,
    initialData,
    initialDataDefinition,
    children,
}: PropsWithChildren<CollectionDataContextProviderProps>) => {
    // PublicationDataContext(s) that wrap this one may be relevant.
    const { forId: nextForId } = useContext(CollectionDataContext)

    const [collectionData, setCollectionData] =
        useState<MaybeLoaded<CollectionDataLoaded>>(initialData)

    if (!isEqual(initialData, collectionData)) {
        setCollectionData(initialData)
    }

    // Checks if the current composition is the one actually requested.
    const forId = useCallback<CollectionDataContextType['forId']>(
        (requestedId) => {
            if (requestedId === contextId) {
                return {
                    initialDataDefinition,
                    collectionData,
                    setCollectionData,
                }
            } else {
                return nextForId(requestedId)
            }
        },
        [collectionData, contextId, initialDataDefinition, nextForId],
    )

    return (
        <CollectionDataContext.Provider
            value={{
                forId,
            }}
        >
            {children}
        </CollectionDataContext.Provider>
    )
}
