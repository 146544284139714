import { layout } from '../../App.routing'

export const trendingArticlesSegment = () => {
    return layout.composition({
        type: 'box',
        props: {},
        feature: 'show-trending-articles',
        contentAreas: {
            main: [
                layout.component({
                    type: 'trending-articles',
                    props: {
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: 'trending-articles',
                            offset: 0,
                            pageSize: 4,
                            excludeCurations: ['top-stories'],
                            excludeSources: ['Daily Mail', 'PerthNow'],
                            applyTrendingStoriesExclusion: true,
                        },
                    },
                }),
            ],
        },
    })
}
