import { datadogRum } from '@datadog/browser-rum'
import { default as version } from '../server/version'
import { DataLoaderGlobalParams } from 'libs/web-common/src/data'

let datadogIsInitialised = false

export function initializeDatadog(services: Partial<DataLoaderGlobalParams>) {
    const isEnabled = !!services.config?.datadogEnv

    if (!isEnabled) {
        services.log?.info('Datadog is not enabled; skipping initialisation')
        return
    }

    if (datadogIsInitialised) {
        services.log?.warn(
            'Datadog is already initialised; skipping initialisation',
        )
        return
    }

    datadogRum.init({
        applicationId: '73000a46-5acf-406b-99cf-66d58bd2163a',
        clientToken: 'pub842eb353ee452e1d59e81b167518df29',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'the-nightly',
        env: services.config?.datadogEnv,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: version.sha,
        sessionSampleRate: 1,
        sessionReplaySampleRate: 0, // https://wanews.atlassian.net/browse/DPT-1646
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',

        /**
         * disable tracing: we're seeing it produce errors:
         * ```
         * Failed to load resource: Request header field traceparent
         * is not allowed by Access-Control-Allow-Headers.
         * ```
         * */
        allowedTracingUrls: [],
    })
    services.log?.info('Datadog initialised')
    datadogIsInitialised = true
}
