import styled from '@emotion/styled'
import { colors } from '../../../../../__styling/settings/colors'
import { fonts } from '../../../../../__styling/settings/fonts'
import { breakpoint } from '../../../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'

export const StyledWestRewardsDivider = styled('div')({
    position: 'relative',
    height: calcRem(48),

    [breakpoint('xxs')]: {
        height: calcRem(66),
    },
})

export const StyledWestRewardsDividerVectorGray = styled('div')({
    position: 'absolute',
    width: '100%',
    height: '100%',

    [breakpoint('xxs')]: {
        height: calcRem(66),
    },

    '& > svg': {
        width: '100%',
        height: '100%',
    },
})

export const StyledWestRewardsDividerVectorPurple = styled(
    StyledWestRewardsDividerVectorGray,
)({
    position: 'static',
})

export const StyledWestRewardsHeaderTitle2022 = styled('div')(
    {
        fontWeight: 400,
        fontSize: calcRem(32),
        lineHeight: 2,
        textAlign: 'center',

        [breakpoint('xxs')]: {
            height: calcRem(66),
        },
    },

    ({ theme }) => ({
        fontFamily: theme?.fonts?.serif || fonts.thewest.serif,
        color: theme?.colors?.text?.primary || colors.black,
    }),
)
