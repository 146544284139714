import React from 'react'

import { StyledHeading } from './HomepageEditions.styled'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import {
    StyledButton,
    StyledEditionList,
    StyledHomepageEditions,
} from './HomepageEditions.styled'
import { EditionItem } from './EditionItem'

export const HomepageEditions: React.FC<{
    editions: EditionV4DTO[]
    title: string
    lazyloadImages?: boolean
}> = ({ editions, title, lazyloadImages }) => {
    if (!editions || editions.length === 0) {
        return null
    }

    return (
        <StyledHomepageEditions>
            <StyledHeading>
                <h2>{title}</h2>
                <StyledButton to={'/editions'} isTop>
                    View All
                </StyledButton>
            </StyledHeading>
            <StyledEditionList>
                {editions.map((edition, idx) => (
                    <EditionItem
                        edition={edition}
                        key={idx}
                        lazyloadImages={lazyloadImages}
                    />
                ))}
            </StyledEditionList>
            <StyledButton to={'/editions'}>View All</StyledButton>
        </StyledHomepageEditions>
    )
}
