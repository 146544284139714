import React, { FC } from 'react'
import { NotificationCentreItemV4DTO } from '@west-australian-newspapers/publication-types'
import { ResponsivePictureSizes } from '../../content/Picture/responsive'
import {
    StyledNotificationCentreBodyColumn,
    StyledNotificationCentreBodyColumnHeadline,
    StyledNotificationCentreBodyColumnSubHeadline,
    StyledNotificationCentreCardContainer,
    StyledNotificationCentreCardLink,
    StyledNotificationCentrePicture,
    StyledNotificationCentrePictureColumn,
} from './NotificationCentreCard.styled'
import { ResponsivePicture } from '../../content/Picture/Picture'
import {
    getReadableTimeSince,
    ResponsivePictureLayout,
} from '@news-mono/component-library'
import { mergeImageDtosToImageSource } from '../../../../web-common/src/__api-mapping/v4/lib/to-image'

interface NotificationCentreCardProps {
    notification: NotificationCentreItemV4DTO
    index: number
    onViewItemEvent: (
        index: number,
        notification: NotificationCentreItemV4DTO,
    ) => void
}

const imageWidths: ResponsivePictureSizes = {
    mobile: '64px',
    tablet: '64px',
    desktop: '64px',
    fallbackWidth: 64,
    mode: 'fixed',
}

export const NotificationCentreCard: FC<NotificationCentreCardProps> = ({
    notification,
    index,
    onViewItemEvent,
}) => {
    const handleLinkClick = () => {
        onViewItemEvent(index, notification)
    }

    const thumbnailImage = mergeImageDtosToImageSource(
        ...notification.cardImage,
    )

    const readableTime = getReadableTimeSince({
        publicationDate: notification.dateSent,
        comparisonDate: new Date().toISOString(),
        hierarchy: 'theNightlyNotificationCentre',
    })

    return (
        <StyledNotificationCentreCardLink
            to={notification.url}
            onClick={handleLinkClick}
            openInternalInNewWindow={false}
            openExternalInNewWindow={false}
        >
            <StyledNotificationCentreCardContainer>
                {thumbnailImage && (
                    <div>
                        <StyledNotificationCentrePicture
                            layout={ResponsivePictureLayout.ObjectFitContain}
                            image={thumbnailImage}
                            imageWidths={imageWidths}
                            fixedRatio={'original'}
                        />
                    </div>
                )}
                <StyledNotificationCentreBodyColumn>
                    <StyledNotificationCentreBodyColumnHeadline>
                        {notification.body}
                    </StyledNotificationCentreBodyColumnHeadline>
                    <StyledNotificationCentreBodyColumnSubHeadline>
                        {readableTime}
                    </StyledNotificationCentreBodyColumnSubHeadline>
                </StyledNotificationCentreBodyColumn>
            </StyledNotificationCentreCardContainer>
        </StyledNotificationCentreCardLink>
    )
}
