import { Features } from "@news-mono/web-common";
import { BreachBannerLayout, BreachWallContentType, BreachWallStyle } from "../../helpers/BreachWallTypes";
import { AbTestExperiment, AbTestServiceType } from "../AbTestingProvider";
import { TheWestSection } from "@news-mono/common";

const defaultBreachWallConfig: { creativeName: string, deal: string, type: BreachWallContentType } = {
    creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
    deal: 'default',
    type: 'default',
}
const theWestSections: TheWestSection[] = [
    'genwest',
    'default'
]

export const BreachExperimentOne: AbTestExperiment = {
    getId(): Features {
        return "breach-ab-test-1"
    },

    getExperimentId(): string {
        return "breach-ab-test-1"
    },
    getFeatureToggleId(): Features {
        return 'launch-darkly-a-b-testing'
    },
    getServiceType(): AbTestServiceType {
        return "launch-darkly"
    },
    getVariations(): number {
        return 4;
    },
    getBreachStyle(_variant: number): BreachWallStyle {
        return 'abtest-container'
    },

    getWhitelistedExperimentSections(): TheWestSection[] | undefined {
        return theWestSections
    },
    getWhitelistedExperimentLocations(): string[] | undefined {
        return undefined
    },

    getBreachWallConfig(variant: number): { creativeName: string; deal: string; type: BreachWallContentType; } {
        switch (variant) {
            case 1:
                return {
                    creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
                    deal: 'DIG1X4',
                    type: 'abtest-b-1x4'
                }
            case 2:
                return {
                    creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
                    deal: 'DIG50CX8',
                    type: 'abtest-c-50cx8'
                }
            case 3:
                return {
                    creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
                    deal: 'DIG50X8',
                    type: 'abtest-d-50px8'
                }
            default:
                return defaultBreachWallConfig;
        }

    },
    getBreachBannerConfig(variant: number): BreachBannerLayout {
        switch (variant) {
            case 1:
                return {
                    mainBannerText: "First month for $1",
                    subBannerText: "on Everyday Digital",
                    tncBannerText: "T's & C's apply"
                }
            case 2:
                return {
                    topBannerText: "Everyday Digital",
                    mainBannerText: "50c A DAY",
                    subBannerText: "for 8 weeks",
                    tncBannerText: "T's & C's apply"
                }
            case 3:
                return {
                    mainBannerText: "50% OFF",
                    subBannerText: "Everyday digital for 8 weeks",
                    tncBannerText: "T's & C's apply"
                }
        }

        return {}
    },

    getVariantFromType(type: BreachWallContentType): number {
        switch (type) {
            case "abtest-b-1x4":
                return 1;
            case "abtest-c-50cx8":
                return 2;
            case "abtest-d-50px8":
                return 3;
            default:
                return -1;
        }
    }
}

export const BreachVanishingCousins: AbTestExperiment = {
    getId(): Features {
        return "breach-ab-test-1"
    },

    getExperimentId(): string {
        return "breach-vanishing-cousins"
    },
    getFeatureToggleId(): Features {
        return 'vanishing-cousins-breach-screen'
    },
    getServiceType(): AbTestServiceType {
        return "launch-darkly"
    },
    getVariations(): number {
        return 1;
    },
    getBreachStyle(_variant: number): BreachWallStyle {
        return 'vanishing-cousins'
    },

    getWhitelistedExperimentSections(): TheWestSection[] | undefined {
        return theWestSections
    },
    getWhitelistedExperimentLocations(): string[] | undefined {
        return [
            // Vanishing Cousins
            '/features/vanishing-cousins',
            // Bikie Code
            '/features/bikie-code',
            // Boy in the Blue Cap
            '/features/gerard-ross',
            '/news/gerard-ross',
            // Lisa Govan
            '/features/catching-lisa-govans-killer',
            // Tiny Pinder
            '/features/foul-play',
        ]
    },

    getBreachWallConfig(_variant: number): { creativeName: string; deal: string; type: BreachWallContentType; } {
        return {
            creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
            deal: 'VC23',
            type: 'vanishing-cousins'
        }
    },
    getBreachBannerConfig(_variant: number): BreachBannerLayout {
        return {
            topBannerText: 'DISCOVER HOMEGROWN SERIES AND MORE',
            mainBannerText: "40% OFF",
            subBannerText: "Everyday Digital",
            tncBannerText: "T&Cs apply"
        }
    },
    getVariantFromType(_type: BreachWallContentType): number {
        return 1;
    }
}

// export const BreachExperimentTwo: AbTestExperiment = {
//     getId(): string {
//         return "breach-ab-test-2"
//     },

//     getExperimentId(): string {
//         return 'aS40UEDARV-J1Js9Q_CsvQ'
//     },
//     getFeatureToggleId(): Features {
//         return 'google-optimize-a-b-testing'
//     },
//     getServiceType(): AbTestServiceType {
//         return 'google-optimize'
//     },
//     getVariations(): number {
//         return 4;
//     },
//     getBreachStyle(_variant: number): BreachWallStyle {
//         return 'abtest2-container'
//     },

//     getWhitelistedExperimentSections(): TheWestSection[] | undefined {
//         return theWestSections
//     },
//     getWhitelistedExperimentLocations(): string[] | undefined {
//         return undefined
//     },

//     getBreachWallConfig(variant: number): { creativeName: string, deal: string, type: BreachWallContentType } {
//         switch (variant) {
//             case 1:
//                 return {
//                     creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
//                     deal: 'DIG20X8',
//                     type: 'ab-test-promo-20-percent'
//                 }
//             case 2:
//                 return {
//                     creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
//                     deal: 'DIG30X8',
//                     type: 'ab-test-promo-30-percent'
//                 }
//             case 3:
//                 return {
//                     creativeName: 'TW_Article_Breach_WestBlue_Design_PhotoPackages',
//                     deal: 'DIG40X8',
//                     type: 'ab-test-promo-40-percent'
//                 }
//             default:
//                 return defaultBreachWallConfig;
//         }
//     },
//     getBreachBannerConfig(variant: number): BreachBannerLayout {
//         if (variant < 1 || variant > 3) {
//             return {}
//         }

//         return {
//             mainBannerText: String((variant+1) * 10) + '% OFF',
//             subBannerText: 'Everyday Digital for 8 weeks',
//             tncBannerText: "T&Cs apply"
//         }
//     },

//     getVariantFromType(type: BreachWallContentType): number {
//         switch (type) {
//             case "ab-test-promo-20-percent":
//                 return 1;
//             case "ab-test-promo-30-percent":
//                 return 2;
//             case "ab-test-promo-40-percent":
//                 return 3;
//             default:
//                 return -1;
//         }
//     },
// }
