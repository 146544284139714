import { CardInformation } from 'web-common'
import { NotificationCentreListSection } from '../../types'

export const getNotificationCentreCardInformation = (
    sections: NotificationCentreListSection[],
): CardInformation[] => {
    return sections
        .flatMap((section) => section.notifications)
        .map((notification, index) => ({
            item_brand: notification.source,
            index,
            item_category: notification.primaryTopic,
            item_category2: notification.kind,
            item_id: notification.publicationId,
            item_heading: notification.heading,
            item_name: notification.body,
            item_variant: false,
            price: 0.0,
        }))
}
