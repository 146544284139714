import React from 'react'
import { UserIcon } from '../TheNightlyHeaderActions/assets/UserIcon'
import {
    StyledAuthLink,
    StyledIconUserContainer,
    StyledLoginModalCloseButton,
    StyledLoginModalContainer,
} from './TheNightlyHeaderLoginModal.styled'
import { AuthenticationState } from '@news-mono/web-common'
import {
    LoggedInButtons,
    LoggedInInformationContainer,
} from './TheNightlyHeaderLoginModalLoggedIn'
import {
    LoggedOutButtons,
    LoggedOutInformationContainer,
} from './TheNightlyHeaderLoginModalLoggedOut'
import { useAuthActions } from '../../user-registration'
import { useHistory } from 'react-router'
import { saveOriginUrl } from '../../user-registration/warden/__helpers/origin-url-helper'
import { useOnClickOutside } from '../../__helpers/on-click-outside'
import {
    StyledContainer,
    StyledContainerWithBorderTopAndBottom,
} from '../../user-registration/warden'

// Props to pass through from the primary actions level
export interface TheNightlyLoginActionLinksProps {
    authState: AuthenticationState
    onEvent: (event: any) => void
}
// Props to pass down to sub-components of the login modal
export interface TheNightlyHeaderLoginStateProps {
    authenticationState: AuthenticationState
    hide: () => void
}
// Props to pass down to the buttons within the login modal
export interface TheNightlyLoginModalButtonProps {
    onMyAccountClick: () => void
    onLogoutClick: () => void
    onLoginClick: () => void
    onCreateAccountClick: () => void
    onSavedPublicationClick: () => void
    onEvent: (event: any) => void
}
export interface TheNightlyLoginModalProps {
    buttonProps: TheNightlyLoginModalButtonProps
    modalProps: { show: () => void; hide: () => void }
    isLoggedIn: boolean
    authState: AuthenticationState
    ignoreFade?: boolean
    headerLoginModalContainer: React.MutableRefObject<HTMLElement | null> | null
}

// The top-level of the login, containing the logged in/logged out state buttons, and all
// the handlers for within.
export const TheNightlyHeaderLogin: React.FC<TheNightlyLoginActionLinksProps> =
    ({ authState, onEvent }) => {
        const history = useHistory()

        const { onLoginClick, onLogoutClick, onRegisterClick } =
            useAuthActions()
        const isLoggedIn = authState.isLoggedIn
        const headerLoginModalContainer = React.useRef<HTMLElement | null>(null)

        // Show & Hide the Login Modal
        const [isHidden, setHidden] = React.useState(true)
        const hide = React.useCallback(() => {
            setHidden(true)
        }, [])
        const show = React.useCallback(() => {
            setHidden(false)
        }, [])

        useOnClickOutside(
            headerLoginModalContainer,
            () => {
                hide()
            },
            { enabled: !isHidden },
        )

        // Handles whenever the user clicks on the "My account" button while
        // current logged in. This should re-direct them to the account section
        // of TheNightly, however that is awaiting completion.
        const onMyAccountClick = () => {
            hide()
            history.push('/manage-account')
        }

        const onSavedPublicationClick = () => {
            hide()
            history.push('/saved-articles')
        }

        return (
            <>
                {/* Show the user icon when logged in or on mobile, because we don't want the other buttons, BUT ignore on forceOpenLoginModal */}
                <StyledIconUserContainer
                    isLoggedIn={isLoggedIn}
                    onClick={show}
                    id="header-user-icon"
                >
                    <UserIcon show={true} />
                </StyledIconUserContainer>
                {/* Show the normal login & sign up buttons when not logged in, BUT ignore on forceOpenLoginModal */}
                {!isLoggedIn && (
                    <StyledAuthLink
                        id="header-login-button"
                        onClick={(e) => {
                            e.preventDefault()
                            show()
                        }}
                    >
                        Log in
                    </StyledAuthLink>
                )}

                {/* Show the login modal when the login button is clicked */}
                {!isHidden && (
                    <TheNightlyHeaderLoginModal
                        headerLoginModalContainer={headerLoginModalContainer}
                        buttonProps={{
                            onMyAccountClick,
                            onSavedPublicationClick,
                            onLoginClick: () => {
                                saveOriginUrl(true)
                                onLoginClick()
                            },
                            onLogoutClick: () => {
                                saveOriginUrl(true)
                                onLogoutClick(onEvent)
                            },
                            onCreateAccountClick: () =>
                                onRegisterClick('login-modal', 'web'), //this compoennt doesn't display in the app rendition
                            onEvent,
                        }}
                        authState={authState}
                        modalProps={{ show, hide }}
                        isLoggedIn={!!isLoggedIn}
                    />
                )}
            </>
        )
    }

export const TheNightlyHeaderLoginModal: React.FC<TheNightlyLoginModalProps> =
    ({
        buttonProps,
        modalProps,
        isLoggedIn,
        ignoreFade = false,
        authState,
        headerLoginModalContainer,
    }) => {
        const {
            onMyAccountClick,
            onLoginClick,
            onLogoutClick,
            onCreateAccountClick,
            onSavedPublicationClick,
            onEvent,
        } = buttonProps
        const { hide, show } = modalProps

        // Show different states depending on the logged in/logged out state
        const LoginStateInformation = isLoggedIn
            ? LoggedInInformationContainer
            : LoggedOutInformationContainer
        const Buttons = isLoggedIn ? LoggedInButtons : LoggedOutButtons

        return (
            <StyledLoginModalContainer
                isLoggedIn={isLoggedIn}
                ignoreFade={ignoreFade}
                ref={(el) =>
                    headerLoginModalContainer !== null
                        ? (headerLoginModalContainer.current = el)
                        : null
                }
            >
                <StyledContainer>
                    <LoginStateInformation
                        authenticationState={authState}
                        hide={hide}
                    />
                </StyledContainer>
                <StyledContainerWithBorderTopAndBottom>
                    <Buttons
                        onMyAccountClick={onMyAccountClick}
                        onLogoutClick={() => {
                            onLogoutClick()
                            hide()
                        }}
                        onLoginClick={onLoginClick}
                        onCreateAccountClick={onCreateAccountClick}
                        onSavedPublicationClick={onSavedPublicationClick}
                        onEvent={onEvent}
                    />
                </StyledContainerWithBorderTopAndBottom>
                <StyledLoginModalCloseButton onClick={hide}>
                    Close
                </StyledLoginModalCloseButton>
            </StyledLoginModalContainer>
        )
    }
