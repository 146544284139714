import styled from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import { Section } from '@west-australian-newspapers/publication-types'
import { breakpoint } from '../../../__styling/style-functions'

export const StyledLogoWrapLink = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['section']),
})<{ section: Section }>(() => ({
    display: 'block',
    width: '100%',
    maxWidth: 400,
    flex: 5,

    [breakpoint('sm')]: {
        flex: 2,
    },
}))

export const StyledFeatureLogoWrapLink = styled(WebLink)(() => ({
    display: 'block',
    width: '100%',
    maxWidth: 'none',
    flex: 35,

    [breakpoint('sm')]: {
        flex: 2,
    },
}))

export const StyledFeatureLogoRow = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
})
