import React, { ReactNode, useEffect } from 'react'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import { EditionItem } from './EditionItem'
import {
    InterlacedCta,
    StyledEditionList,
    StyledEditionsHeading,
    StyledEditionsSection,
    StyledEditionsPage,
} from './Editions.styled'
import { createEditionViewItemListEvent } from '@news-mono/web-common'

interface EditionsProps {
    editions: EditionV4DTO[]
    title: string
    interlacedComponent?: ReactNode
    lazyloadImages?: boolean
    onEvent: (event: any) => void
}

export const Editions: React.FC<EditionsProps> = ({
    editions,
    title,
    interlacedComponent,
    lazyloadImages,
    onEvent,
}) => {
    const COUNT_OF_EDITIONS_BEFORE_CTA = 10 // Displays up to 10 editions before the Interlaced Component, and the rest after the component

    useEffect(() => {
        onEvent(
            createEditionViewItemListEvent({
                editions,
                collectionName: 'TheNightlyEditionPage',
                itemCategory: 'thenightly_editions_page_latest_editions',
            }),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StyledEditionsPage>
            <StyledEditionsSection>
                <StyledEditionsHeading>
                    {editions.length > 0 && <h2>{title}</h2>}
                </StyledEditionsHeading>
                <StyledEditionList>
                    {editions
                        .slice(0, COUNT_OF_EDITIONS_BEFORE_CTA)
                        .map((edition, idx) => (
                            <EditionItem
                                edition={edition}
                                key={idx}
                                lazyloadImages={lazyloadImages}
                                onEvent={onEvent}
                            />
                        ))}
                </StyledEditionList>
            </StyledEditionsSection>
            {interlacedComponent && (
                <InterlacedCta>{interlacedComponent}</InterlacedCta>
            )}
            <StyledEditionsSection>
                <StyledEditionList>
                    {editions
                        .slice(COUNT_OF_EDITIONS_BEFORE_CTA)
                        .map((edition, idx) => (
                            <EditionItem
                                edition={edition}
                                key={idx}
                                lazyloadImages={lazyloadImages}
                                onEvent={onEvent}
                            />
                        ))}
                </StyledEditionList>
            </StyledEditionsSection>
        </StyledEditionsPage>
    )
}
