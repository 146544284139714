import {
    ArticleLikePublication,
    EntitledToView,
    isLiveSportsTopic,
} from '@news-mono/web-common'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { nth } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { ArticleBlockContent } from '../../../publication/ArticleBlockContent/ArticleBlockContent'
import { ArticleHero } from '../../../publication/ArticleHero/ArticleHero'
import { Byline } from '../../../publication/Byline/Byline'
import { Headline } from '../../../publication/Headline/Headline'
import { LiveEvent } from '../../../publication/LiveEvent/LiveEvent'
import { getEditorialType } from '../../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import { PublicationProps } from '../../../templates/Publication/Publication.props'
import { StyledStickyCell } from '../../../templates/Publication/TheWestPublication/TheWestPublication.styled'
import { ArticleBreach } from '../../../user-registration/breach/ArticleBreach'
import { appendToHeadline } from '../../../__helpers/append-to-headline'
import { EditorialLabel } from './components'
import {
    StyledGrid,
    StyledMobilePaddingContainer,
    StyledArticleContent,
    StyledSharingContainer,
} from './TheNightlyPublication.styled'
import { TopicList } from '../../../content/TopicList/TopicList'
import { maxSecondLevel } from '../lib/max-second-level'
import { metrics } from '../../../__styling'
import { Sharing } from '../../../buttons/TheNightlySharing/Sharing'
import { useLocation } from 'react-router'
import { Footnote, Link } from '../../../publication/Footnote/Footnote'
import { getCanonicalLink } from '../lib/get-canonical-link'

const getOriginallyPublishedText = (
    canonicalLink: Link | undefined,
    publication: ArticleLikePublication,
): string => {
    if (!canonicalLink) {
        return ''
    }

    return `Originally published ${
        canonicalLink.text === publication.source ? 'on' : ' as'
    } `
}

export const TheNightlyPublication: React.FC<PublicationProps> = (props) => {
    const publication = props.data
    const content = publication.content
    const primaryTopic = publication.primaryTopic.id
    const secondaryTopics = publication.secondaryTopics
    const heroImage = publication.heroImage
    const storyClassification = true
    const fundingType = getFundingType(publication, storyClassification)
    const editorialType = getEditorialType(publication, storyClassification)
    const allowVideoPlaylist =
        publication.heroVideo?.seriesInfo !== null &&
        publication.heroVideo?.seriesInfo?.episode !== 'trailer'

    const topicIds: string[] = secondaryTopics.map((topic) => topic.id)
    topicIds.push(primaryTopic)

    const paywallCssClass =
        publication.requiredAccess.level !== 'anonymous' ? 'paywall' : undefined

    const publicationKind = publication.kind
    const isEvent = publicationKind === 'event'

    const [liveEventUpdated, setLiveEventUpdated] = useState(
        nth(0, isEvent ? publication.entries?.documents : [])?.lastUpdated ??
            publication.lastUpdated,
    )

    // originally published details
    const canonicalLink = getCanonicalLink(publication, true)
    const originallyPublishedText = getOriginallyPublishedText(
        canonicalLink,
        publication,
    )

    const websocketSetTimestamp = (
        post: EventPostV4DTO | KilledEventPostV4DTO,
    ) => setLiveEventUpdated(post.lastUpdated)

    const sharingSectionRef = useRef<HTMLDivElement>(null)
    const { hash } = useLocation()

    useEffect(() => {
        if (sharingSectionRef.current && hash === '#comments') {
            sharingSectionRef.current.scrollIntoView(true)
        }
    }, [sharingSectionRef, hash])

    return (
        <StyledGrid itemProp="articleBody">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: metrics.thenightly.margins.outerMargin,
                }}
            >
                <div>
                    {editorialType && <EditorialLabel type={editorialType} />}

                    {editorialType && isLiveSportsTopic(primaryTopic) ? (
                        <Headline
                            text={appendToHeadline(
                                publication.heading,
                                editorialType,
                            )}
                        />
                    ) : (
                        <StyledMobilePaddingContainer>
                            <Headline text={publication.heading} />
                        </StyledMobilePaddingContainer>
                    )}
                </div>

                <StyledMobilePaddingContainer>
                    <Byline
                        profile={publication.profile}
                        text={publication.byline}
                        timestampUpdated={
                            isEvent ? liveEventUpdated : undefined
                        }
                        timestamp={publication.publicationDate}
                        fundingType={fundingType}
                        source={publication.source}
                        isFullWidth={true}
                        url={publication._self}
                        title={publication.heading}
                        requiredAccess={publication.requiredAccess}
                        allowCommenting={publication.allowCommenting}
                        publicationKind={publication.kind}
                        readTimeMinutes={
                            publication.readTimeMinutes ?? undefined
                        }
                        id={publication.id}
                        onEvent={props.onEvent}
                    />
                </StyledMobilePaddingContainer>
                <EntitledToView
                    requiredAccess={publication.requiredAccess}
                    articleSlug={publication.slug}
                    topicIds={topicIds}
                    entitled={() => {
                        if (props.heroOptions.isVideoStickyEnabled === true) {
                            return (
                                <>
                                    <StyledStickyCell className="sticky-cell">
                                        <ArticleHero
                                            heroVideo={publication.heroVideo}
                                            heroImage={heroImage}
                                            imageFetchPriority="high"
                                            heading={publication.heading}
                                            onEvent={props.onEvent}
                                            showVideoTitles={true}
                                            isVideoStickyEnabled={true}
                                            getVideoQueue={props.getVideoQueue}
                                            publicationPathname={
                                                props.location.pathname
                                            }
                                            adUnitPath={
                                                props.adState.targeting
                                                    .adUnitPath
                                            }
                                            showVideoPlaylist={
                                                allowVideoPlaylist
                                            }
                                        />
                                    </StyledStickyCell>
                                </>
                            )
                        } else {
                            return (
                                <div>
                                    <ArticleHero
                                        heroVideo={publication.heroVideo}
                                        heroImage={heroImage}
                                        imageFetchPriority="high"
                                        heading={publication.heading}
                                        onEvent={props.onEvent}
                                        showVideoTitles={true}
                                        isVideoStickyEnabled={false}
                                        getVideoQueue={props.getVideoQueue}
                                        publicationPathname={
                                            props.location.pathname
                                        }
                                        adUnitPath={
                                            props.adState.targeting.adUnitPath
                                        }
                                    />
                                </div>
                            )
                        }
                    }}
                />
            </div>
            <div className={paywallCssClass} data-testid="BreachScreen">
                {content && (
                    <StyledArticleContent>
                        <ArticleBreach
                            requiredAccess={publication.requiredAccess}
                            topicIds={topicIds}
                            location={props.location}
                            onEvent={props.onEvent}
                            blocks={content}
                            targetedContent={props.targetedContent}
                            inlinePositionedContent={
                                props.inlinePositionedContent
                            }
                            publication={publication}
                            slug={publication.slug}
                            log={props.log}
                        >
                            {({
                                blocks,
                                targetedContent,
                                inlinePositionedContent,
                            }) => (
                                <>
                                    <div>
                                        <ArticleBlockContent
                                            blocks={blocks}
                                            targetedContentElement={
                                                targetedContent &&
                                                targetedContent.position ===
                                                    'inline'
                                                    ? targetedContent.element
                                                    : undefined
                                            }
                                            inlinePositionedContent={
                                                inlinePositionedContent
                                            }
                                            onEvent={props.onEvent}
                                            log={props.log}
                                            publicationKind={publication.kind}
                                            publicationPathname={
                                                props.location.pathname
                                            }
                                            adUnitPath={
                                                props.adState.targeting
                                                    .adUnitPath
                                            }
                                            getVideoQueue={props.getVideoQueue}
                                            requiredAccess={
                                                publication.requiredAccess
                                            }
                                        />

                                        {isEvent && (
                                            <LiveEvent
                                                publication={publication}
                                                log={props.log}
                                                adState={props.adState}
                                                maxPages={
                                                    props.liveBlogOptions
                                                        ?.maxPages
                                                }
                                                pageSize={
                                                    props.liveBlogOptions
                                                        ?.pageSize
                                                }
                                                onEvent={props.onEvent}
                                                onLiveEventUpdate={
                                                    websocketSetTimestamp
                                                }
                                            />
                                        )}

                                        <StyledMobilePaddingContainer>
                                            {canonicalLink && (
                                                <Footnote
                                                    text={
                                                        originallyPublishedText
                                                    }
                                                    link={canonicalLink}
                                                />
                                            )}
                                        </StyledMobilePaddingContainer>
                                    </div>
                                </>
                            )}
                        </ArticleBreach>
                    </StyledArticleContent>
                )}
            </div>
            <StyledMobilePaddingContainer>
                <TopicList
                    primaryTopic={maxSecondLevel(publication.primaryTopic)}
                    secondaryTopics={publication.secondaryTopics}
                />
            </StyledMobilePaddingContainer>
            <StyledSharingContainer
                ref={sharingSectionRef}
                commentsAllowed={publication.allowCommenting}
            >
                <Sharing
                    publicationId={publication.id}
                    url={publication._self}
                    text={publication.heading}
                    onEvent={props.onEvent}
                    shareOptions={[
                        'facebook',
                        'linkedin',
                        'email_tn',
                        'clipboard',
                    ]}
                    originator="publicationShare"
                />
            </StyledSharingContainer>
        </StyledGrid>
    )
}
TheNightlyPublication.displayName = 'TheNightlyPublication'
