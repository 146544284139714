import styled from '@emotion/styled'
import { breakpoint } from '../../../__styling'

/** Positions the multiple groups of links. */
export const LinkGroupsWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: 64,

    [breakpoint('sm')]: {
        flexDirection: 'row',
        gap: 36,
    },
})

/** Positions the links within a group of links. */
export const LinkGroup = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    gap: 16,
})
