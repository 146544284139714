import { TheNightlySection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes, noIndexMeta } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export const theNightlyAccountDeletedRoute: StaticRoutes<TheNightlySection> = {
    ['/connect/account-deleted']: ({ getAdTargeting }) => {
        const section = 'default'

        return {
            kind: 'page',
            heading: 'Account Deleted',
            pageMeta: {
                meta: [noIndexMeta],
            },
            // used by `/server/middleware/optimised-ssr` to determine redirect status
            pageType: 'static',
            hideHeading: true,
            adTargeting: getAdTargeting('rss', section, 'account-deleted'),
            section,
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thenightly.siteMetrics.mainContentWidth,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'account-deleted',
                                props: {
                                    masthead: 'The Nightly',
                                    contactEmail: '[support@thenightly.com.au]',
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
