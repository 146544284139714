import { TNFooterLinks } from 'libs/component-library/src/navigation/TNFooter/components'

const UTMTag = `?utm_source=TheNightly&utm_medium=Referral&utm_campaign=footer&utm_content=footerlink`

export const footerLinks: TNFooterLinks = {
    linkGroups: [
        // Only 1 link group in MVP
        [
            {
                name: 'HOME',
                link: `/`,
            },
            {
                name: 'THE EDITION',
                link: `/editions`,
                forceInternalSSR: true,
            },
            {
                name: 'ABOUT',
                link: `/about`,
            },
            {
                name: 'CONTACT',
                link: `/contact`,
            },
            {
                name: 'EDITORIAL POLICY',
                link: `https://www.sevenwestmedia.com.au/assets/pdfs/Group-Editorial-Policy2.pdf${UTMTag}`,
            },
            {
                name: 'EDITORIAL COMPLAINTS',
                link: `/editorial-complaints`,
            },
        ],
    ],
    legalLinks: [
        {
            name: 'Privacy Policy',
            link: `https://www.sevenwestmedia.com.au/privacy-policies/privacy${UTMTag}`,
        },
        {
            name: 'Terms of Use',
            link: `/terms-of-use`,
        },
    ],
    socialLinks: {
        facebook: {
            name: 'Facebook',
            link: 'https://facebook.com/thenightlyau',
        },
        x: {
            name: 'X',
            link: 'https://twitter.com/thenightlyau',
        },
        linkedin: {
            name: 'LinkedIn',
            link: 'https://www.linkedin.com/company/the-nightly/',
        },
        instagram: {
            name: 'Instagram',
            link: 'https://www.instagram.com/thenightlyau/',
        },
        youtube: {
            name: 'YouTube',
            link: 'https://youtube.com/@thenightlyau',
        },
    },
}
