
import { BreachBannerLayout, BreachWallContentType, BreachWallStyle } from "../helpers/BreachWallTypes"
import * as LaunchDarklyService from "./services/LaunchDarklyService"
import { TheWestSection } from "@news-mono/common"
import H from 'history'
import { BreachExperimentOne, BreachVanishingCousins } from "./tests/BreachScreenTests"
import { useEffect, useState } from "react"
import { Features, useFeature } from "@news-mono/web-common"

//-----------------------------------------------------------------------------

// Used to easily manouver between services
export type AbTestServiceType =
| 'launch-darkly'
| 'optimizely'

export interface AbTestExperiment {
    // Returns a custom id used for anything inside the services
    getId(): string
    // Returns the generated experiment ID used to send variation data.
    getExperimentId(): string
    // Returns the feature toggle id used to determine if there is an AB Test Active.
    getFeatureToggleId(): Features
    // Used to determine which service type to use to handle variant distribution.
    getServiceType(): AbTestServiceType
    // Gets the maximum variants for the experiment (including the base)
    getVariations(): number
    // Returns the BreachStyle that the experiment is using
    getBreachStyle(variant: number): BreachWallStyle

    // Returns the section requirements
    getWhitelistedExperimentSections(): TheWestSection[] | undefined
    // Returns the location requirements
    getWhitelistedExperimentLocations(): string[] | undefined

    /**
     * Return the appropriate variation breach wall config.
     */
    getBreachWallConfig(variant: number): { creativeName: string, deal: string, type: BreachWallContentType }

    /**
     * Return the breach wall banner config, if present.
     */
    getBreachBannerConfig(variant: number): BreachBannerLayout

    /**
     * Return a number (or -1) based on the provided BreachWallContentType
     */
    getVariantFromType(type: BreachWallContentType): number
}

//-----------------------------------------------------------------------------

// Stores the active experiment, for ease of determination in the future.
//   This is the location path variables, to make it easier to
//   to find a location specific breach in the future.
// NOTE:
//   global experiment is saved under __global__
const storedExperiments = new Map<string, AbTestExperiment>()
// Key used for storing in the map above
const globalExperimentKey = '__global__'

//-----------------------------------------------------------------------------

// Stores all active experiments...
//   This doesn't determine if they're on or off, that
//   is determined from the LaunchDarkly Toggle.
const experiments: AbTestExperiment[] = [];

// Stores all registered testing services...
//   If they're in this list, then they will be used when
//   an experiment references the service.
const testingServices: Map<AbTestServiceType, { useVariantForExperiment(experiment?: AbTestExperiment): number | undefined }> = new Map()

// Used to determine if the above fields have been
// populated...
let registered = false

//-----------------------------------------------------------------------------

export function checkExperimentRequirements(experiment: AbTestExperiment, location: H.Location, section: TheWestSection): boolean {
    // check against sections, if it's undefined it has no section requirements...
    const whitelistedSections = experiment.getWhitelistedExperimentSections()
    if(typeof(whitelistedSections) !== 'undefined' && whitelistedSections.find(sec => sec === section) === undefined) {
        return false
    }

    // check against location paths, if it's undefined it has no location requirements...
    const whitelistedLocations = experiment.getWhitelistedExperimentLocations()
    if(typeof(whitelistedLocations) !== 'undefined') {
        const pathName = location.pathname

        if(whitelistedLocations.find(path => pathName.includes(path)) === undefined) {
            return false
        }

        // Store via the location paths for future checking
        storedExperiments.set(pathName, experiment)
    } else {
        // No location checks, which means it's true - so store as a global experiment
        storedExperiments.set(globalExperimentKey, experiment)
    }

    return true
}

/**
 * Return a stored experiment based on the location, or undefined if
 * unable to find.
 */
export function getStoredExperiment(location?: H.Location): AbTestExperiment | undefined {
    if (typeof(location) !== 'undefined') {
        const locationExperiment = storedExperiments.get(location.pathname)
        if(typeof(locationExperiment) !== 'undefined') {
            return locationExperiment
        }
    }


    const globalExperiment = storedExperiments.get(globalExperimentKey)
    if(typeof(globalExperiment) !== 'undefined') {
        return globalExperiment
    }

    return undefined
}

/**
 * Returns a list of all experiments for external LaunchDarkly toggle
 * switch checking. This is due to the fact you cannot check a feature
 * state from outside of a React Component.
 */
export function useActiveExperiment(section: TheWestSection, location: H.Location, isAbTest: boolean): AbTestExperiment | undefined {
    useRegisterVariables(isAbTest)
    let experiment = undefined

    // no ab test is active, so don't return an experiment
    if(!isAbTest) {
        return experiment
    }

    // check if there is a stored experiment at this path. That would mean
    // the user has viewed this page before and got the same breach screen.
    const storedExperiment = getStoredExperiment(location)

    // if there is a stored experiment, use that!
    if(typeof(storedExperiment) !== 'undefined') {
        experiment = storedExperiment
    } else {
        // if there is experiments to use, then set the first one
        if (experiments.length > 0) {
            for (let index = 0; index < experiments.length; index++) {
                const element = experiments[index];

                if (checkExperimentRequirements(element, location, section)) {
                    experiment = element
                    break
                }
            }
        }
    }

    return experiment
}

/**
 * Returns the distributed variant based on the provided experiment, or the default
 * variant if something goes wrong.
 */
export function useVariantId(experiment?: AbTestExperiment): number | undefined {
    const defaultVariant = getDefaultVariant()
    const proceed = experiment !== undefined
    const serviceType = proceed ? experiment.getServiceType() : 'launch-darkly'
    const service = testingServices.get(serviceType)
    const variant = service?.useVariantForExperiment(experiment)

    // if experiment wasn't found, return default
    if (!proceed) {
        return defaultVariant
    }

    // if service wasn't found, return default
    if (service === undefined || typeof(service) === 'undefined') {
        return defaultVariant
    }

    return variant
}

/**
 * Returns a hardcoded default variant used as a fail-safe.
 */

export function getDefaultVariant(): number {
    return 0;
}

//-----------------------------------------------------------------------------

function useRegisterVariables(isAbTest: boolean) {
    const processExperiments = !registered && isAbTest

    // SET ALL EXPERIMENTS BELOW THIS LINE:
    useRegisterExperiment(BreachExperimentOne, processExperiments)
    useRegisterExperiment(BreachVanishingCousins, processExperiments)

    if(!processExperiments) return
    registered = true

    // SET ALL SERVICES BELOW THIS LINE:
    registerService('launch-darkly', { useVariantForExperiment(experiment) { return LaunchDarklyService.useVariantForExperiment(experiment) }})
}

function useRegisterExperiment(experiment: AbTestExperiment, process: boolean) {
    const feature = useFeature(experiment.getFeatureToggleId())

    if(feature && process) {
        experiments.push(experiment)
    }
}

/**
 * Used for lazy service implementation :)
 */
function registerService(service: AbTestServiceType, consumer: { useVariantForExperiment(experiment: AbTestExperiment): number | undefined }) {
    testingServices.set(service, consumer)
}
