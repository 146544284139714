import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledAd = styled('div')({
    display: 'none',

    [breakpoint('sm')]: {
        background: colors.thewest.greyErmine,
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        height: 116, // stops jank
        /** leaderboardBillboard ad unit on The West has paddingBottom applied when
         *  ad unit is displaying leaderboard size
         */
        paddingBottom: calcRem(13),

        '&::after': {
            content: `''`,
            boxShadow: `0 ${calcRem(1)} ${calcRem(16)} 0 ${colors.black35}`,
            height: calcRem(10),
            position: 'absolute',
            bottom: -10,
            opacity: 1,
            width: '100%',
        },
    },

    /** remove padding when we're displaying the billboard ad */
    '@media screen and (min-width: 970px )': {
        paddingBottom: 0,
    },
})
