import React from 'react'
import { StyledTNInfoCard } from './TNInfoCard.styled'

export const TNInfoCard: React.FC = () => {
    return (
        <StyledTNInfoCard>
            <h2>The news doesn't stop at night.</h2>
            <p>
                The Nightly is Australia's first evening news curation within a
                digital paper.
                <br />
                Rise above the noise of the daily news cycle.
            </p>
            {/* Todo: Link TBD */}
            <a href="#" target="_blank">
                {"Read Editor's note ->"}
            </a>
        </StyledTNInfoCard>
    )
}
