import * as React from 'react'
import { SVGProps } from 'react'
const TheNightlyLogo = (props: SVGProps<SVGSVGElement>) => {
    const { fill } = props
    return (
        <svg
            {...props}
            viewBox="0 0 80 80"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <title>The Nightly</title>
            <path
                fill={fill ?? '#fff'}
                d="M74.6136 80V0H52.8997V41.875H52.2748L23.8437 0H5.4104V54H27.1243V37.9688H27.5929L56.4926 80H74.6136Z"
            />
            <path
                fill={fill ?? '#fff'}
                d="M16.1728 80C10.2231 80 5.3999 75.1758 5.3999 69.2249C5.3999 63.2741 10.2231 58.45 16.1728 58.45C22.1224 58.45 26.9456 63.2741 26.9456 69.225C26.9456 75.1758 22.1224 80 16.1728 80Z"
            />
        </svg>
    )
}
export default TheNightlyLogo
