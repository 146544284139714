import styled, { StyledComponent } from '@emotion/styled'
import { calcRem, breakpoint, fonts } from '../../../__styling'

export const StyledNightlyEditionContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: calcRem(0, 32, 20, 32),
    width: '100%',

    [breakpoint('sm')]: {
        paddingBottom: calcRem(80),
    },
}))

export const StyledNightlyEditionContent = styled('div')(({ theme }) => ({
    display: 'grid',
    alignItems: 'center',
    padding: 0,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gridRowGap: calcRem(10),
    gridColumnGap: calcRem(64),

    [breakpoint('sm')]: {
        padding: `0 ${calcRem(64)} 0 ${calcRem(64)}`,
        alignSelf: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr auto 1fr',
        gridRowGap: calcRem(32),
    },
}))

export const StyledNightlyEditionPicture = styled('picture')(({ theme }) => ({
    justifyContent: 'end',
    gridArea: 'picture',
    gridRow: '2 / span 1',
    gridColumn: '1 / span 1',

    img: {
        width: '100%',
        border: `solid 1px ${theme.colors.borders.primary}`,
        borderRadius: calcRem(7),
    },
    [breakpoint('sm')]: {
        gridRow: '1 / span 3',
    },
}))

const GetStyledNightlyEditionHeading = (headingType: 'h1' | 'h2') =>
    styled(headingType)(() => ({
        margin: 0,
        fontFamily: fonts.thenightly.sansSerif,
        fontSize: calcRem(40),
        fontWeight: 700,
        letterSpacing: '-1.6px',
        textTransform: 'capitalize',
        lineHeight: calcRem(48),
        display: 'block',
        gridArea: 'heading',
        gridRow: '1 / span 1',
        gridColumn: '1 / span 1',
        paddingBottom: calcRem(12),

        [breakpoint('sm')]: {
            lineHeight: calcRem(48),
            fontSize: calcRem(45),
            paddingBottom: 0,
            gridRow: '1 / span 1',
            gridColumn: '2 / span 1',
        },
        [breakpoint('lg')]: {
            lineHeight: calcRem(56),
            fontSize: calcRem(51),
        },

        transition: 'font-size 0.25s, line-height 0.25s',
    }))
export const StyledNightlyEditionLandingPageHeading =
    GetStyledNightlyEditionHeading('h1')

export const StyledNightlyEditionHeading = GetStyledNightlyEditionHeading('h2')

export const StyledNightlyEditionTextContainer = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: calcRem(8),
    gridArea: 'subheading',
    gridRow: '3 / span 1',
    gridColumn: '1 / span 1',

    [breakpoint('sm')]: {
        gridRow: '2 / span 1',
        gridColumn: '2 / span 1',
    },
}))

export const StyledNightlyEditionSubHeading = styled('h3')(() => ({
    margin: 0,
    fontFamily: fonts.thenightly.sansSerif,
    fontSize: calcRem(20),
    fontWeight: 700,
    lineHeight: calcRem(24),
    letterSpacing: '-0.22px',

    span: {
        display: 'inline-block',
    },

    [breakpoint('sm')]: {
        fontSize: calcRem(22),
        lineHeight: calcRem(32),
    },
    [breakpoint('lg')]: {
        fontSize: calcRem(25),
        lineHeight: calcRem(32),
    },
    transition: 'font-size 0.25s, line-height 0.25s',
}))

export const StyledNightlyEditionButtonContainer = styled('div')(() => ({
    gridArea: 'buttonarea',
    gridRow: '4 / span 1',
    gridColumn: '1 / span 1',
    alignSelf: 'self-start',
    display: 'grid',
    gap: calcRem(8),

    [breakpoint('sm')]: {
        gridRow: '3 / span 1',
        gridColumn: '2 / span 1',
        width: '60%',
    },
    [breakpoint('lg')]: {
        gridRow: '3 / span 1',
        gridColumn: '2 / span 1',
        width: '40%',
    },
}))

export const StyledNightlyEditionTeaser = styled('p')(() => ({
    margin: 0,

    fontFamily: fonts.thenightly.serif,
    fontSize: calcRem(20),
    fontWeight: 400,
    lineHeight: calcRem(30),
}))

export const Divider = styled.span(({ theme }) => {
    return {
        display: 'block',
        height: calcRem(1),
        width: '100%',
        background: theme.colors.borders.secondary,
    }
})
