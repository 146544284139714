import React from 'react'
import {
    TheNightlyHeaderLoginStateProps,
    TheNightlyLoginModalButtonProps,
} from './TheNightlyHeaderLoginModal'
import {
    StyledIconCrossContainer,
    StyledInformationHeader,
    StyledInformationSecondaryText,
} from './TheNightlyHeaderLoginModal.styled'
import StyledIconExitCross from './components/StyledIconExitCross'
import { tokens } from '@news-mono/design-tokens'
import {
    StyledContainer,
    StyledWardenButton,
} from '../../user-registration/warden'

// The Logged Out state of the the top information section. While logged out this is to
// display an incentive to subscribe.
export const LoggedOutInformationContainer: React.FC<TheNightlyHeaderLoginStateProps> =
    (props) => {
        const { hide } = props

        return (
            <>
                <StyledContainer
                    marginBottom={8}
                    addPadding={false}
                    flexDirection={'row'}
                >
                    <StyledInformationHeader>Hi there!</StyledInformationHeader>
                    <StyledIconCrossContainer onClick={hide}>
                        <StyledIconExitCross />
                    </StyledIconCrossContainer>
                </StyledContainer>
                <StyledInformationSecondaryText>
                    Log in or create a free acount.
                </StyledInformationSecondaryText>
            </>
        )
    }

// The Logged Out state of the middle section (buttons). While the user is logged out this
// is to display a "Log In" and a "Create Free Account" button.
export const LoggedOutButtons: React.FC<TheNightlyLoginModalButtonProps> = (
    props,
) => (
    <>
        <StyledWardenButton
            id="login-modal-log-in-button"
            onClick={props.onLoginClick}
            useBorder={true}
            roundEdges={true}
            marginBottom={12}
        >
            LOG IN
        </StyledWardenButton>
        <StyledWardenButton
            id="login-modal-create-account-button"
            onClick={props.onCreateAccountClick}
            backgroundColors={{
                default: tokens.thenightly.colors.palette.utility.error.default,
                hover: tokens.thenightly.colors.palette.utility.error.hover,
            }}
            roundEdges={true}
        >
            CREATE FREE ACCOUNT
        </StyledWardenButton>
    </>
)
