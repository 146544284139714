import {
    CollectionDataLoaded,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React, { useContext, useEffect } from 'react'
import {
    TopicSectionArticleCardList,
    TopicSectionArticleCardListProps,
} from './TopicSectionArticleCardList'
import { CollectionDataContext } from '../../../contexts/CollectionDataContext/CollectionDataContext'
import { useProduct } from '../../../__product'
import { MaybeLoaded } from 'json-react-layouts-data-loader'

export const TopicSectionArticleCardListRegistration =
    createRegisterableComponentWithData(
        'topic-section-card-list',
        ContentDataDefinitionLoader,
        (
            {
                expectedItemCount,
                adUnits,
                adTargeting,
            }: Omit<
                TopicSectionArticleCardListProps,
                'items' | 'onEvent' | 'api' | 'adState' | 'toggles'
            >,
            { dataDefinitionArgs, ...initialData },
            { onEvent, adState, config, store },
        ) => {
            const contextData = useContext(CollectionDataContext)
            const product = useProduct()
            const toggles = store.getState().toggles

            const data: MaybeLoaded<CollectionDataLoaded> =
                dataDefinitionArgs.type === 'context'
                    ? contextData.forId(dataDefinitionArgs.contextId)
                          .collectionData
                    : { loaded: false }

            const fireViewEvent = (data: MaybeLoaded<CollectionDataLoaded>) => {
                if (data.loaded) {
                    onEvent(
                        createCollectionAvailableEvent(
                            data.result.publications,
                            'TopicSectionArticleCardList',
                            product,
                            onEvent,
                        ),
                    )
                }
            }

            useEffect(() => {
                if (data.loaded) {
                    fireViewEvent(data)
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [data])

            return (
                <TopicSectionArticleCardList
                    items={
                        data.loaded
                            ? {
                                  loaded: true,
                                  result: data.result.publications,
                              }
                            : { loaded: false }
                    }
                    adUnits={adUnits}
                    expectedItemCount={expectedItemCount}
                    onEvent={onEvent}
                    api={config.contentApi}
                    adState={adState}
                    isStandalonePage={true}
                    adTargeting={adTargeting}
                    config={config}
                    toggles={toggles}
                />
            )
        },
    )
