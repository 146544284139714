import { createRenderTarget } from '../../render-target'
import { TheNightlyHeaderWeb } from './TheNightlyHeader.web'

export interface TheNightlyHeaderProps {
    onEvent: (event: any) => void
    isHome?: boolean
    className?: string
}

export const TheNightlyHeader = createRenderTarget('TheNightlyHeader', {
    web: TheNightlyHeaderWeb,
    amp: TheNightlyHeaderWeb,
    rss: TheNightlyHeaderWeb,
    preview: TheNightlyHeaderWeb,
    app: TheNightlyHeaderWeb,
})
TheNightlyHeader.displayName = 'TheNightlyHeader'
