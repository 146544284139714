import styled from '@emotion/styled'
import { colors as nightlyColors } from '../../../../../design-tokens/src/thenightly/colors'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import { BreakpointKeys, metrics } from '../../../__styling'
import { IconChevronUpTN } from '../../../icons'

const { white } = tokens.thenightly.colors.palette
export const StyledNavWrapperClass = 'stylednavwrapper'

export const SectionContainer = styled.div<{
    showBorder?: boolean
    hideAtBreakpoint?: BreakpointKeys
}>(({ showBorder, hideAtBreakpoint }) => [
    { display: 'flex', flexDirection: 'column', gap: calcRem(16) },

    hideAtBreakpoint && {
        [breakpoint(hideAtBreakpoint)]: {
            display: 'none',
        },
    },

    showBorder && {
        [breakpoint('lg')]: {
            borderRight: '0.5px solid',
            borderColor: nightlyColors.palette.starlight.default,
        },
    },
])

export const StyledButton = styled.button({
    // reset button styles.
    border: 'none',
    padding: 0,
    background: 'transparent',
    textAlign: 'left',

    display: 'flex',
    flexDirection: 'column',
    color: nightlyColors.palette.neutral[50],
    cursor: 'pointer',
    '&.no-selection': {
        color: white,
    },
    '&.selected': {
        color: nightlyColors.palette.starlight.default,
    },
    transition: 'color 0.25s',
})

export const StyledSectionItem = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: calcRem(16, 0),

    transition: 'padding 0.25s',

    [breakpoint('lg')]: {
        padding: calcRem(2, 0, 0, 0),
    },
})

export interface StyledIconContainerProps {
    selected: boolean
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>(
    ({ selected }) => ({
        width: calcRem(24),
        height: calcRem(24),
        marginRight: calcRem(32),
        marginLeft: calcRem(0),
        transform: 'translate(0px, 2px)', // fix alignment issue
        rotate: selected ? 'none' : '180deg',
        transition: 'rotate 0.25s, margin 0.25s',
        [breakpoint('lg')]: {
            rotate: '90deg',
            marginRight: selected ? calcRem(8) : calcRem(24),
        },
    }),
)

export const StyledExpanderIcon = styled(IconChevronUpTN)({})
