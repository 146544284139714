import { layout } from '../../App.routing'

export const latestEditionSegment = layout.component({
    type: 'nightly-latest-edition',
    props: {
        dataDefinitionArgs: {
            type: 'edition',
            page: 1,
            pageSize: 1,
        },
        title: 'Latest Edition',
        type: 'homepage',
        lazyloadImages: true,
    },
})

export const previousEditionsSegment = layout.component({
    type: 'nightly-previous-editions',
    props: {
        dataDefinitionArgs: {
            type: 'edition',
            page: 1,
            pageSize: 5,
        },
        title: 'All Editions',
        type: 'homepage',
        lazyloadImages: true,
    },
})
