import React from 'react'
import {
    StyledPublicationItemSkeleton,
    StyledSkeletonMedia,
    StyledSkeletonText,
    StyledSkeletonByLine,
    StyledPublicationListSkeleton,
} from './PublicationListSkeleton.styled'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { Skeleton } from '../../content'
import { toArray } from '@news-mono/web-common'

export interface PublicationListSkeletonProps extends ResponsiveContainer {
    count?: number
    dark?: boolean
    textLines?: number
    overrideWidth?: number
    overrideHeight?: number
}

export const PublicationListSkeleton: React.FC<PublicationListSkeletonProps> =
    ({ count = 1, textLines, overrideHeight, overrideWidth, dark = false }) => {
        const array = toArray(count)

        return (
            <StyledPublicationListSkeleton>
                {array.map((_: any, i: number) => (
                    <StyledPublicationItemSkeleton
                        key={i}
                        overrideHeight={overrideHeight}
                        overrideWidth={overrideWidth}
                    >
                        <StyledSkeletonMedia>
                            <Skeleton count={1} dark={dark} />
                        </StyledSkeletonMedia>
                        <StyledSkeletonText>
                            <Skeleton
                                count={textLines || 2}
                                height={23}
                                dark={dark}
                            />
                            <StyledSkeletonByLine>
                                <Skeleton count={1} dark={dark} />
                            </StyledSkeletonByLine>
                            <StyledSkeletonByLine>
                                <Skeleton count={1} dark={dark} />
                            </StyledSkeletonByLine>
                        </StyledSkeletonText>
                    </StyledPublicationItemSkeleton>
                ))}
            </StyledPublicationListSkeleton>
        )
    }
