import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { SpecialEdition, SpecialEditionProps } from './SpecialEdition'

export const NightlySpecialEditionRegistration = createRegisterableComponent(
    'nightly-special-edition',
    (props: SpecialEditionProps) => {
        return <SpecialEdition {...props} />
    },
)
