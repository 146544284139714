import { getAuth0UpdateAppMetaActionPath } from '@news-mono/web-common'

export const SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME = 'signedUpToNewsletter'
export const OPTED_IN_NEWSLETTER_COOKIE_NAME = 'optInNewsletter'

export const updateUserMetadata = async (
    userId: string,
    newsletterOptIn?: boolean,
    newsletterSignup?: boolean,
) => {
    const response = await fetch(getAuth0UpdateAppMetaActionPath, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: userId,
            newsletterOptIn: newsletterOptIn,
            newsletterSignup: newsletterSignup,
        }),
    })

    if (!response.ok || response.status !== 201) {
        throw new Error('Error updating app metadata')
    }

    return
}

export type NewsletterListItem = {
    listId: string
    listName: string
}

const tnOptInList = [
    {
        listId: 'thenightly_newsletter-news',
        listName: 'thenightly_newsletter',
    },
] as NewsletterListItem[]

export const newsletterSubscribe = async (
    token: string,
    userEmail: string | undefined,
    userName: string | undefined,
) => {
    console.debug('Calling newsletter subscription')

    return await fetch('/newsletter-subscribe', {
        method: 'POST',
        body: JSON.stringify({
            token,
            email: userEmail,
            firstName: userName ?? '',
            lastName: '',
            optOutLists: tnOptInList.map((item) => item.listId),
            requestToken: true,
            createContactOnly: true,
            source: 'The Nightly Newsletter Registration',
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
