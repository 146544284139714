import { paginationProps } from '../useNotificationCentre'
import { ContentDataTypes } from '@news-mono/web-common'

export const getNotificationCentreEventContext = (
    notificationCount: number,
) => ({
    timestamp: Date.now(),
    componentType: 'notification-centre',
    componentRenderPath: '',
    collectionCardCount: notificationCount,
    dataDefinition: {
        type: 'notification-centre',
        total: notificationCount,
        pageSize: paginationProps.pageSize,
        paging: paginationProps,
    } as ContentDataTypes,
})
