import {
    NavEventArgs,
    NavItem as WebNavItem,
    WebLink,
} from '@news-mono/web-common'
import React from 'react'
import { StyledNavItem } from './NavItem.styled'

export interface NavItemProps {
    webNavItem: WebNavItem
    navPos: number
    onClick?: (args: NavEventArgs) => void
}

export const NavItem: React.FC<NavItemProps> = ({
    webNavItem,
    onClick,
    navPos,
}) => {
    return (
        <StyledNavItem
            role="menuitem"
            onClick={() => {
                onClick &&
                    onClick({
                        navPos,
                        navText: webNavItem.name,
                        navLink: webNavItem.link,
                        navParent: parent.name,
                    })
            }}
        >
            <WebLink
                to={webNavItem.link}
                forceInternalSSR={webNavItem.forceInternalSSR}
            >
                {webNavItem.name}
            </WebLink>
        </StyledNavItem>
    )
}

NavItem.displayName = 'TNFlyoutNavItem'
