import styled, { CSSObject } from '@emotion/styled'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { calcRem, getComponentTheme, Theme } from '../../../__styling'
import { DefaultButtonProps } from './DefaultButton'

import { WebLink } from '@news-mono/web-common'
import { interactionSwitch } from '../../../__styling/style-functions/interactionState'

type ButtonStyleProps = Pick<DefaultButtonProps, 'color' | 'fill' | 'layout'>

export const getButtonStyles = ({
    theme,
    color,
    fill,
    layout,
}: ButtonStyleProps & { theme: Theme }) => {
    const buttonTheme = getComponentTheme(theme, 'buttonTheme')
    const styles: CSSObject = {
        display: 'flex',
        flexGrow: layout?.shouldGrow ? 1 : 0,
        alignSelf: layout?.alignSelf ?? 'center',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: buttonTheme.borderRadius,
        gap: buttonTheme.gap,
        padding: buttonTheme.padding,
        cursor: 'pointer',
        // Remove default border.
        borderStyle: 'none',
        borderWidth: 0,

        ':disabled': {
            cursor: 'not-allowed',
        },
    }
    return [
        styles,
        // ! Disabled does nothing for link buttons ! Violates HTML Spec.
        interactionSwitch(
            buttonTheme[color][fill].interactionState,
            ({ background, textColor, border }) => ({
                background,
                color: textColor,
                outlineStyle: border?.style,
                outlineColor: border?.color,
                outlineWidth: calcRem(border?.width ?? 0),
                // Inset the outline.
                outlineOffset: calcRem(-(border?.width ?? 0)),
            }),
        ),
        textMixin(theme, buttonTheme.textType),
    ]
}

export const StyledButton = styled.button<ButtonStyleProps>((props) => [
    {
        // Reset button styles.
        padding: 0,
        border: 'none',
        background: 'transparent',
    },
    getButtonStyles(props),
])

export const StyledButtonLink = styled(WebLink)<ButtonStyleProps>((props) => [
    {
        // Reset anchor styles.
        color: 'inherit',
        textDecoration: 'none',
    },
    getButtonStyles(props),
])
