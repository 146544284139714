import { NavReducerState } from './reducer/reducer'
import { NavDisplayState } from '@news-mono/component-library'

export const defaultState: NavReducerState = {
    navDisplayState: 'header',
    activeContentArea: 0,
    contentNavMenuItems: [],
    scrollDirection: 'scroll-down',
    highlightDirection: 'right',
    previousVerticalScrollPosition: 0,
    isSystemScroll: false,
    adHeight: 0,
    bannerHeight: 0,
    contentNavHeight: 0,
    isMobile: false,
    isMobileEnabled: false,
    isDesktopEnabled: false,
    isContentNavRoute: false,
}

export const SCROLL_BEHAVIOUR = 'auto'

export const headerVisibleStates: NavDisplayState[] = [
    'header-with-ad',
    'header',
    'content-nav-with-header',
]

export const contentNavVisibleStates: NavDisplayState[] = [
    'content-nav',
    'content-nav-with-header',
]

export const MOBILE_SMALL_OFFSET = 12
export const MOBILE_LARGE_OFFSET = 120
export const DESKTOP_OFFSET = 80

// Routes to show the content nav
export const ALLOWED_CONTENT_NAV_ROUTES = ['/']

// When we unmount the Nav in the animation, it causes a scroll event. These are predictable,
// so we ignore ones that align to the below numbers.
export const CONTENT_NAV_UNMOUNT_SCROLL_VELOCITY = 176
export const NO_VELOCITY_EVENT = 0

export const UNMOUNT_SCROLL_VELOCITIES = [
    CONTENT_NAV_UNMOUNT_SCROLL_VELOCITY,
    NO_VELOCITY_EVENT,
]
