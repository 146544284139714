import { NavEventArgs } from '@news-mono/web-common'
import React from 'react'
import { StyledLinkGroup } from '../TNFlyoutNav.styled'
import { TheNightlyNavItem } from '../../../routing'
import { NavSectionLabel } from './NavSectionLabel'
import { NavSectionOptions } from './NavSectionOptions'
import { BreakpointKeys } from '../../../__styling'
import { TheNightlySection } from '@news-mono/common'
import {
    SectionContainer,
    StyledButton,
    StyledIconContainer,
    StyledSectionItem,
    StyledExpanderIcon,
} from './NavSection.styled'

export interface NavSectionProps {
    sectionHeadings: TheNightlyNavItem[]
    currentSection: TheNightlySection | null
    onSectionClick: (args: TheNightlyNavItem) => void
    onTopicClick: (args: NavEventArgs) => void
    hideAtBreakpoint?: BreakpointKeys
}

export const NavSection: React.FC<NavSectionProps> = ({
    sectionHeadings,
    currentSection,
    onSectionClick,
    onTopicClick,
    hideAtBreakpoint,
}) => {
    return (
        <SectionContainer
            showBorder={currentSection !== null}
            hideAtBreakpoint={hideAtBreakpoint}
        >
            <p>Topics</p>
            <StyledLinkGroup role="menu">
                {sectionHeadings
                    .filter((item) => !item.hideFromFlyoutMenu)
                    .map((navItem: TheNightlyNavItem, index: number) => {
                        const isSelected = currentSection === navItem.section

                        return (
                            <li role="menuitem" key={`topics-${navItem.name}`}>
                                <StyledButton
                                    onClick={() => onSectionClick(navItem)}
                                    className={
                                        currentSection !== null
                                            ? isSelected
                                                ? 'selected'
                                                : ''
                                            : 'no-selection'
                                    }
                                    key={index}
                                    aria-expanded={isSelected}
                                    aria-controls={`section-nav-links-${navItem.name}`}
                                    aria-haspopup
                                >
                                    <StyledSectionItem>
                                        <NavSectionLabel
                                            navItem={navItem}
                                            navPos={index}
                                        />
                                        <StyledIconContainer
                                            selected={isSelected}
                                        >
                                            <StyledExpanderIcon />
                                        </StyledIconContainer>
                                    </StyledSectionItem>
                                    <NavSectionOptions
                                        name={navItem.name.toLocaleLowerCase()}
                                        sectionItems={navItem.subNavLinks ?? []}
                                        onClick={onTopicClick}
                                        hideAtBreakpoint={'lg'}
                                        isOpen={isSelected}
                                    />
                                </StyledButton>
                            </li>
                        )
                    })}
            </StyledLinkGroup>
        </SectionContainer>
    )
}

NavSection.displayName = 'TNFlyoutNavSection'
