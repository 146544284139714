import styled from '@emotion/styled'
import { TheWestPalette, tokens } from '@news-mono/design-tokens'
import { colors } from '../../../../../__styling/settings/colors'
import { fonts } from '../../../../../__styling/settings/fonts'
import {
    breakpoint,
    breakpointMax,
} from '../../../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'

export const StyledWestRewardsCardsContainer = styled('div')({
    display: 'none',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    [breakpoint('xxs')]: {
        display: 'flex',
    },
})

export const StyledWestRewardsCardWrapper = styled('div')({
    position: 'relative',
    marginBottom: calcRem(40),
    flex: '1 1 0',
    minWidth: `max(50%, ${calcRem(350)})`,
    maxWidth: calcRem(450),
})

export const StyledWestRewardsMobileCarouselContainer = styled('div')({
    display: 'flex',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    margin: 'auto',

    [breakpoint('xxs')]: {
        display: 'none',
    },
})

export const StyledWestRewardsMobileCardWrapper = styled('div')({
    flexShrink: 0,
    scrollSnapAlign: 'center',
    width: '100%',
    padding: calcRem(5),
})

export const StyledWestRewardsCardIconWrapper = styled('div')({
    width: calcRem(150),
    margin: 'auto',
    position: 'relative',

    [breakpoint('xxs')]: {
        margin: calcRem(0, 16),
    },
})

export const StyledWestRewardsCardTextWrapper = styled('div')({
    borderRadius: calcRem(1),
    position: 'relative',
    textAlign: 'center',
    padding: 0,

    [breakpoint('xxs')]: {
        borderLeft: '2px solid #B5B9BA',
        textAlign: 'revert',
        padding: calcRem(0, 16),
    },
})

export const StyledWestRewardsCardTitle = styled.h3(
    {
        fontWeight: 700,
        fontSize: calcRem(32),
        textTransform: 'uppercase',
        lineHeight: 1,
        marginBottom: '1rem',

        [breakpoint('xxs')]: {
            marginTop: 'revert',
        },
    },
    ({ theme }) => ({
        fontFamily: theme?.fonts?.sansSerifCond || fonts.thewest.sansSerifCond,
        color:
            (theme?.colors.palette as TheWestPalette)?.greyElephant ||
            colors.thewest.greyElephant,
    }),
)

export const StyledWestRewardsCardDescription = styled('p')(
    {
        fontWeight: 400,
        fontSize: calcRem(16),
    },
    ({ theme }) => ({
        fontFamily: theme?.fonts.serif || fonts.thewest.serif,
        color: theme?.colors.text.primary || colors.black,
    }),
)

export const StyledWestRewardsCardLink = styled('a')(
    {
        position: 'relative',
        bottom: 0,
        fontWeight: 400,
        fontSize: calcRem(16),
        textDecorationLine: 'underline',
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        a: {
            color: 'inherit',
            '&:hover, &:active, &:focus': {
                color: 'inherit',
            },
        },
    },
    ({ theme }) => ({
        fontFamily: theme?.fonts.serif || fonts.thewest.serif,
        color:
            (theme?.colors.palette as TheWestPalette)?.silver ||
            tokens.thewest.colors.palette.silver,
    }),
)

export const StyledCarouselEmptyDot = styled('span')(
    {
        height: calcRem(12),
        width: calcRem(12),
        border: '2px solid',
        borderRadius: '50%',
        display: 'inline-block',
    },
    ({ theme }) => ({
        borderColor:
            theme?.colors?.brand || tokens.thewest.colors.palette.westblue,
    }),
)

export const StyledCarouselFullDot = styled(StyledCarouselEmptyDot)(
    ({ theme }) => ({
        backgroundColor:
            theme?.colors?.brand || tokens.thewest.colors.palette.westblue,
    }),
)

export const StyledCarouselDotsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '30%',
    margin: 'auto',
    position: 'relative',
    top: calcRem(170),

    [breakpoint('xxs')]: {
        display: 'none',
    },
})

export const StyledWestRewardsLogoGradientBackground = styled('div')(
    ({
        height,
        width,
    }: {
        height: number | string
        width: number | string
    }) => ({
        display: 'inline-block',
        padding: '0.25rem 0.5rem',
        background: 'linear-gradient(270deg, #DC01BE 0%, #AA01E1 100%);',
        borderRadius: '100rem',
        height,
        width,

        '& > svg': {
            verticalAlign: 'top',
        },
    }),
)
export const StyledWestRewardsCallToAction = styled('div')(
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '1rem',
        borderRadius: '0.75rem',
        border: '1px solid',
        fontSize: '1rem',
        margin: '2rem auto 1rem auto',
        maxWidth: calcRem(500),
        minWidth: calcRem(150),

        [breakpointMax('xxs')]: {
            margin: '2rem 1rem 1rem 1rem',
        },

        '& > svg': {
            display: 'block',
            marginBottom: '1rem',
            maxWidth: calcRem(200),
            height: 'auto',
        },
    },
    ({ theme }) => ({
        borderColor: theme.colors.borders.primary,
        '.mark': {
            color: theme.colors.brand,
        },
    }),
)
