import styled from '@emotion/styled'
import * as React from 'react'

export interface UserIconProps {
    dimension?: number
    fillColor?: string
    show?: boolean
}

export const UserIcon: React.FC<UserIconProps> = ({
    dimension = 24,
    fillColor = '#161616',
    show = false,
}) => (
    <StyledUserIcon
        width={dimension}
        height={dimension}
        viewBox={`0 0 ${dimension} ${dimension}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // Prop elements below here:
        show={show}
        dimension={dimension}
        fillColor={fillColor}
    >
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                fill={fillColor}
            />
            <path
                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                fill={fillColor}
            />
        </g>
    </StyledUserIcon>
)

const StyledUserIcon = styled('svg')<UserIconProps>((props) => ({
    display: props.show ? 'block' : 'none',
    cursor: props.show ? 'pointer' : undefined,
}))
