import React from 'react'
import {
    StyledTheNightlyDivHeaderWrapper,
    StyledTheNightlyHeader,
} from '../TheNightlyHeader/TheNightlyHeader.styled'

import { MastHeadLogo } from './logos/MastheadLogo'
import { TheNightlyHeaderProps } from './TheNightlyHeader'

export const TheNightlyHeaderWeb: React.FC<TheNightlyHeaderProps> = ({
    className,
    isHome,
}) => {
    return (
        <StyledTheNightlyDivHeaderWrapper as={isHome ? 'h1' : undefined}>
            <StyledTheNightlyHeader className={className}>
                <MastHeadLogo />
            </StyledTheNightlyHeader>
        </StyledTheNightlyDivHeaderWrapper>
    )
}
TheNightlyHeaderWeb.displayName = 'TheNightlyHeader'
