import { navReducer } from './reducer/reducer'
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import {
    encodeId,
    HighlightDirection,
    ProviderChildProps,
    ScrollProps,
} from '@news-mono/component-library'
import { useScroll, useIntersectionObserver, useAnalytics } from './hooks'
import { defaultState, SCROLL_BEHAVIOUR } from './constants'
import { NavEvent, useFeature } from '@news-mono/web-common'
import {
    getOffset,
    getScrollDirection,
    isAllowedContentNavRoute,
    isUnmountScrollEvent,
    verticalScrollTo,
} from './util'
import { useLocation } from 'react-router'
import { ContentNavMenuItem, NavDisplayState, ScrollDirection } from './types'
import { useViewport } from '../../../__helpers/use-viewport'
import { meetsThresholdToShowAd } from './reducer/util'

export interface TheNightlyHeaderNavExternalState {
    navDisplayState: NavDisplayState
    activeContentArea: number
    contentNavMenuItems: ContentNavMenuItem[]
    scrollDirection: ScrollDirection
    isMobile: boolean
    adHeight: number
    isSystemScroll: boolean
    highlightDirection: HighlightDirection
    contentNavHeight: number
    bannerHeight: number
}

export interface TheNightlyHeaderNavState
    extends TheNightlyHeaderNavExternalState {
    onMenuItemClick: (index: number) => void
    onHomeItemClick: () => void
    onHamburgerItemClick: () => void
    updateAdHeight: (height: number) => void
    updateContentNavHeight: (height: number) => void
    updateBannerHeight: (bannerHeight: number) => void
}

interface UseTheNightlyHeaderNavProps {
    onEvent: (event: NavEvent) => void
    flyOutProps?: ProviderChildProps
}

export const useTheNightlyHeaderNav = ({
    onEvent,
    flyOutProps,
}: UseTheNightlyHeaderNavProps): TheNightlyHeaderNavState => {
    const { pathname } = useLocation()
    const isContentNavRoute = isAllowedContentNavRoute(pathname)
    const mobileFeatureFlag = useFeature('content-nav-mobile')
    const desktopFeatureFlag = useFeature('content-nav-desktop')
    const { isMobile } = useViewport()

    const initialState = {
        ...defaultState,
        isMobile,
        isMobileEnabled: mobileFeatureFlag && isContentNavRoute,
        isDesktopEnabled: desktopFeatureFlag && isContentNavRoute,
        isContentNavRoute,
    }

    const [state, dispatch] = useReducer(navReducer, initialState)

    // Check if we should show ad on mount, once the adHeight has been established
    useEffect(() => {
        if (
            meetsThresholdToShowAd({ state, newScrollPosition: window.scrollY })
        ) {
            dispatch({
                type: 'set-nav-display-state',
                payload: {
                    navDisplayState: 'header-with-ad',
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.adHeight])

    // Reset header nav state on navigate
    useEffect(() => {
        dispatch({
            type: 'reset-state',
            payload: {
                newState: initialState,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const {
        fireScrollToTopEvent,
        fireOpenFlyoutNavEvent,
        fireContentNavMenuItemClickedEvent,
    } = useAnalytics({
        navState: state,
        onEvent,
    })

    if (isMobile !== state.isMobile) {
        dispatch({
            type: 'update-is-mobile',
            payload: {
                isMobile,
            },
        })
    }

    const handleUpdateBannerHeight = useCallback((bannerHeight: number) => {
        dispatch({
            type: 'update-banner-height',
            payload: {
                bannerHeight,
            },
        })
    }, [])

    const endContentAreaScroll = useCallback(
        (index: number, verticalScrollPosition: number) => {
            // Dispatch a scroll as occasionally the scroll event can fire after the verticalScrollTo
            // promise resolves. This might cause an unwanted menu state change on mobile devices.
            dispatch({
                type: 'scroll',
                payload: {
                    verticalScrollPosition,
                },
            })

            dispatch({
                type: 'set-content-area',
                payload: {
                    contentAreaIndex: index,
                },
            })

            dispatch({
                type: 'set-system-scroll',
                payload: {
                    isSystemScroll: false,
                },
            })
        },
        [],
    )

    const handleSetContentArea = useCallback(
        (index: number) => {
            const menuItem = state.contentNavMenuItems.find(
                (menuItem) => menuItem.index === index,
            )
            if (menuItem) {
                dispatch({
                    type: 'set-system-scroll',
                    payload: {
                        isSystemScroll: true,
                    },
                })

                dispatch({
                    type: 'set-scroll-direction',
                    payload: {
                        scrollDirection: getScrollDirection(
                            state.activeContentArea,
                            index,
                        ),
                    },
                })

                const offsetTop =
                    document.getElementById(encodeId(menuItem.heading))
                        ?.offsetTop || 0

                const scrollOffset =
                    offsetTop - getOffset(state.navDisplayState, state.isMobile)

                verticalScrollTo(scrollOffset)
                    .then(() => {
                        endContentAreaScroll(index, scrollOffset)
                    })
                    .catch(() => {
                        // This scenario is when the scroll is interrupted or cancelled.
                        // It is mainly an issue with smooth scrolling. For now, we will just
                        // set the same target index, but update the scroll position
                        // In the future, it might be worth doing a check to see what content
                        // area we are in, or even restarting the scroll.
                        endContentAreaScroll(index, window.scrollY)
                    })

                fireContentNavMenuItemClickedEvent(index)
            }
        },
        [
            endContentAreaScroll,
            fireContentNavMenuItemClickedEvent,
            state.activeContentArea,
            state.contentNavMenuItems,
            state.isMobile,
            state.navDisplayState,
        ],
    )

    const handleUpdateAdHeight = useCallback((height: number) => {
        dispatch({
            type: 'update-ad-height',
            payload: {
                adHeight: height,
            },
        })
    }, [])

    const handleUpdateContentNavHeight = useCallback((height: number) => {
        dispatch({
            type: 'update-content-nav-height',
            payload: {
                contentNavHeight: height,
            },
        })
    }, [])

    const handleIntersectingContentArea = useCallback((id: string) => {
        dispatch({
            type: 'intersect-content-area',
            payload: {
                contentAreaId: id,
            },
        })
    }, [])

    const handleExitedIntersection = useCallback(
        (id: string, isExitDirectionUp: boolean) => {
            dispatch({
                type: 'exiting-intersection',
                payload: {
                    contentAreaId: id,
                    isExitDirectionUp,
                },
            })
        },
        [],
    )

    const handleSetContentNavMenuItems = useCallback((nodes: Element[]) => {
        dispatch({
            type: 'set-content-nav-items',
            payload: {
                nodes,
            },
        })
    }, [])

    const handleOpenFlyout = useCallback(() => {
        if (!flyOutProps) {
            return undefined
        }

        if (flyOutProps.mainNavOpen) {
            flyOutProps.closeNavigation()
        } else {
            flyOutProps.openNavigation()
            fireOpenFlyoutNavEvent()
        }
    }, [fireOpenFlyoutNavEvent, flyOutProps])

    const handleScrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: SCROLL_BEHAVIOUR,
        })
        fireScrollToTopEvent()
    }, [fireScrollToTopEvent])

    const handleVerticalScroll = ({ velocity }: ScrollProps) => {
        if (isUnmountScrollEvent(velocity)) {
            return
        }

        dispatch({
            type: 'scroll',
            payload: {
                verticalScrollPosition: window.scrollY,
            },
        })
    }

    // Sets up scroll event listeners
    useScroll(handleVerticalScroll)

    // Sets up Intersection Observer
    useIntersectionObserver({
        pathname,
        setIntersectingCallback: handleIntersectingContentArea,
        setExitedIntersection: handleExitedIntersection,
        setMenuItemsCallback: handleSetContentNavMenuItems,
    })

    return useMemo(
        () => ({
            ...state,
            onMenuItemClick: handleSetContentArea,
            onHamburgerItemClick: handleOpenFlyout,
            onHomeItemClick: handleScrollToTop,
            updateAdHeight: handleUpdateAdHeight,
            updateContentNavHeight: handleUpdateContentNavHeight,
            updateBannerHeight: handleUpdateBannerHeight,
        }),
        [
            handleOpenFlyout,
            handleScrollToTop,
            handleSetContentArea,
            handleUpdateAdHeight,
            handleUpdateBannerHeight,
            handleUpdateContentNavHeight,
            state,
        ],
    )
}
