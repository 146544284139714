import styled from '@emotion/styled'
import { EditorialType, FundingType } from '../../../lib'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, metrics } from '../../../../../__styling'
import { Product } from '@news-mono/web-common'

export const StyledEditorialLabel = styled.div<{
    type: EditorialType | FundingType
}>(({ theme, type }) => [
    {
        paddingBottom: calcRem(8),
        paddingLeft: metrics.thenightly.margins.md,
        paddingRight: metrics.thenightly.margins.md,

        [breakpoint('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    // Check that it's on TheNightly and we're providing a container for the LIVE label
    theme.kind === Product.TheNightly &&
        type === EditorialType.Live && {
            display: 'flex',
            gap: calcRem(4),
            paddingBottom: calcRem(8),

            [breakpoint('sm')]: {
                paddingBottom: calcRem(16),
            },
        },
])

export const StyledEditorialLabelText = styled.span<{
    type: EditorialType | FundingType
}>(({ theme, type }) => ({
    textTransform: 'uppercase',
    font: theme.fonts.heading,
    fontSize: calcRem(18),
    fontWeight: 600,
    lineHeight: calcRem(24),
    letterSpacing: calcRem(-0.36),
    color:
        type === EditorialType.Breaking ||
        type === EditorialType.Live ||
        type === EditorialType.Exclusive
            ? tokens.thenightly.colors.palette.utility.error.default
            : undefined,
}))
