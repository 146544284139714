import React, { forwardRef, useRef } from 'react'
import {
    StyledNotificationCentreCardList,
    StyledNotificationCentreHeader,
    StyledNotificationCentreHeaderHeading,
    StyledNotificationCentreModalContainer,
    StyledNotificationCentreNoNotificationsContainer,
} from './NotificationCentreModal.styled'
import { NotificationCentreCloseIcon } from './NotificationCentreCloseIcon'
import { NotificationCentreListSection } from '../types'
import { NotificationCentreSectionList } from './NotificationCentreSectionList'
import { CollectionEvent } from '@news-mono/web-common'
import { getNotificationCentreCardInformation } from '../hooks/helpers'

interface NotificationCentreModalProps {
    isOpen: boolean
    notificationSections: NotificationCentreListSection[]
    closeModal: () => void
    onEvent: (event: CollectionEvent) => void
}

export const NotificationCentreModal = forwardRef<
    HTMLDivElement,
    NotificationCentreModalProps
>(({ isOpen, notificationSections, closeModal, onEvent }, ref) => {
    if (!isOpen) return null

    const hasNotifications =
        getNotificationCentreCardInformation(notificationSections).length > 0

    return (
        <StyledNotificationCentreModalContainer ref={ref}>
            <StyledNotificationCentreHeader>
                <StyledNotificationCentreHeaderHeading>
                    In case you missed it
                </StyledNotificationCentreHeaderHeading>
                <NotificationCentreCloseIcon onClick={closeModal} />
            </StyledNotificationCentreHeader>
            <StyledNotificationCentreCardList>
                {hasNotifications ? (
                    <NotificationCentreSectionList
                        notificationSections={notificationSections}
                        onEvent={onEvent}
                    />
                ) : (
                    <StyledNotificationCentreNoNotificationsContainer>
                        <span>There was an error fetching notifications.</span>
                        <span>Please try again later.</span>
                    </StyledNotificationCentreNoNotificationsContainer>
                )}
            </StyledNotificationCentreCardList>
        </StyledNotificationCentreModalContainer>
    )
})
