import styled from '@emotion/styled'
import { breakpointSwitch, getComponentTheme } from '../../__styling'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

export const StyledNotificationCentreModalContainer = styled.div(
    ({ theme }) => {
        const containerTheme = getComponentTheme(
            theme,
            'notificationCentre',
        ).container
        return [
            {
                display: containerTheme.display,
                flexDirection: containerTheme.flexDirection,
                backgroundColor: containerTheme.backgroundColor,
                overflow: containerTheme.overflow,
                zIndex: containerTheme.zIndex,
                position: containerTheme.position,
            },
            breakpointSwitch(
                containerTheme.breakpointState,
                ({
                    width,
                    height,
                    maxHeight,
                    borderRadius,
                    top,
                    boxShadow,
                    right,
                }) => ({
                    width,
                    height,
                    maxHeight,
                    borderRadius,
                    top,
                    boxShadow,
                    right,
                }),
            ),
        ]
    },
)

export const StyledNotificationCentreHeader = styled.div(({ theme }) => {
    const headerTheme = getComponentTheme(theme, 'notificationCentre').header

    return [
        {
            display: headerTheme.display,
            flexDirection: headerTheme.flexDirection,
            justifyContent: headerTheme.justifyContent,
            alignItems: headerTheme.alignItems,
            backgroundColor: headerTheme.backgroundColor,
            zIndex: headerTheme.zIndex,
            width: headerTheme.width,

            padding: headerTheme.padding,
            borderBottom: headerTheme.borderBottom,
        },
        breakpointSwitch(headerTheme.breakpointState, ({ position, top }) => ({
            position,
            top,
        })),
    ]
})

export const StyledNotificationCentreHeaderHeading = styled.span(
    ({ theme }) => {
        return textMixin(theme, 'subheading')
    },
)

export const StyledNotificationCentreCardList = styled.div(({ theme }) => {
    const cardListTheme = getComponentTheme(
        theme,
        'notificationCentre',
    ).cardList

    return breakpointSwitch(cardListTheme.breakpointState, ({ padding }) => ({
        padding,
    }))
})

export const StyledNotificationCentreNoNotificationsContainer = styled.div(
    ({ theme }) => {
        const noNotificationsTheme = getComponentTheme(
            theme,
            'notificationCentre',
        ).noNotifications
        return [
            {
                display: noNotificationsTheme.display,
                flexDirection: noNotificationsTheme.flexDirection,
                padding: noNotificationsTheme.padding,
                alignItems: noNotificationsTheme.alignItems,
                color: noNotificationsTheme.color,
            },
            textMixin(theme, 'body-alt'),
        ]
    },
)
