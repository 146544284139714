import {
    StyledDivider,
    StyledFixedMenuItem,
    StyledHamburgerIcon,
    StyledMenuItemFirstOption,
} from '../ContentNavMenuItem.styled'
import React, { FC } from 'react'

interface HamburgerMenuItemProps {
    onClick: () => void
}

export const HamburgerMenuItem: FC<HamburgerMenuItemProps> = ({ onClick }) => {
    return (
        <StyledFixedMenuItem>
            <StyledMenuItemFirstOption onClick={() => onClick()}>
                <StyledHamburgerIcon />
            </StyledMenuItemFirstOption>
            <StyledDivider />
        </StyledFixedMenuItem>
    )
}
