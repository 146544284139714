import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { TagLinkNavigation, TagLinkNavigationProps } from './TagLinkNavigation'

type TagLinkNavigationRegistrationProps = Omit<
    TagLinkNavigationProps,
    'trailingContent'
>

export const TagLinkNavigationRegistration =
    createRegisterableComposition<'trailingContent'>()(
        'tag-link-navigation',
        (contentAreas, props: TagLinkNavigationRegistrationProps) => (
            <TagLinkNavigation
                trailingContent={contentAreas.trailingContent}
                {...props}
            />
        ),
    )
