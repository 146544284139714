import React, { FC, useRef } from 'react'
import {
    ContentNav,
    TheNightlyHeaderNavState,
} from '@news-mono/component-library'
import { CSSTransition } from 'react-transition-group'
import { contentNavVisibleStates } from '../useTheNightlyHeaderNav/constants'
import { StyledAnimatedContentNav } from './AnimatedContentNav.styled'

interface AnimatedContentNavProps {
    navState: TheNightlyHeaderNavState
    navHeight: number
}

const TRANSITION_TIME = 200

export const AnimatedContentNav: FC<AnimatedContentNavProps> = ({
    navState,
    navHeight,
}) => {
    const ref = useRef<HTMLDivElement | null>(null)

    const { navDisplayState, bannerHeight, contentNavHeight } = navState

    const isVisible = contentNavVisibleStates.includes(navDisplayState)
    const isHeaderNavVisible = navDisplayState === 'content-nav-with-header'

    return (
        <CSSTransition
            in={isVisible}
            timeout={TRANSITION_TIME}
            classNames="content-nav"
            nodeRef={ref}
        >
            <StyledAnimatedContentNav
                className="header-navigation"
                transitionTime={TRANSITION_TIME}
                hasHeaderNav={isHeaderNavVisible}
                navHeight={navHeight}
                contentNavHeight={contentNavHeight}
                bannerHeight={bannerHeight}
                isVisible={isVisible}
                ref={ref}
            >
                <ContentNav navState={navState} />
            </StyledAnimatedContentNav>
        </CSSTransition>
    )
}
