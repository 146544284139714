import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    SpecialEditionHome,
    SpecialEditionHomeProps,
} from './SpecialEditionHome'

export const NightlySpecialEditionHomeRegistration =
    createRegisterableComponent(
        'nightly-special-edition-home',
        (props: SpecialEditionHomeProps) => {
            return <SpecialEditionHome {...props} />
        },
    )
