import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import {
    Divider,
    StyledNightlyEditionButtonContainer,
    StyledNightlyEditionContainer,
    StyledNightlyEditionContent,
    StyledNightlyEditionPicture,
    StyledNightlyEditionTextContainer,
} from '../SpecificEdition/NightlySpecificEdition.styled'

const { neutral } = tokens.thenightly.colors.palette

export const HeaderContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
})

export const StyledNightlyEditionTextContainerLeft = styled(
    StyledNightlyEditionTextContainer,
)(({}) => ({
    [breakpoint('sm')]: {
        gridColumn: '1 / span 1',
    },
}))

export const StyledNightlyEditionPictureRight = styled(
    StyledNightlyEditionPicture,
)(({ theme }) => ({
    [breakpoint('sm')]: {
        gridColumn: '2 / span 1',
    },
}))

export const StyledNightlyEditionButtonContainerLeft = styled(
    StyledNightlyEditionButtonContainer,
)(() => ({
    [breakpoint('sm')]: {
        gridColumn: '1 / span 1',
        width: '100%',
    },
    [breakpoint('lg')]: {
        width: '100%',
    },
}))

export const StyledNightlyEditionContentHome = styled(
    StyledNightlyEditionContent,
)(({ theme }) => ({
    paddingTop: '68px',

    [breakpoint('sm')]: {
        padding: `68px 0 0 0`,
    },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: '68px',
}))
