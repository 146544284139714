import styled from '@emotion/styled'
import { calcRem, colors, fonts, metrics } from '@news-mono/component-library'
import { tokens } from '@news-mono/design-tokens'

export const StyledSite = styled('div')({
    fontFamily: fonts.thenightly.sansSerif,
    fontSize: metrics.thenightly.baseFontSize,
    color: tokens.thenightly.colors.palette.neutral[100],
    lineHeight: 1.5,
})

export const SiteFullWidthAd = styled('div')({
    width: '100%',
    background: colors.thewest.greyErmine,
})

export const SiteHeaderAd = styled('div')({
    marginTop: calcRem(-20),
})
