import styled from '@emotion/styled'
import { WestRewardsLogo } from '../../../../../logos/TheWestRewardsLogo/TheWestRewardsLogo'
import { breakpoint } from '../../../../../__styling'
import { colors } from '../../../../../__styling/settings/colors'
import { ThemeMargins } from '../../../../../__styling/settings/metrics'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'
import { themedValue } from '../../../../../__styling/themed-value'

export interface StyledWestRewardsWrapperProps {
    verticalSpacing?: keyof ThemeMargins | undefined
}

export const StyledWestRewardsWrapper = styled(
    'div',
)<StyledWestRewardsWrapperProps>(({ theme, verticalSpacing }) => ({
    padding: calcRem(theme.margins.sm, theme.margins.md),
    marginBottom: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
}))

export const StyledWestRewardsContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

    [breakpoint('xs')]: {
        flexDirection: 'row',
    },
})

export const StyledWestRewardsLogoContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 205,
    height: 72,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    background: themedValue(theme, {
        thewest: colors.thewest.greyErmine,
        perthnow: colors.perthnow.greyBasalt90,
        sevennews: colors.sevennews.charade,
        fallback: undefined,
    }),
}))

export const StyledWestRewardsLogo = styled(WestRewardsLogo)({
    width: 173,
    height: 48,
})

export const StyledWestRewardsMessage = styled('div')(({ theme }) => ({
    position: 'relative',
    maxWidth: calcRem(240),
    marginLeft: theme.margins.sm,

    [breakpoint('xs')]: {
        maxWidth: calcRem(280),
    },

    p: {
        fontWeight: 500,
        fontSize: calcRem(18),
        lineHeight: calcRem(24),
    },
}))
