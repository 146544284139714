import React, { ReactElement } from 'react'
import {
    AsideSlot,
    LunaContainer,
    BeforeAsideSlot,
    AfterAsideSlot,
    ColumnModeDivider,
    SplitModeDivider,
} from './Luna.styled'

export type DividerConfig = {
    /** Dividers when in column-layout. */
    column?: {
        /** The divider between beforeAside and Aside. */
        hasBeforeAsideAndAsideDivider?: boolean
        /** The divider between Aside and afterAside. */
        hasAsideAndAfterAsideDivider?: boolean
    }
    /** Dividers when in split-layout. */
    split?: {
        /** The divider between Aside and the content. */
        hasVerticalDivider?: boolean
        /** The divider between beforeAside and afterAside. */
        hasBeforeAsideAndAfterAsideDivider?: boolean
    }
}

export type LunaProps = {
    content: {
        beforeAside: ReactElement
        aside: ReactElement
        afterAside: ReactElement
    }
    hasBeforeAside?: boolean
    hasAfterAside?: boolean
    asideSide?: 'right' | 'left'
    dividerConfig?: DividerConfig
    hasMarginTop?: boolean
}

export const Luna = ({
    content,
    hasBeforeAside = true,
    hasAfterAside = true,
    asideSide,
    dividerConfig,
    hasMarginTop = false,
}: LunaProps) => {
    const hasBeforeAsideAndAfterAsideDivider =
        dividerConfig?.split?.hasBeforeAsideAndAfterAsideDivider ?? true
    const hasVerticalDivider = dividerConfig?.split?.hasVerticalDivider ?? true
    const hasBeforeAsideAndAsideDivider =
        dividerConfig?.column?.hasBeforeAsideAndAsideDivider ?? true
    const hasAsideAndAfterAsideDivider =
        dividerConfig?.column?.hasAsideAndAfterAsideDivider ?? true

    return (
        <LunaContainer
            dividerConfig={dividerConfig}
            hasBeforeAside={hasBeforeAside}
            hasAfterAside={hasAfterAside}
            asideSide={asideSide ?? 'left'}
        >
            {hasBeforeAside && (
                <>
                    <BeforeAsideSlot hasMarginTop={hasMarginTop}>
                        {content.beforeAside}
                    </BeforeAsideSlot>
                    {hasBeforeAsideAndAsideDivider && (
                        <ColumnModeDivider gridArea="columnDivider1" />
                    )}
                </>
            )}
            <AsideSlot hasMarginTop={hasMarginTop}>{content.aside}</AsideSlot>
            {hasAfterAside && (
                <>
                    {hasAsideAndAfterAsideDivider && (
                        <ColumnModeDivider gridArea="columnDivider2" />
                    )}
                    <AfterAsideSlot>{content.afterAside}</AfterAsideSlot>
                </>
            )}
            {hasVerticalDivider && (
                <SplitModeDivider gridArea="splitDivider1" />
            )}
            {hasBeforeAside &&
                hasAfterAside &&
                hasBeforeAsideAndAfterAsideDivider && (
                    <SplitModeDivider gridArea="splitDivider2" />
                )}
        </LunaContainer>
    )
}
