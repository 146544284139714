import styled from '@emotion/styled'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
    calcRem,
    fonts,
} from '../../../__styling'
import { WebLink } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'

const palette = tokens.thenightly.colors.palette

export const StyledDownloadAppCta = styled.div({
    color: palette.neutral[10],
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),
    height: '100%',
    width: '100%',
})

export const StyledHeading = styled.div({
    fontFamily: fonts.thenightly.subheading,
    fontSize: calcRem(25),
    fontWeight: 700,
    lineHeight: calcRem(32),
    letterSpacing: calcRem(-0.55),

    // Tablet
    [breakpointBetween('sm', 'mlg')]: {
        fontSize: calcRem(22),
        lineHeight: calcRem(32),
        letterSpacing: calcRem(-0.48),
    },

    // Mobile
    [breakpointMax('sm')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(24),
        letterSpacing: calcRem(-0.44),
    },
})

export const StyledDescriptionButtonContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: calcRem(16),

    // Tablet
    [breakpointBetween('sm', 'mlg')]: {
        flexDirection: 'row',
    },

    // Mobile
    [breakpointMax('sm')]: {
        flexDirection: 'column',
    },
})

export const StyledDescription = styled.p({
    fontFamily: fonts.thenightly.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 400,
    lineHeight: calcRem(24),
    margin: 0,

    // Tablet
    [breakpointBetween('sm', 'mlg')]: {
        flexGrow: 1,
    },

    // Mobile
    [breakpointMax('sm')]: {
        alignSelf: 'flex-start',
        flexGrow: 'unset',
    },
})

export const StyledButtonGroup = styled.div({
    display: 'flex',
    gap: calcRem(16),
    height: 'inherit',
    lineHeight: 'normal',
    marginTop: calcRem(8),
    justifyContent: 'center',

    // Desktop
    [breakpoint('lg')]: {
        alignSelf: 'flex-start',
    },

    // Tablet
    [breakpointBetween('sm', 'lg')]: {
        marginTop: 0,
    },
})

const BUTTON_HEIGHT = 48
const GOOGLE_IMG_HEIGHT = 170
const GOOGLE_IMG_WIDTH = 564
const APPLE_IMG_WIDTH = 144
const APPLE_IMG_HEIGHT = 48

export const GoogleButton = styled(WebLink)({
    display: 'block',
    flex: (BUTTON_HEIGHT / GOOGLE_IMG_HEIGHT) * GOOGLE_IMG_WIDTH,
    maxWidth: calcRem((BUTTON_HEIGHT / GOOGLE_IMG_HEIGHT) * GOOGLE_IMG_WIDTH),

    img: {
        maxHeight: calcRem(BUTTON_HEIGHT),
        width: '100%',
    },

    [breakpoint('sm')]: {
        img: {
            height: calcRem(BUTTON_HEIGHT),
            width: calcRem(
                (BUTTON_HEIGHT / GOOGLE_IMG_HEIGHT) * GOOGLE_IMG_WIDTH,
            ),
        },
    },
})

export const AppleButton = styled(WebLink)({
    display: 'block',
    flex: APPLE_IMG_WIDTH,
    maxWidth: calcRem(APPLE_IMG_WIDTH),

    svg: {
        maxHeight: calcRem(BUTTON_HEIGHT),
        width: '100%',
    },

    [breakpoint('sm')]: {
        svg: {
            height: calcRem(BUTTON_HEIGHT),
            width: calcRem(APPLE_IMG_WIDTH),
        },
    },
})
