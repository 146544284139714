import React from 'react'
import { NavItem } from '@news-mono/web-common'
import { StyledTextLink } from './TNFooter.styled'

export const getTextLinks = (links: NavItem[]) => {
    return links.map(({ name, link }, i) => {
        return (
            <StyledTextLink key={i} to={link}>
                {name}
            </StyledTextLink>
        )
    })
}
