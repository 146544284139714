import { RefObject, useState } from 'react'

interface UseDraggableScrollProps {
    ref: RefObject<HTMLElement>
}

// For usability and accessibility don't count small movements as a scroll
const SCROLL_THRESHOLD = 10

// Inspired by https://github.com/g-delmo/use-draggable-scroll
export const useDraggableScroll = ({ ref }: UseDraggableScrollProps) => {
    const [hasDragged, setHasDragged] = useState(false)

    let initialPosition = { scrollLeft: 0, mouseX: 0 }

    const mouseMoveHandler = (event: MouseEvent) => {
        event.preventDefault()
        if (ref.current) {
            const deltaX = event.clientX - initialPosition.mouseX

            ref.current.scrollLeft = initialPosition.scrollLeft - deltaX

            if (Math.abs(deltaX) > SCROLL_THRESHOLD) {
                setHasDragged(true)
            }
        }
    }

    const mouseUpHandler = (event: MouseEvent) => {
        event.preventDefault()
        if (ref.current) {
            ref.current.style.cursor = 'pointer'
        }

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
    }

    const clickHandler = (event: MouseEvent) => {
        event.preventDefault()
        setHasDragged(false)
        document.removeEventListener('click', clickHandler)
    }

    const onMouseDown = (event: { clientX: number; clientY: number }) => {
        if (ref.current) {
            initialPosition = {
                scrollLeft: ref.current.scrollLeft,
                mouseX: event.clientX,
            }

            ref.current.style.cursor = 'grabbing'
            ref.current.style.userSelect = 'none'

            // These bug out if you try to use the addListener wrapper.
            // We also can't add them to the ref as the scroll won't be ended
            // if the user drags outside the div
            document.addEventListener('mousemove', mouseMoveHandler)
            document.addEventListener('mouseup', mouseUpHandler)
            document.addEventListener('click', clickHandler)
        }
    }

    return { onMouseDown, hasDragged }
}
