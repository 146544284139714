import { TheNightlySection } from '@news-mono/common'
import {
    getCoralStoryId,
    inlineArticleAds,
    metrics,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    getBaseUrl,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
} from '@news-mono/web-common'
import {
    ArticleLikeV4DTO,
    PageMetaDTO,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { getNoIndexMeta } from './allowList'
import { toFeatureState } from '@etrigan/feature-toggles-client'
// Used for GA / Quicklinks background coloring

export const createFeatureRoute = ({
    article,
    config,
    section,
    getAdTargeting,
    sectionMeta,
    pageMeta,
    toggles,
}: {
    article: ArticleLikeV4DTO
    config: BaseClientConfig
    section: TheNightlySection
    getAdTargeting: GetRouteAdTargeting
    sectionMeta: SectionMetaInfoDTO
    pageMeta?: PageMetaDTO
    toggles?: TogglesReduxState
}): PageType<TheNightlySection> => {
    const featureState = toggles && toFeatureState(toggles)

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'article',
        section,
        article.topics.primary,
        ...article.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: article.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }
    const baseUrl = getBaseUrl(config.publicUrl, sectionMeta)
    const articleUrl = `${baseUrl}/${article.topics.primary.id}/${article.slug}`
    const requiredAccess = article.requiredAccess ?? {
        level: 'anonymous',
    }

    return {
        kind: 'page',
        heading: article.heading,
        pageMeta: {
            ...pageMeta,
            description: article.homepageTeaser,
            meta: getNoIndexMeta(article.source, featureState),
        },
        socialMeta: {
            title: article.socialHead || article.homepageHead,
            description: article.socialTeaser || article.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        additionalPageProperties: {
            primaryTopic: article.topics.primary,
            secondaryTopics: article.topics.secondary,
            publicationKind:
                article.layoutView === 'feature'
                    ? 'feature-article'
                    : article.kind,
        },
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth: '100%',
                    hasBackgroundFill: true,
                    verticalGutters: ['unset', 'md'],
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                hasTopSpacing: false,
                                containerWidth:
                                    metrics.thewest.featureContentWidth,
                                hasBackgroundFill: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'article-breach-composition',
                                        props: {
                                            requiredAccess,
                                            article,
                                        },
                                        contentAreas: {
                                            common: [
                                                layout.component({
                                                    type: 'article-hero',
                                                    props: {
                                                        adUnitPath,
                                                        heading:
                                                            article.heading,
                                                        publicationPathname:
                                                            article.slug,
                                                        imageRenderType:
                                                            'feature',
                                                        figcaptionRenderOptions:
                                                            'hidden',
                                                    },
                                                }),
                                                // This nested composition controls the feature-headline & associated content offset
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        containerWidth: 1220,
                                                        verticalSpacing: 'lg',
                                                        positionOffset: {
                                                            direction: 'top',
                                                            value: 40,
                                                        },
                                                        breakpoints: {
                                                            xxs: {
                                                                verticalSpacing:
                                                                    'md',
                                                                positionOffset:
                                                                    {
                                                                        direction:
                                                                            'top',
                                                                        value: 100,
                                                                    },
                                                            },
                                                            sm: {
                                                                verticalSpacing:
                                                                    'md',
                                                                positionOffset:
                                                                    {
                                                                        direction:
                                                                            'top',
                                                                        value: 90,
                                                                    },
                                                            },
                                                            md: {
                                                                verticalSpacing:
                                                                    'xl',
                                                                positionOffset:
                                                                    {
                                                                        direction:
                                                                            'top',
                                                                        value: 110,
                                                                    },
                                                            },
                                                        },
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            layout.component({
                                                                type: 'feature-headline',
                                                                props: {
                                                                    text: article.heading,
                                                                    classification:
                                                                        article.classification,
                                                                },
                                                            }),
                                                            layout.component({
                                                                type: 'figcaption',
                                                                props: {
                                                                    figcaptionLayout:
                                                                        'feature-article-position',
                                                                    kind: 'hero-image',
                                                                    separatedFromMedia:
                                                                        true,
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                            ],
                                            main: [
                                                layout.nestedComposition({
                                                    type: 'feature-composition',
                                                    props: {
                                                        containerWidth:
                                                            metrics.thewest
                                                                .siteMetrics
                                                                .mainContentWidth,
                                                        horizontalGutters: true,
                                                        hasBackgroundFill: true,
                                                        stretchSelf: true,
                                                        horizontalGap: 'sm',
                                                    },
                                                    contentAreas: {
                                                        sidebar: [
                                                            layout.component({
                                                                type: 'ad-unit',
                                                                props: {
                                                                    noticePosition:
                                                                        'none',
                                                                    padding: [
                                                                        metrics
                                                                            .thewest
                                                                            .margins
                                                                            .xxl,
                                                                        0,
                                                                        metrics
                                                                            .thewest
                                                                            .margins
                                                                            .md,
                                                                        0,
                                                                    ],
                                                                    slot: {
                                                                        id: 'sidebar-top',
                                                                        size: 'desktopMrecHalfPage',
                                                                        pageType:
                                                                            'article',
                                                                    },
                                                                    adType: 'inline',
                                                                },
                                                            }),
                                                        ],
                                                        main: [
                                                            layout.component({
                                                                type: 'article-feature',
                                                                props: {
                                                                    meta: {
                                                                        slug: article.slug,
                                                                        kind: 'article',
                                                                        inlinePublicationContent:
                                                                            [
                                                                                inlineArticleAds(
                                                                                    {
                                                                                        insertAfter: 3,
                                                                                        noticePosition:
                                                                                            'none',
                                                                                        hiddenUntilLoaded:
                                                                                            false,
                                                                                        mastHead:
                                                                                            'thewest',
                                                                                        slotID: 'article-mrec-one',
                                                                                        size: 'alwaysMrec',
                                                                                        pageType:
                                                                                            'article',
                                                                                    },
                                                                                ),
                                                                                inlineArticleAds(
                                                                                    {
                                                                                        insertAfter: 12,
                                                                                        noticePosition:
                                                                                            'below-center',
                                                                                        hiddenUntilLoaded:
                                                                                            true,
                                                                                        mastHead:
                                                                                            'thewest',
                                                                                        slotID: 'article-mrec-two',
                                                                                        size: 'below768Mrec',
                                                                                        pageType:
                                                                                            'article',
                                                                                    },
                                                                                ),
                                                                                inlineArticleAds(
                                                                                    {
                                                                                        insertAfter: 21,
                                                                                        noticePosition:
                                                                                            'below-center',
                                                                                        hiddenUntilLoaded:
                                                                                            true,
                                                                                        mastHead:
                                                                                            'thewest',
                                                                                        slotID: 'article-mrec-three',
                                                                                        size: 'below768Mrec',
                                                                                        pageType:
                                                                                            'article',
                                                                                    },
                                                                                ),
                                                                                inlineArticleAds(
                                                                                    {
                                                                                        insertAfter: 30,
                                                                                        noticePosition:
                                                                                            'below-center',
                                                                                        hiddenUntilLoaded:
                                                                                            true,
                                                                                        mastHead:
                                                                                            'thewest',
                                                                                        slotID: 'article-mrec-four',
                                                                                        size: 'below768Mrec',
                                                                                        pageType:
                                                                                            'article',
                                                                                    },
                                                                                ),

                                                                                {
                                                                                    kind: 'oovvuu-featured-card',
                                                                                    insertAfter: 7,
                                                                                    props: {
                                                                                        oovvuuUrl:
                                                                                            config.oovvuuUrl,
                                                                                        oovvuuPath:
                                                                                            config.oovvuuPath,
                                                                                    },
                                                                                },
                                                                            ],
                                                                    },
                                                                    dataDefinitionArgs:
                                                                        {
                                                                            publicationId:
                                                                                article.id,
                                                                        },
                                                                    section,
                                                                    isVideoStickyEnabled:
                                                                        true,
                                                                },
                                                            }),

                                                            layout.component({
                                                                type: 'content-separator',
                                                                props: {
                                                                    verticalBottomSpacing:
                                                                        'lg',
                                                                },
                                                            }),
                                                        ],

                                                        skinny: [
                                                            layout.component({
                                                                type: 'sharing',
                                                                props: {
                                                                    text: article.heading,
                                                                    url: articleUrl,
                                                                    shareOptions:
                                                                        [
                                                                            'facebook',
                                                                            'twitter',
                                                                            'email',
                                                                            'clipboard',
                                                                        ],
                                                                    isSticky:
                                                                        true,
                                                                    layout: 'stacked-md-desktop',
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                            ],
                                            comments: [
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        verticalSpacing: 'lg',
                                                        hasTopSpacing: true,
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            layout.component({
                                                                type: 'coral',
                                                                feature:
                                                                    'comments',
                                                                props: {
                                                                    storyID:
                                                                        getCoralStoryId(
                                                                            section,
                                                                            config.publicHostname,
                                                                            article.id,
                                                                        ),
                                                                    horizontalSpacing:
                                                                        'md',
                                                                    articleCommentsEnabled:
                                                                        article.allowCommenting,
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
