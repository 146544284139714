import {
    ALLOWED_CONTENT_NAV_ROUTES,
    DESKTOP_OFFSET,
    MOBILE_LARGE_OFFSET,
    MOBILE_SMALL_OFFSET,
    SCROLL_BEHAVIOUR,
    UNMOUNT_SCROLL_VELOCITIES,
} from './constants'
import { NavDisplayState, ScrollDirection } from './types'

export const isAllowedContentNavRoute = (pathname: string) => {
    return ALLOWED_CONTENT_NAV_ROUTES.includes(pathname)
}

export const isContentNavOpen = (navState: NavDisplayState) => {
    return navState === 'content-nav' || navState === 'content-nav-with-header'
}

export const getOffset = (
    navDisplayState: NavDisplayState,
    isMobile: boolean,
) => {
    if (navDisplayState === 'content-nav-with-header' && isMobile) {
        return MOBILE_LARGE_OFFSET
    }

    if (navDisplayState === 'content-nav' && isMobile) {
        return MOBILE_SMALL_OFFSET
    }

    return DESKTOP_OFFSET
}

// When the nav components are unmounted, it causes a predictable scroll event. This checks
// if the scroll event velocity matches this event. This isn't perfect, but it would be very unlucky
// for a natural scroll to have this velocity.
export const isUnmountScrollEvent = (velocity: number) => {
    const absoluteVelocity = Math.abs(velocity)

    return UNMOUNT_SCROLL_VELOCITIES.includes(absoluteVelocity)
}

// With smooth and auto scroll we don't know when the scroll ends (until scrollend is supported by Safari)
// This checks each from of the scroll and compares the scrollY position to the last.
// When this matches the previous position we can assume that our scroll has ended.
// Inspired by https://stackoverflow.com/a/60001032
export const verticalScrollTo = (top: number): Promise<void> => {
    return new Promise((resolve, reject) => {
        let sameOffsetCount = 0
        let prevOffset = window.scrollY

        window.scrollTo({ top, behavior: SCROLL_BEHAVIOUR })
        requestAnimationFrame(check)

        // this function will be called every painting frame
        // for the duration of the scroll operation
        function check() {
            const currentOffset = window.scrollY
            const isAtScrollTarget = Math.abs(currentOffset - top) <= 1
            if (currentOffset === prevOffset) {
                if (sameOffsetCount > 2) {
                    if (isAtScrollTarget) {
                        return resolve()
                    }
                    return reject()
                }
                sameOffsetCount++
            } else {
                sameOffsetCount = 0
                prevOffset = currentOffset
            }
            requestAnimationFrame(check)
        }
    })
}

export const getScrollDirection = (
    oldIndex: number,
    newIndex: number,
): ScrollDirection => {
    return oldIndex > newIndex ? 'scroll-up' : 'scroll-down'
}
