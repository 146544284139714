import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import {
    TheNightlyTheme,
    breakpointMax,
    metrics,
    breakpoint,
} from '../../../__styling'
import TheNightlyLogo from '../../../logos/TheNightlyLogo/TheNightlyLogo'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'

export interface StyledContainerProps {
    showAsDivider?: boolean
    blockStyle?: 'comments' | 'default'
}

export const StyledContainer = styled('div')<StyledContainerProps>(
    ({ showAsDivider = false }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        background: tokens.thenightly.colors.palette.neutral[0],
        padding: showAsDivider ? 0 : `${metrics.thenightly.margins.m2l}px 0`,
        borderTop: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
        borderBottom: showAsDivider
            ? '0px'
            : `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,

        [breakpointMax('sm')]: {
            justifyContent: 'space-around',
            flexDirection: 'column',
        },
    }),
)

export const StyledRowContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    gap: calcRem(24),

    [breakpointMax('sm')]: {
        flexDirection: 'column',
    },
})

export const StyledColumnContainer = styled('div')({
    margin: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(16),

    [breakpointMax('sm')]: {
        flexDirection: 'column',
        gap: metrics.thenightly.margins.sm,
        width: '90%',
    },
})

export const StyledTNHeaderLogo = styled(TheNightlyLogo)({
    width: calcRem(40),
    height: calcRem(40),
    display: 'block',

    [breakpointMax('sm')]: {
        width: calcRem(32),
        height: calcRem(32),
    },
})

export const Heading = styled.p(({ theme }) => [
    {
        textAlign: 'center',
        margin: 0,
    },
    textMixin(theme as TheNightlyTheme, 'subheading'),
])
