import React, { FC, useEffect, useRef } from 'react'
import {
    decodeId,
    HighlightDirection,
    sectionThemeOverrider,
} from '@news-mono/component-library'
import { TheNightlySection } from '../../__styling/settings/sections/thenightly'
import { StyledMenuItem } from './ContentNavMenuItem.styled'
import { CSSTransition } from 'react-transition-group'

interface ContentNavMenuItemProps {
    section: string
    heading: string
    isSelected: boolean
    isMobile: boolean
    isSystemScroll: boolean
    highlightDirection: HighlightDirection
    handleScrollNavigation: () => void
}

interface SectionValue {
    backgroundColor?: string
    primaryColor?: string
}

const ANIMATION_TIME = 200

export const ContentNavMenuItem: FC<ContentNavMenuItemProps> = ({
    section,
    heading,
    isSelected,
    isMobile,
    isSystemScroll,
    highlightDirection,
    handleScrollNavigation,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const override = sectionThemeOverrider(
        'thenightly',
        section as TheNightlySection,
    )

    const sectionValues: SectionValue | undefined = override.sectionValues

    useEffect(() => {
        if (isSelected && !isSystemScroll) {
            //I'm not sure why the setTimeout is needed, but it doesn't work without it
            setTimeout(function () {
                ref.current?.scrollIntoView({
                    behavior: 'auto',
                    block: 'nearest',
                    inline: isMobile ? 'center' : 'nearest',
                })
            }, 100)
        }
    }, [isMobile, isSelected, isSystemScroll])

    return (
        <CSSTransition
            in={isSelected}
            timeout={ANIMATION_TIME}
            classNames="selected"
            appear
            nodeRef={ref}
        >
            <StyledMenuItem
                backgroundOverride={sectionValues?.backgroundColor}
                textOverride={sectionValues?.primaryColor}
                isSelected={isSelected}
                highlightDirection={highlightDirection}
                transitionTime={ANIMATION_TIME}
                onClick={() => handleScrollNavigation()}
                ref={ref}
            >
                {decodeId(heading)}
            </StyledMenuItem>
        </CSSTransition>
    )
}
