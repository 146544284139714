import styled from '@emotion/styled'
import { calcRem, fonts } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export interface ButtonTextProps {
    useUnderlineOnHover?: boolean
    marginLeft?: number
    color?: string
}
const StyledButtonText = styled('p')<ButtonTextProps>(
    ({
        useUnderlineOnHover = true,
        marginLeft = 8,
        color = tokens.thenightly.colors.palette.neutral[0],
    }) => ({
        fontFamily: fonts.thenightly.sansSerif,
        fontSize: calcRem(20),
        fontWeight: 400,
        lineHeight: calcRem(24),
        margin: 0,

        color: color ?? undefined,
        marginLeft: marginLeft ? calcRem(marginLeft) : undefined,

        '&:hover, &:focus, &:active': {
            textDecoration: useUnderlineOnHover ? 'underline' : undefined,
        },
    }),
)

StyledButtonText.displayName = 'StyledButtonText'
export default StyledButtonText
