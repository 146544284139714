import React from 'react'
import { ArticleBlockContent } from '../../publication'
import { PublicationProps } from './Publication.props'

export function ShorthandPublication(
    props: Pick<PublicationProps, 'onEvent' | 'adState' | 'data' | 'log'>,
) {
    const publication = props.data
    const content = publication.content

    return (
        <>
            {content && (
                <ArticleBlockContent
                    blocks={content}
                    onEvent={props.onEvent}
                    log={props.log}
                    adUnitPath={props.adState.targeting.adUnitPath}
                />
            )}
        </>
    )
}
