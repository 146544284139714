import { useContext, useMemo } from 'react'
import { ContentDataTypes } from '@news-mono/web-common'
import { CollectionDataContext } from '../../contexts/CollectionDataContext/CollectionDataContext'

// A page size to be used in the event a non-paginated dataDefinition is provided.
const FALLBACK_PAGE_SIZE = 15

/** A hook that provides controls for pagination components. */
export const usePagination = (contextId: string) => {
    const { collectionData, initialDataDefinition } = useContext(
        CollectionDataContext,
    ).forId(contextId)

    const paging = useMemo<Paging>(
        () => getPaging(initialDataDefinition),
        [initialDataDefinition],
    )

    return { collectionData, paging }
}

export type Paging = {
    page: number
    pageSize: number
    pageOffset?: number
}

const getPaging = (dataDefinition: ContentDataTypes): Paging => {
    if (dataDefinition.type === 'listing') {
        return dataDefinition.paging
    } else if (dataDefinition.type === 'edition') {
        return {
            pageSize: dataDefinition.pageSize,
            page: dataDefinition.page,
        }
    }

    return { page: 1, pageSize: FALLBACK_PAGE_SIZE }
}
