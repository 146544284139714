import React from 'react'
import { FiltersContainer, StyledHeader } from './SavedArticlesFilters.styled'
import { ResultSort } from '../../result-filters/ResultSort/ResultSort'
import { TopicFilter } from '../../result-filters/TopicFilter/TopicFilter'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import { DataLayerEventName, FilterContext } from '@news-mono/web-common'
import { FilterEvent } from '../../result-filters/filter-events-util'
import { latestSavedSortOption, oldestSavedSortOption } from '../../result-filters'

export type SavedArticlesFiltersProps = {
    showHeader: boolean
    onEvent: (event: FilterEvent) => void
}

export const SavedArticlesFilters = ({
    showHeader,
    onEvent,
}: SavedArticlesFiltersProps) => {
    const { context } = React.useContext(FilterContext)

    return (
        <FiltersContainer>
            {showHeader && (
                <StyledHeader>
                    <SectionHeader heading="Filter" />
                </StyledHeader>
            )}

            <ResultSort
                defaultOption={latestSavedSortOption}
                options={[latestSavedSortOption, oldestSavedSortOption]}
                onEvent={onEvent}
                dataLayerEventName={DataLayerEventName.savedArticlesFilter}
            />
            <TopicFilter
                topicCounts={context?.topicCounts}
                onEvent={onEvent}
                dataLayerEventName={DataLayerEventName.savedArticlesFilter}
            />
        </FiltersContainer>
    )
}
