import { AppState, AuthenticationState } from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'

export type RequiredAuthenticationState =
    | 'signed-out'
    | 'signed-in'
    | 'signed-in-and-verified'

interface AuthenticatedToViewProps {
    /** Should be true if someone can only view the page while email verified,
     *  or false if only allowed to view the page un-email verified */
    requiredAuthenticationState: RequiredAuthenticationState
    /** Handlers for valid and invalid authentication state */
    authenticationValid: () => React.ReactElement<any> | null
    authenticationInvalid?: () => React.ReactElement<any> | null
}

export function isValidAuthenticationState(
    authentication: AuthenticationState,
    requiredState: RequiredAuthenticationState,
) {
    if (requiredState === 'signed-in-and-verified') {
        return authentication.isLoggedIn //&& authentication.isVerified // TODO: Add once implemented
    }

    return authentication.isLoggedIn === (requiredState === 'signed-in')
}

export const AuthenticatedToView: React.FC<AuthenticatedToViewProps> = ({
    requiredAuthenticationState,
    authenticationValid,
    authenticationInvalid,
}) => {
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isValid = isValidAuthenticationState(
        authentication,
        requiredAuthenticationState,
    )

    return isValid
        ? authenticationValid()
        : authenticationInvalid
        ? authenticationInvalid()
        : null
}
