import React from 'react'
import {
    NightlyBreadcrumbContainer,
    StyledChevron,
    StyledTopic,
    TopicDivider,
    TopicContainer,
    SecondaryTopicsContainer,
    ChevronContainer,
    TopicChevronWrapper,
    Link,
    SecondaryTopicsItem,
    SecondaryTopicsWrapper,
} from './TheNightlyBreadcrumb.styled'
import { TheNightlyBreadcrumbProps } from './TheNightlyBreadcrumb.routing'
import { RoutableNightlySection } from '@west-australian-newspapers/publication-types'
import { NavItem } from '@news-mono/web-common'
import { TheNightlyNavItem } from '../../routing'

export const TheNightlyBreadcrumb: React.FC<TheNightlyBreadcrumbProps> = ({
    topics,
    nightlySectionNavItems,
    subTopicNavItems,
}) => {
    const [showSecondaryTopics, setShowSecondaryTopics] = React.useState(false)
    const { primary } = topics

    const primaryTopic = primary.parent ?? primary
    const hasSecondaryTopic = primary.parent !== undefined

    let parentTopic: TheNightlyNavItem | undefined
    let subTopic: NavItem | undefined
    let secondaryTopics: NavItem[] = []
    if (primaryTopic.id in subTopicNavItems) {
        parentTopic =
            nightlySectionNavItems[primaryTopic.id as RoutableNightlySection]

        secondaryTopics = parentTopic.subNavLinks ?? []

        if (hasSecondaryTopic) {
            subTopic = subTopicNavItems[
                primaryTopic.id as RoutableNightlySection
            ].find((item) => item.link === `/${primary.id}`)
        }
    }

    return (
        <NightlyBreadcrumbContainer>
            <TopicChevronWrapper showPadding={showSecondaryTopics}>
                <TopicContainer aria-label="breadcrumbs">
                    {parentTopic && (
                        <StyledTopic to={parentTopic.link}>
                            {parentTopic.name}
                        </StyledTopic>
                    )}
                    {subTopic && (
                        <>
                            <TopicDivider aria-hidden="true" />
                            <StyledTopic to={`${subTopic.link}`}>
                                {subTopic.name}
                            </StyledTopic>
                        </>
                    )}
                </TopicContainer>
                {secondaryTopics.length > 0 && (
                    <ChevronContainer
                        onClick={() =>
                            setShowSecondaryTopics(!showSecondaryTopics)
                        }
                    >
                        <StyledChevron isOpen={showSecondaryTopics} />
                    </ChevronContainer>
                )}
            </TopicChevronWrapper>
            {secondaryTopics && (
                <SecondaryTopicsWrapper isOpen={showSecondaryTopics}>
                    <SecondaryTopicsContainer>
                        {secondaryTopics.map((topic, index) => {
                            const isCurrent = topic.name === subTopic?.name

                            return (
                                <SecondaryTopicsItem
                                    isCurrent={isCurrent}
                                    key={index}
                                >
                                    <Link
                                        to={`${topic.link}`}
                                        aria-current={
                                            isCurrent ? 'location' : undefined
                                        }
                                    >
                                        {topic.name}
                                    </Link>
                                </SecondaryTopicsItem>
                            )
                        })}
                    </SecondaryTopicsContainer>
                </SecondaryTopicsWrapper>
            )}
        </NightlyBreadcrumbContainer>
    )
}
