import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { TheNightlyPageNotFound } from './TheNightlyPageNotFound'

export const TheNightlyPageNotFoundRegistration = createRegisterableComponent(
    'thenightly-404-page',
    () => (
        <RouteStatus statusCode={404}>
            <TheNightlyPageNotFound />
        </RouteStatus>
    ),
)
