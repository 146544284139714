import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem } from '../../__styling/style-functions'
import { IconChevronDownTN } from '../../icons/IconChevronDownTN/IconChevronDownTN'
import styled from '@emotion/styled'
import { getComponentTheme, breakpointSwitch } from '../../__styling'

export const NightlyBreadcrumbContainer = styled.div(({ theme }) => {
    const breadcrumbTheme = getComponentTheme(
        theme,
        'nightlyBreadcrumb',
    ).breadcrumbContainer
    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: breadcrumbTheme.gap,
    }
})

interface TopicChevronWrapperProps {
    showPadding?: boolean
}
export const TopicChevronWrapper = styled.div<TopicChevronWrapperProps>(
    ({ showPadding }) => {
        return {
            display: 'flex',
            alignItems: 'center',
            gap: calcRem(4),
            padding: showPadding ? 0 : calcRem(0, 15, 8, 0),

            [breakpoint('sm')]: {
                padding: 0,
            },
            transition: 'padding 0.25s',
            flexWrap: 'wrap',
        }
    },
)

export const TopicContainer = styled.nav(({ theme }) => {
    const topicTheme = getComponentTheme(theme, 'nightlyBreadcrumb').topic
    return {
        display: 'flex',
        alignItems: 'center',
        gap: topicTheme.gap,
        flexWrap: 'wrap',
    }
})

export const ChevronContainer = styled.div(({ theme }) => {
    const chevronTheme = getComponentTheme(theme, 'nightlyBreadcrumb').chevron
    return {
        cursor: 'pointer',

        ':hover': {
            opacity: chevronTheme.hover.opacity,
        },
    }
})

export const StyledChevron = styled(IconChevronDownTN)<{ isOpen: boolean }>(
    ({ theme, isOpen }) => {
        const topicTheme = getComponentTheme(theme, 'nightlyBreadcrumb').topic
        return {
            width: calcRem(24),
            height: calcRem(24),
            display: 'flex',
            color: topicTheme.textColor,

            rotate: `${isOpen ? -180 : 0}deg`,

            transition: 'rotate 0.25s',
        }
    },
)

export const StyledTopic = styled(WebLink)(({ theme }) => {
    const topicTheme = getComponentTheme(theme, 'nightlyBreadcrumb').topic
    return [
        {
            color: topicTheme.textColor,
            textDecoration: 'none',
            fontWeight: topicTheme.fontWeight,

            ':hover': {
                opacity: topicTheme.hover.opacity,
            },
        },
        breakpointSwitch(
            topicTheme.breakpointState,
            (textStyles) => textStyles,
        ),
    ]
})

export const TopicDivider = styled.div(({ theme }) => {
    const dividerTheme = getComponentTheme(
        theme,
        'nightlyBreadcrumb',
    ).topicDivider
    return {
        width: calcRem(2),
        height: calcRem(16),
        backgroundColor: dividerTheme.backgroundColor,
    }
})

export const SecondaryTopicsWrapper = styled.div<{ isOpen: boolean }>(
    ({ theme, isOpen }) => {
        const breadcrumbContainerTheme = getComponentTheme(
            theme,
            'nightlyBreadcrumb',
        ).breadcrumbContainer
        return {
            display: 'grid',
            gridTemplateRows: `${isOpen ? 1 : 0}fr`,
            marginTop: isOpen ? breadcrumbContainerTheme.gap : 0,

            transition: 'grid-template-rows 0.25s, margin 0.25s',
        }
    },
)

export const SecondaryTopicsContainer = styled.ol(({ theme }) => {
    const secondaryTopicsTheme = getComponentTheme(
        theme,
        'nightlyBreadcrumb',
    ).secondaryTopicContainer
    return {
        // Allows the container to collapse.
        minHeight: 0,

        display: 'flex',
        paddingLeft: 0,
        margin: 0,
        listStyle: 'none',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: secondaryTopicsTheme.gap,
        overflow: 'hidden',
    }
})

interface TopicItemProps {
    isCurrent?: boolean
}
export const SecondaryTopicsItem = styled.li<TopicItemProps>(
    ({ theme, isCurrent }) => {
        const secondaryTopic = getComponentTheme(
            theme,
            'nightlyBreadcrumb',
        ).secondaryTopic
        return [
            {
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
                cursor: 'pointer',
                color: isCurrent
                    ? secondaryTopic.active.color
                    : secondaryTopic.color,
                fontWeight: isCurrent ? 500 : 400,
            },
            breakpointSwitch(
                secondaryTopic.breakpointState,
                (textStyles) => textStyles,
            ),
        ]
    },
)

export const Link = styled(WebLink)(({ theme }) => {
    const linkTheme = getComponentTheme(theme, 'nightlyBreadcrumb').link
    return {
        color: linkTheme.color,
        textDecoration: linkTheme.textDecoration,
        ':hover': {
            opacity: linkTheme.hover.opacity,
        },
    }
})
