import React, { FC } from 'react'

interface HomeIconProps {
    className?: string
}

export const HomeIcon: FC<HomeIconProps> = ({ className }) => (
    <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.9823 1.76424C10.631 1.49099 10.4553 1.35436 10.2613 1.30184C10.0902 1.2555 9.9098 1.2555 9.73865 1.30184C9.54468 1.35436 9.36902 1.49099 9.0177 1.76424L2.23539 7.03937C1.78202 7.39199 1.55534 7.5683 1.39203 7.7891C1.24737 7.98469 1.1396 8.20503 1.07403 8.4393C1 8.70376 1 8.99094 1 9.5653V16.8002C1 17.9203 1 18.4804 1.21799 18.9082C1.40973 19.2845 1.71569 19.5905 2.09202 19.7822C2.51984 20.0002 3.0799 20.0002 4.2 20.0002H6.2C6.48003 20.0002 6.62004 20.0002 6.727 19.9457C6.82108 19.8978 6.89757 19.8213 6.9455 19.7272C7 19.6203 7 19.4803 7 19.2002V12.6002C7 12.0402 7 11.7601 7.10899 11.5462C7.20487 11.3581 7.35785 11.2051 7.54601 11.1092C7.75992 11.0002 8.03995 11.0002 8.6 11.0002H11.4C11.9601 11.0002 12.2401 11.0002 12.454 11.1092C12.6422 11.2051 12.7951 11.3581 12.891 11.5462C13 11.7601 13 12.0402 13 12.6002V19.2002C13 19.4803 13 19.6203 13.0545 19.7272C13.1024 19.8213 13.1789 19.8978 13.273 19.9457C13.38 20.0002 13.52 20.0002 13.8 20.0002H15.8C16.9201 20.0002 17.4802 20.0002 17.908 19.7822C18.2843 19.5905 18.5903 19.2845 18.782 18.9082C19 18.4804 19 17.9203 19 16.8002V9.5653C19 8.99094 19 8.70376 18.926 8.4393C18.8604 8.20503 18.7526 7.98469 18.608 7.7891C18.4447 7.5683 18.218 7.39199 17.7646 7.03937L10.9823 1.76424Z"
            stroke="#8D8D8D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
