import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledHeaderContainer = styled.div(({ theme }) => {
    const margins = theme.margins

    return {
        width: '100%',
        padding: calcRem(0, margins.outerMargin),

        [breakpoint('lg')]: {
            padding: 0,
        },
    }
})

export const StyledHeader = styled.h2(({ theme }) => {
    const margins = theme.margins

    // The LeadArticle card has negative spacing on small screens which overlaps the headline
    const smallScreenMargin = margins.md + margins.xxl

    return {
        margin: calcRem(8, 0, smallScreenMargin, 0),
        width: '100%',

        color: tokens.thenightly.colors.palette.utility.error.default,
        fontWeight: 700,
        fontSize: calcRem(40),
        letterSpacing: '-1.6px',
        lineHeight: calcRem(48),

        transition: 'font-size 0.25s, width 0.25s, padding 0.25s',

        [breakpoint('sm')]: {
            marginBottom: calcRem(margins.outerMargin),

            fontSize: calcRem(45),
            letterSpacing: '-1.8px',
        },

        [breakpoint('lg')]: {
            fontSize: calcRem(51),
            lineHeight: calcRem(56),
            letterSpacing: '-2.04px',
        },
    }
})

export const StyledKicker = styled.span({
    textTransform: 'uppercase',
    fontSize: calcRem(18),
    lineHeight: calcRem(24),
    fontWeight: 600,
    letterSpacing: calcRem(-0.36),
    color: tokens.thenightly.colors.palette.utility.error.default,
})
