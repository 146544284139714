import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import TheNightlyLogo from '../../logos/TheNightlyLogo/TheNightlyLogo'
import { breakpoint, breakpointMax, calcRem, metrics } from '../../__styling'
import { DownloadAppCta, LinkGroups } from './components'

const palette = tokens.thenightly.colors.palette

/** The entire footer, edge-to-edge of the screen. */
export const Footer = styled.footer({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBlockStart: 'auto',
    background: palette.neutral[90],
})

/**
 * The content of the footer, limited to the center 1140.
 * The maxWidth is wider so as to allow for padding.
 */
export const ContentWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 1188,
    padding: calcRem(
        metrics.thenightly.margins.xxl,
        metrics.thenightly.margins.m2l,
        metrics.thenightly.margins.xxxxl,
    ),
    gap: calcRem(metrics.thenightly.margins.xxl),

    [breakpointMax('mlg')]: {
        padding: calcRem(40, 32),
    },

    [breakpointMax('sm')]: {
        gap: calcRem(metrics.thenightly.margins.lg),
        padding: calcRem(40, 24),
    },
})

/** Positions the elements above the wrapper. */
export const TopWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
})

/** The divider line. */
export const Divider = styled.span({
    display: 'block',
    width: '100%',
    height: 1,
    background: palette.neutral[60],
})

/* Breaks the flex row layout into a new row */
export const RowBreak = styled(Divider)({
    display: 'none',
    flexBasis: '100%',
    height: 0,

    [breakpointMax('mlg')]: {
        display: 'block',
        height: 1,
        order: 2,
        marginTop: calcRem(40),
        marginBottom: calcRem(40),
    },
})

/** Positions the links */
export const StyledLinkGroups = styled(LinkGroups)({
    display: 'flex',
    flexGrow: 1,

    [breakpointMax('mlg')]: {
        order: 3,
    },
})

/** Positions the app download CTA */
export const StyledCta = styled(DownloadAppCta)({
    display: 'flex',
    maxWidth: calcRem(411),

    [breakpointMax('mlg')]: {
        order: 1,
        flexGrow: 1,
        flexBasis: '100%',
        maxWidth: 'none',
    },
})

/** Positions the nightly logo. */
export const StyledTheNightlyLogo = styled(TheNightlyLogo)({
    marginLeft: calcRem(120),
    width: 64,
    height: 64,
    display: 'none',

    [breakpoint('sm')]: {
        display: 'block',
        width: 120,
        height: 120,
    },

    [breakpointMax('mlg')]: {
        order: 4,
    },
})

/** The styles for a link with only text in it. */
export const StyledTextLink = styled(WebLink)({
    textDecoration: 'none',
    color: palette.neutral[10],
    fontSize: 16,
    lineHeight: '150%',
    fontStyle: 'normal',
    fontWeight: 400,
})

/** Positions elements below the divider. */
export const BottomWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: 64,
})

/** Positions the copyright, and the multiple legal links. */
export const LegalWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(metrics.thenightly.margins.xxl),

    [breakpoint('sm')]: {
        flexDirection: 'row',
        gap: 54,
    },
})

/** Styles the copyright text. */
export const Copyright = styled.div({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutral[10],
    fontSize: 16,
    lineHeight: '150%',
    fontStyle: 'normal',
    fontWeight: 400,
    // order it to the last on sm screen
    order: 1,
    [breakpoint('sm')]: {
        order: 0,
    },
})
