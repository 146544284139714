import { getComponentTheme } from '../../__styling'
import styled from '@emotion/styled'

export const StyledNotificationCentreCloseIcon = styled.div(({ theme }) => {
    const headerTheme = getComponentTheme(theme, 'notificationCentre').header
        .closeIcon
    return {
        cursor: headerTheme.cursor,
        alignItems: headerTheme.alignItems,
        display: headerTheme.display,

        '&:hover': {
            svg: {
                path: {
                    stroke: headerTheme.hoverStroke,
                },
            },
        },
    }
})
