import {
    EditionV4DTO,
    LdJsonItemDTO,
    PageMetaDTO,
} from '@west-australian-newspapers/publication-types'
import { BaseClientConfig } from 'web-common'

export function getEditionPageMeta(
    config: BaseClientConfig,
    edition: EditionV4DTO,
): PageMetaDTO {
    const meta = {
        link: [{ rel: 'canonical', href: edition._self }],
        description: edition.teaser,
        meta: [
            {
                property: 'og:image',
                content: edition.socialImage?.src ?? '',
            },
            {
                property: 'og:image:width',
                content: edition.socialImage?.width.toString() ?? '0',
            },
            {
                property: 'og:image:height',
                content: edition.socialImage?.height.toString() ?? '0',
            },
            {
                name: 'twitter:image',
                content: edition.socialImage?.src ?? '',
            },
            {
                property: 'og:description',
                content: edition.teaser ?? edition.socialTeaser,
            },
            {
                property: 'twitter:description',
                content: edition.teaser ?? edition.socialTeaser,
            },
        ],
        ldJson: [
            publicationIssueLdJson(config, edition),
            newspaperLdJson(config),
            webPageLdJson(config, edition),
            imageObjectLdJson(config, edition),
            breadcrumbListLdJson(config, edition),
        ],
    }
    return meta
}

export function publicationIssueLdJson(
    config: BaseClientConfig,
    edition: EditionV4DTO,
): LdJsonItemDTO {
    return {
        '@context': 'http://schema.org',
        '@type': 'PublicationIssue',
        '@id': `${config.publicUrl}/#/schema/Issue`,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': edition._self,
        },
        isPartOf: {
            '@type': 'Newspaper',
            '@id': `${config.publicUrl}/#/schema/Newspaper`,
        },
        name: edition.heading,
        description: edition.teaser,
        publisher: {
            '@type': 'NewsMediaOrganization',
            '@id': `${config.publicUrl}/#/schema/Organization`,
        },
        image: {
            '@type': 'ImageObject',
            '@id': `${config.publicUrl}/#/schema/PrimaryImage`,
        },
        datePublished: edition.publicationDate,
        inLanguage: 'en-AU',
    }
}

export function newspaperLdJson(config: BaseClientConfig): LdJsonItemDTO {
    return {
        '@context': 'http://schema.org',
        '@type': 'Newspaper',
        '@id': `${config.publicUrl}/#/schema/Newspaper`,
        name: 'The Nightly',
        publisher: {
            '@id': `${config.publicUrl}/#/schema/Organization`,
        },
        url: config.publicUrl,
    }
}

export function webPageLdJson(
    config: BaseClientConfig,
    edition: EditionV4DTO,
): LdJsonItemDTO {
    return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        '@id': edition._self,
        url: edition._self,
        name: edition.heading,
        description: edition.teaser,
        publisher: {
            '@type': 'NewsMediaOrganization',
            '@id': `${config.publicUrl}/#/schema/Organization`,
        },
        image: {
            '@type': 'ImageObject',
            '@id': `${config.publicUrl}/#/schema/PrimaryImage`,
        },
        breadcrumb: {
            '@type': 'BreadcrumbList',
            '@id': `${config.publicUrl}/#/schema/BreadcrumbList`,
        },
        isPartOf: {
            '@type': 'WebSite',
            '@id': `${config.publicUrl}/#/schema/WebSite`,
        },
        inLanguage: 'en-AU',
    }
}

export function imageObjectLdJson(
    config: BaseClientConfig,
    edition: EditionV4DTO,
): LdJsonItemDTO {
    return {
        '@context': 'http://schema.org',
        '@type': 'ImageObject',
        '@id': `${config.publicUrl}/#/schema/PrimaryImage`,
        url: edition.socialImage?.src ?? '',
        contentUrl: edition.socialImage?.src ?? '',
        width: edition.socialImage?.width ?? 0,
        height: edition.socialImage?.height ?? 0,
        caption: `${edition.heading} Front Page`,
        inLanguage: 'en-AU',
    }
}

export function breadcrumbListLdJson(
    config: BaseClientConfig,
    edition: EditionV4DTO,
): LdJsonItemDTO {
    return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        '@id': `${config.publicUrl}/#/schema/BreadcrumbList`,
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: config.publicUrl,
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: 'Editions',
                item: `${config.publicUrl}/editions`,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: edition.heading,
            },
        ],
    }
}
