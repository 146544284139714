import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export interface WardenSubTitleProps {
    isAdaptiveSize?: boolean
}
export const StyledWardenSubTitle = styled('h3')<WardenSubTitleProps>(
    ({ isAdaptiveSize = false }) => ({
        fontFamily: fonts.thenightly.subheading,
        fontWeight: 700,
        color: tokens.thenightly.colors.text.heading,

        // Anything small than xxs
        fontSize: calcRem(isAdaptiveSize ? 20 : 25),
        lineHeight: calcRem(isAdaptiveSize ? 24 : 32),
        letterSpacing: calcRem(isAdaptiveSize ? -0.44 : -0.55),

        // Anything bigger than or equal to Surface Duo
        [breakpoint('xxs')]: {
            fontSize: isAdaptiveSize ? calcRem(25) : undefined,
            lineHeight: isAdaptiveSize ? calcRem(32) : undefined,
            letterSpacing: isAdaptiveSize ? calcRem(-0.55) : undefined,
        },
    }),
)
StyledWardenSubTitle.displayName = 'StyledWardenSubTitle'
