import { metrics } from '@news-mono/component-library'
import {
    CheckedComponentInformation,
    CheckedCompositionInformation,
} from 'json-react-layouts'
import { layout } from '../App.routing'
import { AdNoticePosition } from 'libs/component-library/src/advertising/AdUnit/AdUnit.styled'
import { toWords } from 'number-to-words'

/**
 * Helper to create multiple ad units to display a mrec on mobile,
 * and billboard/leaderboards on tablet/desktop
 **/
export function billboardLeaderboardMrec(
    id: string,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    id: string,
    bottomPadding: number,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    mrecId: string,
    billboardLeaderboardId: string,
    bottomPadding?: number,
    noticePosition?: AdNoticePosition,
    lazyLoadingDistance?: number,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    mrecId: string,
    billboardLeaderboardIdOrBottomPadding?: string | number,
    bottomPadding?: number,
    noticePosition?: AdNoticePosition,
    lazyLoadingDistance?: number,
): Array<CheckedComponentInformation> {
    const actualBottomPadding =
        typeof billboardLeaderboardIdOrBottomPadding === 'number'
            ? billboardLeaderboardIdOrBottomPadding
            : bottomPadding || metrics.thewest.margins.xl

    return [
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: noticePosition ?? 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id:
                        billboardLeaderboardIdOrBottomPadding &&
                        typeof billboardLeaderboardIdOrBottomPadding ===
                            'string'
                            ? mrecId
                            : `mrec-${mrecId}`,
                    size: 'below768Mrec',
                    lazyLoadingDistance: lazyLoadingDistance,
                },
                adType: 'inline',
            },
        }),
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id:
                        billboardLeaderboardIdOrBottomPadding &&
                        typeof billboardLeaderboardIdOrBottomPadding ===
                            'string'
                            ? billboardLeaderboardIdOrBottomPadding
                            : `billboard-leaderboard-${mrecId}`,
                    size: 'leaderboardBillboard',
                    lazyLoadingDistance: lazyLoadingDistance,
                },
                adType: 'inline',
            },
        }),
    ]
}

export const getHomepageMidCollectionAds = (
    displayDesktop: boolean,
    position: number,
): CheckedCompositionInformation => {
    const word = toWords(position)

    return getTopicAds(
        displayDesktop,
        `mrec-${word}`,
        word,
        0,
        'above-center',
        0,
    )
}

/**
 * Hides desktop billboard/leaderboards ad when displayDesktop is false
 * Primarily created for hiding odd topic ads on homepage
 **/
export const getTopicAds = (
    displayDesktop: boolean,
    mrecId: string,
    billboardLeaderboardIdOrBottomPadding: string | number,
    bottomPadding: number,
    noticePosition: AdNoticePosition,
    lazyLoadingDistance?: number,
): CheckedCompositionInformation => {
    const compositionMain = getTopicAdComponents(
        displayDesktop,
        mrecId,
        billboardLeaderboardIdOrBottomPadding,
        0,
        bottomPadding,
        noticePosition,
        lazyLoadingDistance,
    )
    return layout.composition({
        type: 'thor',
        props: {
            hasBackgroundFill: false,
            containerWidth: metrics.thenightly.siteMetrics.mainContentWidth,
            horizontalGutters: false,
            verticalGutters: false,
        },
        contentAreas: {
            main: compositionMain,
        },
    })
}

/**
 * Returns components that:
 * Hides desktop billboard/leaderboards ad when displayDesktop is false
 * Primarily created for hiding odd topic ads on homepage
 **/
export const getTopicAdComponents = (
    displayDesktop: boolean,
    mrecId: string,
    billboardLeaderboardIdOrBottomPadding: string | number,
    topPadding: number,
    bottomPadding: number,
    noticePosition: AdNoticePosition,
    lazyLoadingDistance?: number,
): CheckedComponentInformation[] => {
    const leaderBillboardAd = layout.component({
        type: 'ad-unit',
        props: {
            noticePosition: noticePosition ?? 'below-center',
            hiddenUntilLoaded: true,
            padding: [topPadding, 0, bottomPadding, 0],
            slot: {
                id:
                    billboardLeaderboardIdOrBottomPadding &&
                    typeof billboardLeaderboardIdOrBottomPadding === 'string'
                        ? billboardLeaderboardIdOrBottomPadding
                        : `billboard-leaderboard-${mrecId}`,
                size: 'leaderboardBillboard',
                lazyLoadingDistance: lazyLoadingDistance,
            },
            adType: 'inline',
        },
    })

    const mobileAd = layout.component({
        type: 'ad-unit',
        props: {
            noticePosition: noticePosition ?? 'below-center',
            hiddenUntilLoaded: true,
            padding: [topPadding, 0, bottomPadding, 0],
            slot: {
                id:
                    billboardLeaderboardIdOrBottomPadding &&
                    typeof billboardLeaderboardIdOrBottomPadding === 'string'
                        ? mrecId
                        : `mrec-${mrecId}`,
                size: 'below768Mrec',
            },
            adType: 'inline',
            lazyLoadingDistance: lazyLoadingDistance,
        },
    })

    const compositionMain = [mobileAd]
    displayDesktop && compositionMain.push(leaderBillboardAd)

    return compositionMain
}
