import React from 'react'

export const ArrowNarrowRightIcon: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
    >
        <path
            d="M4.5 12H20.5M20.5 12L14.5 6M20.5 12L14.5 18"
            stroke="#161616"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
