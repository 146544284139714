import { createRegisterableComponent } from '@news-mono/web-common'
import { Pagination } from './Pagination'
import React from 'react'
import { usePagination } from './usePagination'

type PaginationRegistrationProps = {
    contextId: string
}

/**
 * A pagination component hooked up to use CollectionDataContext.
 */
export const PaginationRegistration = createRegisterableComponent(
    'pagination',
    ({ contextId }: PaginationRegistrationProps, services) => {
        const { collectionData, paging } = usePagination(contextId)

        if (!collectionData.loaded) {
            return null
        }

        const { result } = collectionData

        if (
            !(result.kind === 'listing' || result.kind === 'edition') ||
            !result.total
        ) {
            return null
        }

        return (
            <Pagination
                itemCount={result.total}
                paging={paging}
                onEvent={services.onEvent}
            />
        )
    },
)
