import React from 'react'
import {
    StyledWestRewardsDivider,
    StyledWestRewardsDividerVectorGray,
    StyledWestRewardsDividerVectorPurple,
    StyledWestRewardsHeaderTitle2022,
} from './WestRewardsDivider.styled'
import {
    WestRewardsDividerVectorGray,
    WestRewardsDividerVectorPurple,
} from './WestRewardsVectors'

export const WestRewardsDivider: React.FC = () => {
    return (
        <>
            <StyledWestRewardsDivider>
                <StyledWestRewardsDividerVectorGray>
                    <WestRewardsDividerVectorGray />
                </StyledWestRewardsDividerVectorGray>
                <StyledWestRewardsDividerVectorPurple>
                    <WestRewardsDividerVectorPurple />
                </StyledWestRewardsDividerVectorPurple>
            </StyledWestRewardsDivider>
            <StyledWestRewardsHeaderTitle2022>
                Subscribers get more
            </StyledWestRewardsHeaderTitle2022>
        </>
    )
}
