import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { AmpImage } from '../Picture/Picture.amp'
import { TheNightlyHorizontalLogo } from '../../logos/TheNightlyLogo/TheNightlyHorizontalLogo'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { LogoProps } from './logos/createHeaderLogo'

export const StyledTheNightlyDivHeaderWrapper = styled('div')({
    display: 'none',

    [breakpoint('lg')]: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: metrics.thenightly.siteMetrics.mainContentWidth,
        margin: '0 auto',
        padding: `0 ${calcRem(metrics.thenightly.margins.md)}`,
    },
})

export const StyledTheNightlyHeader = styled('div')({
    padding: calcRem(metrics.thenightly.margins.md, 0),
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
})

export const StyledTheNightlyHorizontalLogo = styled(TheNightlyHorizontalLogo)({
    display: 'none',
    [breakpoint('xxs')]: {
        display: 'block',
    },
})

export interface ImageLogoProps {
    /* This allows amp to get the aspect ratio */
    ampInitialSize: { width: number; height: number }
    heights?: {
        desktop: number
        tablet: number
        mobile: number
    }
    verticalSpacing?: number
}

export const StyledImageLogo = styled('img')<LogoProps>((props) => ({
    display: 'block',
    height: props.heights ? calcRem(props.heights.desktop) : 'auto',
    margin: '0 auto',
    paddingTop: props.verticalSpacing ? calcRem(props.verticalSpacing) : 0,
    paddingBottom: props.verticalSpacing ? calcRem(props.verticalSpacing) : 0,
    maxWidth: '100%',
    maxHeight: '82px',

    [breakpointMax('sm')]: {
        height: props.heights ? calcRem(props.heights.tablet) : 'auto',
    },
    [breakpointMax('xxs')]: {
        height: props.heights ? calcRem(props.heights.mobile) : 'auto',
    },
}))

export const StyledAmpLogo = styled(AmpImage)({
    display: 'block',
})

export const StyledLink = styled(WebLink)(({ theme }) => ({
    paddingLeft: calcRem(metrics.thewest.margins.sm),
    lineHeight: 1,
    fontFamily: fonts.thewest.sansSerif,
    fontSize: calcRem(14),
    fontWeight: 500,
    color: colors.black,
    textDecoration: 'none',
    transition: `color ${easing.easeOut.fast}`,

    '&:not(:last-child)': {
        borderRight: `1px solid ${colors.black}`,
        paddingRight: calcRem(metrics.thewest.margins.sm),
    },

    '&:hover, &:focus': {
        color: theme.sectionValues.primaryColor,
        textDecoration: 'underline',
    },

    '&:focus': {
        outline: 'none',
    },
}))
