import styled from '@emotion/styled'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

const { neutral } = tokens.thenightly.colors.palette

export const HeaderContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
})

export const HeaderPrefix = styled.p(({ theme }) => [
    textMixin<TheNightlyThemeConfig>(theme, 'item-small'),
    {
        margin: 0,

        color: neutral[60],
    },
])
