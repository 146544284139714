import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { FlexDirectionProperty } from 'csstype'
import { breakpoint, calcRem, metrics } from '../../../__styling'

export interface ContainerWithBorderTopAndBottomProps {
    flexDirection?: FlexDirectionProperty
    color?: string
    addPadding?: boolean
    addMargin?: boolean
}

/**
 * Used as a container with top and bottom borders. This is used
 * with the login modal, inline call to actions, etc.
 */
export const StyledContainerWithBorderTopAndBottom = styled(
    'div',
)<ContainerWithBorderTopAndBottomProps>(
    ({
        color = tokens.thenightly.colors.palette.neutral[50],
        addPadding = true,
        addMargin = false,
        flexDirection = 'column',
    }) => ({
        borderTop: `0.5px solid ${color}`,
        borderBottom: `0.5px solid ${color}`,
        display: 'flex',
        flexDirection: flexDirection,

        // Anything small than xxs
        padding: addPadding
            ? calcRem(metrics.thenightly.margins.md)
            : undefined,
        margin: addMargin
            ? calcRem(
                  metrics.thenightly.margins.lg,
                  metrics.thenightly.margins.sm,
              )
            : undefined,

        // Anything bigger than or equal to Surface Duo
        [breakpoint('xxs')]: {
            padding: addPadding ? calcRem(24, 16) : undefined,
            margin: addMargin
                ? `${calcRem(metrics.thenightly.margins.lg)} 0`
                : undefined,
        },
    }),
)

StyledContainerWithBorderTopAndBottom.displayName =
    'StyledContainerWithBorderTopAndBottom'
export default StyledContainerWithBorderTopAndBottom
