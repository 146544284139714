import React from 'react'
import { MarsContent } from './Mars.routing'
import {
    MarsContainer,
    MarsHeader,
    PrimarySlot,
    SecondarySlot,
    SlotContainer,
} from './Mars.styled'

type MarsProps = {
    content: MarsContent
    sectionHeader?: String
}

export const Mars = ({ content, sectionHeader }: MarsProps) => {
    return (
        <MarsContainer>
            <MarsHeader>{sectionHeader}</MarsHeader>
            <SlotContainer>
                <PrimarySlot>{content.primary}</PrimarySlot>
                <SecondarySlot>{content.secondary}</SecondarySlot>
            </SlotContainer>
        </MarsContainer>
    )
}
