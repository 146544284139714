import { createRenderTarget } from '../../render-target'
import { TNFooterWeb } from './TNFooter.web'
import { TNFooterLinks } from './components'

export interface TNFooterProps {
    links: TNFooterLinks
}

export const TNFooter = createRenderTarget('TNFooter', {
    web: TNFooterWeb,
    amp: null,
    rss: TNFooterWeb,
    preview: TNFooterWeb,
    app: null,
})
TNFooter.displayName = 'TNFooter'
