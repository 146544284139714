import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../../__styling'
import { FlexDirectionProperty } from 'csstype'

export interface ContainerProps {
    flexDirection?: FlexDirectionProperty
    marginBottom?: number
    addPadding?: boolean
}
export const StyledContainer = styled('div')<ContainerProps>(
    ({
        flexDirection = 'column',
        marginBottom = undefined,
        addPadding = true,
    }) => ({
        display: 'flex',
        flexDirection: flexDirection,
        marginBottom: marginBottom ? calcRem(marginBottom) : undefined,

        // Anything small than xxs
        padding: addPadding ? calcRem(16) : undefined,

        // Anything bigger than or equal to Surface Duo
        [breakpoint('xxs')]: {
            padding: addPadding ? calcRem(24, 16) : undefined,
        },
    }),
)

StyledContainer.displayName = 'StyledContainer'
export default StyledContainer
