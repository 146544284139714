import React from 'react'
import { TNFooterProps } from './TNFooter'
import {
    Footer,
    ContentWrapper,
    Divider,
    BottomWrapper,
    LegalWrapper,
    Copyright,
    TopWrapper,
    StyledTheNightlyLogo,
    RowBreak,
    StyledCta,
    StyledLinkGroups,
} from './TNFooter.styled'
import { LegalLinks, SocialLinks } from './components'
import { useFeature } from '@news-mono/web-common'

/**
 * Footer for the nightly.
 */
export const TNFooterWeb = ({
    links: { linkGroups, legalLinks, socialLinks },
}: TNFooterProps) => {
    const appCtaEnabled = useFeature('web-app-download')

    return (
        <Footer id="footer">
            <ContentWrapper>
                <TopWrapper>
                    {/* When the mlg breakpoint is hit, the following will be re-ordered in the flex layout */}
                    {appCtaEnabled && <RowBreak />}
                    <StyledLinkGroups linkGroups={linkGroups} />
                    {appCtaEnabled && <StyledCta />}
                    <StyledTheNightlyLogo />
                </TopWrapper>
                <Divider />
                <BottomWrapper>
                    <LegalWrapper>
                        <Copyright>
                            © Seven West Media Limited{' '}
                            {new Date().getFullYear()}
                        </Copyright>
                        <LegalLinks legalLinks={legalLinks} />
                        <SocialLinks socialLinks={socialLinks} />
                    </LegalWrapper>
                </BottomWrapper>
            </ContentWrapper>
        </Footer>
    )
}
