import {
    createRegisterableComponentWithData,
    EditionLoader,
} from '@news-mono/web-common'
import React from 'react'
import { NightlyLatestEdition } from './LatestEdition/NightlyLatestEdition'

export interface NightlyLatestEditionRouteProps {
    title: string
    type: 'homepage' | 'editions'
    lazyloadImages?: boolean
}

export const NightlyLatestEditionRegistration =
    createRegisterableComponentWithData(
        'nightly-latest-edition',
        EditionLoader,
        (
            { type, title, lazyloadImages }: NightlyLatestEditionRouteProps,
            data,
            services,
        ) => {
            if (!data.loaded) return null

            const edition = data.result.documents?.[0]

            if (!edition) {
                services?.log?.error(data, 'NightlyLatestEdition: empty result')
                return null
            }

            return (
                <NightlyLatestEdition
                    edition={edition}
                    title={title}
                    type={type}
                    lazyloadImages={lazyloadImages}
                    onEvent={services.onEvent}
                />
            )
        },
    )
