import { TheNightlySection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { AdTargeting, PageType, StaticRoutes } from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'

function createPage(
    pageTitle: string,
    description: string,
    adTargeting: AdTargeting,
    contentComponentType: Array<CheckedComponentInformation>,
    section: TheNightlySection = 'default',
    fullWidth?: boolean,
): PageType<TheNightlySection> {
    return {
        kind: 'page',
        heading: pageTitle,
        pageType: 'static',
        section,
        adTargeting,
        hideHeading: true,
        pageMeta: {
            description,
        },
        socialMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    pageHeading: pageTitle,
                    containerWidth:
                        metrics.thenightly.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                    horizontalGutters: 'outerMargin',
                    fullWidth,
                },
                contentAreas: {
                    main: contentComponentType,
                },
            }),
        ],
    }
}

export const staticContentRoutes: StaticRoutes<TheNightlySection> = {
    '/contact': ({ getAdTargeting }) =>
        createPage(
            'Contact Us',
            'Contact Us | The Nightly',
            getAdTargeting('home', 'default', 'contact'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'contact' },
                    },
                }),
            ],
        ),
    '/editorial-policy': ({ getAdTargeting }) =>
        createPage(
            'Editorial Policy',
            'Editorial Policy | The Nightly',
            getAdTargeting('home', 'default', 'editorial-policy'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'editorial-policy',
                        },
                    },
                }),
            ],
        ),
    '/terms-of-use': ({ getAdTargeting }) =>
        createPage(
            'Terms of Use',
            'Terms of Use | The Nightly',
            getAdTargeting('home', 'default', 'terms'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        pageHeading: '',
                        dataDefinitionArgs: {
                            id: 'terms',
                        },
                    },
                }),
            ],
        ),
    '/about': ({ getAdTargeting }) =>
        createPage(
            'About Us',
            'About Us | The Nightly',
            getAdTargeting('home', 'default', 'about'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'about',
                        },
                    },
                }),
            ],
        ),
    '/privacy-policy': ({ getAdTargeting }) =>
        createPage(
            'Privacy Statement',
            'Privacy Statement | The Nightly',
            getAdTargeting('home', 'default', 'privacy-policy'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'privacy-policy' },
                    },
                }),
            ],
        ),
    '/community-guidelines': ({ getAdTargeting }) =>
        createPage(
            'Community Guidelines',
            'The community guidelines for commenting and reader discussions on thenightly.com.au when joining the conversation.',
            getAdTargeting('home', 'default', 'community-guidelines'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'community-guidelines',
                        },
                    },
                }),
            ],
        ),
    '/subscription-terms': ({ getAdTargeting }) =>
        createPage(
            'Subscription Terms',
            'Subscription Terms | The Nightly',
            getAdTargeting('home', 'default', 'subscription-terms'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'subscription-terms',
                        },
                    },
                }),
            ],
        ),
    '/editorial-complaints': ({ getAdTargeting }) =>
        createPage(
            'Editorial Complaints',
            'Editorial Complaints | The Nightly',
            getAdTargeting('home', 'default', 'editorial-complaints'),
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'editorial-complaints',
                        },
                    },
                }),
            ],
        ),
    '/subscribe-to-newsletter': ({ getAdTargeting }) =>
        createPage(
            'Subscribe to Newsletter',
            'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.',
            getAdTargeting('home', 'default', 'subscribe-to-newsletter'),
            [
                layout.component({
                    type: 'tn-newsletter-signup',
                    props: {
                        text: `Sign up to The Nightly's newsletters.`,
                        subText:
                            'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.',
                        buttonLabel: 'Get the Newsletter',
                        mode: 'default',
                        persistent: true,
                    },
                }),
            ],
            'default',
            true,
        ),
    '/content-funding': () =>
        createPage(
            'Commercial content on The Nightly',
            'The Nightly produces a variety of digital content, we aim to make it clear how the content has been commissioned and produced, and who has funded it.',
            {
                pageId: 'content-funding',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'content-funding',
                        },
                    },
                }),
            ],
        ),
}
