import {
    createRegisterableComponentWithData,
    ContentDataDefinitionLoader,
} from '@news-mono/web-common'
import React from 'react'
import { Card, CardProps } from './Card'

type CardRegistrationProps = Omit<CardProps, 'item' | 'onEvent'>

export const CardRegistration = createRegisterableComponentWithData(
    'card',
    ContentDataDefinitionLoader,
    (props: CardRegistrationProps, data, { onEvent }) => {
        const results = data.loaded && data.result.publications[0]
        if (results && results.cardType !== 'publication') {
            throw Error(
                'Only publication types can be displayed in a Lead Card!',
            )
        }

        return (
            <Card
                item={
                    results
                        ? { loaded: true, result: results }
                        : { loaded: false }
                }
                onEvent={onEvent}
                {...props}
            />
        )
    },
)
