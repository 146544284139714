import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ALLOWED_CONTENT_NAV_ROUTES } from '../constants'

export const containerClassNameIdentifier = 'navigationContainer'
export const pageContainerId = 'page-container'
export const contentAreaSuffix = '_CONTENT_NAV_AREA'

interface UseIntersectionObserverProps {
    pathname: string
    setIntersectingCallback: (id: string) => void
    setExitedIntersection: (id: string, isExitDirectionUp: boolean) => void
    setMenuItemsCallback: (nodes: Element[]) => void
}

const MAX_RETRIES = 5

export const useIntersectionObserver = ({
    pathname,
    setIntersectingCallback,
    setExitedIntersection,
    setMenuItemsCallback,
}: UseIntersectionObserverProps) => {
    const [nodes, setNodes] = useState<Element[]>([])
    const [retryCount, setRetryCount] = useState(0)
    const ignoreFirstCallbackFire = useRef(true)

    useEffect(() => {
        if (!ALLOWED_CONTENT_NAV_ROUTES.includes(pathname)) {
            setNodes([])
        }
        ignoreFirstCallbackFire.current = true
        setRetryCount(0)
    }, [pathname])

    useEffect(() => {
        let contentObserver: IntersectionObserver | undefined = undefined

        if (
            typeof window !== 'undefined' &&
            ALLOWED_CONTENT_NAV_ROUTES.includes(pathname)
        ) {
            contentObserver = new IntersectionObserver(
                (entries, _) => {
                    entries.forEach((entry) => {
                        // When navigating back to the homepage, the loading can cause a content shift
                        // that results in the callback firing erroneously
                        if (ignoreFirstCallbackFire.current) {
                            ignoreFirstCallbackFire.current = false
                            return
                        }

                        if (entry.isIntersecting) {
                            setIntersectingCallback(entry.target.id)
                        } else {
                            // We need to set exit direction, as we can't rely on the scroll event firing
                            // before the intersection, so do not always know which direction we are scrolling.
                            setExitedIntersection(
                                entry.target.id,
                                isExitDirectionUp(entry.boundingClientRect.top),
                            )
                        }
                    })
                },
                {
                    threshold: [
                        0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
                    ],
                },
            )

            const contentNodes = document.querySelectorAll(
                `.${containerClassNameIdentifier}`,
            )

            // When navigating from another page, this useEffect will often run before the nodes
            // have been added to the array. Adding listeners to check for DOM changes is expensive, so we rerun the
            // useEffect to look for the nodes.
            if (
                contentNodes.length < 1 &&
                nodes.length < 1 &&
                retryCount < MAX_RETRIES
            ) {
                setRetryCount((prev) => prev + 1)
            }

            const nodesToObserve = filterObservedNodes(contentNodes)

            if (contentObserver && nodesToObserve.length > 0) {
                // Add menu observers
                nodesToObserve.map((node) => contentObserver?.observe(node))
                nodesToObserve.map((node) => setNodeToObserved(node))
                setNodes(nodesToObserve)
                setRetryCount(0)
                setMenuItemsCallback(nodesToObserve)
            }
        }

        return () => {
            nodes.map((node) => contentObserver?.unobserve(node))
            nodes.map((node) => setNodeToUnobserved(node))
        }
    }, [
        nodes,
        pathname,
        retryCount,
        setExitedIntersection,
        setIntersectingCallback,
        setMenuItemsCallback,
    ])
}

export const encodeId = (heading: string) => {
    const encodedId = heading.replace(/ /g, '-')
    return encodedId + contentAreaSuffix
}

export const decodeId = (id: string) => {
    const idWithoutSuffix = id.replace(contentAreaSuffix, '')
    return idWithoutSuffix.replace(/-/g, ' ')
}

const OBSERVED_NODE_ATTRIBUTE = 'isObserved'
const OBSERVED_NODE_VALUE = 'true'

const setNodeToObserved = (node: Element) => {
    node.setAttribute(OBSERVED_NODE_ATTRIBUTE, OBSERVED_NODE_VALUE)
}

const setNodeToUnobserved = (node: Element) => {
    node.removeAttribute(OBSERVED_NODE_ATTRIBUTE)
}

const filterObservedNodes = (nodeList: NodeListOf<Element>) => {
    const nodes: Element[] = []

    for (const node of nodeList) {
        if (
            node.getAttribute(OBSERVED_NODE_ATTRIBUTE) !== OBSERVED_NODE_VALUE
        ) {
            nodes.push(node)
        }
    }

    return nodes
}

// If content areas for menu become smaller, we may need to add more nuance to this function.
const isExitDirectionUp = (boundingTop: number) => {
    return boundingTop > 0
}
