import styled, { StyledComponent } from '@emotion/styled'
import { easing } from '../../__styling/settings/easing'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../__styling/style-mixins'
import { breakpoint } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

interface ActionContentsProps {
    rendition?: string
}

export const StyledActionsContent: StyledComponent<ActionContentsProps> =
    styled('div')(() => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: calcRem(72),
        maxWidth: calcRem(metrics.thenightly.siteMetrics.mainContentWidth),
        margin: '0 auto',
        padding: calcRem(0, metrics.thenightly.margins.m2l),

        fontFamily: fonts.thenightly.sansSerif,

        transition: 'height 0.25s, padding 0.25s',

        [breakpoint('sm')]: {
            padding: calcRem(0, 32),
        },

        [breakpoint('lg')]: {
            height: calcRem(80),
            padding: 0,
        },
    }))

export const StyledNightlyHeaderItem = styled('div')(({ theme }) => ({
    button: {
        color: tokens.thenightly.colors.palette.neutral[100],
    },
}))

const linkVertPadding = 13

export const StyledNavTrigger = styled('button')(({ theme }) => ({
    marginRight: calcRem(8),
    padding: calcRem(linkVertPadding / 2, 0),
    whiteSpace: 'nowrap',
    border: 0,
    backgroundColor: 'transparent',
    color: 'currentcolor',
    cursor: 'pointer',
    transition: `background ${easing.easeOut.fast}, boxShadow ${easing.easeOut.fast}`,
}))

export interface StyledSubnavTriggerIconProps {
    isActive?: boolean
}

export const StyledSubnavTriggerIcon = styled(
    'div',
)<StyledSubnavTriggerIconProps>(
    {
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: calcRem(18),
        height: calcRem(2),
        background: 'currentColor',
        transition: `background ${easing.easeOut.fast}`,
        transform: `translateY(-1px)`,
        borderRadius: calcRem(1),

        '&::before, &::after': {
            content: `''`,
            position: 'absolute',
            transformOrigin: 'center',
            right: 0,
            left: 0,
            height: calcRem(2),
            background: 'currentColor',
            transition: `transform ${easing.easeOut.med}, top ${easing.easeOut.fast}, bottom ${easing.easeOut.fast}`,
            borderRadius: calcRem(1),
        },

        '&::before': {
            top: calcRem(5),
        },

        '&::after': {
            bottom: calcRem(5),
        },
    },
    (props) =>
        props.isActive === true && {
            background: 'transparent',

            '&::before': {
                transform: 'rotate(45deg)',
                top: 0,
            },

            '&::after': {
                transform: 'rotate(-45deg)',
                bottom: 0,
            },
        },
)

export const StyledButtonLabelHidden = styled('span')({
    ...visuallyhidden,
})

export const HeaderCenter = styled.div({
    position: 'absolute',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    translate: '-50%',
})

export const StyledActionGroup = styled('div')({
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    height: '100%',
})

export const StyledSearchAction = styled.div({
    display: 'none',
    [breakpoint('lg')]: {
        display: 'flex',
    },
})
