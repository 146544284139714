import styled from '@emotion/styled'
import {
    breakpoint,
    breakpointSwitch,
    getComponentTheme,
} from '../../../__styling'

export const SectionContentContainer = styled.div(({ theme }) => {
    const sectionContentTheme = getComponentTheme(theme, 'sectionContent')
    return [
        {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: sectionContentTheme.maxWidth,
            margin: '0 auto',
        },
        breakpointSwitch(sectionContentTheme.gap, (gap) => ({
            gap,
        })),
    ]
})

export const BackToTopWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',

    [breakpoint('lg')]: {
        justifyContent: 'flex-end',
    },
})
