import React, { ReactNode } from 'react'
import {
    Divider,
    FiltersWrapper,
    SavedArticlesFiltersContainer,
    TrailingContent,
} from './SavedArticlesFiltersPanel.styled'
import { useFeature } from '@news-mono/web-common'
import { SavedArticlesFilters } from '../SavedArticlesFilters'
import { FilterEvent } from '../../../result-filters/filter-events-util'

export type SavedArticlesFiltersPanelProps = {
    trailingContent: ReactNode
    onEvent: (event: FilterEvent) => void
}

export const SavedArticlesFiltersPanel = ({
    trailingContent,
    onEvent,
}: SavedArticlesFiltersPanelProps) => {
    const showSearchFilters = useFeature('show-saved-articles-search-filter')

    return (
        <SavedArticlesFiltersContainer>
            {showSearchFilters && (
                <FiltersWrapper>
                    <SavedArticlesFilters showHeader={true} onEvent={onEvent} />
                    <Divider />
                </FiltersWrapper>
            )}
            <TrailingContent>{trailingContent}</TrailingContent>
        </SavedArticlesFiltersContainer>
    )
}
