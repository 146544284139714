import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakpoint, metrics, TheNightlyThemeConfig } from '../../__styling'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { EditorialType } from '../../templates'
import { tokens } from '@news-mono/design-tokens'
import { keyframes } from '@emotion/css'
import { AnimationKeyframePercentage } from './NewsTicker'
import { WebLink } from '@news-mono/web-common'

const dynamicScrollUp = (
    animationKeyframes: AnimationKeyframePercentage,
) => keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  ${animationKeyframes.fadeInEnd.toString()}% {
    transform: translateY(0%);
    opacity: 1;
  }
  ${animationKeyframes.fadeOutStart.toString()}% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`

const dynamicScrollLeft = (
    fullWidth: number,
    animationKeyframes: AnimationKeyframePercentage,
) => keyframes`
0% {
  transform: translateY(0%);
  opacity: 1;
}
${animationKeyframes.scrollLeftStart.toString()}% {
  transform: translateY(0%);
  opacity: 1;
}
${animationKeyframes.scrollLeftEnd.toString()}% {
  transform: translateY(0%) translateX(-${fullWidth}px);
  opacity: 1;
}
100% {
  transform: translateX(-${fullWidth}px) translateY(0%);
  opacity: 1;
}
`

const scrolUpAndLeftWithTrail = (
    fullWidth: number,
    animationKeyframes: AnimationKeyframePercentage,
) => keyframes`
0% {
  transform: translateY(100%);
  opacity: 0;
}
${animationKeyframes.fadeInEnd.toString()}% {
  transform: translateY(0%);
  opacity: 1;
}
${animationKeyframes.scrollLeftStart.toString()}% {
  transform: translateY(0%);
  opacity: 1;
}
${animationKeyframes.scrollLeftEnd.toString()}% {
  transform: translateY(0%) translateX(-${fullWidth}px);
  opacity: 1;
}
/* Pause  */
${animationKeyframes.fadeOutStart}% {
  transform: translateY(0%) translateX(-${fullWidth}px);
  opacity: 1;
}
100% {
  transform: translateX(-${fullWidth}px) translateY(-100%);
  opacity: 0;
}
`
export const StyledContainer = styled('div')<{
    withGradientBackground?: boolean
    isOverflowing: boolean
}>(({ withGradientBackground, isOverflowing }) => [
    {
        display: 'grid',
        gridTemplateColumns: `1fr ${calcRem(24)}`,
        justifyContent: 'flex-start',
        overflow: 'clip',
        overflowClipMargin: 'content-box',
        gap: calcRem(8),
        alignItems: 'center',
        margin: '0 auto',

        background: withGradientBackground
            ? 'linear-gradient(90deg, rgba(253, 187, 100, 0.25) 0.06%, rgba(254, 204, 194, 0.25) 19.58%, rgba(248, 176, 200, 0.25) 39.68%, rgba(181, 136, 215, 0.25) 58.56%, rgba(91, 70, 166, 0.25) 79.42%, rgba(16, 11, 86, 0.25) 100%)'
            : undefined,
        position: 'relative',
        borderTop: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,

        '&::after': withGradientBackground
            ? {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  width: '100vw',
                  height: calcRem(0.5),
                  background: `linear-gradient(90deg, rgba(253, 187, 100, 0.4) 0.06%, rgba(254, 204, 194, 0.4) 19.58%, rgba(248, 176, 200, 0.4) 39.68%, rgba(181, 136, 215, 0.4) 58.56%, rgba(91, 70, 166, 0.4) 79.42%, rgba(16, 11, 86, 0.4) 100%)`,
              }
            : undefined,

        [breakpoint('sm')]: {
            display: 'flex',
            justifyContent: isOverflowing ? 'flex-start' : 'center',
            maxWidth: withGradientBackground
                ? 'undefined'
                : calcRem(metrics.thenightly.siteMetrics.mainContentWidth),
        },

        [breakpoint('lg')]: {
            display: 'flex',
            borderTop: 0,
        },
    },
])

export const StyledTextContainer = styled(WebLink)<{
    horizontalPadding: number
}>(({ horizontalPadding }) => [
    {
        whiteSpace: 'nowrap',
        display: 'flex',
        gap: calcRem(8),
        overflow: 'hidden',
        padding: calcRem(12, 0),
        margin: calcRem(0, horizontalPadding, 0, horizontalPadding),
        textDecoration: 'none',
        color: 'unset',

        [`&:is(:hover, :focus, :visited)`]: {
            textDecoration: 'none',
        },

        [breakpoint('sm')]: {
            margin: calcRem(0, 0, 0, horizontalPadding),
        },
    },
])

export const StyledAnimatedTextContainer = styled('div')<{
    isOverflowing: boolean
    playFadeInOut: boolean
    fullWidth: number
    animationTime: number
    gapBetweenComponents: number
    animationKeyframes: AnimationKeyframePercentage
}>(
    ({
        isOverflowing,
        fullWidth,
        playFadeInOut,
        animationTime,
        animationKeyframes,
        gapBetweenComponents,
    }) => [
        {
            whiteSpace: 'nowrap',
            display: 'flex',
            gap: calcRem(gapBetweenComponents),

            animation: playFadeInOut
                ? isOverflowing
                    ? `${scrolUpAndLeftWithTrail(
                          fullWidth,
                          animationKeyframes,
                      )} ${animationTime}s linear`
                    : `${dynamicScrollUp(
                          animationKeyframes,
                      )} ${animationTime}s linear`
                : isOverflowing
                ? `${dynamicScrollLeft(
                      fullWidth,
                      animationKeyframes,
                  )} ${animationTime}s linear infinite`
                : undefined,

            [breakpoint('sm')]: {
                padding: 0,
            },
        },
    ],
)

export const StyledFixedArrow = styled('div')<{
    isOverflowing: boolean
    animationTime: number
    animationKeyframes: AnimationKeyframePercentage
    arrowPaddingLeft: number
    arrowPaddingRight: number
}>(
    ({
        isOverflowing,
        animationTime,
        animationKeyframes,
        arrowPaddingLeft,
        arrowPaddingRight,
    }) => [
        {
            display: 'flex',
            position: 'fixed',
            right: 0,
            padding: calcRem(0, arrowPaddingRight, 0, arrowPaddingLeft),

            [breakpoint('sm')]: {
                display: isOverflowing ? 'flex' : 'none',
                position: 'relative',

                animation: isOverflowing
                    ? `${dynamicScrollUp(
                          animationKeyframes,
                      )} ${animationTime}s linear`
                    : undefined,
            },
        },
    ],
)

export const StyledFloatyArrow = styled('div')<{
    isOverflowing: boolean
}>(({ isOverflowing }) => [
    {
        display: 'none',

        [breakpoint('sm')]: {
            display: isOverflowing ? 'none' : 'flex',
            position: isOverflowing ? 'absolute' : 'relative',
            padding: isOverflowing ? calcRem(0, 24) : undefined,

            right: isOverflowing ? 0 : undefined,
        },
    },
])

export const StyledClassification = styled.div<{
    type: EditorialType
}>(({ theme, type }) => [
    {
        textTransform: 'uppercase',
        color:
            type === EditorialType.Live || type === EditorialType.Exclusive
                ? tokens.thenightly.colors.palette.utility.error.default
                : undefined,
        display: 'inline-flex',
        gap: calcRem(4),
        span: {
            alignSelf: 'center',
        },
    },
    textMixin<TheNightlyThemeConfig>(theme, 'byline-primary-large'),
])

export const StyledText = styled.div<{ marginRight: number }>(
    ({ theme, marginRight }) => [
        {
            marginRight: calcRem(marginRight),
            display: 'inline-block',
        },
        textMixin<TheNightlyThemeConfig>(theme, 'byline-primary-large'),
    ],
)

export const StyledArrowLink = styled(WebLink)(() => ({
    display: 'flex',
    alignItems: 'center',
}))
