import { WebLink } from '@news-mono/web-common'
import React from 'react'
import {
    StyledWestRewardsContainer,
    StyledWestRewardsLogo,
    StyledWestRewardsLogoContainer,
    StyledWestRewardsMessage,
    StyledWestRewardsWrapper,
} from './WestRewardsMessageStyled'

export const WestRewardsMessage: React.FC = () => {
    return (
        <StyledWestRewardsWrapper>
            <StyledWestRewardsContainer>
                <WebLink to="https://rewards.thewest.com.au/">
                    <StyledWestRewardsLogoContainer>
                        <StyledWestRewardsLogo />
                    </StyledWestRewardsLogoContainer>
                </WebLink>
                <StyledWestRewardsMessage>
                    <p>
                        Enjoy exclusive member discounts, giveaways and
                        competitions with our subscriber rewards program.
                    </p>
                </StyledWestRewardsMessage>
            </StyledWestRewardsContainer>
        </StyledWestRewardsWrapper>
    )
}
