import React, { FC, useEffect, useRef } from 'react'
import {
    StyledNavigationMenuContainer,
    StyledNavigationMenuItemContainer,
} from './ContentNav.styled'
import { ContentNavMenuItem } from './ContentNavMenuItem'
import { HomeMenuItem } from './components/HomeMenuItem'
import { HamburgerMenuItem } from './components/HamburgerMenuItem'
import { TheNightlyHeaderNavState } from '../TNHeaderNav'
import { useDraggableScroll } from './hooks/useDraggableScroll'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'

export interface ContentNavProps {
    navState: TheNightlyHeaderNavState
}

type InternalContentNavProps = ContentNavProps & DimensionProps

export const InternalContentNav: FC<InternalContentNavProps> = ({
    navState,
    height,
    innerRef,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const { onMouseDown, hasDragged } = useDraggableScroll({ ref })

    const {
        activeContentArea,
        contentNavMenuItems: menuItems,
        isMobile,
        isSystemScroll,
        highlightDirection,
        contentNavHeight,
        updateContentNavHeight,
        onMenuItemClick,
        onHomeItemClick,
        onHamburgerItemClick,
    } = navState

    useEffect(() => {
        if (contentNavHeight !== height) {
            updateContentNavHeight(height)
        }
    }, [contentNavHeight, height, updateContentNavHeight])

    const handleScrollNavigation = (index: number) => {
        if (!hasDragged) {
            onMenuItemClick(index)
        }
    }

    return (
        <StyledNavigationMenuContainer
            className="contentNavMenu"
            ref={innerRef}
        >
            {!isMobile && <HamburgerMenuItem onClick={onHamburgerItemClick} />}
            <StyledNavigationMenuItemContainer
                ref={ref}
                onMouseDown={onMouseDown}
            >
                {isMobile && <HomeMenuItem onClick={onHomeItemClick} />}
                {menuItems.map((menuItem) => (
                    <ContentNavMenuItem
                        section={menuItem.section}
                        heading={menuItem.heading}
                        highlightDirection={highlightDirection}
                        isSelected={activeContentArea === menuItem.index}
                        isMobile={isMobile}
                        isSystemScroll={isSystemScroll}
                        handleScrollNavigation={() =>
                            handleScrollNavigation(menuItem.index)
                        }
                        key={menuItem.heading}
                    />
                ))}
            </StyledNavigationMenuItemContainer>
        </StyledNavigationMenuContainer>
    )
}

export const ContentNav = withDimensions<ContentNavProps>(InternalContentNav)

ContentNav.displayName = 'ContentNav'
