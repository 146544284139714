import styled from '@emotion/styled'
import { breakpointMax } from '../../../__styling'
import { ButtonLink } from '../../../buttons/ButtonLink/ButtonLink'

export const StyledLinkButton = styled(ButtonLink)(({ theme }) => ({
    textDecorationLine: 'underline',

    [breakpointMax('xxs')]: {
        textAlign: 'left',
    },
}))
