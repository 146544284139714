import { Features, isFeatureName, useToggleState } from "@news-mono/web-common";
import { AbTestExperiment, getDefaultVariant } from "../AbTestingProvider";

/**
 * Used to get the stored variant for the user's experiment.
 *
 * @return number
 */
export function useVariantForExperiment(experiment?: AbTestExperiment): number {
    const stateFeatures = useToggleState()
    const defaultVariant = getDefaultVariant()

    if (typeof(experiment) === 'undefined') {
        return defaultVariant
    }

    const isFeature = isFeatureName(experiment.getId())
    if(!isFeature) {
        return defaultVariant
    }

    const state = stateFeatures?.[experiment.getId() as Features]

    // We need to take 1 from the variant as we want it to start from 0 -> max variant
    //  where variant 0 is always the control.
    if (typeof(state) !== 'undefined') {
        // if it's a number, return number
        if (typeof(state) === 'number') {
            return Number(state) - 1

        // else if it's a single letter, return alpha value of letter
        } else if (typeof(state) === 'string' && state.length === 1) {
            return (state as string).toLowerCase().charCodeAt(0) - 97
        }
    }

    return defaultVariant
}
