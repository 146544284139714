import { TopicCount } from '@west-australian-newspapers/publication-types'
import React, { useState } from 'react'

export interface FilterContext {
    topicCounts: TopicCount[]
}
export interface FilterContextProps {
    setContext: (context: FilterContext) => void
    clearContext: () => void
    context: FilterContext | undefined
}

export const FilterContext = React.createContext<FilterContextProps>({
    setContext: () => null,
    clearContext: () => null,
    context: undefined,
})

export const FilterContextProvider: React.FC = ({ children }) => {
    const [context, setFilterContext] = useState<FilterContext | undefined>(
        undefined,
    )

    const setContext = (newContext: FilterContext | undefined) => {
        setFilterContext(newContext)
    }

    const clearContext = () => {
        setFilterContext(undefined)
    }

    return (
        <FilterContext.Provider value={{ setContext, clearContext, context }}>
            {children}
        </FilterContext.Provider>
    )
}
