import { TheNightlySection } from '@news-mono/common'
import {
    IconChevronUpTN,
    metrics,
    sectionThemeOverrider,
} from '@news-mono/component-library'
import {
    AdTargeting,
    ContentDataTypes,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ClassificationV4DTO } from '@west-australian-newspapers/publication-types'
import { CheckedComponentInformation } from 'json-react-layouts'
import React from 'react'
import { layout } from '../../App.routing'
import { sectionNavItems } from '../../navigation'
import queryString from 'query-string'
import H from 'history'
import { getPaginatedCanonicalUrl, parsePageParam } from '../helpers'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

export interface Props {
    topic: Topic
    publicationKind?: 'article' | 'gallery'
    topicSource?: string
    section: TheNightlySection
    getAdTargeting: GetRouteAdTargeting
    classification?: ClassificationV4DTO
    location: H.Location<any>
    baseUrl: string
    toggles: TogglesReduxState
}

const MAIN_CONTENT_ID = 'main-content'

export const getTopicPageRouteInfo = ({
    topic,
    publicationKind,
    topicSource,
    section,
    getAdTargeting,
    classification,
    location,
    baseUrl,
    toggles,
}: Props): PageType<TheNightlySection> => {
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )
    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    if (section === 'default') {
        throw new Error("'default' section does not exist.")
    }
    const query = queryString.parse(location.search)
    const page = parsePageParam(query.page)
    const title =
        page > 1
            ? `${topic.seoTitle || topic.title} | Page ${page}`
            : topic.seoTitle || topic.title
    const topicUrl = `${baseUrl}${location.pathname}`
    const canonicalUrl =
        page > 1 ? getPaginatedCanonicalUrl(topicUrl, page) : topicUrl

    const featureState = toggles && toFeatureState(toggles)

    const isOlympicsRoute = topic.id === 'sport/olympics'
    const olympicsMedalWidgetEnabled =
        isOlympicsRoute &&
        Boolean(
            featureState &&
                isFeatureEnabled(featureState, 'paris-olympics-widgets', false),
        )

    return {
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        includeFromSectionInMetaDescription: !topic.seoDescription,
        pageMeta: {
            title,
            description:
                topic.seoDescription || `The latest in ${topic.seoTitle}`,
            meta: [
                {
                    property: 'og:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:title',
                    content: `${topic.seoTitle || topic.title} | The Nightly`,
                },
                {
                    property: 'twitter:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
            ],
            link: [{ rel: 'canonical', href: canonicalUrl }],
        },
        adTargeting,
        section,
        compositions: [
            wrappingContexts(
                [
                    layout.nestedComposition({
                        type: 'thor',
                        props: {
                            horizontalGutters: false,
                            verticalGutters: false,
                            containerWidth:
                                metrics.thenightly.siteMetrics.mainContentWidth,
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'section-header',
                                    props: {
                                        heading: topic.title,
                                        showReadMore: false,
                                        isStandalonePage: true,
                                    },
                                }),
                            ],
                        },
                    }),
                    layout.nestedComposition({
                        type: 'luna',
                        props: {
                            hasBeforeAside: false,
                            dividerConfig: {
                                column: {
                                    hasAsideAndAfterAsideDivider: false,
                                },
                            },
                        },
                        contentAreas: {
                            beforeAside: [],
                            aside: [
                                layout.nestedComposition({
                                    type: 'box',
                                    props: {
                                        stickyOffset:
                                            'calc(var(--stickyHeaderHeight) + 80px)',
                                    },
                                    contentAreas: {
                                        main: [
                                            layout.nestedComposition({
                                                type: 'tag-link-navigation',
                                                props: {
                                                    sectionNavItem:
                                                        sectionNavItems[
                                                            section
                                                        ],
                                                },
                                                contentAreas: {
                                                    trailingContent: [
                                                        ...getOlympicMedalTallyWidget(
                                                            olympicsMedalWidgetEnabled,
                                                        ),
                                                        layout.component({
                                                            type: 'ad-unit',
                                                            props: {
                                                                adType: 'inline',
                                                                noticePosition:
                                                                    'above-center',
                                                                slot: {
                                                                    id: 'sidebar',
                                                                    size: 'above1280Mrec',
                                                                },
                                                            },
                                                        }),
                                                    ],
                                                },
                                            }),
                                        ],
                                    },
                                }),
                            ],
                            afterAside: [
                                layout.component({
                                    type: 'topic-section-card-list',
                                    props: {
                                        dataDefinitionArgs: {
                                            type: 'context',
                                            contextId: MAIN_CONTENT_ID,
                                            section: section,
                                        },
                                        expectedItemCount: 15,
                                        adUnits: 3,
                                        adTargeting: adTargeting,
                                    },
                                }),
                            ],
                        },
                    }),
                    layout.component({
                        type: 'pagination',
                        props: {
                            contextId: MAIN_CONTENT_ID,
                        },
                    }),
                    layout.component({
                        type: 'nightly-button',
                        props: {
                            variant: 'default',
                            text: 'BACK TO TOP',
                            color: 'primary',
                            fill: 'text',
                            layout: {
                                alignSelf: 'center',
                            },
                            action: {
                                type: 'button',
                                onClick: () => window.scrollTo({ top: 0 }),
                            },
                            icon: {
                                iconPosition: 'right',
                                IconElement: <IconChevronUpTN />,
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            adType: 'inline',
                            noticePosition: 'above-center',
                            slot: {
                                id: 'below-page-content',
                                size: 'billboardAbove1280leaderboardAbove768MrecBelow',
                            },
                        },
                    }),
                ],
                section,
                {
                    type: 'listing',
                    topics: [topic.id],
                    includeSubTopics: true,
                    publicationKind,
                    source: topicSource,
                    classification,
                    paging: {
                        page,
                        pageSize: 15,
                        pageOffset: 0,
                    },
                },
            ),
        ],
    }
}

const wrappingContexts = (
    content: CheckedComponentInformation[],
    section: TheNightlySection,
    dataDefinition: ContentDataTypes,
) =>
    layout.composition({
        type: 'scheme-overrider',
        props: {
            override: sectionThemeOverrider('thenightly', section),
        },

        contentAreas: {
            children: [
                layout.nestedComposition({
                    type: 'collection-data-context',
                    props: {
                        contextId: MAIN_CONTENT_ID,
                        dataDefinitionArgs: dataDefinition,
                    },
                    contentAreas: {
                        children: content,
                    },
                }),
            ],
        },
    })

const getOlympicMedalTallyWidget = (
    featureEnabled: boolean,
): CheckedComponentInformation[] => {
    return featureEnabled
        ? [
              layout.component({
                  type: 'olympics-medal-tally',
                  props: {
                      size: 'small',
                      listSize: 10,
                      widgetTitle: 'medal-tally',
                      svgColourOverride: '#06671e',
                  },
              }),
          ]
        : []
}
