import {
    CheckedCompositionInformation,
    RendererMiddleware,
} from 'json-react-layouts'
import React from 'react'
import { ComponentServices } from '../routing/page-definition'
import {
    AuthenticatedToView,
    RequiredAuthenticationState,
} from '../data/authentication/AuthenticatedToView'

export const requireAuthenticationMiddleware: RendererMiddleware<
    ComponentServices,
    {
        requiresAuthentication?: {
            authenticationState?: RequiredAuthenticationState
            notAuthorized?: CheckedCompositionInformation
        }
    }
> = (componentProps, middlewareProps, services, next) => {
    if (middlewareProps.requiresAuthentication) {
        return (
            <AuthenticatedToView
                requiredAuthenticationState={
                    middlewareProps.requiresAuthentication
                        .authenticationState || 'signed-in-and-verified'
                }
                authenticationValid={() => (
                    <>{next(componentProps, middlewareProps, services)}</>
                )}
                authenticationInvalid={() => (
                    <>
                        {middlewareProps.requiresAuthentication
                            ?.notAuthorized &&
                            services.layout
                                .createRenderers({
                                    services: services.services,
                                })
                                .renderCompositions(
                                    middlewareProps.requiresAuthentication
                                        .notAuthorized,
                                )}
                    </>
                )}
            />
        )
    }

    return next(componentProps, middlewareProps, services)
}
