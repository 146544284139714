import { TheNightlySection } from '@news-mono/common'
import { calcRem } from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../App.routing'
import { getPuzzleRoute } from './puzzles/getPuzzleRoute'

const socialImage = require('./puzzles/assets/puzzles-social-image.png')

export const puzzles: StaticRoutes<TheNightlySection> = {
    '/puzzles': ({ getAdTargeting, config, store }) => {
        const canonicalUrl = config.publicUrl + '/puzzles'
        const topic: Topic = {
            id: 'puzzles',
            metadata: {},
            title: 'Puzzles',
            seoTitle: 'Fun Daily Online Puzzles | The Nightly',
            seoDescription: `Challenge yourself with The Nightly’s latest crosswords.`,
        }

        const inApp = store.getState().render.renditionType === 'app'

        return {
            kind: 'page',
            heading: 'Puzzles',
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                seoTitle: topic.seoTitle,
                seoDescription: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: [
                    {
                        property: 'og:image',
                        content: socialImage,
                    },
                    {
                        name: 'twitter:image',
                        content: socialImage,
                    },
                    {
                        property: 'og:image:height',
                        content: '630',
                    },
                    {
                        property: 'og:image:width',
                        content: '1200',
                    },
                    {
                        property: 'og:description',
                        content: topic.seoDescription,
                    },
                    {
                        property: 'twitter:description',
                        content: topic.seoDescription,
                    },
                ],
            },
            adTargeting: getAdTargeting('home', 'default', topic),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        flex: {
                            default: {
                                flexDirection: 'column',
                                gap: calcRem(40),
                                alignItems: 'center',
                                hasBeforeAfterMargins: inApp,
                            },
                        },
                    },
                    contentAreas: {
                        main: inApp
                            ? [
                                  layout.component({
                                      type: 'puzzles-listing',
                                      props: {},
                                  }),
                              ]
                            : [
                                  layout.component({
                                      type: 'puzzles-listing',
                                      props: {},
                                  }),
                                  layout.component({
                                      type: 'ad-unit',
                                      props: {
                                          adType: 'inline',
                                          noticePosition: 'above-center',
                                          slot: {
                                              id: 'below-page-content',
                                              size: 'leaderboardAbove768MrecBelow',
                                          },
                                      },
                                  }),
                              ],
                    },
                }),
            ],
        }
    },
    '/puzzles/crossword': getPuzzleRoute(
        {
            id: 'puzzles/crossword',
            title: 'Crossword',
            seoTitle: 'Crossword - Puzzles',
            seoDescription:
                'Solve one of The Nightly’s mini crosswords or quick crosswords now.',
            metadata: {},
            parent: {
                id: 'puzzles',
                title: 'Puzzles',
                seoTitle: 'Puzzles',
                metadata: {},
            },
        },
        'Crossword',
        'https://amuselabs.com/pmm/date-picker?set=pagemasters-the-nightly&embed=1&limit=14&noFollow=1',
    ),
}
