import React, { FC } from 'react'
import {
    StyledNewNotificationsBadge,
    StyledNotificationBellContainer,
} from './NotificationBellIcon.styled'

interface NotificationBellIconProps {
    hasNewItems: boolean
    isApp: boolean
    onClick: () => void
}

export const NotificationBellIcon: FC<NotificationBellIconProps> = ({
    hasNewItems,
    isApp,
    onClick,
}) => {
    return (
        <StyledNotificationBellContainer isApp={isApp} onClick={onClick}>
            {hasNewItems && <StyledNewNotificationsBadge />}
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.61853 20.1C10.2532 20.6601 11.0868 21 11.9998 21C12.9128 21 13.7464 20.6601 14.381 20.1M17.3998 8.4C17.3998 6.96783 16.8308 5.59432 15.8182 4.58162C14.8055 3.56893 13.4319 3 11.9998 3C10.5676 3 9.19409 3.56893 8.1814 4.58162C7.1687 5.59432 6.59977 6.96783 6.59977 8.4C6.59977 11.1812 5.8982 13.0854 5.11447 14.3449C4.45339 15.4073 4.12285 15.9385 4.13497 16.0867C4.14839 16.2508 4.18315 16.3133 4.31537 16.4114C4.43479 16.5 4.9731 16.5 6.04974 16.5H17.9498C19.0264 16.5 19.5648 16.5 19.6842 16.4114C19.8164 16.3133 19.8512 16.2508 19.8646 16.0867C19.8767 15.9385 19.5462 15.4073 18.8851 14.3449C18.1013 13.0854 17.3998 11.1812 17.3998 8.4Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </StyledNotificationBellContainer>
    )
}
