import {
    getContentNavState,
    getMenuItemsFromNodes,
    getScrollChange,
    isContentNavUsable,
    meetsThresholdToShowAd,
    doesNotMeetThresholdToShowHeader,
    doesNotMeetThresholdToShowAd,
    isMenuChangeInSameDirectionAsScroll,
    getMenuItemIndex,
    scrollDistanceDoesntMeetThreshold,
    isContentNavActive,
    shouldOpenContentNav,
    getHighlightDirection,
} from './util'
import { TheNightlyHeaderNavExternalState } from '../useTheNightlyHeaderNav'
import {
    ExitingIntersectionPayload,
    IntersectContentAreaPayload,
    ResetStatePayload,
    ScrollPayload,
    SetContentAreaPayload,
    SetContentNavItemsPayload,
    SetNavDisplayStatePayload,
    SetScrollDirectionPayload,
    SetSystemScrollPayload,
    UpdateAdHeightPayload,
    UpdateBannerHeightPayload,
    UpdateContentNavHeightPayload,
    UpdateIsMobilePayload,
} from './types'

export type DispatchActions =
    | { type: 'scroll'; payload: ScrollPayload }
    | { type: 'set-content-area'; payload: SetContentAreaPayload }
    | { type: 'intersect-content-area'; payload: IntersectContentAreaPayload }
    | { type: 'set-content-nav-items'; payload: SetContentNavItemsPayload }
    | { type: 'exiting-intersection'; payload: ExitingIntersectionPayload }
    | { type: 'set-system-scroll'; payload: SetSystemScrollPayload }
    | { type: 'set-scroll-direction'; payload: SetScrollDirectionPayload }
    | { type: 'update-ad-height'; payload: UpdateAdHeightPayload }
    | { type: 'update-is-mobile'; payload: UpdateIsMobilePayload }
    | { type: 'update-banner-height'; payload: UpdateBannerHeightPayload }
    | {
          type: 'update-content-nav-height'
          payload: UpdateContentNavHeightPayload
      }
    | { type: 'set-nav-display-state'; payload: SetNavDisplayStatePayload }
    | { type: 'reset-state'; payload: ResetStatePayload }

export interface NavReducerState extends TheNightlyHeaderNavExternalState {
    previousVerticalScrollPosition: number
    isMobileEnabled: boolean
    isDesktopEnabled: boolean
    isContentNavRoute: boolean
}

export interface ScrollProps {
    state: NavReducerState
    newScrollPosition: number
    scrollDelta: number
}

export const navReducer = (state: NavReducerState, action: DispatchActions) => {
    const { type, payload } = action

    switch (type) {
        case 'scroll':
            return handleScroll(state, payload)
        case 'intersect-content-area':
            return handleIntersectContentArea(state, payload)
        case 'exiting-intersection':
            return handleExitIntersection(state, payload)
        case 'set-content-area':
            return handleSetContentArea(state, payload)
        case 'set-content-nav-items':
            return {
                ...state,
                contentNavMenuItems: getMenuItemsFromNodes(payload.nodes),
            }
        case 'update-ad-height':
            return {
                ...state,
                adHeight: payload.adHeight,
            }
        case 'update-is-mobile':
            return {
                ...state,
                isMobile: payload.isMobile,
            }
        case 'update-banner-height':
            return {
                ...state,
                bannerHeight: payload.bannerHeight,
            }
        case 'update-content-nav-height':
            return {
                ...state,
                contentNavHeight: payload.contentNavHeight,
            }
        case 'set-system-scroll':
            return {
                ...state,
                isSystemScroll: payload.isSystemScroll,
            }
        case 'set-scroll-direction':
            return {
                ...state,
                scrollDirection: payload.scrollDirection,
            }
        case 'set-nav-display-state':
            return {
                ...state,
                navDisplayState: payload.navDisplayState,
            }
        case 'reset-state':
            return payload.newState
        default:
            return state
    }
}

const handleScroll = (
    state: NavReducerState,
    payload: ScrollPayload,
): NavReducerState => {
    const { direction: scrollDirection, delta: scrollDelta } = getScrollChange(
        payload.verticalScrollPosition,
        state.previousVerticalScrollPosition,
    )

    // Return early if it is a horizontal scroll, system scroll or the scroll doesn't meet distance thresholds
    if (
        scrollDelta === 0 ||
        state.isSystemScroll ||
        scrollDistanceDoesntMeetThreshold(scrollDelta)
    ) {
        return {
            ...state,
            previousVerticalScrollPosition: payload.verticalScrollPosition,
        }
    }

    const scrollProps = {
        state,
        newScrollPosition: payload.verticalScrollPosition,
        scrollDelta,
    }

    if (scrollDirection === 'scroll-up') {
        const newState = handleScrollUp(scrollProps)
        return {
            ...newState,
            previousVerticalScrollPosition: payload.verticalScrollPosition,
            scrollDirection: 'scroll-up',
        }
    } else {
        const newState = handleScrollDown(scrollProps)
        return {
            ...newState,
            previousVerticalScrollPosition: payload.verticalScrollPosition,
            scrollDirection: 'scroll-down',
        }
    }
}

const handleScrollUp = (props: ScrollProps): NavReducerState => {
    const { state } = props

    switch (state.navDisplayState) {
        case 'header-with-ad':
            return state
        case 'header':
        case 'none':
            if (meetsThresholdToShowAd(props)) {
                return {
                    ...state,
                    navDisplayState: 'header-with-ad',
                }
            }

            return {
                ...state,
                navDisplayState: 'header',
            }
        case 'content-nav-with-header':
            if (isContentNavActive(state)) {
                return state
            }

            return {
                ...state,
                activeContentArea: 0,
                navDisplayState: 'header',
            }
        case 'content-nav':
            if (isContentNavActive(state)) {
                return {
                    ...state,
                    navDisplayState: state.isMobile
                        ? 'content-nav-with-header'
                        : 'content-nav',
                }
            }

            if (meetsThresholdToShowAd(props)) {
                return {
                    ...state,
                    activeContentArea: 0,
                    navDisplayState: 'header-with-ad',
                }
            }

            return {
                ...state,
                activeContentArea: 0,
                navDisplayState: 'header',
            }
    }
}

const handleScrollDown = (props: ScrollProps): NavReducerState => {
    const { state } = props

    switch (state.navDisplayState) {
        case 'header-with-ad':
            if (doesNotMeetThresholdToShowHeader(props)) {
                return {
                    ...state,
                    navDisplayState: 'none',
                }
            }
            if (doesNotMeetThresholdToShowAd(props)) {
                return {
                    ...state,
                    navDisplayState: 'header',
                }
            }
            return state
        case 'header':
            if (doesNotMeetThresholdToShowHeader(props)) {
                return {
                    ...state,
                    navDisplayState: 'none',
                }
            } else {
                return state
            }
        case 'content-nav-with-header':
            return {
                ...state,
                navDisplayState: 'content-nav',
            }
        case 'none':
        case 'content-nav':
            return state
    }
}

const handleIntersectContentArea = (
    state: NavReducerState,
    payload: IntersectContentAreaPayload,
): NavReducerState => {
    if (!isContentNavUsable(state)) {
        return state
    }

    const newContentAreaIndex = getMenuItemIndex(
        state.contentNavMenuItems,
        payload.contentAreaId,
    )

    if (newContentAreaIndex < 1) {
        return state
    }

    const highlightDirection = getHighlightDirection(
        newContentAreaIndex,
        state.activeContentArea,
    )

    if (shouldOpenContentNav(newContentAreaIndex, state)) {
        return {
            ...state,
            navDisplayState: 'content-nav',
            activeContentArea: newContentAreaIndex,
            highlightDirection,
        }
    }

    // Check if the change in active content area matches the scroll direction
    // This prevents the menu jumping back and forth if we have multiple observation
    // thresholds on the IntersectionObserver
    if (isMenuChangeInSameDirectionAsScroll(newContentAreaIndex, state)) {
        return {
            ...state,
            navDisplayState: getContentNavState(state),
            activeContentArea: newContentAreaIndex,
            highlightDirection,
        }
    }

    return state
}

const handleExitIntersection = (
    state: NavReducerState,
    payload: ExitingIntersectionPayload,
): NavReducerState => {
    if (
        !isContentNavUsable(state) ||
        state.isSystemScroll ||
        !payload.isExitDirectionUp
    ) {
        return state
    }

    const exitedContentAreaIndex = getMenuItemIndex(
        state.contentNavMenuItems,
        payload.contentAreaId,
    )

    // Only reset the menu if this is the first content area
    if (exitedContentAreaIndex === 1) {
        return {
            ...state,
            activeContentArea: 0,
            navDisplayState: 'header',
        }
    }

    return state
}

const handleSetContentArea = (
    state: NavReducerState,
    payload: SetContentAreaPayload,
): NavReducerState => {
    if (!isContentNavUsable(state)) {
        return state
    }

    return {
        ...state,
        activeContentArea: payload.contentAreaIndex,
        highlightDirection: getHighlightDirection(
            payload.contentAreaIndex,
            state.activeContentArea,
        ),
    }
}
