import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    emptyGetSavedArticlesResult,
    getSavedPublicationsByUserId,
} from '../helpers'
import {
    AppState,
    AuthenticationState,
    ConfigurationContext,
    DataLayerEventName,
    FilterContext,
    ListingCollectionDataLoaded,
} from '@news-mono/web-common'

import { SavedArticlesProps } from './SavedArticles'
import { defaultOption, latestSavedSortOption } from '../../result-filters'

export const useSavedArticles = ({
    paging,
    sortBy,
    topics,
    onEvent,
}: SavedArticlesProps) => {
    const { wanUserId } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )
    const config = useContext(ConfigurationContext)
    const { setContext } = useContext(FilterContext)

    const [results, setResults] = useState<ListingCollectionDataLoaded>(
        emptyGetSavedArticlesResult,
    )
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (wanUserId) {
            setIsLoading(true)
            getSavedPublicationsByUserId(config, wanUserId, {
                paging: paging,
                sort: sortBy,
                topics: topics ? topics.split(',') : undefined,
            })
                .then((result) => {
                    setResults(result)
                    setContext({ topicCounts: result.topicCounts ?? [] })

                    onEvent({
                        type: DataLayerEventName.savedArticlesResults,
                        originator: 'SavedArticles',
                        payload: {
                            sortBy: sortBy ?? latestSavedSortOption.paramName,
                            topics: topics ?? defaultOption,
                            totalResults: result.total ?? 0,
                        },
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
        // We can ignore the setContext dependency because the setContext and clearContext functions aren't going to change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, wanUserId, paging, sortBy, topics])

    return {
        results,
        isLoading,
    }
}
