import {
    CollectionDataLoaded,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React, { useContext, useEffect, useState } from 'react'
import {
    JournoProfileArticleCardList,
    JournoProfileArticleCardListProps,
} from './JournoProfileArticleCardList'
import { CollectionDataContext } from '../../../contexts/CollectionDataContext/CollectionDataContext'
import { useProduct } from '../../../__product'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { isEqual } from 'lodash'

export const JournoProfileArticleCardListRegistration =
    createRegisterableComponentWithData(
        'journo-profile-card-list',
        ContentDataDefinitionLoader,
        (
            {
                expectedItemCount,
                adUnits,
                adTargeting,
            }: Omit<
                JournoProfileArticleCardListProps,
                'items' | 'onEvent' | 'api' | 'adState'
            >,
            { dataDefinitionArgs, ...initialData },
            { onEvent, adState, config },
        ) => {
            const contextData = useContext(CollectionDataContext)
            const [data, setData] = useState(initialData)
            const product = useProduct()

            // eslint-disable-next-line react-hooks/exhaustive-deps
            const fireViewEvent = (data: MaybeLoaded<CollectionDataLoaded>) => {
                if (data.loaded) {
                    onEvent(
                        createCollectionAvailableEvent(
                            data.result.publications,
                            'JournoProfileArticleCardList',
                            product,
                            onEvent,
                        ),
                    )
                }
            }

            // Dirty second useEffect with no dependencies to fire off a view_item_list
            // on page load.
            useEffect(() => {
                fireViewEvent(data)
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [])

            useEffect(() => {
                if (dataDefinitionArgs.type === 'context') {
                    const updatedData = contextData.forId(
                        dataDefinitionArgs.contextId,
                    ).collectionData

                    // Don't update if it's the same, obviously :shrug:
                    if (isEqual(updatedData, data)) return

                    setData(updatedData)
                    fireViewEvent(updatedData)
                }
            }, [contextData, data, dataDefinitionArgs, fireViewEvent])

            return (
                <JournoProfileArticleCardList
                    items={
                        data.loaded
                            ? {
                                  loaded: true,
                                  result: data.result.publications,
                              }
                            : { loaded: false }
                    }
                    adUnits={adUnits}
                    expectedItemCount={expectedItemCount}
                    onEvent={onEvent}
                    api={config.contentApi}
                    adState={adState}
                    adTargeting={adTargeting}
                    config={config}
                />
            )
        },
    )
