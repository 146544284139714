import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

const navScrollShadowSize = 30

export const TNStyledQuickLinks = styled('div')<{ hasBackgroundFill: boolean }>(
    ({ theme, hasBackgroundFill }) => ({
        backgroundColor: hasBackgroundFill ? colors.white : undefined,
        margin: '0 auto',
        display: 'none',
        alignSelf: themedValue(theme, {
            thenightly: 'center',
            fallback: undefined,
        }),
        width: themedValue(theme, {
            thenightly: '100%',
            fallback: undefined,
        }),

        [breakpoint('mlg')]: {
            display: 'block',
        },

        [breakpoint('sm')]: {
            padding: themedValue(theme, {
                thewest: `0 ${calcRem(metrics.thewest.margins.outerMargin)}`,
                thenightly: `0 ${calcRem(
                    metrics.thenightly.margins.outerMargin,
                )}`,
                fallback: undefined,
            }),
        },
        borderBottom: themedValue(theme, {
            thenightly: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
            fallback: undefined,
        }),

        borderTop: themedValue(theme, {
            thenightly: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
            fallback: undefined,
        }),
    }),
)

export const StyledNav = styled('nav')({
    fontFamily: fonts.thewest.sansSerif,
    color: colors.thewest.greyGorilla,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
})

export const StyledNavList = styled('ul')({
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    alignContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 500,
    position: 'relative',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    whiteSpace: 'nowrap',
    WebkitOverflowScrolling: 'touch',
    MsOverflowStyle: 'none',

    '&::-webkit-scrollbar': {
        display: 'none',
    },

    backgroundImage: `
        radial-gradient(
            ellipse ${navScrollShadowSize}px 210% at ${
        navScrollShadowSize * -0.25
    }px center,
                ${colors.thewest.greyKoala} 0%,
                transparent 50%)
    `,

    // Mask the gradient until needed
    '&::before': {
        content: `''`,
        minWidth: 10,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: zIndex.thewest.mainNavScrollShadow,
        backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0), ${colors.white} 40%)`,
    },
})

export const StyledNavItem = styled('li')(({ theme }) => ({
    padding: themedValue(theme, {
        thewest: calcRem(metrics.thewest.margins.sm),
        thenightly: calcRem(metrics.thenightly.margins.md),
        fallback: undefined,
    }),
    fontSize: themedValue(theme, {
        thewest: calcRem(14),
        thenightly: calcRem(16),
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        thenightly: 400,
        fallback: undefined,
    }),

    // Add vertical divider for TheNightly
    position: themedValue(theme, {
        thenightly: 'relative',
        fallback: undefined,
    }),

    '&:not(:last-child):after': themedValue(theme, {
        thenightly: {
            content: `''`,
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            height: calcRem(12),
        },
        fallback: undefined,
    }),
}))

export const StyledLink = styled(WebLink)(({ theme }) => ({
    textDecoration: 'none',
    transition: 'background 250ms ease-out',
    color: themedValue(theme, {
        thewest: 'currentColor',
        thenightly: tokens.thenightly.colors.palette.neutral[100],
        fallback: undefined,
    }),

    '&:hover': {
        textDecoration: 'underline',
        color: theme.sectionValues.primaryColor,
    },

    '&:focus': {
        textDecoration: 'underline',
        outline: 'none',
    },
}))
