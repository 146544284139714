import { RefObject, useCallback, useRef, useState } from 'react'
import { useOnClickOutside } from '../../__helpers/on-click-outside'

interface UseNotificationCentreModalProps {
    openModalCallback: () => void
    closeModalCallback: () => void
}

export interface UseNotificationCentreModalResult {
    isOpen: boolean
    modalRef: RefObject<HTMLDivElement>
    openModal: () => void
    closeModal: () => void
}

export const useNotificationCentreModal = ({
    openModalCallback,
    closeModalCallback,
}: UseNotificationCentreModalProps): UseNotificationCentreModalResult => {
    const [isOpen, setIsOpen] = useState(false)
    const modalRef = useRef<HTMLDivElement>(null)

    const openModal = useCallback(() => {
        setIsOpen(true)
        openModalCallback()
    }, [openModalCallback])
    const closeModal = useCallback(() => {
        setIsOpen(false)
        closeModalCallback()
    }, [closeModalCallback])

    useOnClickOutside(modalRef, () => closeModal(), { enabled: isOpen })

    return {
        isOpen,
        openModal,
        closeModal,
        modalRef,
    }
}
