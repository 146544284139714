import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts } from '../../../../__styling'
import { WebLink } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'

const { neutral } = tokens.thenightly.colors.palette

export const StyledEditionItem = styled.li({
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '1',
    gap: calcRem(8),
    width: '100%',

    img: {
        width: '100%',
        border: `1px solid ${neutral[100]}`,
        borderRadius: calcRem(4),
    },
    time: {
        margin: 0,
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: calcRem(20),
        lineHeight: calcRem(24),
        letterSpacing: '-0.44px',
    },

    [breakpoint('sm')]: {
        width: calcRem(200),
        a: { minHeight: calcRem(285) },

        time: {
            fontWeight: 700,
            fontSize: calcRem(25),
            lineHeight: calcRem(32),
            letterSpacing: '-0.55px',
        },
    },
    [breakpoint('lg')]: {
        gap: calcRem(12),
        time: {
            marginTop: calcRem(12),
        },
    },
    transition: 'margin 0.25s, gap 0.25s',
})

export const StyledItemLink = styled(WebLink)({
    display: 'none',
    color: neutral[70],
    lineHeight: '150%',
    paddingTop: calcRem(12),

    [breakpoint('sm')]: {
        display: 'inline-block',
    },
})

export const StyledItemDetail = styled.div({
    color: neutral[70],
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: calcRem(12),

    a: { minHeight: 'unset' },

    p: {
        fontFamily: fonts.thenightly.body,
        margin: 0,
    },
})

export const StyledItemDescription = styled.p({
    flex: 1,
})
