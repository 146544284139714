import { NavEventArgs, NavItem as WebNavItem } from '@news-mono/web-common'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { NavItem } from './NavItem'
import { BreakpointKeys } from '../../../__styling'
import {
    StyledSubLinkGroupWrapper,
    StyledSubLinkList,
} from './NavSectionOptions.styled'

export interface NavSectionOptionsProps {
    sectionItems: WebNavItem[]
    onClick: (args: NavEventArgs) => void
    hideAtBreakpoint?: BreakpointKeys
    isOpen: boolean
    name: string
}

export type NavSectionOptionsRef = {
    focus: () => void
} | null

export const NavSectionOptions = React.forwardRef<
    NavSectionOptionsRef,
    NavSectionOptionsProps
>(({ sectionItems, onClick, hideAtBreakpoint, isOpen, name }, ref) => {
    const inputRef = useRef<HTMLUListElement | null>(null)

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef?.current?.focus()
        },
    }))

    useEffect(() => {
        inputRef?.current?.focus()
    }, [])

    return (
        <StyledSubLinkGroupWrapper
            isOpen={isOpen}
            hideAtBreakpoint={hideAtBreakpoint}
        >
            <StyledSubLinkList
                id={`section-nav-links-${name}`}
                aria-hidden={false}
                role="menu"
                ref={inputRef}
                tabIndex={ref ? 0 : -1}
            >
                {sectionItems.map((navItem: WebNavItem, index: number) => (
                    <NavItem
                        webNavItem={navItem}
                        onClick={onClick}
                        navPos={index}
                        key={index}
                    />
                ))}
            </StyledSubLinkList>
        </StyledSubLinkGroupWrapper>
    )
})

NavSectionOptions.displayName = 'TNFlyoutNavSectionOptions'
