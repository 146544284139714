import React from 'react'
import { EditorialType, FundingType } from '../../../lib'
import {
    StyledEditorialLabel,
    StyledEditorialLabelText,
} from './EditorialLabel.styled'
import { IconLiveTN } from '../../../../../icons'

export interface EditorialLabelProps {
    className?: string
    type: EditorialType | FundingType
    cardHasBackground?: boolean
    isLarge?: boolean
}

export const EditorialLabel: React.FC<EditorialLabelProps> = ({
    className,
    type,
}) => {
    return (
        <StyledEditorialLabel className={className} type={type}>
            {/* Display the LIVE icon when appropriate */}
            {type === EditorialType.Live && <IconLiveTN />}
            {/* Display the label text at ALL times */}
            <StyledEditorialLabelText type={type}>
                {type}
            </StyledEditorialLabelText>
        </StyledEditorialLabel>
    )
}
EditorialLabel.displayName = 'EditorialLabel'
