import styled from '@emotion/styled'
import { fonts, metrics } from '../..'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import { keyframes } from '@emotion/react'
import { StyledWardenButton } from '../../user-registration/warden/__components/StyledWardenButton'
import { zIndex } from '../../__styling/settings/z-index'

// Styling for component fade-in & fade-out
const componentFadeIn = keyframes({
    '0%': {
        opacity: 0,
    },
    '100%': {
        opacity: 1,
    },
})

// Styling for the normal buttons outside of the login modal

export interface LoginModalProps {
    isLoggedIn?: boolean
    ignoreFade?: boolean
    showOnSmallDevices?: boolean
}
export const StyledIconUserContainer = styled.div<LoginModalProps>(
    ({ isLoggedIn = false }) => ({
        margin: 0,
        display: isLoggedIn ? 'inline' : 'none',
    }),
)

export const StyledAuthLink = styled.button<LoginModalProps>(
    ({ showOnSmallDevices = true }) => ({
        fontFamily: fonts.thenightly.sansSerif,
        fontWeight: 400,
        color: tokens.thenightly.colors.palette.neutral[100],
        backgroundColor: 'transparent',
        border: 'none',
        textDecoration: 'none',
        textTransform: 'uppercase',
        cursor: 'pointer',
        padding: 0,

        // Device Settings
        display: showOnSmallDevices ? 'inline' : 'none',
        marginRight: showOnSmallDevices ? undefined : calcRem(24),

        [breakpoint('lg')]: {
            display: 'inline',
        },
    }),
)

// Primary container for the whole login modal
export const StyledLoginModalContainer = styled.div<LoginModalProps>(
    (props) => ({
        position: props.ignoreFade ? undefined : 'absolute',
        background: tokens.thenightly.colors.palette.neutral[100],

        width: calcRem(props.isLoggedIn ? 240 : 271),
        height: 'auto',
        borderRadius: calcRem(12),

        display: 'flex',
        flexDirection: 'column',

        zIndex: zIndex.thenightly.stickyCreateAccountModal,

        // Anything small than xxs
        top: calcRem(8),
        right: calcRem(8),

        [breakpoint('sm')]: {
            top: 0,
        },

        // Anything bigger than or equal to Surface Duo
        [breakpoint('lg')]: {
            right: 0,
            animation: props.ignoreFade
                ? undefined
                : `${componentFadeIn} 0.15s`,
        },
    }),
)

// Apply curved corners to the Close button so that it matches the parent container
// and doesn't present as square when clicking 'Close'
export const StyledLoginModalCloseButton = styled(StyledWardenButton)({
    borderBottomLeftRadius: calcRem(12),
    borderBottomRightRadius: calcRem(12),
})

// Styling for the top "information" section of the login modal
export const StyledInformationHeader = styled('h2')(() => ({
    display: 'block',
    margin: 0,

    color: 'white',
    fontWeight: 700,
    fontFamily: fonts.thenightly.sansSerif,
    maxWidth: '90%',

    // Anything small than xxs
    fontSize: calcRem(20),
    lineHeight: calcRem(24),
    letterSpacing: calcRem(-0.44),

    // Anything bigger than or equal to Surface Duo
    [breakpoint('xxs')]: {
        fontSize: calcRem(25),
        lineHeight: calcRem(32),
        letterSpacing: calcRem(-0.55),
    },

    // Truncated text formatting
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}))
export const StyledIconCrossContainer = styled.div({
    height: calcRem(16),
    width: calcRem(16),

    display: 'flex',
    marginLeft: 'auto',
    cursor: 'pointer',
    alignSelf: 'center',
})

export const StyledInformationSecondaryText = styled.p(() => ({
    fontFamily: fonts.thenightly.sansSerif,
    color: tokens.thenightly.colors.palette.neutral[10],
    fontSize: calcRem(12),
    lineHeight: calcRem(16),

    margin: 0,

    // Truncated text formatting
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}))
