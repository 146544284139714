import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { EmailVerified } from './TheNightlyEmailVerified'

export const TheNightlyEmailVerifiedRegistration = createRegisterableComponent(
    'thenightly-email-verified',
    () => {
        return <EmailVerified />
    },
)
