import * as React from 'react'
import { SVGProps } from 'react'
export const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>YouTube</title>
        <g clipPath="url(#clip0_11769_61711)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3764 4.04996C22.4086 4.32814 23.2227 5.14632 23.4982 6.18541C24 8.06996 24 12 24 12C24 12 24 15.9313 23.4982 17.8145C23.2227 18.8536 22.4086 19.6718 21.3764 19.95C19.5055 20.4545 12 20.4545 12 20.4545C12 20.4545 4.49591 20.4545 2.62364 19.95C1.59136 19.6718 0.777273 18.8536 0.501818 17.8145C0 15.93 0 12 0 12C0 12 0 8.06996 0.501818 6.18541C0.777273 5.14632 1.59136 4.32814 2.62364 4.04996C4.49455 3.54541 12 3.54541 12 3.54541C12 3.54541 19.5055 3.54541 21.3764 4.04996ZM15.8186 12L9.5459 15.5686V8.43134L15.8186 12Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_11769_61711">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
