import styled from '@emotion/styled'
import React from 'react'

export interface AppIconProps {
    className?: string
    fillColor?: string
    logoWidth?: '100%' | number | undefined
    logoHeight?: number | undefined
}

const StyledAppIcon = styled('svg')<AppIconProps>((props) => ({
    // IE11 and some android browsers requires a height and width to render correctly
    maxHeight: props.logoHeight || 25,
    maxWidth: props.logoWidth || 25,
    width: '100%',
}))

export const AppIcon: React.FC<AppIconProps> = ({
    className,
    logoWidth,
    logoHeight,
}) => (
    <StyledAppIcon
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 800 800"
        logoWidth={logoWidth}
        logoHeight={logoHeight}
    >
        <g id="tn-square-app-logo">
            <path
                d="M746.135 800V0H528.996V418.75H522.748L238.437 0H54.1034L54.1034 540H271.242V379.688H275.928L564.926 800H746.135Z"
                fill="white"
            />
            <path
                d="M161.728 800C102.231 800 53.999 751.758 53.999 692.249C53.999 632.741 102.231 584.5 161.728 584.5C221.224 584.5 269.456 632.741 269.456 692.25C269.456 751.758 221.224 800 161.728 800Z"
                fill="white"
            />
        </g>
    </StyledAppIcon>
)
AppIcon.displayName = 'AppIcon'
