import { NotificationCentreListSection } from '../types'
import React, { FC, useEffect } from 'react'
import { StyledNotificationCentreSectionList } from './NotificationCentreSectionList.styled'
import { NotificationCentreSection } from './NotificationCentreSection'
import { CollectionEvent } from '@news-mono/web-common'
import { useNotificationCentreEvents } from '../hooks/useNotificationCentreEvents'

interface NotificationCentreSectionListProps {
    notificationSections: NotificationCentreListSection[]
    onEvent: (event: CollectionEvent) => void
}

export const NotificationCentreSectionList: FC<NotificationCentreSectionListProps> =
    ({ notificationSections, onEvent }) => {
        const { onViewItemEvent, onViewItemListEvent } =
            useNotificationCentreEvents({
                notificationSections,
                onEvent,
            })

        useEffect(() => {
            onViewItemListEvent()
        }, [onViewItemListEvent])

        return (
            <StyledNotificationCentreSectionList>
                {notificationSections.map((notificationSection) => (
                    <NotificationCentreSection
                        notificationSection={notificationSection}
                        key={notificationSection.title}
                        onViewItemEvent={onViewItemEvent}
                    />
                ))}
            </StyledNotificationCentreSectionList>
        )
    }
