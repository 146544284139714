import React from 'react'
import {
    AccordionContainer,
    Divider,
} from './SavedArticlesFiltersAccordion.styled'
import { SavedArticlesFilters } from '../SavedArticlesFilters'
import { Accordion } from '../../../navigation/TNAccordion/Accordion'
import { FilterEvent } from '../../../result-filters/filter-events-util'

export type SavedArticlesFiltersProps = {
    onEvent: (event: FilterEvent) => void
}

export const SavedArticlesFiltersAccordion = ({
    onEvent,
}: SavedArticlesFiltersProps) => {
    return (
        <AccordionContainer>
            <Accordion headerText="Filter">
                <SavedArticlesFilters showHeader={false} onEvent={onEvent} />
            </Accordion>
            <Divider />
        </AccordionContainer>
    )
}
