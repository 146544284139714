import {
    createRenderTarget,
    ProviderChildProps,
} from '@news-mono/component-library'
import { Product } from '@news-mono/web-common'
import H from 'history'
import { SiteProps } from '../Site'
import { BaseTheNightlyWeb } from './BaseTheNightly.web'

export interface BaseTheNightlyProps extends SiteProps {
    location: H.Location
    flyOutProps: ProviderChildProps
    product: Product
    hostname: string
}

export const BaseTheNightly = createRenderTarget<BaseTheNightlyProps>(
    'BaseTheNightly',
    {
        web: BaseTheNightlyWeb,
        amp: null,
        preview: BaseTheNightlyWeb,
        rss: BaseTheNightlyWeb,
        app: BaseTheNightlyWeb,
    },
)
