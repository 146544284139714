import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { colors as nightlyColors } from '../../../../design-tokens/src/thenightly/colors'
import { fonts } from '../../__styling/settings/fonts'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../__styling/style-mixins'
import { tokens } from '@news-mono/design-tokens'
import { metrics } from '../../__styling'
import { IconCross } from './assets/IconCross'
import { StyledLink } from '../TNQuickLinks/TNQuickLinks.styled'

const { md, m2l } = metrics.thenightly.margins
const { white, neutral, starlight } = tokens.thenightly.colors.palette
export const StyledNavWrapperClass = 'stylednavwrapper'

export const StyledNavWrapper = withClass(StyledNavWrapperClass)(
    styled.nav<{ isOpen: boolean }>(({ isOpen }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        maxHeight: '100vh',
        overflow: 'auto',
        top: 0,
        backgroundColor: neutral[100],
        fontFamily: fonts.thenightly.sansSerif,
        zIndex: zIndex.thenightly.flyoutNav,
        color: colors.white,

        transform: `translate(${isOpen ? 0 : '0, -100%'})`,

        transition: 'transform 0.25s',
    })),
)

export const StyledActionContent = styled.div({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    width: '100%',
    padding: calcRem(md, m2l),
    maxWidth: metrics.thenightly.siteMetrics.mainContentWidth,
})

export const StyledActionWrapper = styled.div({
    color: white,
    display: 'flex',
    justifyContent: 'center',

    width: '100%',

    borderBottomStyle: 'solid',
    borderBottomWidth: calcRem(0.5),
    borderBottomColor: neutral[50],

    a: {
        color: white,
        textUnderlinePosition: 'under',
    },
})

export const StyledUnderActionBar = styled.div({
    display: 'flex',

    width: '100%',
    maxWidth: metrics.thenightly.siteMetrics.mainContentWidth,
})

export const StyledNavBody = styled.div({
    display: 'flex',
    flex: 1,
    gap: calcRem(16),
    width: '100%',
    padding: calcRem(24, 0),

    transition: 'gap 0.25s, padding 0.25s',

    [breakpoint('lg')]: {
        gap: calcRem(80),
        padding: calcRem(32, 0),
    },
})

export const StyledSocialGroup = styled.div({
    borderRight: `0.5px solid ${neutral[50]}`,
    padding: calcRem(m2l),
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(24),
    svg: {
        display: 'block',
    },
    marginRight: calcRem(24),

    transition: 'margin 0.25s',

    [breakpoint('lg')]: {
        marginRight: calcRem(40),
    },
})

export const StyledSectionsNav = styled.div({
    display: 'none',

    [breakpoint('sm')]: {
        display: 'flex',
        gap: calcRem(24),
        width: '100%',
    },
})

export const StyledNavMain = styled.div({
    width: '100%',
    maxWidth: calcRem(240),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: calcRem(80),

    p: {
        margin: 0,
        textTransform: 'uppercase',
        fontSize: calcRem(12),
        fontWeight: 600,
        lineHeight: calcRem(16),
        color: starlight.default,
    },
})

export const DesktopNavMain = styled(StyledNavMain)({
    display: 'none',

    [breakpoint('lg')]: {
        display: 'inherit',
    },
})

export const QuickLinkContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(32),

    transition: 'gap 0.25s',

    [breakpoint('lg')]: {
        gap: calcRem(80),
    },
})

export const StyledLinkGroup = styled.ul({
    display: 'flex',
    flexDirection: 'column',
    listStyleType: 'none',
    margin: 0,

    paddingLeft: calcRem(md),
    paddingBottom: 0,
    borderRight: 'none',
    fontSize: calcRem(20),
    lineHeight: calcRem(24),

    transition: 'gap 0.25s',

    [breakpoint('lg')]: {
        gap: calcRem(12),
        paddingRight: calcRem(8),
    },

    '& li': {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
    },

    '& a': {
        textDecoration: 'none',
        padding: calcRem(8, 0),
        color: white,

        transition: 'padding 0.25s',

        [breakpoint('lg')]: {
            padding: 0,
        },
    },
})

export const LinkContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),
})

export const StyledCloseButton = styled.button({
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
    textUnderlinePosition: 'under',
    justifyItems: 'flex-start',
})

export const StyledIconCross = styled(IconCross)({
    height: calcRem(24),
    width: calcRem(24),
    fill: colors.white,
    '&:hover': {
        cursor: 'pointer',
    },
})

export const StyledButtonText = styled.span(visuallyhidden)

export const StyledSearchAction = styled.div({
    width: '80%',
    [breakpoint('lg')]: {
        display: 'none',
    },
})

export const StyledTextLink = styled(StyledLink)({
    textDecoration: 'underline',
    '&:hover': {
        color: nightlyColors.palette.starlight.default,
    },
})

export const StyledTopRightActions = styled.div({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
})
