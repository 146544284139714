import { TheNightlySection } from '@news-mono/common'
import {
    RESULT_SORT_PARAM,
    TIME_FILTER_PARAM,
    TOPIC_FILTER_PARAM,
    theNightlySearchFilterHeaderOverride,
} from '@news-mono/component-library'
import { QueryString, StaticPageRoute } from '@news-mono/web-common'
import xss from 'xss'
import { layout } from '../../App.routing'

interface SearchQuery extends QueryString {
    search: string
    page: number
    when: string
    sort: string
    topics: string
}

function queryHasParam(
    query: QueryString,
    param: string,
): query is SearchQuery {
    if (query[param] && typeof query[param] === 'string') {
        return true
    }
    return false
}

export const getSearchPageRouteInfo =
    (query: QueryString): StaticPageRoute<TheNightlySection> =>
    ({ config, getAdTargeting }) => {
        const searchValue = queryHasParam(query, 'search')
            ? xss(query.search)
            : false
        const timeFilterValue = queryHasParam(query, TIME_FILTER_PARAM)
            ? xss(query[TIME_FILTER_PARAM])
            : undefined
        const sortValue = queryHasParam(query, RESULT_SORT_PARAM)
            ? xss(query[RESULT_SORT_PARAM])
            : undefined
        const topicValue = queryHasParam(query, TOPIC_FILTER_PARAM)
            ? xss(query[TOPIC_FILTER_PARAM])
            : undefined
        const pageValue = queryHasParam(query, 'page') ? +query.page : 1

        // Ensure we keep the search terms in the canonicalUrl on the search result page
        const canonicalUrl =
            config.publicUrl +
            '/search' +
            (searchValue ? '?search=' + encodeURIComponent(searchValue) : '')

        const adTargeting = getAdTargeting('search', 'default', 'search')

        return {
            kind: 'page',
            heading: `Search: ${
                searchValue ? searchValue : 'You need to provide a search term!'
            }`,
            hideHeading: true,
            pageType: 'search',
            pageMeta: {
                title: 'Search',
                description: 'Search Results',
                link: [{ rel: 'canonical', href: canonicalUrl }],
            },
            adTargeting,
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'luna',
                    props: {
                        hasAfterAside: false,
                    },
                    contentAreas: {
                        beforeAside: [
                            layout.component({
                                type: 'search',
                                props: {
                                    searchTerm: searchValue ? searchValue : '',
                                    dataDefinitionArgs: {
                                        searchTerm: searchValue
                                            ? searchValue
                                            : '',
                                        timeFilter: timeFilterValue,
                                        sortBy: sortValue,
                                        topic: topicValue,
                                        masthead: 'thenightly',
                                        options: {
                                            paging: {
                                                page: pageValue,
                                                pageSize: 20,
                                            },
                                        },
                                    },
                                },
                            }),
                        ],
                        aside: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    horizontalSpacing: 'outerMargin',
                                    horizontalSpacingBreakpoint: 'sm',
                                    stickyOffset:
                                        'calc(var(--stickyHeaderHeight))',
                                },
                                contentAreas: {
                                    main: [
                                        layout.nestedComposition({
                                            type: 'component-overrider',
                                            props: {
                                                override: () =>
                                                    theNightlySearchFilterHeaderOverride,
                                            },
                                            contentAreas: {
                                                children: [
                                                    layout.nestedComposition({
                                                        type: 'search-filters',
                                                        props: {},
                                                        contentAreas: {
                                                            trailingContent: [
                                                                layout.component(
                                                                    {
                                                                        type: 'ad-unit',
                                                                        props: {
                                                                            adType: 'inline',
                                                                            noticePosition:
                                                                                'above-center',
                                                                            slot: {
                                                                                id: 'sidebar',
                                                                                size: 'above1280Mrec',
                                                                            },
                                                                        },
                                                                    },
                                                                ),
                                                            ],
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                        afterAside: [],
                    },
                }),
            ],
        }
    }
