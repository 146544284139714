import { TheNightlySection } from '@news-mono/common'
import { IconChevronUpTN, metrics } from '@news-mono/component-library'
import {
    RouteInformation,
    RouteResolution,
    RouteServices,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import React from 'react'
import queryString from 'query-string'
import { getPaginatedCanonicalUrl, parsePageParam } from '../helpers'

const MAIN_CONTENT_ID = 'main-content'

export function createLatestEditionPage(
    services: RouteServices<RouteResolution>,
    section: TheNightlySection,
    pageTitle: string,
): RouteInformation<TheNightlySection> {
    const baseUrl = services.config.publicUrl + '/editions'
    const query = queryString.parse(services.location.search)
    const page = parsePageParam(query.page)
    const title =
        page > 1
            ? `${pageTitle} Digital Edition | Page ${page}`
            : `${pageTitle} Digital Edition`
    const canonicalUrl =
        page > 1 ? getPaginatedCanonicalUrl(baseUrl, page) : baseUrl

    return {
        kind: 'page',
        heading: 'Digital Edition',
        pageType: 'static',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title,
            description: `${pageTitle} digital edition: Read the latest edition of ${pageTitle} – exactly as it appears in print – here online using your mobile device or desktop browser.`,
            link: [{ rel: 'canonical', href: canonicalUrl }],
        },
        socialMeta: {
            title: `${pageTitle} Digital Edition`,
            description: `${pageTitle} digital edition: Read the latest edition of ${pageTitle} – exactly as it appears in print – here online using your mobile device or desktop browser.`,
        },
        adTargeting: services.getAdTargeting('home', section, 'default'),
        section: section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth: '100%',
                    horizontalGutters: false,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                horizontalSpacingBreakpoint: 'sm',
                                horizontalSpacing: 'outerMargin',
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'md',
                                            containerWidth:
                                                metrics.thenightly.siteMetrics
                                                    .mainContentWidth,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'nightly-latest-edition',
                                                    props: {
                                                        dataDefinitionArgs: {
                                                            type: 'edition',
                                                            page: 1,
                                                            pageSize: 1,
                                                        },
                                                        title: pageTitle,
                                                        type: 'editions',
                                                        lazyloadImages: true,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            flex: {
                                                default: {
                                                    hasBeforeAfterMargins: true,
                                                    flexDirection: 'column',
                                                    gap: '40px',
                                                },
                                                lg: {
                                                    hasBeforeAfterMargins: true,
                                                    flexDirection: 'column',
                                                    gap: '80px',
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'ad-unit',
                                                    props: {
                                                        adType: 'inline',
                                                        noticePosition:
                                                            'below-center',
                                                        slot: {
                                                            id: 'editions-page-1',
                                                            size: 'billboardAbove1280leaderboardAbove768MrecBelow',
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                flex: {
                                    default: {
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        gap: '40px',
                                    },
                                    lg: {
                                        gap: '80px',
                                    },
                                },
                                containerWidth: '100%',
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'collection-data-context',
                                        props: {
                                            contextId: MAIN_CONTENT_ID,
                                            dataDefinitionArgs: {
                                                type: 'edition',
                                                page,
                                                throw404OnNoResults: true,
                                                pageSize: 20,
                                            },
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.component({
                                                    type: 'nightly-previous-editions',
                                                    props: {
                                                        dataDefinitionArgs: {
                                                            type: 'context',
                                                            contextId:
                                                                MAIN_CONTENT_ID,
                                                            section: 'editions',
                                                        },
                                                        title: 'Latest Editions',
                                                        type: 'editions',
                                                        lazyloadImages: true,
                                                    },
                                                }),
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        horizontalSpacingBreakpoint:
                                                            'sm',
                                                        horizontalSpacing:
                                                            'outerMargin',
                                                        flex: {
                                                            default: {
                                                                flexDirection:
                                                                    'column',
                                                                gap: '40px',
                                                            },
                                                            lg: {
                                                                gap: '32px',
                                                            },
                                                        },
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            layout.component({
                                                                type: 'pagination',
                                                                props: {
                                                                    contextId:
                                                                        MAIN_CONTENT_ID,
                                                                },
                                                            }),
                                                            layout.component({
                                                                type: 'nightly-button',
                                                                props: {
                                                                    variant:
                                                                        'default',
                                                                    text: 'BACK TO TOP',
                                                                    color: 'primary',
                                                                    fill: 'text',
                                                                    layout: {
                                                                        alignSelf:
                                                                            'center',
                                                                    },
                                                                    action: {
                                                                        type: 'button',
                                                                        onClick:
                                                                            () =>
                                                                                window.scrollTo(
                                                                                    {
                                                                                        top: 0,
                                                                                    },
                                                                                ),
                                                                    },
                                                                    icon: {
                                                                        iconPosition:
                                                                            'right',
                                                                        IconElement:
                                                                            (
                                                                                <IconChevronUpTN />
                                                                            ),
                                                                    },
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'ad-unit',
                                                    props: {
                                                        adType: 'inline',
                                                        noticePosition:
                                                            'below-center',
                                                        slot: {
                                                            id: 'editions-page-2',
                                                            size: 'billboardAbove1280leaderboardAbove768MrecBelow',
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
