import React, { ReactElement } from 'react'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import { IconChevronUpTN } from '../../../icons'
import {
    BackToTopWrapper,
    SectionContentContainer,
} from './SectionContent.styled'
import { ContentNavAnchor } from '../../../navigation/TNContentNav/ContentNavAnchor'
import { DataLayerEventName, ScrollEvent } from '@news-mono/web-common'

export type SectionContentProps = {
    content: {
        children: ReactElement
    }
    onEvent: (event: ScrollEvent) => void
    section?: string
    heading?: string
}

export const SectionContent = ({
    content,
    onEvent,
    section,
    heading,
}: SectionContentProps) => {
    const handleScroll = () => {
        window.scrollTo({ top: 0 })
        if (heading && section) {
            onEvent({
                type: DataLayerEventName.scrollToTop,
                originator: `SectionContent-${heading}`,
                payload: {},
            })
        }
    }

    return (
        <ContentNavAnchor section={section} heading={heading}>
            <SectionContentContainer>
                {content.children}
                <BackToTopWrapper>
                    <NightlyButton
                        variant={'default'}
                        text={'back to top'}
                        action={{
                            type: 'button',
                            onClick: () => handleScroll(),
                        }}
                        color={'primary'}
                        fill={'text'}
                        icon={{
                            IconElement: <IconChevronUpTN />,
                            iconPosition: 'right',
                        }}
                    />
                </BackToTopWrapper>
            </SectionContentContainer>
        </ContentNavAnchor>
    )
}
