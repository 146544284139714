import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { SavedArticles } from './SavedArticles'
import { Paging } from '../../data-controllers/Pagination/usePagination'

export interface SavedArticlesRouteProps {
    paging: Paging
    timeFilter?: string
    sortBy?: string
    topics?: string
}

export const SavedArticlesRegistration = createRegisterableComponent(
    'saved-articles',
    (props: SavedArticlesRouteProps, services) => {
        return (
            <SavedArticles
                onEvent={services.onEvent}
                paging={props.paging as Paging}
                sortBy={props.sortBy}
                topics={props.topics}
            />
        )
    },
)
