import { createRenderTarget } from '../../render-target'
import { TheNightlyHeaderActionsWeb } from './TheNightlyHeaderActions.web'

export const TheNightlyHeaderActions = createRenderTarget(
    'TheNightlyHeaderActions',
    {
        web: TheNightlyHeaderActionsWeb,
        amp: null,
        rss: null,
        preview: TheNightlyHeaderActionsWeb,
        app: TheNightlyHeaderActionsWeb,
    },
)
TheNightlyHeaderActions.displayName = 'TheNightlyHeaderActions'
