import { TheNightlySection } from '@news-mono/common'
import {
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    publicationAdKind,
    TogglesReduxState,
    AdTargeting,
} from '@news-mono/web-common'
import {
    ArticleLikeV4DTO,
    PageMetaDTO,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { getNoIndexMeta } from './allowList'
import { toFeatureState } from '@etrigan/feature-toggles-client'

export const createShorthandRoute = ({
    article,
    section,
    getAdTargeting,
    pageMeta,
    toggles,
}: {
    article: ArticleLikeV4DTO
    config: BaseClientConfig
    section: TheNightlySection
    getAdTargeting: GetRouteAdTargeting
    sectionMeta: SectionMetaInfoDTO
    pageMeta?: PageMetaDTO
    toggles?: TogglesReduxState
}): PageType<TheNightlySection> => {
    const featureState = toggles && toFeatureState(toggles)

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        publicationAdKind[article.kind],
        section,
        article.topics.primary,
        ...article.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: article.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    return {
        kind: 'page',
        heading: article.heading,
        pageMeta: {
            ...pageMeta,
            description: article.homepageTeaser,
            meta: getNoIndexMeta(article.source, featureState),
        },
        socialMeta: {
            title: article.socialHead || article.homepageHead,
            description: article.socialTeaser || article.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        additionalPageProperties: {
            publicationKind: 'shorthand-publication',
            hasVerticalGutter: false,
        },
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'shorthand-publication',
                            props: {
                                meta: {
                                    slug: article.slug,
                                    kind: 'article',
                                    inlinePublicationContent: [],
                                },
                                dataDefinitionArgs: {
                                    publicationId: article.id,
                                },
                                section,
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
