import styled from '@emotion/styled'
import { breakpoint, calcRem, metrics } from '../../../__styling'

export const LegalLinksWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: calcRem(metrics.thenightly.margins.md),
    flexWrap: 'wrap',

    [breakpoint('sm')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: 54,
        alignItems: 'center',
    },
})
