import { NavItem } from '@news-mono/web-common'
import React from 'react'
import { LegalLinksWrapper } from './LegalLinks.styled'
import { getTextLinks } from '../utils'

export type LegalLinksProps = {
    legalLinks: NavItem[]
}

/**
 * The legal links.
 */
export const LegalLinks = ({ legalLinks }: LegalLinksProps) => {
    if (legalLinks.length === 0) {
        return null
    }

    return <LegalLinksWrapper>{getTextLinks(legalLinks)}</LegalLinksWrapper>
}
