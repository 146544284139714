import React from 'react'

export const WestRewardsDividerVectorGray = () => {
    return (
        <svg
            viewBox="0 0.5 839 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <g opacity="0.5" filter="url(#filter0_d_2073_27984)">
                <path
                    d="M410.141 22.6303C609.54 22.6303 807.008 52.0252 839 67V1H5C39.1984 7.10084 169.097 22.6303 410.141 22.6303Z"
                    fill="#1C204F"
                />
                <path
                    d="M410.141 22.6303C609.54 22.6303 807.008 52.0252 839 67V1H5C39.1984 7.10084 169.097 22.6303 410.141 22.6303Z"
                    stroke="black"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2073_27984"
                    x="0.912109"
                    y="0.5"
                    width="842.588"
                    height="75.2861"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2073_27984"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2073_27984"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export const WestRewardsDividerVectorPurple = () => {
    return (
        <svg
            viewBox="4.5 0.5 844 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <g filter="url(#filter0_d_2073_27983)">
                <path
                    d="M433.859 22.6303C234.46 22.6303 36.9921 52.0252 5 67V1H839C804.802 7.10084 674.903 22.6303 433.859 22.6303Z"
                    fill="url(#paint0_linear_2073_27983)"
                />
                <path
                    d="M433.859 22.6303C234.46 22.6303 36.9921 52.0252 5 67V1H839C804.802 7.10084 674.903 22.6303 433.859 22.6303Z"
                    stroke="black"
                    strokeLinecap="round"
                />
                <path
                    d="M433.859 22.6303C234.46 22.6303 36.9921 52.0252 5 67V1H839C804.802 7.10084 674.903 22.6303 433.859 22.6303Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2073_27983"
                    x="0.5"
                    y="0.5"
                    width="842.588"
                    height="75.2861"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2073_27983"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2073_27983"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_2073_27983"
                    x1="844.096"
                    y1="33.9998"
                    x2="5"
                    y2="34"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DC01BE" />
                    <stop offset="1" stopColor="#AA01E1" />
                </linearGradient>
            </defs>
        </svg>
    )
}
