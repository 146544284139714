import { TheNightlySection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes, noIndexMeta } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export const theNightlyEmailVerifiedRoute: StaticRoutes<TheNightlySection> = {
    ['/email-verified']: ({ getAdTargeting, resolution }) => {
        const section = 'default'

        return {
            kind: 'page',
            heading: 'Email Verified',
            pageMeta: {
                meta: [noIndexMeta],
            },
            // used by `/server/middleware/optimised-ssr` to determine redirect status
            pageType: 'static',
            hideHeading: true,
            adTargeting: getAdTargeting('rss', section, 'email-verified'),
            section,
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thenightly.siteMetrics.mainContentWidth,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'thenightly-email-verified',
                                props: {},
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
