import styled from '@emotion/styled'
import { getComponentTheme } from '../../__styling'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

export const StyledNotificationCentreSectionContainer = styled.div(
    ({ theme }) => {
        const sectionTheme = getComponentTheme(
            theme,
            'notificationCentre',
        ).section

        return {
            display: sectionTheme.display,
            flexDirection: sectionTheme.flexDirection,
            gap: sectionTheme.gap,
        }
    },
)

export const StyledNotificationCentreSectionTitle = styled.div(({ theme }) => {
    return textMixin(theme, 'item-small')
})
