import styled from '@emotion/styled'
import {
    calcRem,
    fonts,
    getComponentTheme,
    breakpointSwitch,
} from '@news-mono/component-library'
import { WebLink } from '@news-mono/web-common'

export const StyledTNAppDownloadCard = styled.div(({ theme }) => {
    const appDLTheme = getComponentTheme(theme, 'nightlyAppDLCard')
    return [
        {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: appDLTheme.fontSize,
            overflow: 'hidden',
            height: calcRem(290),
            padding: appDLTheme.padding,

            transition: 'height 0.25s',
        },
        breakpointSwitch(appDLTheme.height, (height) => ({
            height,
        })),
    ]
})
export const StyledAppDownloadImg = styled.img(({ theme }) => {
    const imgTheme = getComponentTheme(theme, 'nightlyAppDLCard').img
    return [
        {
            display: 'none',
            verticalAlign: 'bottom',
            paddingLeft: calcRem(16),
            transform: `translateY(${calcRem(4)})`,
            width: calcRem(370),
            loading: 'lazy',
        },
        breakpointSwitch(imgTheme.display, (display) => ({
            display,
        })),
    ]
})

export const StyledActionCard = styled.div(({ theme }) => {
    const actionCardTheme = getComponentTheme(
        theme,
        'nightlyAppDLCard',
    ).actionCard
    return {
        maxWidth: calcRem(411),
        padding: actionCardTheme.padding,
        display: 'flex',
        flexDirection: 'column',
        gap: actionCardTheme.gap,
        height: '100%',
        width: '100%',
    }
})

export const StyledRow = styled.div(({ theme }) => {
    const rowTheme = getComponentTheme(theme, 'nightlyAppDLCard').row
    return {
        display: 'flex',
        alignItems: 'center',
        gap: rowTheme.gap,
    }
})

export const StyledHeading = styled.div(({ theme }) => {
    const headingTheme = getComponentTheme(theme, 'nightlyAppDLCard').heading
    return [
        {
            fontFamily: fonts.thenightly.sansSerif,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            margin: 0,

            transition: 'font-size 0.25s, line-height 0.25s',
        },
        breakpointSwitch(
            headingTheme.breakpointState,
            ({ fontSize, lineHeight }) => ({
                fontSize,
                lineHeight,
            }),
        ),
    ]
})

export const StyledDescription = styled.p(({ theme }) => {
    const descriptionTheme = getComponentTheme(
        theme,
        'nightlyAppDLCard',
    ).description
    return {
        color: descriptionTheme.color,
        fontSize: descriptionTheme.fontSize,
        lineHeight: descriptionTheme.lineHeight,
        fontFamily: descriptionTheme.fontFamily,
        margin: 0,
    }
})

export const StyledButtonGroup = styled.div(({ theme }) => {
    const buttonGroupTheme = getComponentTheme(
        theme,
        'nightlyAppDLCard',
    ).buttonGroup
    return [
        {
            display: 'flex',
            gap: buttonGroupTheme.gap,

            'a:first-child': {
                minWidth: calcRem(144),
            },

            transition: 'margin-top 0.25s',
        },
        breakpointSwitch(buttonGroupTheme.marginTop, (marginTop) => ({
            marginTop,
        })),
    ]
})

export const StyledLinkButton = styled(WebLink)({
    display: 'block',
    height: calcRem(48),

    img: {
        height: '100%',
    },
})

export const StyledTNLogoWrapper = styled.div(({ theme }) => {
    const logoWrapperTheme = getComponentTheme(
        theme,
        'nightlyAppDLCard',
    ).logoWrapper
    return {
        width: calcRem(50),
        height: calcRem(50),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: logoWrapperTheme.background,
        borderRadius: logoWrapperTheme.borderRadius,
    }
})
