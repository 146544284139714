import { createRegisterableComponent, NavItem } from '@news-mono/web-common'
import {
    RoutableNightlySection,
    TopicV4DTO,
} from '@west-australian-newspapers/publication-types'
import React from 'react'
import { TheNightlyNavItem } from '../../routing'
import { TheNightlyBreadcrumb } from './TheNightlyBreadcrumb'

export interface TheNightlyBreadcrumbProps {
    topics: { primary: TopicV4DTO; secondary: TopicV4DTO[] }
    nightlySectionNavItems: Record<RoutableNightlySection, TheNightlyNavItem>
    subTopicNavItems: Record<RoutableNightlySection, NavItem[]>
}
export const TheNightlyBreadcrumbRegistration = createRegisterableComponent(
    'thenightly-breadcrumb',
    ({
        topics,
        nightlySectionNavItems,
        subTopicNavItems,
    }: TheNightlyBreadcrumbProps) => {
        return (
            <TheNightlyBreadcrumb
                topics={topics}
                nightlySectionNavItems={nightlySectionNavItems}
                subTopicNavItems={subTopicNavItems}
            />
        )
    },
)
