import React, { useContext } from 'react'
import { useOriginUrl } from '../../warden'
import { useLocation } from 'react-router'
import { TNToastComponent } from '../../../content/TNToastNotification/TNToastNotification'
import {
    AppState,
    AuthenticationState,
    ConfigurationContext,
} from '@news-mono/web-common'
import { useAuthActions } from '../../authentication/AuthenticationProvider'
import { useSelector } from 'react-redux'
import {
    EmailVerifiedComponentProps,
    GetEmailVerifiedStateComponent,
    consumeQueryParamsAndReturn,
    onContinueReadingClick,
    onReturnHomeClick,
} from '../../warden/email-verified'

export const EmailVerified = () => {
    const originUrl = useOriginUrl()
    const config = useContext(ConfigurationContext)
    const homepageUrl = config.publicUrl

    // Brings user to the login page
    const { onLoginClick } = useAuthActions()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isLoggedIn = authentication.isLoggedIn
    const componentProps: EmailVerifiedComponentProps = {
        onContinueReading: onContinueReadingClick(originUrl, homepageUrl),
        onReturnHome: onReturnHomeClick(homepageUrl),
        onReturnToLogin: onLoginClick,
        originUrl: originUrl,
        isLoggedIn: isLoggedIn,
    }

    return consumeQueryParamsAndReturn(queryParams, {
        AlreadyVerified: AlreadyVerifiedStateComponent,
        Invalid: InvalidStateComponent,
        Verified: VerifiedStateComponent,
    })(componentProps)
}

const AlreadyVerifiedStateComponent = (props: EmailVerifiedComponentProps) => (
    <>
        <TNToastComponent
            type={'error'}
            text={'Something went wrong.'}
            useMaxWidth={false}
            forceMaxWidth={true}
        />

        {GetEmailVerifiedStateComponent({ state: 'already_verified', props })}
    </>
)

const VerifiedStateComponent = (props: EmailVerifiedComponentProps) => (
    <>
        <TNToastComponent
            type={'success'}
            text={'Your email has been verified.'}
            useMaxWidth={false}
        />

        {GetEmailVerifiedStateComponent({
            state: 'verified',
            props: {
                ...props,
                showThankYouMessage: true,
                originUrlValues: {
                    showButton: true,
                    text: `You can now log in to access all features including comments and puzzles.`,
                    buttonText: `Continue Reading`,
                },
                noOriginUrlValues: {
                    showButton: true,
                    showLoginButton: true,
                    text: (
                        <>
                            You can now log in to access all features including
                            comments and puzzles or return <a href="/">home</a>.
                        </>
                    ),
                    buttonText: `Return to Home`,
                },
            },
        })}
    </>
)

const InvalidStateComponent = (props: EmailVerifiedComponentProps) => (
    <>
        <TNToastComponent
            type={'error'}
            text={'Invalid email verification.'}
            useMaxWidth={false}
            forceMaxWidth={true}
        />

        {GetEmailVerifiedStateComponent({ state: 'invalid', props })}
    </>
)
