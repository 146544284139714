import { NavItem } from '@news-mono/web-common'
import React, { ReactNode } from 'react'
import { useLocation } from 'react-router'
import { TheNightlyNavItem } from '../../routing'
import {
    TagLinkList,
    TagLinkListItem,
    TagLink,
    TagLinkWrapper,
    Divider,
    TagLinkNavContainer,
    TrailingContent,
} from './TagLinkNavigation.styled'

export type TagLinkNavigationProps = {
    sectionNavItem: TheNightlyNavItem
    trailingContent: ReactNode
}

export const TagLinkNavigation = ({
    sectionNavItem,
    trailingContent,
}: TagLinkNavigationProps) => {
    const location = useLocation()

    return (
        <TagLinkNavContainer>
            <TagLinkWrapper>
                <TagLinkList>
                    {(sectionNavItem.subNavLinks ?? []).map(
                        ({ name, link }, i) => (
                            <TagLinkListItem key={i}>
                                <TagLink
                                    to={link}
                                    state={
                                        link === location.pathname
                                            ? 'active'
                                            : 'default'
                                    }
                                >
                                    {name}
                                </TagLink>
                            </TagLinkListItem>
                        ),
                    )}
                </TagLinkList>
            </TagLinkWrapper>
            <Divider />
            <TrailingContent>{trailingContent}</TrailingContent>
        </TagLinkNavContainer>
    )
}
