import { TheNightlySection } from '@news-mono/common'
import { AdUnitWrapperProps, metrics } from '@news-mono/component-library'
import {
    AdDefinition,
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import {
    GalleryV4DTO,
    PageMetaDTO,
} from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { getNoIndexMeta } from './allowList'
import { toFeatureState } from '@etrigan/feature-toggles-client'

const getTopParentTopic = (topic: Topic): Topic => {
    if (topic.parent) {
        return getTopParentTopic(topic.parent)
    }

    return topic
}

export const createGalleryRoute = ({
    gallery,
    numberGalleryItems,
    getAdTargeting,
    pageMeta,
    section,
    toggles,
}: {
    gallery: GalleryV4DTO
    numberGalleryItems: number
    section: TheNightlySection
    getAdTargeting: GetRouteAdTargeting
    pageMeta?: PageMetaDTO
    toggles?: TogglesReduxState
}): PageType<TheNightlySection> => {
    const featureState = toggles && toFeatureState(toggles)

    const parentTopic = getTopParentTopic(gallery.topics.primary)

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'article',
        section,
        gallery.topics.primary,
        ...gallery.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: gallery.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const numberOfGalleryAds = Math.ceil((numberGalleryItems - 1) / 3)

    // eslint-disable-next-line prefer-spread
    const adContent = Array.apply(null, Array(numberOfGalleryAds)).map(
        (_: any, i: number) => {
            const adInfo: AdUnitWrapperProps & { slot: AdDefinition } = {
                noticePosition: 'below-center',
                hiddenUntilLoaded: false,
                padding: [0, 0, 18, 0],
                slot: {
                    id: `gallery-${i}`,
                    size: 'leaderboard',
                },
                adType: 'inline',
            }
            return adInfo
        },
    )

    return {
        kind: 'page',
        heading: gallery.heading,
        pageMeta: {
            ...pageMeta,
            description: gallery.homepageTeaser,
            meta: getNoIndexMeta(gallery.source, featureState),
        },
        socialMeta: {
            title: gallery.socialHead || gallery.homepageHead,
            description: gallery.socialTeaser || gallery.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic: gallery.topics.primary,
                                enableHeaderTag: true,
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalSpacing: 'unset',
                    horizontalGutters: 'mobSm',
                    verticalGutters: ['md', 'md'],
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                horizontalGutters: 'mobSm',
                                hasBackgroundFill: true,
                                verticalSpacing: 'md',

                                breakpoints: {
                                    md: {
                                        horizontalGutters: 'md',
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'gallery',
                                        props: {
                                            meta: {
                                                kind: 'gallery',
                                                slug: gallery.slug,
                                                numberImages:
                                                    numberGalleryItems,
                                                galleryAds: adContent,
                                            },
                                            section,
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'thor',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                horizontalGutters: false,
                                hasBackgroundFill: false,
                                verticalGutters: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            initialColumns: 1,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            teaserMode: 'hidden',
                                            verticalSpacing: 'xl',
                                            hasBackground: true,
                                            fontScale: 1,
                                            sectionHeader: {
                                                heading: 'More Galleries',
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [parentTopic.id],
                                                includeSubTopics: true,
                                                publicationKind: 'gallery',
                                                paging: {
                                                    page: 1,
                                                    pageSize: 12,
                                                },
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
