import styled from '@emotion/styled'
import {
    breakpoint,
    calcRem,
    fonts,
    metrics,
} from '@news-mono/component-library'
import { tokens } from '@news-mono/design-tokens'

const { m2l, xxl } = metrics.thenightly.margins
const { starlight, neutral } = tokens.thenightly.colors.palette

export const StyledTNInfoCard = styled('div')({
    fontFamily: fonts.thenightly.sansSerif,
    textAlign: 'center',
    background: starlight.light,
    borderRadius: '16px',
    margin: calcRem(0, m2l, xxl),
    padding: calcRem(m2l),
    color: neutral[100],

    h2: {
        margin: 0,
        fontWeight: 700,
        fontSize: calcRem(20),
        letterSpacing: '-0.44px',
    },

    p: {
        fontFamily: fonts.thenightly.body,
        lineHeight: '150%',
        color: neutral[80],
    },

    a: {
        color: neutral[100],
    },

    [breakpoint('mlg')]: {
        margin: calcRem(0, 0, xxl, 0),
    },
})
