import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { ArticleCardList, ArticleCardListProps } from './ArticleCardList'
import { CollectionDataContext } from '../../contexts/CollectionDataContext/CollectionDataContext'

export const ArticleCardListRegistration = createRegisterableComponentWithData(
    'article-card-list',
    ContentDataDefinitionLoader,
    (
        {
            expectedItemCount,
            interlacedComponents,
            adTargeting,
            debuggingCustomId,
        }: Omit<ArticleCardListProps, 'items' | 'onEvent'>,
        { dataDefinitionArgs, ...initialData },
        { onEvent },
    ) => {
        const contextData = useContext(CollectionDataContext)

        let data = initialData

        if (dataDefinitionArgs.type === 'context') {
            data = contextData.forId(
                dataDefinitionArgs.contextId,
            ).collectionData
        }

        return (
            <ArticleCardList
                items={
                    data.loaded
                        ? {
                              loaded: true,
                              result: data.result.publications,
                          }
                        : { loaded: false }
                }
                expectedItemCount={expectedItemCount}
                onEvent={onEvent}
                adTargeting={adTargeting}
                interlacedComponents={interlacedComponents}
                debuggingCustomId={debuggingCustomId}
            />
        )
    },
)
