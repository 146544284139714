import { useTheme } from '@emotion/react'
import { TheWestSection } from '@news-mono/common'
import {
    BreachEvent,
    click_origin_breach_screen,
    DataLayerEventName,
    useToggleState,
    ViewSubscriptionItem,
} from '@news-mono/web-common'
import {
    TheWestRegionalSections,
    TheWestSections,
} from '@west-australian-newspapers/publication-types'
import React, { useEffect } from 'react'
import {
    StyledAFLTitleBlock,
    StyledBreachWallContent,
    StyledBreachWallContent2022,
    StyledButton,
    StyledFooterImage,
    StyledHelpMessage,
    StyledPackage,
    StyledPackage2022,
    StyledPackageBgImage,
    StyledRegistrationInfo,
    StyledSubTitle,
    StyledTerms2022,
    StyledTextLink,
    StyledTitle,
    StyledPackagesNew2024
} from './BreachWallContent.styled'
import {
    BreachWallContentType,
    BreachWallStyle,
} from '../../helpers/BreachWallTypes'
import { AbTestExperiment } from '../../abtesting/AbTestingProvider'
import { useSection } from '../../../../__helpers'
import { getAttribution } from '../../../../errors/ErrorPage'
import { useFeatureForSection } from '../../../../feature-toggling'
import { LinkContext } from '../../../../navigation'
import { toItemListId, DEFAULT_CART_QUANTITY } from '../../../../tracking'
import { useAuthActions } from '../../../authentication'
import { SubscriptionPackages } from '../../common'
import { createLinkClickEvent } from '../../createLinkClickHandler'
import { WestRewards } from './WestRewards/WestRewards'
import { WestRewardsMessage } from './WestRewardsMessage/WestRewardsMessage'
import SubscriptionPackage from '../SubscriptionPackage/SubscriptionPackage'
import {
    breakpoint,
    breakpointMax,
} from '../../../../__styling/style-functions/breakpoint'
// import AFL50TitleImage from './assets/afl-50-off'

export interface BreachWallContentProps {
    title: () => JSX.Element | null
    terms: () => JSX.Element | null
    mediaBanner: () => JSX.Element | null
    breachBanner: () => JSX.Element | null
    subscriptionPackages?: SubscriptionPackages
    // Values passed down from the BreachWallContentWrapper in relation to the breach
    breachCreativeName: string
    breachType: BreachWallContentType
    breachDeal: string
    breachStyle: BreachWallStyle
    onEvent: (event: BreachEvent) => void
    // Values passed down if it's a test BreachWallContent
    isTest: boolean | undefined
    // AB Testing Values passed down to stop repeated code
    abTestExperiment?: AbTestExperiment
    abTestVariant?: number
    isRegionalOverride: boolean
}

const backgroundImagePrefix = './assets/thewest-digital-hero-variation-'
export const BreachWallContent: React.FC<BreachWallContentProps> = ({
    title,
    subscriptionPackages,
    breachCreativeName,
    breachType,
    breachDeal,
    breachStyle,
    onEvent,
    mediaBanner,
    breachBanner,
    terms,
    isTest,
    abTestExperiment,
    abTestVariant,
    isRegionalOverride,
}) => {
    const renderMediaBanner = mediaBanner
    const renderTitleSection = title
    const renderTerms = terms
    const renderBanner = breachBanner
    const { onSubscribeClick } = useAuthActions()
    const theme = useTheme()
    const masthead =
        getAttribution(theme.kind, theme.section) || 'The West Australian'
    const section = useSection<TheWestSection>()

    const isNewDesign2022 =
        useFeatureForSection({
            feature: 'tp-subscription-new-breach-package-background',
            sections: TheWestSections.default,
        }) ||
        isTest === true ||
        isRegionalOverride

    const isNewBreach2024 =
        useFeatureForSection({
            feature: 'breach-v2-2024',
            sections: TheWestSections.default,
        }) ||
        isTest === true ||
        isRegionalOverride

    const isRegional = !isRegionalOverride && section in TheWestRegionalSections

    const backgroundImageVariation = useBackgroundImageVariation(
        breachStyle,
        abTestExperiment,
    )
    const showBreachBanner =
        canShowBreachBanner(breachStyle, abTestExperiment) && !isRegional

    // handle whenever 'subscription options' is clicked.
    const onAllPackagesClicked = React.useCallback(() => {
        createLinkClickEvent(breachCreativeName, breachDeal, onEvent)()
        onSubscribeClick({
            callToAction: click_origin_breach_screen,
            breachScreenType: 'Premium - Inline',
        })
    }, [breachCreativeName, breachDeal, onEvent, onSubscribeClick])

    const ContentContainer = isNewDesign2022
        ? StyledBreachWallContent2022
        : StyledBreachWallContent
    const PackageContainer = isNewDesign2022 ? StyledPackage2022 : StyledPackage
    let largeImage = ''
    let smallImage = ''

    const afl50PackageBgImageDesktop = require('./assets/white_halftone_desktop.png')
    const afl50PackageBgImageMobile = require('./assets/white_halftone_mobile.png')
    const afl50FooterLogo = require(`./assets/WA_footy_lives_here_logo.png`)

    // Assign background image if utilising new breach design.
    if (isNewDesign2022) {
        if (breachDeal === 'AFL50') {
            largeImage = require(`${backgroundImagePrefix}afl50.png`)
            smallImage = require(`${backgroundImagePrefix}afl50-mobile.png`)
        } else if (breachDeal === 'EOFY-WINTER-SALE') {
            largeImage = require(`${backgroundImagePrefix}eofy.png`)
            smallImage = require(`${backgroundImagePrefix}eofy-mobile.png`)
        } else {
            // always wrap it in a try/catch, so there is a fallback image if something goes wrong
            try {
                largeImage = require(`${backgroundImagePrefix}${backgroundImageVariation}.jpg`)
            } catch {
                largeImage = require(`${backgroundImagePrefix}a.jpg`)
            }
            try {
                smallImage = require(`${backgroundImagePrefix}${backgroundImageVariation}-cropped.jpg`)
            } catch {
                smallImage = require(`${backgroundImagePrefix}a-cropped.jpg`)
            }
        }
    }

    // when everything is loaded, fire off a view_item_list GA4 event
    useEffect(() => {
        const availableDeal = breachDeal === '' ? 'default' : breachDeal
        const breachAvailablePayload: BreachEvent['payload'] = {
            paywallType: 'freemium',
            leadDepth: 0,
            creative: breachCreativeName,
            deal: availableDeal,
            breachScreenType: 'Premium - Inline',
            popupMethod: 'auto',
            pricepoint: [7, 8],
        }
        onEvent({
            type: DataLayerEventName.breachScreenAvailable,
            originator: 'breachscreen',
            payload: breachAvailablePayload,
        })

        const viewSubscriptionItems: ViewSubscriptionItem[] =
            subscriptionPackages?.map((pkg, index) => ({
                index,
                deal_name: breachType,
                item_name: pkg.heading,
                item_id: pkg.rateCode,
                item_brand: masthead,
                component_path: pkg.packagePath,
                item_list_category: 'freemium',
                item_list_category2: 'Premium - Inline',
                item_list_name: breachCreativeName,
                item_list_id: toItemListId(breachCreativeName),
                price: pkg.weeklyPrice,
                promo_price: pkg.promoPrice || 0,
                discount: pkg.discount || 0,
                coupon: pkg.offerCode || '',
                quantity: DEFAULT_CART_QUANTITY,
                currency: 'AUD',
            })) || []
        onEvent({
            type: DataLayerEventName.viewSubscriptionItemListEvent,
            parentType: DataLayerEventName.viewItemListEvent,
            originator: 'breachscreen',
            payload: { items: viewSubscriptionItems },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // New 2024 Optimizely Breachwall design
    if (isNewBreach2024) {
        return (
            <>
                <ContentContainer
                    theme={theme}
                    backgroundColor={isNewBreach2024 ? '#F4F5F5' : undefined}
                    largeImage={undefined}
                    smallImage={undefined}
                    className={breachStyle}
                >
                    {renderMediaBanner()}
                    <StyledPackagesNew2024 
                        breachStyle={breachStyle}
                        className={undefined}
                    >
                        {subscriptionPackages &&
                            subscriptionPackages.map(
                                (subscriptionPackageItem, index) => (
                                    <SubscriptionPackage
                                        key={index}
                                        breachCreativeName={breachCreativeName}
                                        breachDeal={breachDeal}
                                        breachStyle={breachStyle}
                                        breachContent={
                                            showBreachBanner
                                                ? breachType
                                                : undefined
                                        }
                                        onEvent={onEvent}
                                        packageInfo={subscriptionPackageItem}
                                        masthead={masthead}
                                        isTest={isTest}
                                        isRegionalOverride={isRegionalOverride}
                                    />
                                ),
                            )}
                        {(isNewDesign2022 || breachStyle) && (
                            <LinkContext.Consumer>
                                {() => (
                                    <StyledTerms2022
                                        breachStyle={breachStyle}
                                        className={undefined}
                                    >
                                        *See other{' '}
                                        <a href="#" onClick={onAllPackagesClicked}>
                                            subscription options
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href={
                                                'https://subscriber.thewest.com.au/subscription-terms'
                                            }
                                            target="_blank"
                                        >
                                            Terms &amp; Conditions
                                        </a>
                                    </StyledTerms2022>
                                )}
                            </LinkContext.Consumer>
                        )}

                        {isNewBreach2024 && renderBanner()}

                    </StyledPackagesNew2024>
                </ContentContainer>
                {(isNewDesign2022 || isNewBreach2024 || breachStyle) && <WestRewards />}
            </>
        )
    }

    return (
        <>
            <ContentContainer
                theme={theme}
                backgroundColor={isNewDesign2022 ? '#2B2D2E' : undefined}
                largeImage={largeImage}
                smallImage={smallImage}
                className={
                    breachDeal === 'AFL50' ? 'afl50-container' : breachStyle
                }
            >
                {renderMediaBanner()}
                <StyledRegistrationInfo>
                    {!isNewDesign2022 && !breachStyle && renderTitleSection()}
                    {showBreachBanner && renderTitleSection()}
                    {breachDeal === 'AFL50' && (
                        <div className="afl50Banner">
                            <AFL50TitleBlock />
                        </div>
                    )}
                </StyledRegistrationInfo>
                <PackageContainer
                    breachStyle={breachStyle}
                    className={
                        breachDeal === 'AFL50'
                            ? 'afl50-package-container'
                            : undefined
                    }
                >
                    {breachDeal === 'AFL50' && (
                        <StyledPackageBgImage>
                            <source
                                srcSet={afl50PackageBgImageMobile}
                                media={breakpointMax('sm', false)}
                            />
                            <img
                                srcSet={afl50PackageBgImageDesktop}
                                alt="white bg"
                            />
                        </StyledPackageBgImage>
                    )}

                    {subscriptionPackages &&
                        subscriptionPackages.map(
                            (subscriptionPackageItem, index) => (
                                <SubscriptionPackage
                                    key={index}
                                    breachCreativeName={breachCreativeName}
                                    breachDeal={breachDeal}
                                    breachStyle={breachStyle}
                                    breachContent={
                                        showBreachBanner
                                            ? breachType
                                            : undefined
                                    }
                                    onEvent={onEvent}
                                    packageInfo={subscriptionPackageItem}
                                    masthead={masthead}
                                    isTest={isTest}
                                    isRegionalOverride={isRegionalOverride}
                                />
                            ),
                        )}
                    {(isNewDesign2022 || breachStyle) && (
                        <LinkContext.Consumer>
                            {() => (
                                <StyledTerms2022
                                    breachStyle={breachStyle}
                                    className={
                                        breachDeal === 'AFL50'
                                            ? 'afl50-terms'
                                            : undefined
                                    }
                                >
                                    *See other{' '}
                                    <a href="#" onClick={onAllPackagesClicked}>
                                        subscription options
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href={
                                            breachType === 'abtest-b-1x4'
                                                ? 'https://subscriber.thewest.com.au/subscription-terms/first-month-offer'
                                                : breachType ===
                                                      'abtest-c-50cx8' ||
                                                  breachType ===
                                                      'abtest-d-50px8'
                                                ? 'https://subscriber.thewest.com.au/subscription-terms/8weeks-50-off-offer'
                                                : breachType ===
                                                  'ab-test-promo-20-percent'
                                                ? 'https://subscriber.thewest.com.au/subscription-terms/8weeks-20-off-offer'
                                                : breachType ===
                                                  'ab-test-promo-30-percent'
                                                ? 'https://subscriber.thewest.com.au/subscription-terms/8weeks-30-off-offer'
                                                : breachType ===
                                                      'ab-test-promo-40-percent' ||
                                                  breachType ===
                                                      'vanishing-cousins'
                                                ? 'https://subscriber.thewest.com.au/subscription-terms/8weeks-40-off-offer'
                                                : breachDeal === 'AFL50'
                                                ? 'https://subscriber.thewest.com.au/subscription-terms/8weeks-50-off-offer'
                                                : 'https://subscriber.thewest.com.au/subscription-terms'
                                        }
                                        target="_blank"
                                    >
                                        Terms &amp; Conditions
                                    </a>
                                </StyledTerms2022>
                            )}
                        </LinkContext.Consumer>
                    )}

                    {breachDeal === 'AFL50' && (
                        <StyledFooterImage src={afl50FooterLogo} />
                    )}
                </PackageContainer>
                {!isNewDesign2022 && !breachStyle && (
                    <>
                        <StyledButton onClick={onAllPackagesClicked}>
                            View All Packages
                        </StyledButton>
                        <StyledHelpMessage>
                            Need Help? Call us at{' '}
                            <StyledTextLink
                                reverseColor={true}
                                href="tel:1800 811 855"
                            >
                                1800 811 855
                            </StyledTextLink>
                        </StyledHelpMessage>
                        <WestRewardsMessage />
                        {renderTerms()}
                    </>
                )}
            </ContentContainer>
            {(isNewDesign2022 || breachStyle) && <WestRewards />}
        </>
    )
}

function useBackgroundImageVariation(
    breachStyle: BreachWallStyle,
    experiment?: AbTestExperiment,
) {
    const backgroundVariation =
        useToggleState()?.['tp-subscription-hero-variation'] || 'a'

    // check for the background variation against the breachStyle
    switch (breachStyle) {
        case 'vanishing-cousins':
            return 'vanishing-cousins'
        case 'black-friday':
            return 'black-friday'
        case 'eofy':
            return 'eofy'
    }

    // check if an experiment is provided, and it's a breach-ab-test-2
    if (experiment?.getId() === 'breach-ab-test-2') {
        return 'experiment2'
    }

    return backgroundVariation
}

function canShowBreachBanner(
    breachStyle: BreachWallStyle,
    experiment?: AbTestExperiment,
) {
    return (
        (experiment !== undefined && breachStyle !== 'default-breachwall') ||
        breachStyle === 'black-friday' ||
        breachStyle === 'eofy' ||
        breachStyle === 'afl50'
    )
}

const AFL50TitleBlock = () => {
    return (
        <StyledAFLTitleBlock>
            <StyledTitle>50% OFF</StyledTitle>
            <StyledSubTitle>ALL SUBSCRIPTIONS</StyledSubTitle>
        </StyledAFLTitleBlock>
    )
}

BreachWallContent.displayName = 'BreachWallContent'
export default BreachWallContent
