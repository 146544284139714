import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { TheNightlyPageIsUnavailable } from './TheNightlyPageIsUnavailable'

export const TheNightlyPageIsUnavailableRegistration =
    createRegisterableComponent('thenightly-page-is-unavailable', () => (
        <RouteStatus statusCode={451}>
            <TheNightlyPageIsUnavailable />
        </RouteStatus>
    ))
