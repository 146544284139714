import styled from '@emotion/styled'
import { breakpoint, calcRem, metrics, Theme } from '../../__styling'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { tokens } from '@news-mono/design-tokens'

const getTitleStyle = (theme: Theme) => [textMixin(theme, 'subheading')]
const getSubTitleStyle = (theme: Theme) => [textMixin(theme, 'body-alt')]

export const StyledEmptyResultContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    padding: calcRem(
        metrics.thenightly.margins.m2l,
        metrics.thenightly.margins.m2l,
    ),
    margin: calcRem(0, metrics.thenightly.margins.m2l),
    gap: metrics.thenightly.margins.sm,
    border: `1px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
    borderRadius: calcRem(12),

    [breakpoint('lg')]: {
        padding: calcRem(metrics.thenightly.margins.xxxl, 0),
        margin: 0,
    },
})

export const StyledTitle = styled('p')(({ theme }) => [
    getTitleStyle(theme),
    {
        textAlign: 'center',
        margin: 0,
    },
])

export const StyledSubTitle = styled('p')(({ theme }) => [
    getSubTitleStyle(theme),
    {
        textAlign: 'center',
        margin: 0,
    },
])
