import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { TNBreakingNews } from './TNBreakingNews'

export const TNBreakingNewsRegistration = createRegisterableComponentWithData(
    'tn-breaking-news',
    CurationAndCurationMetadataLoader,
    (_, data, { onEvent, adState }) => {
        if (
            data.loaded &&
            data.result.articles.length > 0 &&
            data.result.metadata['breaking-news-headline']
        ) {
            const [mainArticle, ...articles] = data.result.articles
            const headline = data.result.metadata['breaking-news-headline']
            const kicker = data.result.metadata['breaking-news-kicker']

            return (
                <TNBreakingNews
                    headline={headline}
                    kicker={kicker}
                    mainArticle={mainArticle}
                    articles={articles}
                    onEvent={onEvent}
                    adState={adState}
                />
            )
        }
        return null
    },
)
