import React from 'react'
import { SavedArticlesFiltersPanel } from './SavedArticlesFiltersPanel'
import { createRegisterableComposition } from '@news-mono/web-common'

export const SavedArticlesFiltersRegistration =
    createRegisterableComposition<'trailingContent'>()(
        'saved-articles-filters',
        (contentAreas, _props, services) => (
            <SavedArticlesFiltersPanel
                trailingContent={contentAreas.trailingContent}
                onEvent={services.onEvent}
            />
        ),
    )
