import {
    AllEvents,
    createCollectionAvailableEvent,
    Curation_WithMetadata,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledTrendingArticlesArticle,
    StyledTrendingArticlesContainer,
    StyledTrendingArticlesGrid,
    StyledTrendingArticlesHeader,
    StyledTrendingArticlesHeaderIcon,
    StyledTrendingArticlesHeaderText,
} from './TrendingArticles.styled'
import { TrendingNewsIcon } from './assets/TrendingNewsIcon'
import { LandscapeNightly } from '../../cards/LandscapeNightly/LandscapeNightly'
import { useImpressionAvailable } from '../../__helpers/impression-available-helper'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { useProduct } from '../../__product'

interface TrendingArticlesProps {
    data: MaybeLoaded<Curation_WithMetadata>
    onEvent: (event: AllEvents) => void
}

export const TrendingArticles = ({ data, onEvent }: TrendingArticlesProps) => {
    const product = useProduct()

    // Fire analytics when viewed.
    const impressionRef = useImpressionAvailable({
        available: () => {
            if (!data.loaded) return
            onEvent(
                createCollectionAvailableEvent(
                    data.result.articles,
                    'Lima',
                    product,
                    onEvent,
                ),
            )
        },
        loading: !data.loaded,
    })

    if (!data.loaded || data.result.articles.length === 0) {
        return null
    }

    const articles = data.result.articles

    return (
        <StyledTrendingArticlesContainer>
            <StyledTrendingArticlesHeader>
                <StyledTrendingArticlesHeaderIcon>
                    <TrendingNewsIcon />
                </StyledTrendingArticlesHeaderIcon>
                <StyledTrendingArticlesHeaderText>
                    Trending
                </StyledTrendingArticlesHeaderText>
            </StyledTrendingArticlesHeader>
            <StyledTrendingArticlesGrid ref={impressionRef}>
                {articles.map((article, i) => (
                    <StyledTrendingArticlesArticle key={article.id}>
                        <LandscapeNightly
                            item={{
                                loaded: true,
                                result: article,
                            }}
                            onEvent={onEvent}
                            cardNumber={i + 1}
                            contentPosition="center"
                            displayNumber={false}
                            imagePosition="left"
                            hideByline={false}
                        />
                    </StyledTrendingArticlesArticle>
                ))}
            </StyledTrendingArticlesGrid>
        </StyledTrendingArticlesContainer>
    )
}
