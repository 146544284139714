import { NotificationCentreItemV4DTO } from '@west-australian-newspapers/publication-types'
import { NotificationCentreListSection } from '../../types'
import { subDays, subHours } from 'date-fns'

export const buildNotificationList = (
    notifications: NotificationCentreItemV4DTO[],
    date: Date,
): NotificationCentreListSection[] => {
    return builderSections
        .map((section) => ({
            title: section.title,
            notifications: notifications.filter((notification) =>
                section.filterFunction(notification, date),
            ),
        }))
        .filter((section) => section.notifications.length > 0)
}

type FilterFunction = (
    notification: NotificationCentreItemV4DTO,
    date: Date,
) => boolean

interface NotificationListSectionBuilder {
    title: string
    filterFunction: FilterFunction
}

// New refers to the last 24 hours
const nowFilterFunction: FilterFunction = (
    notification: NotificationCentreItemV4DTO,
    date: Date,
) => {
    const timeCutoff = subHours(date, 25).toISOString()

    return notification.dateSent > timeCutoff
}

const lastWeekFilterFunction: FilterFunction = (
    notification: NotificationCentreItemV4DTO,
    date: Date,
) => {
    const timeStart = subHours(date, 25).toISOString()
    const timeCutoff = subDays(date, 7).toISOString()

    return (
        notification.dateSent <= timeStart &&
        notification.dateSent >= timeCutoff
    )
}

const last30DaysFilterFunction: FilterFunction = (
    notification: NotificationCentreItemV4DTO,
    date: Date,
) => {
    const timeStart = subDays(date, 7).toISOString()
    const timeCutoff = subDays(date, 30).toISOString()

    return (
        notification.dateSent < timeStart && notification.dateSent >= timeCutoff
    )
}

const builderSections: NotificationListSectionBuilder[] = [
    {
        title: 'Now',
        filterFunction: nowFilterFunction,
    },
    {
        title: 'Last week',
        filterFunction: lastWeekFilterFunction,
    },
    {
        title: 'Last 30 days',
        filterFunction: last30DaysFilterFunction,
    },
]
