import React, { FC } from 'react'
import {
    StyledDivider,
    StyledMenuItemFirstOption,
} from '../ContentNavMenuItem.styled'
import { HomeIcon } from '../assets/HomeIcon'

interface HomeMenuItemProps {
    onClick: () => void
}

export const HomeMenuItem: FC<HomeMenuItemProps> = ({ onClick }) => {
    return (
        <>
            <StyledMenuItemFirstOption onClick={() => onClick()}>
                <HomeIcon />
            </StyledMenuItemFirstOption>
            <StyledDivider />
        </>
    )
}
