import {
    createRegisterableComponent,
    RouteStatus,
    PageError,
} from '@news-mono/web-common'
import React from 'react'
import { TheNightlySomethingWentWrong } from './TheNightlySomethingWentWrong'
import { Route } from 'react-router-dom'

export const TheNightlySomethingWentWrongRegistration =
    createRegisterableComponent(
        'thenightly-error-page',
        ({ error }: { error: PageError }) => {
            return error.errorType === '500' ? (
                <RouteStatus statusCode={500}>
                    <TheNightlySomethingWentWrong error={error} />
                </RouteStatus>
            ) : (
                <Route>
                    <TheNightlySomethingWentWrong error={error} />
                </Route>
            )
        },
    )
