import styled from '@emotion/styled'
import { getComponentTheme } from '../../__styling'
import { StyledLink } from '../../cards/CardText/CardTextInteractions.styled'
import { ResponsivePicture } from '../../content/Picture/Picture'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

export const StyledNotificationCentreCardLink = styled(StyledLink)(
    ({ theme }) => {
        const cardTheme = getComponentTheme(theme, 'notificationCentre').card

        return {
            '&:hover': {
                backgroundColor: cardTheme.hover.backgroundColor,
                borderRadius: cardTheme.hover.borderRadius,
                margin: cardTheme.hover.margin,
                padding: cardTheme.hover.padding,
            },

            '&:visited': {
                div: {
                    color: cardTheme.visited.color,
                },
            },
        }
    },
)

export const StyledNotificationCentreCardContainer = styled.div(({ theme }) => {
    const cardTheme = getComponentTheme(theme, 'notificationCentre').card

    return {
        display: cardTheme.display,
        flexDirection: cardTheme.flexDirection,
        gap: cardTheme.gap,
        textDecoration: cardTheme.textDecoration,
    }
})

export const StyledNotificationCentrePictureColumn = styled.div(({ theme }) => {
    const cardTheme = getComponentTheme(theme, 'notificationCentre').card.image
    return {
        width: cardTheme.width,
        height: cardTheme.height,
    }
})

export const StyledNotificationCentrePicture = styled(ResponsivePicture)(
    ({ theme }) => {
        const cardTheme = getComponentTheme(theme, 'notificationCentre').card
            .image
        return {
            width: cardTheme.width,
            height: cardTheme.height,
            overflow: cardTheme.overflow,
            paddingBottom: cardTheme.paddingBottom,
        }
    },
)

export const StyledNotificationCentreBodyColumn = styled.div(({ theme }) => {
    const cardTheme = getComponentTheme(theme, 'notificationCentre').card.body
    return {
        display: cardTheme.display,
        flexDirection: cardTheme.flexDirection,
        alignItems: cardTheme.alignItems,
        justifyContent: cardTheme.justifyContent,
        gap: cardTheme.gap,
    }
})

export const StyledNotificationCentreBodyColumnHeadline = styled.div(
    ({ theme }) => {
        return textMixin(theme, 'byline-primary')
    },
)

export const StyledNotificationCentreBodyColumnSubHeadline = styled.div(
    ({ theme }) => {
        return textMixin(theme, 'byline-secondary')
    },
)
