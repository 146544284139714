import { TheNightlySection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes, noIndexMeta } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export const theNightlySignupSuccessRoute: StaticRoutes<TheNightlySection> = {
    ['/connect/register-success']: ({ getAdTargeting }) => {
        const section = 'default'

        return {
            kind: 'page',
            heading: 'Signup Success',
            pageMeta: {
                meta: [noIndexMeta],
            },
            // used by `/server/middleware/optimised-ssr` to determine redirect status
            pageType: 'static',
            hideHeading: true,
            adTargeting: getAdTargeting('rss', section, 'signup-success'),
            section,
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thenightly.siteMetrics.mainContentWidth,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'tn-toast-notification',
                                props: {
                                    type: 'success',
                                    text: "You're in!",
                                    useMaxWidth: false,
                                },
                            }),
                            layout.component({
                                type: 'thenightly-signup-success',
                                props: {},
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
