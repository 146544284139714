import React, { PropsWithChildren } from 'react'
import { NightlyButton } from '../../buttons/NightlyButton/NightlyButton'
import {
    ErrorIdText,
    StyledButtonContainer,
    StyledErrorContainer,
} from './ErrorPage.styled'

type ErrorPageProps = {
    errorId?: string
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
    errorId,
    children,
}: PropsWithChildren<ErrorPageProps>) => {
    return (
        <>
            <StyledErrorContainer>
                {children}
                <br />
            </StyledErrorContainer>
            <StyledButtonContainer>
                <NightlyButton
                    variant={'default'}
                    text={'BACK TO HOMEPAGE'}
                    action={{
                        type: 'link',
                        to: '/',
                    }}
                    color={'primary'}
                    fill={'filled'}
                    layout={{
                        shouldGrow: true,
                        alignSelf: 'stretch',
                    }}
                />
            </StyledButtonContainer>
            {errorId && (
                <ErrorIdText>
                    Error Id: {errorId}
                    <br />
                    {new Date().toISOString()}
                </ErrorIdText>
            )}
        </>
    )
}
