import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../__styling'

interface StyledBreakingNewsContainer {
    isSingleArticleCuration: boolean
}

export const StyledBreakingNewsContainer =
    styled.div<StyledBreakingNewsContainer>(
        ({ theme, isSingleArticleCuration }) => {
            const margins = theme.margins

            const maxWidth = isSingleArticleCuration
                ? calcRem(theme.componentMetrics.imageWidth.lg)
                : calcRem(theme.siteMetrics.mainContentWidth)

            return {
                width: '100%',
                maxWidth,
                margin: calcRem(0),
                borderBottom: `0.5px solid ${theme.colors.borders.secondary}`,
                padding: calcRem(margins.outerMargin, 0),

                [breakpoint('sm')]: {
                    margin: '0 auto',
                    paddingTop: calcRem(48),
                    paddingBottom: calcRem(40),
                },

                [breakpoint('lg')]: {
                    borderBottom: 0,
                    paddingTop: calcRem(60),
                    paddingBottom: calcRem(32),
                },
            }
        },
    )

export const StyledArticleListContainer = styled.div(({ theme }) => {
    const margins = theme.margins

    return {
        margin: calcRem(0, margins.outerMargin),

        [breakpoint('sm')]: {
            margin: 0,
        },
    }
})
