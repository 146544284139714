import styled from '@emotion/styled'
import { breakpoint, calcRem, metrics } from '../../../__styling'

export const StyledCreateAccountInArticleContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    justifyContent: 'center',
    maxWidth: calcRem(480),

    // Anything small than xxs
    padding: calcRem(
        metrics.thenightly.margins.m2l,
        metrics.thenightly.margins.md,
    ),

    // Anything bigger than or equal to Surface Duo
    [breakpoint('xxs')]: {
        padding: calcRem(metrics.thenightly.margins.m2l, 0),
    },

    h3: {
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: calcRem(metrics.thenightly.margins.sm),
    },
    p: {
        display: 'flex',
        textAlign: 'center',
        margin: '0 auto',
    },
    a: {
        cursor: 'pointer',
    },
})
StyledCreateAccountInArticleContainer.displayName =
    'StyledCreateAccountInArticleContainer'
