import React from 'react'
import { useSection } from '../../../__helpers/use-section-meta'
import { StyledTheNightlyHorizontalLogo } from '../TheNightlyHeader.styled'
import { StyledLogoWrapLink } from './MastheadLogo.styled'

interface MastheadLogoProps {
    width?: number
    height?: number
    isFooterLogo?: boolean
}

export const MastHeadLogo: React.FC<MastheadLogoProps> = ({
    width,
    height,
}) => {
    const websiteSection = useSection()

    return (
        <StyledLogoWrapLink
            section={websiteSection}
            to={'/'}
            id={`header-logo`}
        >
            <InternalMastheadLogo width={width} height={height} />
        </StyledLogoWrapLink>
    )
}

export const InternalMastheadLogo: React.FC<MastheadLogoProps> = ({
    width = 400,
    height = 52,
}) => {
    return (
        <StyledTheNightlyHorizontalLogo logoWidth={width} logoHeight={height} />
    )
}
