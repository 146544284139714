import {
    AllEvents,
    ArticleLikePublication,
    ComponentServices,
    RouteResolution,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import {
    InlineContentTypes,
    InlineTNNewsletterBanner,
} from '../SharedPublication.routing'
import { NewsletterSignup } from '../../../banners/NewsletterSignup/NewsletterSignup'
import { StyledInlineContainer } from '../../../banners/NewsletterSignup/NewsletterSignup.styled'
import { TNNewsletterSignup } from '../../../banners/NewsletterSignup/TNNewsletterSignup'
import { RecaptchaProvider } from '../../../recaptcha/Recaptcha'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

export interface getInlinePositionedTNNewsletterBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
    services: ComponentServices<RouteResolution>
}

export function isInlineTNNewsletterBanner(
    content: InlineContentTypes,
): content is InlineTNNewsletterBanner {
    return content.kind === 'inline-tn-newsletter-banner'
}

export function getInlinePositionedTNNewsletterBanner({
    publication,
    inlinePublicationContent,
    onEvent,
    services,
}: getInlinePositionedTNNewsletterBannerProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineTNNewsletterBanner)
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: (
                    <StyledInlineContainer>
                        {services.config.recaptureSiteKey ? (
                            <RecaptchaProvider
                                siteKey={services.config.recaptureSiteKey}
                            >
                                <QueryClientProvider client={queryClient}>
                                    <TNNewsletterSignup
                                        text={`Sign up to The Nightly's newsletters.`}
                                        subText={
                                            'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.'
                                        }
                                        buttonLabel={'Get the Newsletter'}
                                        mode={'inline-article'}
                                        onEvent={services.onEvent}
                                    />
                                </QueryClientProvider>
                            </RecaptchaProvider>
                        ) : (
                            <></>
                        )}
                    </StyledInlineContainer>
                ),
            }
            return mappedContent
        })
}
