import { TheWestSection } from '@news-mono/common'
import {
    AddToCartEvent,
    BreachEvent,
    BreachScreenContext,
    BreachScreenType,
    click_origin_breach_screen,
    DataLayerEventName,
    useFeature,
    useSubscribeWithGoogle,
} from '@news-mono/web-common'
import {
    TheWestRegionalSections,
    TheWestSections,
} from '@west-australian-newspapers/publication-types'
import React, { useContext, useRef } from 'react'
import { DEFAULT_CART_QUANTITY, getCartPayload } from '../../../../'
import { useFeatureForSection } from '../../../../feature-toggling'
import { IconStar } from '../../../../icons/IconStar/IconStar'
import { useAuthActions } from '../../../../user-registration/authentication/AuthenticationProvider'
import { useSection, useSectionMetaInfo } from '../../../../__helpers'
import { ISubscriptionPackage, PackageTheme } from '../../common'
import { createLinkClickEvent } from '../../createLinkClickHandler'
import { ComputedPackageImage } from './ComputedPackageImage'
import {
    BreachOptions,
    PackageOptions,
    Ribbon,
    StyledPackageImage,
    StyledPromoTagline,
    StyledSubscribeWithGoogleButton,
    StyledSubscribeWithGoogleButton2022,
    StyledSubscriptionCancellationMessage,
    StyledSubscriptionPackage,
    StyledSubscriptionPackage2022,
    StyledSubscriptionPackageActions,
    StyledSubscriptionPackageButton,
    StyledSubscriptionPackageButton2022,
    StyledSubscriptionPackageHeading,
    StyledSubscriptionPackageHeading2022,
    StyledSubscriptionPackageOriginalPrice,
    StyledSubscriptionPackageOriginalPrice2022,
    StyledSubscriptionPackagePrice,
    StyledSubscriptionPackagePrice2022,
    StyledSubscriptionPackagePriceDivider2022,
    StyledSubscriptionPackagePriceQualifier,
    StyledSubscriptionButton2024,
    StyledSwgButton2024
} from './SubscriptionPackage.styled'
import { SubscriptionPackageBenefit } from './SubscriptionPackageBenefit'
import {
    BreachWallContentType,
    BreachWallStyle,
} from '../../helpers/BreachWallTypes'

const everyDayNewspaperImage = require('./images/updated2022/Weekend-Papers-plus-Everyday-Digita_Mobile.png')

const footyPackEverydayDigital = require('./images/footypack2023/afl-everyday-digital.png')
const footyPackFriMonPrintDigital = require('./images/footypack2023/afl-everyday-digital-Weekend-Papers-v2.png')
const promoAssetImg = require('./images/footypack2023/afl_football.png')

const everyDayDigitalPromo = require('./images/updated2022/everyday_digital_190_year.png')
const everyDayDigitalPaperPromo = require('./images/updated2022/everyday_digital_paper_190_year.png')

const everyDayDigitalJollyRodgers = require('./images/updated2022/redesign/everyday_digital_june23.png')
const everyDayDigitalPaperJollyRodgers = require('./images/updated2022/redesign/everyday_digital_and_weekend_papers_june23.png')

// default breach screen product images
const everyDayDigitalDefault = require('./images/updated2022/redesign/everyday_digital_may_2024.png')
const everyDayDigitalPaperDefault = require('./images/updated2022/redesign/everyday_digital_weekend_papers_may_2024.png')

export interface SubscriptionPackageProps {
    breachCreativeName: string
    breachDeal: string
    breachStyle: BreachWallStyle
    breachContent?: BreachWallContentType
    packageInfo: ISubscriptionPackage
    masthead?: string
    packageTheme?: PackageTheme
    cancellationMessage?: string
    googleSKU?: string
    onEvent: (event: BreachEvent) => void
    isTest?: boolean
    isRegionalOverride: boolean
}

export const SubscriptionPackage: React.FC<SubscriptionPackageProps> = ({
    breachDeal,
    breachCreativeName,
    breachStyle,
    breachContent,
    onEvent,
    masthead = 'The West Australian',
    packageInfo,
    isTest,
    isRegionalOverride,
}) => {
    const {
        heading,
        originalPrice,
        price,
        priceQualifier,
        ribbonTitle,
        ribbonOptions,
        packagePath,
        offerCode,
        packageTheme = 'default',
        packageType,
        cancellationMessage,
        googleSKU,
        weeklyPrice,
        promoPrice,
        discount,
        rateCode,
        benefitOverride,
    } = packageInfo
    const { onSubscribeClick } = useAuthActions()
    const subscribeWithGoogleEnabled = useFeature('subscribe-with-google')
    const section = useSection<TheWestSection>()
    const isNewDesign =
        useFeatureForSection({
            feature: 'tp-subscription-new-breach-package-design',
            sections: TheWestSections.default,
        }) ||
        isTest === true ||
        isRegionalOverride

    const isNewBreach2024 =
        useFeatureForSection({
            feature: 'breach-v2-2024',
            sections: TheWestSections.default,
        }) ||
        isTest === true ||
        isRegionalOverride    

    const breachName = section === 'genwest' ? 'default' : section

    const breach190Enabled = breachDeal === '190BD23'
    const breachAbTest2 =
        breachStyle === 'abtest2-container' ||
        breachStyle === 'vanishing-cousins' ||
        breachStyle === 'black-friday'
    const isBlackFriday = breachStyle === 'black-friday'

    const benefits = getBenefits(packageType, benefitOverride)

    // Matches swg-breach-albany-advertiser etc
    const subscribeWithGoogleBreachInterfaceEnabled = useFeature(
        `swg-breach-${breachName}`,
    )

    const buttonRef = useRef<HTMLButtonElement>(null)

    const showSwG =
        subscribeWithGoogleEnabled &&
        subscribeWithGoogleBreachInterfaceEnabled &&
        googleSKU

    const sectionMeta = useSectionMetaInfo()

    const breachScreenContext = useContext(BreachScreenContext)
    const breachContext = breachScreenContext.context
    const breachScreenType: BreachScreenType = 'Premium - Inline'

    useSubscribeWithGoogle(
        buttonRef.current as HTMLButtonElement,
        googleSKU || 'everyday_digital_4weeks',
        sectionMeta,
        breachContext,
    )

    const addToCartPayload = getCartPayload(packageInfo, masthead)
    const isDigital = packageType === 'digital'
    const isDigitalPrint = packageType === 'digitalprint'

    const isRegional = section in TheWestRegionalSections

    const packageOptions: PackageOptions = {
        theme: packageTheme,
        type: packageType,
    }
    const breachOptions: BreachOptions = {
        style: breachDeal === 'AFL50' ? 'afl50-style' : breachStyle,
        useModernBreachStyle: breachContent !== undefined,
        useBreachDealStyle: breach190Enabled || isRegionalOverride,
        isRegional: isRegional,
    }

    if (isNewBreach2024) {
        const benefitColor =
            breachStyle === 'black-friday' ? '#043de2' : undefined

        return (
            <StyledSubscriptionPackage2022
                packageOptions={packageOptions}
                breachOptions={breachOptions}
            >
                <StyledSubscriptionPackageHeading2022
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {heading}
                </StyledSubscriptionPackageHeading2022>
                {originalPrice && (
                    <StyledSubscriptionPackageOriginalPrice2022
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                    >
                        {originalPrice}
                    </StyledSubscriptionPackageOriginalPrice2022>
                )}
                <StyledSubscriptionPackagePrice2022
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {price}
                </StyledSubscriptionPackagePrice2022>
                {priceQualifier && (
                    <StyledSubscriptionPackagePriceQualifier
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                    >
                        {priceQualifier}
                    </StyledSubscriptionPackagePriceQualifier>
                )}

                <StyledSubscriptionPackageActions paddingBot={20}>
                    <StyledSubscriptionButton2024
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                        buttonWidth="wide"
                        type="button"
                        title="Subscribe Button"
                        aria-label="Subscribe button"
                        onClick={() => {
                            createLinkClickEvent(
                                breachCreativeName,
                                breachDeal,
                                onEvent,
                                breachScreenType,
                                1,
                                addToCartPayload,
                            )()
                            onSubscribeClick({
                                packagePath,
                                offerCode,
                                callToAction: click_origin_breach_screen,
                                breachScreenType: breachScreenType,
                            })
                        }}
                    >
                        Subscribe
                    </StyledSubscriptionButton2024>

                    {isDigital && showSwG && (
                        <StyledSwgButton2024
                            ref={buttonRef}
                            type="button"
                            aria-label="Subscribe with google button"
                            onClick={() =>
                                onEvent({
                                    type: DataLayerEventName.addToCart,
                                    originator: 'breachscreen',
                                    payload: {
                                        currency: 'AUD',
                                        value:
                                            (promoPrice
                                                ? promoPrice
                                                : weeklyPrice) *
                                            DEFAULT_CART_QUANTITY,
                                        items: [
                                            {
                                                item_id: 'WST_DIGI_DD',
                                                item_brand: masthead,
                                                item_name: 'Everyday Digital',
                                                price: weeklyPrice,
                                                promo_price: promoPrice || 0,
                                                quantity: DEFAULT_CART_QUANTITY,
                                                discount: discount || 0,
                                                coupon: offerCode || '',
                                                item_variant: 'swg',
                                            },
                                        ],
                                    },
                                })
                            }
                        />
                    )}
                </StyledSubscriptionPackageActions>

                {benefits.map((benefit) => (
                    <SubscriptionPackageBenefit
                        benefit={benefit}
                        key={benefit.name}
                        color={benefitColor}
                    />
                ))}

                <StyledSubscriptionPackageActions>
                    {breachStyle === 'black-friday' ? (
                        <StyledSubscriptionCancellationMessage
                            packageOptions={packageOptions}
                            breachOptions={breachOptions}
                        >
                            T&Cs apply
                            <br />
                            {cancellationMessage}
                        </StyledSubscriptionCancellationMessage>
                    ) : (
                        <StyledSubscriptionCancellationMessage
                            packageOptions={packageOptions}
                            breachOptions={breachOptions}
                        >
                            {cancellationMessage}
                        </StyledSubscriptionCancellationMessage>
                    )}
                </StyledSubscriptionPackageActions>
            </StyledSubscriptionPackage2022>
        )
        
    }

    if (isNewDesign && !isNewBreach2024) {
        // Get package images & scales based on the breach style & deal being used.
        const digitalImage = getDigitalImage(
            breachStyle,
            breachDeal,
            breachContent,
        )
        const digitalPaperImage = getDigitalPrintImage(breachStyle, breachDeal)
        const digitalScale = digitalImage
            ? getCustomImageScale(digitalImage)
            : undefined
        const digitalPaperScale = getCustomImageScale(digitalPaperImage)

        const benefitColor =
            breachStyle === 'black-friday' ? '#043de2' : undefined

        return (
            <StyledSubscriptionPackage2022
                packageOptions={packageOptions}
                breachOptions={breachOptions}
            >
                {isDigital &&
                    (digitalImage ? (
                        <StyledPackageImage
                            src={digitalImage}
                            customScale={digitalScale}
                            breachStyle={breachStyle}
                            packageType={packageType}
                            alt=""
                            role="presentation"
                        />
                    ) : (
                        <ComputedPackageImage section={section} />
                    ))}
                {isDigitalPrint && (
                    <StyledPackageImage
                        src={digitalPaperImage}
                        customScale={digitalPaperScale}
                        breachStyle={breachStyle}
                        packageType={packageType}
                        alt=""
                        role="presentation"
                    />
                )}

                {breachAbTest2 && ribbonTitle && (
                    <Ribbon
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                        className={ribbonOptions}
                    >
                        <span>ON</span>
                        <span>SALE</span>
                    </Ribbon>
                )}

                {!breachAbTest2 && ribbonTitle && (
                    <Ribbon
                        packageOptions={{
                            ...packageOptions,
                            theme: packageTheme,
                        }}
                        breachOptions={breachOptions}
                        className={
                            ribbonOptions ? ribbonOptions : 'newDesignTag'
                        }
                    >
                        <span>
                            <IconStar />
                            {ribbonTitle}
                        </span>
                    </Ribbon>
                )}

                <StyledSubscriptionPackageHeading2022
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {heading}
                </StyledSubscriptionPackageHeading2022>
                {originalPrice && (
                    <StyledSubscriptionPackageOriginalPrice2022
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                    >
                        {originalPrice}
                    </StyledSubscriptionPackageOriginalPrice2022>
                )}
                <StyledSubscriptionPackagePrice2022
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {price}
                </StyledSubscriptionPackagePrice2022>
                {priceQualifier && (
                    <StyledSubscriptionPackagePriceQualifier
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                    >
                        {priceQualifier}
                    </StyledSubscriptionPackagePriceQualifier>
                )}
                <StyledSubscriptionPackagePriceDivider2022
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                />

                {breachDeal === 'AFL50' && (
                    <StyledPromoTagline hasAsset={!!promoAssetImg}>
                        {promoAssetImg && (
                            <img src={promoAssetImg} alt="football" />
                        )}
                        A chance to win 1 of 25 tickets to the RAC Derby
                    </StyledPromoTagline>
                )}

                {benefits.map((benefit) => (
                    <SubscriptionPackageBenefit
                        benefit={benefit}
                        key={benefit.name}
                        color={benefitColor}
                    />
                ))}

                <StyledSubscriptionPackageActions>
                    <StyledSubscriptionPackageButton2022
                        packageOptions={packageOptions}
                        breachOptions={breachOptions}
                        buttonWidth="wide"
                        type="button"
                        title="Subscribe Button"
                        aria-label="Subscribe button"
                        onClick={() => {
                            createLinkClickEvent(
                                breachCreativeName,
                                breachDeal,
                                onEvent,
                                breachScreenType,
                                1,
                                addToCartPayload,
                            )()
                            onSubscribeClick({
                                packagePath,
                                offerCode,
                                callToAction: click_origin_breach_screen,
                                breachScreenType: breachScreenType,
                            })
                        }}
                    >
                        Subscribe
                    </StyledSubscriptionPackageButton2022>

                    {isDigital && showSwG && (
                        <StyledSubscribeWithGoogleButton2022
                            ref={buttonRef}
                            type="button"
                            aria-label="Subscribe with google button"
                            onClick={() =>
                                onEvent({
                                    type: DataLayerEventName.addToCart,
                                    originator: 'breachscreen',
                                    payload: {
                                        currency: 'AUD',
                                        value:
                                            (promoPrice
                                                ? promoPrice
                                                : weeklyPrice) *
                                            DEFAULT_CART_QUANTITY,
                                        items: [
                                            {
                                                item_id: 'WST_DIGI_DD',
                                                item_brand: masthead,
                                                item_name: 'Everyday Digital',
                                                price: weeklyPrice,
                                                promo_price: promoPrice || 0,
                                                quantity: DEFAULT_CART_QUANTITY,
                                                discount: discount || 0,
                                                coupon: offerCode || '',
                                                item_variant: 'swg',
                                            },
                                        ],
                                    },
                                })
                            }
                        />
                    )}

                    {breachStyle === 'black-friday' ? (
                        <StyledSubscriptionCancellationMessage
                            packageOptions={packageOptions}
                            breachOptions={breachOptions}
                        >
                            T&Cs apply
                            <br />
                            {cancellationMessage}
                        </StyledSubscriptionCancellationMessage>
                    ) : (
                        <StyledSubscriptionCancellationMessage
                            packageOptions={packageOptions}
                            breachOptions={breachOptions}
                        >
                            {cancellationMessage}
                        </StyledSubscriptionCancellationMessage>
                    )}
                </StyledSubscriptionPackageActions>
            </StyledSubscriptionPackage2022>
        )
    }

    return (
        // Note: There is high editorial care factor on these images, please ensure
        // appropriate approvals have happened before replacing or updating
        <StyledSubscriptionPackage
            packageOptions={packageOptions}
            breachOptions={breachOptions}
        >
            {isDigital && breachDeal !== 'AFL50' && (
                <ComputedPackageImage section={section}></ComputedPackageImage>
            )}

            {isDigitalPrint && breachDeal !== 'AFL50' && (
                <StyledPackageImage
                    src={everyDayNewspaperImage}
                    breachStyle={breachStyle}
                    alt=""
                    role="presentation"
                />
            )}

            {breachDeal === 'AFL50' && isDigitalPrint && (
                <StyledPackageImage
                    src={footyPackFriMonPrintDigital}
                    breachStyle={breachStyle}
                    alt=""
                    role="presentation"
                />
            )}

            {breachDeal === 'AFL50' && packageType == 'digital' && (
                <StyledPackageImage
                    src={footyPackEverydayDigital}
                    breachStyle={breachStyle}
                    alt=""
                    role="presentation"
                />
            )}

            <StyledSubscriptionPackageHeading
                packageOptions={packageOptions}
                breachOptions={breachOptions}
            >
                {heading}
            </StyledSubscriptionPackageHeading>
            {originalPrice && (
                <StyledSubscriptionPackageOriginalPrice
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {originalPrice}
                </StyledSubscriptionPackageOriginalPrice>
            )}
            <StyledSubscriptionPackagePrice
                packageOptions={packageOptions}
                breachOptions={breachOptions}
            >
                {price}
            </StyledSubscriptionPackagePrice>
            {priceQualifier && (
                <StyledSubscriptionPackagePriceQualifier
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {priceQualifier}
                </StyledSubscriptionPackagePriceQualifier>
            )}
            {ribbonTitle && (
                <Ribbon
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    <span>
                        <IconStar />
                        {ribbonTitle}
                    </span>
                </Ribbon>
            )}

            <StyledSubscriptionPackageActions>
                <StyledSubscriptionPackageButton
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                    buttonWidth="wide"
                    onClick={() => {
                        createLinkClickEvent(
                            breachCreativeName,
                            breachDeal,
                            onEvent,
                            breachScreenType,
                            1,
                            addToCartPayload,
                        )()
                        onSubscribeClick({
                            packagePath,
                            offerCode,
                            callToAction: click_origin_breach_screen,
                            breachScreenType: breachScreenType,
                        })
                    }}
                >
                    Subscribe Now
                </StyledSubscriptionPackageButton>
                {isDigital && showSwG && (
                    <StyledSubscribeWithGoogleButton
                        ref={buttonRef}
                        onClick={() => {
                            const payload = getCartPayload(
                                packageInfo,
                                masthead,
                                { varient: 'swg' },
                            )
                            payload !== undefined ??
                                onEvent({
                                    type: DataLayerEventName.addToCart,
                                    originator: 'breachscreen',
                                    payload:
                                        payload as AddToCartEvent['payload'],
                                })
                        }}
                    />
                )}

                <StyledSubscriptionCancellationMessage
                    packageOptions={packageOptions}
                    breachOptions={breachOptions}
                >
                    {cancellationMessage}
                </StyledSubscriptionCancellationMessage>
            </StyledSubscriptionPackageActions>
        </StyledSubscriptionPackage>
    )
}

export const PACKAGE_BENEFIT_NAMES = [
    'Access to all digital content',
    'Regional online news',
    'The West app',
    'Daily digital edition newspaper',
    'Subscriber rewards',
    'Weekend papers delivered',
] as const

export type SubscriptionPackageBenefitValue = {
    name: typeof PACKAGE_BENEFIT_NAMES[number]
    isAvailable: boolean
}

export const getBenefits = (packageType?: string, customBenefit?: string[]) =>
    customBenefit
        ? customBenefit
              .map((benefit) => ({
                  name: benefit,
                  isAvailable:
                      benefit === 'Weekend papers delivered'
                          ? packageType === 'digitalprint'
                          : true,
              }))
              .sort((a, b) =>
                  a.isAvailable === b.isAvailable
                      ? 0
                      : b.isAvailable > a.isAvailable
                      ? 1
                      : -1,
              )
        : PACKAGE_BENEFIT_NAMES.map((benefit) => ({
              name: benefit,
              isAvailable:
                  benefit === 'Weekend papers delivered'
                      ? packageType === 'digitalprint'
                      : true,
          })).sort((a, b) =>
              a.isAvailable === b.isAvailable
                  ? 0
                  : b.isAvailable > a.isAvailable
                  ? 1
                  : -1,
          )

function getDigitalImage(
    breachStyle: BreachWallStyle,
    breachDeal: string,
    breachContent?: BreachWallContentType,
): string | undefined {
    // check against the breach styles for unique images
    switch (breachStyle) {
        case 'vanishing-cousins':
        case 'black-friday':
            return everyDayDigitalJollyRodgers
    }
    // now check against deal/sub-sources
    switch (breachDeal) {
        case 'AFL50':
            return footyPackEverydayDigital
        case '190BD23':
            return everyDayDigitalPromo
    }

    // if it's an ab test, return the promo
    if (breachContent !== undefined) {
        return everyDayDigitalPromo
    }

    return everyDayDigitalDefault
}
function getDigitalPrintImage(
    breachStyle: BreachWallStyle,
    breachDeal: string,
): string {
    switch (breachStyle) {
        case 'black-friday':
            return everyDayDigitalPaperJollyRodgers
    }
    switch (breachDeal) {
        case 'AFL50':
            return footyPackFriMonPrintDigital
    }

    return everyDayDigitalPaperDefault
}
function getCustomImageScale(image: string): number | undefined {
    switch (image) {
        case everyDayDigitalPaperJollyRodgers:
            return 1.5
        case everyDayDigitalJollyRodgers:
            return 1.4
    }
}

SubscriptionPackage.displayName = 'SubscriptionPackage'
export default SubscriptionPackage
