import styled from '@emotion/styled'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import {
    breakpoint,
    breakpointSwitch,
    calcRem,
    getComponentTheme,
} from '../../__styling'
import { WebLink } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'

export type ActiveState = 'active' | 'default'

export type TagLinkProps = {
    state?: ActiveState
}

const { neutral } = tokens.thenightly.colors.palette

export const TagLink = styled(WebLink)<TagLinkProps>(
    ({ theme, state = 'default' }) => {
        const tagLinkTheme = getComponentTheme(theme, 'taglinkNav').tagLink

        return [
            [
                {
                    // reset anchor styles.
                    color: 'unset',
                    textDecoration: 'none',

                    width: '100%',
                    padding: calcRem(4, 12),
                    borderRadius: calcRem(40),

                    ...(state === 'default' && {
                        outlineStyle: 'solid',
                        outlineWidth: calcRem(1),
                        outlineOffset: calcRem(-1),
                        outlineColor: neutral[30],
                    }),

                    // && workaround to emotion not setting the font-styles in the correct order.
                    '&&': {
                        fontWeight: state === 'active' ? 'bold' : undefined,
                    },

                    [breakpoint('lg')]: {
                        padding: 0,
                        borderRadius: 0,

                        outlineStyle: 'none',

                        textDecoration:
                            state === 'active' ? 'underline' : undefined,
                    },
                },
                breakpointSwitch(tagLinkTheme, ({ textType }) =>
                    textMixin(theme, textType),
                ),
                breakpointSwitch(tagLinkTheme, (states) => ({
                    color: states[state].textColor,
                    background: states[state].background,
                })),
            ],
        ]
    },
)

export const TagLinkWrapper = styled.nav({
    display: 'flex',
    padding: calcRem(0, 24),

    [breakpoint('sm')]: {
        padding: calcRem(0, 12),
    },

    [breakpoint('lg')]: {
        padding: 0,
    },
})

export const TagLinkList = styled.ul({
    // Reset ul styles.
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyleType: 'none',

    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: calcRem(8),
    width: '100%',

    [breakpoint('lg')]: {
        flexDirection: 'column',
        gap: calcRem(12),
    },
})

export const TagLinkListItem = styled.li({
    // Reset li styles
    display: 'flex',
    flexWrap: 'wrap',

    [breakpoint('lg')]: {
        width: '100%',
    },
})

export const TagLinkNavContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',

    [breakpoint('lg')]: {
        gap: calcRem(40),
    },
})

export const Divider = styled.div(({ theme }) => {
    const tagLinkTheme = getComponentTheme(theme, 'taglinkNav').divider
    return {
        display: 'none',

        [breakpoint('lg')]: {
            display: 'flex',
            height: calcRem(1),
            background: tagLinkTheme.color,
        },
    }
})

export const TrailingContent = styled.div({
    display: 'none',

    [breakpoint('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(24),
    },
})
