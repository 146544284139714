import { ContentDataTypes } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { theNightlySmallSectionHeaderOverride } from '@news-mono/component-library'

export const cardList = (
    data: ContentDataTypes,
    heading: string,
    sectionText?: string,
) =>
    layout.nestedComposition({
        type: 'component-overrider',
        props: {
            override: () => theNightlySmallSectionHeaderOverride,
        },
        contentAreas: {
            children: [
                layout.component({
                    type: 'juliet',
                    props: {
                        cardType: {
                            type: 'landscape',
                            format: 'landscape-common',
                        },
                        verticalSpacing: 'md',
                        removeHorizontalGutters: true,
                        hideByline: false,
                        topicLevel: 'parent',
                        numbered: true,
                        sectionHeader: {
                            heading,
                            sectionText,
                        },
                        dataDefinitionArgs: data,
                    },
                }),
            ],
        },
    })
