import { TheWestSection } from '@news-mono/common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { useFeatureForSection } from '../../../../feature-toggling'
import { StyledPackageImage } from './SubscriptionPackage.styled'
const thewest = require('./images/updated2022/everyday_digital.png')
const albanyx1 = require('./images/AlbanyAdvertiser-devicecomp-min.png')
const albanyx2 = require('./images/AlbanyAdvertiser-devicecomp-min@2x.png')
const augustax1 = require('./images/AugustaMargaretRiverTimes-devicecomp-min.png')
const augustax2 = require('./images/AugustaMargaretRiverTimes-devicecomp-min@2x.png')
const broomex1 = require('./images/BroomeAdvertiser-devicecomp-min.png')
const broomex2 = require('./images/BroomeAdvertiser-devicecomp-min@2x.png')
const bunburyx1 = require('./images/BunburyHerald-devicecomp-min.png')
const bunburyx2 = require('./images/BunburyHerald-devicecomp-min@2x.png')
const busseltonx1 = require('./images/BusseltonDunsborougTimes-devicecomp-min.png')
const busseltonx2 = require('./images/BusseltonDunsborougTimes-devicecomp-min@2x.png')
const geraldtonx1 = require('./images/GeraldtonGuardian-devicecomp-min.png')
const geraldtonx2 = require('./images/GeraldtonGuardian-devicecomp-min@2x.png')
const greatSouthernx1 = require('./images/GreatSouthernHerald-devicecomp-min.png')
const greatSouthernx2 = require('./images/GreatSouthernHerald-devicecomp-min@2x.png')
const harveyx1 = require('./images/GreatSouthernHerald-devicecomp-min.png')
const harveyx2 = require('./images/GreatSouthernHerald-devicecomp-min@2x.png')
const kalMinerx1 = require('./images/KalgoorlieMiner-devicecomp-min.png')
const kalMinerx2 = require('./images/KalgoorlieMiner-devicecomp-min@2x.png')
const kimberleyx1 = require('./images/KimberleyEcho-devicecomp-min.png')
const kimberleyx2 = require('./images/KimberleyEcho-devicecomp-min@2x.png')
const manjimupx1 = require('./images/ManjimupBridgetownTimes-devicecomp-min.png')
const manjimupx2 = require('./images/ManjimupBridgetownTimes-devicecomp-min@2x.png')
const midwestx1 = require('./images/MidwestTimes-devicecomp-min.png')
const midwestx2 = require('./images/MidwestTimes-devicecomp-min@2x.png')
const narroginx1 = require('./images/NarroginObserver-devicecomp-min.png')
const narroginx2 = require('./images/NarroginObserver-devicecomp-min@2x.png')
const northwestx1 = require('./images/NorthWestTelegraph-devicecomp-min.png')
const northwestx2 = require('./images/NorthWestTelegraph-devicecomp-min@2x.png')
const pilbarax1 = require('./images/PilbaraNews-devicecomp-min.png')
const pilbarax2 = require('./images/PilbaraNews-devicecomp-min@2x.png')
const southWestx1 = require('./images/SouthWesternTimes-devicecomp-min.png')
const southWestx2 = require('./images/SouthWesternTimes-devicecomp-min@2x.png')

const redesign2022 = {
    thewestx1: require('./images/updated2022/redesign/mobile/everyday_digital_cropped.png'),
    thewestx2: require('./images/updated2022/redesign/everyday_digital_cropped.png'),
}
const redesign2022EditionsModal = {
    thewestx1: require('./images/updated2022/redesign/mobile/everyday_digital_full_crop.png'),
    thewestx2: require('./images/updated2022/redesign/everyday_digital_full_crop.png'),
}

export interface RegionalPackageImageProps {
    section: TheWestSection
    editionsModal?: boolean
}

export const ComputedPackageImage: React.FC<RegionalPackageImageProps> = ({
    section,
    editionsModal,
}) => {
    const isNewDesign = useFeatureForSection({
        feature: 'tp-subscription-new-breach-package-design',
        sections: TheWestSections.default,
    })

    const thewestx1 = editionsModal
        ? redesign2022EditionsModal.thewestx1
        : redesign2022.thewestx1
    const thewestx2 = editionsModal
        ? redesign2022EditionsModal.thewestx2
        : redesign2022.thewestx2

    switch (section) {
        case 'albany-advertiser':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${albanyx1}, ${albanyx2} 2x`}
                    src={albanyx1}
                    alt="Albany Advertiser Digital Package"
                />
            )
        case 'augusta-margaret-river-times':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${augustax1}, ${augustax2} 2x`}
                    src={augustax1}
                    alt="Augusta Margaret River Times Digital Package"
                />
            )
        case 'bunbury-herald':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${bunburyx1}, ${bunburyx2} 2x`}
                    src={bunburyx1}
                    alt="Bunbury Herald Digital Package"
                />
            )
        case 'broome-advertiser':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${broomex1}, ${broomex2} 2x`}
                    src={broomex1}
                    alt="Broome Advertiser Digital Package"
                />
            )
        case 'busselton-dunsborough-times':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${busseltonx1}, ${busseltonx2} 2x`}
                    src={busseltonx1}
                    alt="Busselton Dunsborough Times Digital Package"
                />
            )
        case 'geraldton-guardian':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${geraldtonx1}, ${geraldtonx2} 2x`}
                    src={geraldtonx1}
                    alt="Geraldton Guardian Digital Package"
                />
            )
        case 'great-southern-herald':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${greatSouthernx1}, ${greatSouthernx2} 2x`}
                    src={greatSouthernx1}
                    alt="Great Southern Herald Digital Package"
                />
            )
        case 'harvey-waroona-reporter':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${harveyx1}, ${harveyx2} 2x`}
                    src={harveyx1}
                    alt="Harvey Waroona Reporter Digital Package"
                />
            )
        case 'kalgoorlie-miner':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${kalMinerx1}, ${kalMinerx2} 2x`}
                    src={kalMinerx1}
                    alt="Kalgoorlie Miner Digital Package"
                />
            )
        case 'the-kimberley-echo':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${kimberleyx1}, ${kimberleyx2} 2x`}
                    src={kimberleyx2}
                    alt="Kimberley Echo Digital Package"
                />
            )
        case 'manjimup-bridgetown-times':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${manjimupx1}, ${manjimupx2} 2x`}
                    src={manjimupx1}
                    alt="Manjimup Bridgetown Times Digital Package"
                />
            )
        case 'midwest-times':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${midwestx1}, ${midwestx2} 2x`}
                    src={midwestx1}
                    alt="Midwest Times Digital Package"
                />
            )
        case 'narrogin-observer':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${narroginx1}, ${narroginx2} 2x`}
                    src={narroginx1}
                    alt="Narrogin Observer Digital Package"
                />
            )
        case 'north-west-telegraph':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${northwestx1}, ${northwestx2} 2x`}
                    src={northwestx1}
                    alt="North West Telegraph Digital Package"
                />
            )
        case 'pilbara-news':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${pilbarax1}, ${pilbarax2} 2x`}
                    src={pilbarax1}
                    alt="Pilbara News Digital Package"
                />
            )
        case 'south-western-times':
            return (
                <StyledPackageImage
                    isRegional={true}
                    isEditionsModal={editionsModal}
                    srcSet={`${southWestx1}, ${southWestx2} 2x`}
                    src={southWestx1}
                    alt="South Western Times Digital Package"
                />
            )

        default:
            return isNewDesign ? (
                <StyledPackageImage
                    isEditionsModal={editionsModal}
                    src={thewestx1}
                    srcSet={`${thewestx1}, ${thewestx2} 2x`}
                    alt="The West Australian"
                />
            ) : (
                <StyledPackageImage src={thewest} alt="" />
            )
    }
}
