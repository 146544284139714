import styled from '@emotion/styled'
import { calcRem, getComponentTheme, breakpointSwitch } from '../../__styling'

export const MarsContainer = styled.section(({ theme }) => {
    const marsTheme = getComponentTheme(theme, 'mars')
    return [
        {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            maxWidth: calcRem(1140),
            margin: '0 auto',
            borderBottom: marsTheme.border,

            transition: 'width 0.25s, gap 0.25s, padding 0.25s',
        },
        breakpointSwitch(marsTheme.breakpointState, ({ padding, gap }) => ({
            padding,
            gap,
        })),
    ]
})

export const MarsHeader = styled.h2(({ theme }) => {
    const headerTheme = getComponentTheme(theme, 'mars').header
    return [
        {
            fontWeight: 700,
            margin: 0,

            transition: 'font-size 0.25s, width 0.25s, padding 0.25s',
        },
        breakpointSwitch(
            headerTheme.breakpointState,
            ({ fontSize, width, letterSpacing, padding, lineHeight }) => ({
                fontSize,
                width,
                letterSpacing,
                padding,
                lineHeight,
            }),
        ),
    ]
})

export const SlotContainer = styled.div(({ theme }) => {
    const slotContainerTheme = getComponentTheme(theme, 'mars').slotContainer
    return [
        {
            display: 'flex',
            gap: calcRem(16),
        },
        breakpointSwitch(slotContainerTheme.flexDirection, (flexDirection) => ({
            flexDirection,
        })),
    ]
})

export const PrimarySlot = styled.div(({ theme }) => {
    const slotTheme = getComponentTheme(theme, 'mars').slot

    return [
        {
            display: 'flex',
            flexDirection: 'column',
            transition: 'width 0.25s',
        },
        breakpointSwitch(slotTheme.width, (width) => ({
            width,
        })),
    ]
})

export const SecondarySlot = styled.div(({ theme }) => {
    const slotTheme = getComponentTheme(theme, 'mars').slot

    return [
        {
            display: 'block',

            transition: 'width 0.25s',
        },
        breakpointSwitch(slotTheme.width, (width) => ({
            width,
        })),
    ]
})
