import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Luna, LunaProps } from './Luna'

type LunaRegistrationProps = Omit<LunaProps, 'content'>

export const LunaRegistration = createRegisterableComposition<
    keyof LunaProps['content']
>()<'luna', LunaRegistrationProps>('luna', (contentAreas, props) => (
    <Luna content={contentAreas} {...props} />
))
