import React from 'react'
import {
    StyledColumnContainer,
    StyledContainer,
    StyledRowContainer,
    Heading,
    StyledTNHeaderLogo,
} from './TNCreateAccountCTA.styled'
import { AppState, AuthenticationState } from '@news-mono/web-common'
import { useSelector } from 'react-redux'
import { useAuthActions } from '../../authentication'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'

export const TNCreateAccountCTA: React.FC = () => {
    const { isLoggedIn } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )
    const renditionType =
        useSelector((state: AppState) => state.render.renditionType) === 'app'
            ? 'app'
            : 'web'
    const { onRegisterClick } = useAuthActions()

    return (
        <StyledContainer showAsDivider={isLoggedIn}>
            {!isLoggedIn && (
                <StyledRowContainer>
                    <StyledColumnContainer>
                        <StyledTNHeaderLogo fill="#000" />
                        <Heading>Join The Nightly for full access.</Heading>
                    </StyledColumnContainer>
                    <StyledColumnContainer>
                        <NightlyButton
                            variant="default"
                            text="CREATE FREE ACCOUNT"
                            action={{
                                type: 'button',
                                onClick: () =>
                                    onRegisterClick(
                                        'homepage-cta',
                                        renditionType,
                                    ),
                            }}
                            color="emphasis"
                            fill="filled"
                        />
                    </StyledColumnContainer>
                </StyledRowContainer>
            )}
        </StyledContainer>
    )
}
