import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import NightlySpecificEdition from './SpecificEdition/NightlySpecificEdition'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'

export interface NightlySpecificEditionRouteProps {
    edition: EditionV4DTO
    lazyloadImages?: boolean
}

export const NightlySpecificEditionFocusRegistration =
    createRegisterableComponent(
        'nightly-specific-edition-focus',
        (props: NightlySpecificEditionRouteProps, { onEvent }) => {
            return (
                <NightlySpecificEdition
                    {...props}
                    onEvent={onEvent}
                    showAllEditionsButton
                />
            )
        },
    )
