import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { SectionContent, SectionContentProps } from './SectionContent'

type SectionContentRegistrationProps = Omit<SectionContentProps, 'content'>

export const SectionContentRegistration = createRegisterableComposition<
    keyof SectionContentProps['content']
>()<'section-content', SectionContentRegistrationProps>(
    'section-content',
    (contentAreas, props, { onEvent }) => (
        <SectionContent {...props} content={contentAreas} onEvent={onEvent} />
    ),
)
