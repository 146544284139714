import { NavItem } from '@news-mono/web-common'
import React from 'react'
import {
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    XIcon,
} from '@news-mono/component-library'
import { SocialLinksWrapper, StyledSocialLink } from './SocialLinks.styled'
import { YoutubeIcon } from '../../../icons/IconNightlySocial/YoutubeIcon'

export type NightlySocialLinks = {
    facebook: NavItem
    x: NavItem
    linkedin: NavItem
    instagram: NavItem
    youtube: NavItem
}

export type SocialLinksProps = {
    socialLinks: NightlySocialLinks
}

/**
 * The social links with icons.
 */
export const SocialLinks = ({
    socialLinks: { facebook, x, linkedin, instagram, youtube },
}: SocialLinksProps) => {
    return (
        <SocialLinksWrapper>
            <StyledSocialLink to={facebook.link}>
                <FacebookIcon />
            </StyledSocialLink>
            <StyledSocialLink to={linkedin.link}>
                <LinkedinIcon />
            </StyledSocialLink>
            <StyledSocialLink to={instagram.link}>
                <InstagramIcon />
            </StyledSocialLink>
            <StyledSocialLink to={youtube.link}>
                <YoutubeIcon />
            </StyledSocialLink>
            <StyledSocialLink to={x.link}>
                <XIcon />
            </StyledSocialLink>
        </SocialLinksWrapper>
    )
}
