import styled from '@emotion/styled'
import { calcRem, metrics, fonts, breakpoint } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { WebLink, createPropFilter } from '@news-mono/web-common'

export const StyledNightlyLatestEditionContainer = styled('div')(
    ({ theme }) => ({
        color: tokens.thenightly.colors.palette.neutral[100],
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        gap: calcRem(metrics.thenightly.margins.m2l),

        padding: calcRem(0, 32),

        [breakpoint('sm')]: {
            padding: 0,
        },

        [breakpoint('lg')]: {
            alignItems: 'center',
        },
        transition: 'align-items 0.25s, padding 0.25s',
    }),
)

interface StyledHeadingProps {
    isTop?: boolean
}

export const StyledHeading = styled('h2', {
    shouldForwardProp: createPropFilter()(['isTop']),
})<StyledHeadingProps>(({ theme, isTop }) => ({
    margin: 0,
    fontFamily: fonts.thenightly.sansSerif,
    fontSize: calcRem(40),
    fontWeight: 700,
    letterSpacing: '-1.6px',
    textTransform: 'capitalize',
    lineHeight: calcRem(48),
    display: isTop ? 'block' : 'none',

    [breakpoint('sm')]: {
        display: isTop ? 'none' : 'block',
        lineHeight: calcRem(48),
        fontSize: calcRem(45),
        marginBottom: isTop
            ? undefined
            : calcRem(metrics.thenightly.margins.sm),
    },
    [breakpoint('lg')]: {
        lineHeight: calcRem(56),
        fontSize: calcRem(51),
    },

    transition: 'font-size 0.25s, line-height 0.25s',
}))

export const StyledCardBody = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: calcRem(metrics.thenightly.margins.md),

    [breakpoint('sm')]: {
        justifyContent: 'start',
    },
    [breakpoint('lg')]: {
        justifyContent: 'center',
    },
}))

export const StyleImgLink = styled(WebLink)({
    display: 'block',
})

export const StyledNightlyLatestEditionLink = styled(WebLink)(({ theme }) => ({
    display: 'flex',
    flexShrink: 0,
    width: calcRem(163),
    height: '100%',
    [breakpoint('sm')]: {
        width: calcRem(256),
    },
    [breakpoint('lg')]: {
        width: '50%',
    },
    img: {
        width: '100%',
        border: `1px solid ${tokens.thenightly.colors.palette.neutral[100]}`,
        borderRadius: '4px',
        boxShadow: '0px 38.7142px 50.63px -24.63631px rgba(0, 0, 0, 0.15)',
    },

    transition: 'width 0.25s',
}))

export const StyledTextBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    gap: calcRem(metrics.thenightly.margins.sm),
    p: {
        margin: 0,
        lineHeight: '150%',
        color: tokens.thenightly.colors.palette.neutral[70],
        fontFamily: fonts.thenightly.serif,
        fontSize: calcRem(16),
    },
    [breakpoint('sm')]: {
        p: {
            marginBottom: calcRem(metrics.thenightly.margins.md),
        },
    },
    [breakpoint('lg')]: {
        p: {
            marginBottom: calcRem(metrics.thenightly.margins.lg),
        },
    },
    transition: 'margin 0.25s, width 0.25s',
}))

export const StyledTitle = styled('h3')(({ theme }) => ({
    margin: 0,
    fontFamily: fonts.thenightly.sansSerif,
    fontSize: calcRem(20),
    fontWeight: 700,
    lineHeight: calcRem(24),
    letterSpacing: '-0.22px',
    textTransform: 'uppercase',

    span: {
        display: 'inline-block',
    },
    // toggling display of full date upon resizing
    'span:first-child,span:last-child': {
        display: 'none',
    },

    [breakpoint('sm')]: {
        span: {
            display: 'none',
            fontSize: calcRem(22),
            lineHeight: calcRem(32),
        },
        'span:first-child,span:last-child': {
            display: 'inline-block',
        },
    },
    [breakpoint('lg')]: {
        span: {
            fontSize: calcRem(25),
            lineHeight: calcRem(32),
        },
    },
    transition: 'font-size 0.25s, line-height 0.25s',
}))

interface StyledButtonWrapperProps {
    isBottom?: boolean
}

export const StyledButtonWrapper = styled('div')<StyledButtonWrapperProps>(
    ({ theme, isBottom }) => ({
        display: isBottom ? 'flex' : 'none',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        fontWeight: 600,
        fontFamily: fonts.thenightly.sansSerif,
        gap: calcRem(metrics.thenightly.margins.sm),
        width: '100%',

        [breakpoint('sm')]: {
            width: '80%',
            display: isBottom ? 'none' : 'flex',
        },
    }),
)
