import {
    createRegisterableComponentWithData,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import {
    getPublicationInfoFromRouteResolution,
    PublicationRouteProps,
} from '../../templates/Publication/SharedPublication.routing'
import { ArticlePublicationDataDefinitionLoader } from './ArticlePublicationDataLoader'
import { ShorthandPublication } from './ShorthandPublication'

export const ShorthandPublicationRegistration =
    createRegisterableComponentWithData(
        'shorthand-publication',
        ArticlePublicationDataDefinitionLoader,
        (props: PublicationRouteProps, data, services) => {
            const publicationInfo =
                getPublicationInfoFromRouteResolution(services)
            if (!isArticleLikeType(publicationInfo.publication)) {
                throw new Error('Article component rendered for non-article')
            }

            if (data.loaded) {
                return (
                    <ShorthandPublication
                        data={publicationInfo.publication}
                        onEvent={services.onEvent}
                        log={services.log}
                        adState={services.adState}
                    />
                )
            }
            return null
        },
    )
