import {
    AppState,
    AuthenticationState,
    RenderTargetContext,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { MenuAction, MenuActionProps } from './MenuAction'
import {
    HeaderCenter,
    StyledActionGroup,
    StyledActionsContent,
    StyledSearchAction,
} from './TheNightlyHeaderActions.styled'
import { TheNightlyHeaderLogin } from '../TheNightlyHeaderLogin/TheNightlyHeaderLoginModal'
import TheNightlyLogo from '../../logos/TheNightlyLogo/TheNightlyLogo'
import styled from '@emotion/styled'
import H from 'history'
import { breakpoint, calcRem } from '../../__styling'
import { useSection } from '../../__helpers/use-section-meta'
import { StyledLogoWrapLink } from '../../content/TheNightlyHeader/logos/MastheadLogo.styled'
import { useSelector } from 'react-redux'
import { TheNightlyHeader } from '../../content/TheNightlyHeader/TheNightlyHeader'
import { SearchAction } from '../SearchAction/SearchAction'
import { FeatureToggle } from '../../feature-toggling'
import { NotificationCentre } from '@news-mono/component-library'

export interface TheNightlyHeaderActionsWebProps extends MenuActionProps {
    onEvent: (event: any) => void
    isHome: boolean
    location: H.Location
}

export const StyledTNHeaderLogo = styled(TheNightlyLogo)({
    width: calcRem(40),
    height: calcRem(40),
    display: 'block',

    [breakpoint('lg')]: {
        display: 'none',
    },
})

const StyledTNHeaderWrapper = styled('div')({
    margin: calcRem(10, 0),

    [breakpoint('lg')]: {
        display: 'none',
    },
})

const TNHeaderLogo: React.FC<{ fill: string; isHome: boolean }> = ({
    fill,
    isHome,
}) => {
    const websiteSection = useSection()

    return (
        <StyledTNHeaderWrapper as={isHome ? 'h1' : undefined}>
            <StyledLogoWrapLink
                section={websiteSection}
                to={'/'}
                id={'header-mobile-logo'}
            >
                <StyledTNHeaderLogo fill={fill} />
            </StyledLogoWrapLink>
        </StyledTNHeaderWrapper>
    )
}

export const TheNightlyHeaderActionsWeb: React.FC<TheNightlyHeaderActionsWebProps> =
    ({ onEvent, isHome, location, ...menuActionProps }) => {
        const { renderTarget } = useContext(RenderTargetContext)
        // Hide navigation on server-error page.
        const isNavigationDisabled = renderTarget === 'static-error-page'

        const authentication = useSelector<AppState, AuthenticationState>(
            ({ authentication }) => authentication,
        )
        const isAuthLoading = authentication.isLoading

        const inApp = useSelector(
            (state: AppState) => state.render.renditionType === 'app',
        )

        return (
            <StyledActionsContent>
                {!isNavigationDisabled && <MenuAction {...menuActionProps} />}
                <HeaderCenter
                    onClick={() => {
                        if (isHome) {
                            window.scrollTo({ top: 0 })
                        }
                    }}
                >
                    <TNHeaderLogo fill="#000" isHome={isHome} />
                    <TheNightlyHeader
                        onEvent={onEvent}
                        isHome={location.pathname === '/'}
                    />
                </HeaderCenter>
                {!isNavigationDisabled && !isAuthLoading && (
                    <StyledActionGroup>
                        {!inApp && (
                            <FeatureToggle
                                feature={'search'}
                                on={() => (
                                    <StyledSearchAction>
                                        <SearchAction
                                            onEvent={onEvent}
                                            callLocation="Top Nav"
                                        />
                                    </StyledSearchAction>
                                )}
                            />
                        )}
                        <NotificationCentre
                            isLoggedIn={authentication.isLoggedIn}
                            onEvent={onEvent}
                        />
                        {!inApp && (
                            <TheNightlyHeaderLogin
                                onEvent={onEvent}
                                authState={authentication}
                            />
                        )}
                    </StyledActionGroup>
                )}
            </StyledActionsContent>
        )
    }
TheNightlyHeaderActionsWeb.displayName = 'TheNightlyHeaderActionsWeb'
