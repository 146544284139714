import React, { FC } from 'react'

export const TrendingNewsIcon: FC = () => {
    return (
        <svg
            viewBox={`0 0 48 48`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="48" height="48" rx="24" fill="#DDD9E5" />
            <path
                d="M37.3334 17.3334L26.8418 27.8249C26.3138 28.3529 26.0498 28.6169 25.7454 28.7158C25.4776 28.8028 25.1891 28.8028 24.9213 28.7158C24.6169 28.6169 24.3529 28.3529 23.8249 27.8249L20.1752 24.1752C19.6472 23.6472 19.3831 23.3832 19.0787 23.2843C18.8109 23.1972 18.5225 23.1972 18.2547 23.2843C17.9502 23.3832 17.6862 23.6472 17.1582 24.1752L10.6667 30.6667M37.3334 17.3334H28M37.3334 17.3334V26.6667"
                stroke="#310048"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
