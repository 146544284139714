import { NotificationCentreListSection } from '../types'
import {
    CollectionEvent,
    DataLayerEventName,
    getProductName,
} from '@news-mono/web-common'
import {
    getNotificationCentreCardInformation,
    getNotificationCentreEventContext,
} from './helpers'
import { useCallback } from 'react'
import { NotificationCentreItemV4DTO } from '@west-australian-newspapers/publication-types'

interface NotificationCentreEventsProps {
    notificationSections: NotificationCentreListSection[]
    onEvent: (event: CollectionEvent) => void
}

export const useNotificationCentreEvents = ({
    notificationSections,
    onEvent,
}: NotificationCentreEventsProps) => {
    const cardInformation =
        getNotificationCentreCardInformation(notificationSections)
    const notificationCentreContext = getNotificationCentreEventContext(
        cardInformation.length,
    )

    const onViewItemListEvent = useCallback(() => {
        onEvent({
            context: notificationCentreContext,
            type: DataLayerEventName.viewItemList,
            originator: 'NotificationCentre',
            payload: {
                cardInformation,
            },
        })
    }, [notificationCentreContext, onEvent, cardInformation])

    const onViewItemEvent = useCallback(
        (index: number, notification: NotificationCentreItemV4DTO) => {
            onEvent({
                context: notificationCentreContext,
                type: DataLayerEventName.selectItemEvent,
                originator: 'NotificationCentre',
                payload: {
                    item_brand: notification.source,
                    index,
                    item_category: notification.primaryTopic,
                    item_category2: notification.kind,
                    item_id: notification.publicationId,
                    item_heading: notification.heading,
                    item_name: notification.body,
                    item_variant: false,
                    price: 0.0,
                    link_text: notification.body,
                    link_url: notification.url,
                },
            })
        },
        [notificationCentreContext, onEvent],
    )

    return {
        onViewItemListEvent,
        onViewItemEvent,
    }
}
