import React from 'react'
import { SearchFiltersPanel } from './SearchFiltersPanel'
import { Features, createRegisterableComposition } from '@news-mono/web-common'

export type SearchFiltersProps = {
    featureToggle: Features
}

export const SearchFiltersRegistration =
    createRegisterableComposition<'trailingContent'>()(
        'search-filters',
        ({ trailingContent }, _, { onEvent }) => (
            <SearchFiltersPanel
                trailingContent={trailingContent}
                onEvent={onEvent}
            />
        ),
    )
