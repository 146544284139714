import styled from '@emotion/styled'
import {
    calcRem,
    breakpoint,
    getComponentTheme,
    breakpointSwitch,
} from '../../__styling'

export const StyledTrendingArticlesContainer = styled.div(({ theme }) => {
    const trendingArticlesContainerTheme = getComponentTheme(
        theme,
        'trendingArticles',
    ).container

    return [
        {
            display: 'flex',
            flexDirection: 'column',
        },
        breakpointSwitch(
            trendingArticlesContainerTheme.breakpointState,
            ({ maxWidth, margin, gap }) => ({
                maxWidth,
                margin,
                gap,
            }),
        ),
    ]
})

export const StyledTrendingArticlesHeader = styled.div(({ theme }) => {
    const trendingArticlesHeaderTheme = getComponentTheme(
        theme,
        'trendingArticles',
    ).header

    return [
        {
            display: 'flex',
            gap: calcRem(theme.margins.md),
            alignItems: 'center',
            flexDirection: 'row',
        },
        breakpointSwitch(
            trendingArticlesHeaderTheme.breakpointState,
            ({ gap }) => ({
                gap,
            }),
        ),
    ]
})

export const StyledTrendingArticlesHeaderIcon = styled.div(({ theme }) => {
    const trendingArticlesHeaderIcon = getComponentTheme(
        theme,
        'trendingArticles',
    ).svg

    return [
        breakpointSwitch(
            trendingArticlesHeaderIcon.breakpointState,
            ({ height, width }) => ({
                height,
                width,
            }),
        ),
    ]
})

export const StyledTrendingArticlesHeaderText = styled.div(({ theme }) => {
    const trendingArticlesHeaderTextTheme = getComponentTheme(
        theme,
        'trendingArticles',
    ).headerText

    return [
        {
            fontWeight: 700,
            flexGrow: 1,
        },
        breakpointSwitch(
            trendingArticlesHeaderTextTheme.breakpointState,
            ({ fontSize, letterSpacing, lineHeight }) => ({
                fontSize,
                letterSpacing,
                lineHeight,
            }),
        ),
    ]
})

export const StyledTrendingArticlesGrid = styled.div(({ theme }) => {
    const trendingArticlesGridContainerTheme = getComponentTheme(
        theme,
        'trendingArticles',
    ).gridContainer

    return [
        {
            display: 'grid',
            gridTemplateRows: 'auto',
        },
        breakpointSwitch(
            trendingArticlesGridContainerTheme.breakpointState,
            ({ gridTemplateColumns, columnGap }) => ({
                gridTemplateColumns,
                columnGap,
            }),
        ),
    ]
})

export const StyledTrendingArticlesArticle = styled.div(({ theme }) => {
    const trendingArticlesTheme = getComponentTheme(theme, 'trendingArticles')

    return {
        borderBottom: trendingArticlesTheme.border,
        padding: calcRem(theme.margins.md, 0),
    }
})
