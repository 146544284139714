import React, { useEffect } from 'react'
import {
    BannerEvent,
    CollectionEvent,
    NavEvent,
    NavItem,
} from '@news-mono/web-common'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'
import {
    NavDisplayState,
    TheNightlyHeaderNavState,
} from './useTheNightlyHeaderNav'
import { StickyAccountBannerWrapper } from '../../user-registration'
import { AnimatedHeaderNav } from './components/AnimatedHeaderNav'
import { AnimatedContentNav } from './components/AnimatedContentNav'

export interface TNNavHeaderWithStickyBannerProps {
    navState: TheNightlyHeaderNavState
    navigation: JSX.Element
    navItems: NavItem[]
    onEvent: (event: NavEvent | CollectionEvent | BannerEvent) => void
    adComponent?: JSX.Element
}

export type TNHeaderNavInternalProps = TNNavHeaderWithStickyBannerProps &
    DimensionProps

export const InternalTNHeaderNav: React.FC<TNHeaderNavInternalProps> = (
    props,
) => {
    const stickyBannerHeight = getStickyBannerHeight({
        displayState: props.navState.navDisplayState,
        height: props.height,
        adHeight: props.navState.adHeight,
        contentNavHeight: props.navState.contentNavHeight,
        isMobile: props.navState.isMobile,
    })

    useEffect(() => {
        document.documentElement.style.setProperty(
            '--stickyHeaderHeight',
            `${stickyBannerHeight}px`,
        )
    }, [stickyBannerHeight])

    return (
        <>
            <AnimatedHeaderNav {...props} />
            <StickyAccountBannerWrapper
                isHidden={false}
                message="Join The Nightly for full access."
                onBannerChange={props.navState.updateBannerHeight}
                existingBannerHeight={props.navState.bannerHeight}
                onEvent={props.onEvent}
            />
            <AnimatedContentNav
                navState={props.navState}
                navHeight={props.height}
            />
        </>
    )
}

interface GetStickyBannerHeightProps {
    displayState: NavDisplayState
    height: number
    adHeight: number
    contentNavHeight: number
    isMobile: boolean
}

const getStickyBannerHeight = ({
    displayState,
    height,
    adHeight,
    contentNavHeight,
    isMobile,
}: GetStickyBannerHeightProps) => {
    switch (displayState) {
        case 'header-with-ad':
            return height + adHeight
        case 'header':
            return height
        case 'content-nav-with-header':
            return height + contentNavHeight
        case 'content-nav':
            if (isMobile) {
                return contentNavHeight
            }
            return 0
        case 'none':
        default:
            return 0
    }
}

export const TNHeaderNav =
    withDimensions<TNNavHeaderWithStickyBannerProps>(InternalTNHeaderNav)

TNHeaderNav.displayName = 'TNHeaderNav'
