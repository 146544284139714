import { TheNightlySection } from '@news-mono/common'

export function parsePageParam(pageParam: unknown, maxPage = 10): number {
    const defaultPage = 1
    if (pageParam === undefined || pageParam === null) {
        return defaultPage
    }
    if (typeof pageParam === 'string') {
        pageParam = pageParam || defaultPage
    }
    if (isNaN(pageParam as any)) {
        throw new Error('404')
    }
    const page = parseInt(pageParam as string)
    if (page > 0 && page <= maxPage) {
        return page
    }
    throw new Error('404')
}

export function getPaginatedCanonicalUrl(
    baseUrl: string,
    page: number,
): string {
    return `${baseUrl}?page=${page}`
}
