import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { IconCross } from '../../../icons'

const StyledIconExitCross = styled(IconCross)(() => ({
    fill: tokens.thenightly.colors.palette.neutral[30],
    alignSelf: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',

    '&:hover, &:focus, &:active': {
        fill: tokens.thenightly.colors.palette.neutral[20],
    },
}))

StyledIconExitCross.displayName = 'StyledIconExitCross'
export default StyledIconExitCross
