import React from 'react'
import {
    DefaultButton,
    DefaultButtonProps,
} from './DefaultButton/DefaultButton'
import { AlignSelfProperty } from 'csstype'
import { WebLinkProps } from '@news-mono/web-common'

export type BaseTheNightlyButton<Variant extends string> = {
    variant: Variant
    text: string
    isDisabled?: boolean
    layout?: {
        alignSelf?: AlignSelfProperty
        shouldGrow?: boolean
    }
    action:
        | {
              type: 'button'
              onClick: () => void
          }
        | ({
              type: 'link'
              onClick?: (event: any) => void
          } & WebLinkProps)
}

export type NightlyButtonProps = DefaultButtonProps // | other variants, e.g. ArrowButtonProps

export const NightlyButton = (props: NightlyButtonProps) => {
    switch (props.variant) {
        case 'default':
        default:
            return <DefaultButton {...props} />
    }
}
