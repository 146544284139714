import styled from '@emotion/styled'
import { breakpoint, calcRem, getComponentTheme } from '../../../__styling'

export const SavedArticlesFiltersContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    padding: calcRem(80, 0, 40, 0),
    [breakpoint('lg')]: {
        gap: calcRem(40),
    },
})

export const FiltersWrapper = styled.div({
    display: 'none',
    [breakpoint('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(16),
    },
})

export const Divider = styled.div(({ theme }) => {
    const tagLinkTheme = getComponentTheme(theme, 'taglinkNav').divider
    return {
        display: 'none',
        marginTop: calcRem(40),

        [breakpoint('lg')]: {
            display: 'flex',
            height: calcRem(1),
            background: tagLinkTheme.color,
        },
    }
})

export const TrailingContent = styled.div({
    display: 'none',

    [breakpoint('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(24),
    },
})

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'block',
    width: '100%',
}))
