import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TNCreateAccountCTA } from './TNCreateAccountCTA'

export const TNCreateAccountCTARegistration = createRegisterableComponent(
    'thenightly-create-account-cta',
    () => {
        return <TNCreateAccountCTA />
    },
)
