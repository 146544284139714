import {
    createRegisterableComponentWithData,
    isArticleLikeType,
} from '@news-mono/web-common'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import {
    getPublicationInfoFromRouteResolution,
    PublicationRouteProps,
} from '../../../templates/Publication/SharedPublication.routing'
import { ArticlePublicationDataDefinitionLoader } from '../ArticlePublicationDataLoader'
import { VideoQueue, getVideoQueue } from '../lib/get-video-queue'
import { getPublicationPageProperties } from '../lib/publication-page-properties'
import { shouldExcludeVideoSeries } from '../lib/should-include-video-series'
import { getNightlyInlinePositionedContent } from './get-nightly-inline-positioned-content'
import { TheNightlyPublication } from './TheNightlyPublication'
import { getVideoSeriesQueue } from '../lib/get-video-series'

export const TheNightlyArticleRegistration =
    createRegisterableComponentWithData(
        'article',
        ArticlePublicationDataDefinitionLoader,
        (props: PublicationRouteProps, data, services) => {
            const publicationInfo =
                getPublicationInfoFromRouteResolution(services)
            if (!isArticleLikeType(publicationInfo.publication)) {
                throw new Error('Article component rendered for non-article')
            }

            if (data.loaded) {
                const inlinePositionedContent =
                    getNightlyInlinePositionedContent({
                        adState: services.adState,
                        publication: publicationInfo.publication,
                        meta: props.meta,
                        log: services.log,
                        onEvent: services.onEvent,
                        toggles: services.store.getState().toggles,
                        services: services,
                    })

                return (
                    <PageProps
                        pageProperties={getPublicationPageProperties(
                            publicationInfo.publication,
                        )}
                    >
                        <TheNightlyPublication
                            data={publicationInfo.publication}
                            inlinePositionedContent={inlinePositionedContent}
                            onEvent={services.onEvent}
                            log={services.log}
                            getVideoQueue={() => {
                                if (
                                    publicationInfo.publication.heroVideo
                                        ?.seriesInfo
                                ) {
                                    if (
                                        publicationInfo.publication.heroVideo
                                            .seriesInfo.episode === 'trailer'
                                    ) {
                                        return Promise.resolve<VideoQueue>({
                                            type: 'video-series',
                                            items: [],
                                        })
                                    }
    
                                    return getVideoSeriesQueue(
                                        services,
                                        publicationInfo.publication.heroVideo
                                            .seriesInfo.series,
                                        publicationInfo.publication.heroVideo
                                            .seriesInfo.episode,
                                    )
                                }
                                return getVideoQueue(
                                    services,
                                    publicationInfo.publication.id,
                                    shouldExcludeVideoSeries(
                                        publicationInfo.publication,
                                        services.store.getState().toggles,
                                    ),
                                )
                            }}
                            heroOptions={{
                                isVideoStickyEnabled:
                                    props.isVideoStickyEnabled,
                            }}
                            relatedPublications={
                                data.result.relatedPublications
                            }
                            location={services.location}
                            adState={services.adState}
                        />
                    </PageProps>
                )
            }
            return null
        },
    )
