import styled from '@emotion/styled'
import React from 'react'
import { metrics } from '../../../__styling'

export interface ArrowProps {
    className?: string
    fillColor?: string
    logoWidth?: '100%' | number | undefined
    logoHeight?: number | undefined
}

const StyledArrow = styled('svg')<ArrowProps>((props) => ({
    // IE11 and some android browsers requires a height and width to render correctly
    maxHeight: props.logoHeight,
    maxWidth: props.logoWidth,
    width: '100%',
}))

export const Arrow: React.FC<ArrowProps> = ({
    className,
    logoWidth,
    logoHeight,
}) => (
    <StyledArrow
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 72 36"
        logoWidth={logoWidth}
        logoHeight={logoHeight}
    >
        <g id="tn-arrow">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.1703 16.7073L54.8773 3.41421L56.2915 2L71.9988 17.7073L56.2915 33.4145L54.8773 32.0003L68.1703 18.7073H0V16.7073H68.1703Z"
                fill="#161616"
            />
        </g>
    </StyledArrow>
)
Arrow.displayName = 'Arrow'
