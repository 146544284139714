import {
    Product,
    WebLink,
    createEditionSelectItemEvent,
    scaleImage,
} from '@news-mono/web-common'
import React from 'react'
import { format } from 'date-fns'
import {
    StyledItemDescription,
    StyledEditionItem,
    StyledItemDetail,
    StyledItemLink,
} from './EditionItem.styled'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'

export const EditionItem: React.FC<{
    edition: EditionV4DTO
    lazyloadImages?: boolean
    onEvent?: (event: any) => void
}> = ({ edition, lazyloadImages = false, onEvent }) => {
    const mainImage = edition.mainImages && {
        img: scaleImage(edition.mainImages[0].src, 300, false),
        alt: edition.mainImages[0].altText ?? edition.mainImages[0].captionText,
    }
    return (
        <StyledEditionItem>
            {mainImage && (
                <WebLink to={edition.editionWebUrl}>
                    <img
                        src={mainImage.img}
                        alt={mainImage.alt}
                        loading={lazyloadImages ? 'lazy' : undefined}
                    />
                </WebLink>
            )}
            <time dateTime={edition.publicationDate}>
                {format(new Date(edition.publicationDate), 'd MMM yyyy')}
            </time>
            <StyledItemDetail>
                <StyledItemDescription>{edition.teaser}</StyledItemDescription>
                <StyledItemLink
                    to={edition.editionWebUrl}
                    onClick={() =>
                        onEvent &&
                        onEvent(
                            createEditionSelectItemEvent({
                                edition,
                                collectionName:
                                    'TheNightlyLatestEditionsReadButton',
                                product: Product.TheNightly,
                                itemDetails: {
                                    category: 'thenightly_editions_page',
                                    linkText: 'Read ->',
                                    linkUrl: edition.editionWebUrl,
                                },
                            }),
                        )
                    }
                >
                    {'Read ->'}
                </StyledItemLink>
            </StyledItemDetail>
        </StyledEditionItem>
    )
}
