import styled from '@emotion/styled'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    fonts,
    metrics,
} from '../../../../__styling'
import { WebLink, createPropFilter, WebLinkProps } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'

const { md, m2l } = metrics.thenightly.margins
const { neutral } = tokens.thenightly.colors.palette

export const StyledHomepageEditions = styled.div({
    padding: calcRem(0, m2l),
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(24),

    [breakpoint('lg')]: {
        gap: calcRem(32),
        padding: 0,
    },
    transition: 'padding 0.25s',
})

export const StyledHeading = styled.div({
    display: 'flex',
    fontFamily: fonts.thenightly.sansSerif,
    justifyContent: 'space-between',
    h2: {
        margin: 0,
        padding: 0,
        fontSize: calcRem(40),
        fontWeight: 700,
        letterSpacing: '-1.6px',
        textTransform: 'capitalize',
        lineHeight: calcRem(48),
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
    },

    [breakpoint('xs')]: {
        h2: {
            fontSize: calcRem(51),
            lineHeight: calcRem(56),
        },
    },
    transition: 'margin 0.25s, font-size 0.25s, line-height 0.25s',
})

export const StyledButton = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['isTop']),
})<{ isTop?: boolean }>(({ isTop }) => ({
    fontFamily: fonts.thenightly.sansSerif,
    display: !isTop ? 'block' : 'none',
    fontWeight: 600,
    color: neutral[100],
    textDecoration: 'none',
    borderRadius: '8px',
    padding: calcRem(16, isTop ? 40 : 0),
    border: `1px solid ${neutral[100]}`,
    textAlign: 'center',
    textTransform: 'uppercase',

    ':focus': {
        textDecoration: 'underline',
        color: tokens.thenightly.colors.actions.button.secondary?.text.hover,
    },

    [breakpoint('xs')]: {
        display: isTop ? 'block' : 'none',
    },
}))

export const StyledEditionList = styled.ul({
    display: 'grid',
    gap: calcRem(md),
    justifyContent: 'center',

    padding: 0,
    margin: 0,

    'li:nth-child(3)': {
        display: 'none',
    },
    'li:nth-child(4)': {
        display: 'none',
    },
    'li:nth-child(5)': {
        display: 'none',
    },

    [breakpointMax('sm')]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [breakpoint('sm')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: calcRem(md),
        'li:nth-child(3)': {
            display: 'flex',
        },
    },
    [breakpoint('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: calcRem(md),
        'li:nth-child(4)': {
            display: 'flex',
        },
    },
    [breakpoint('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        'li:nth-child(5)': {
            display: 'flex',
        },
    },

    transition: 'gap 0.25s',
})
