import React, { FC } from 'react'
import {
    StyledHeader,
    StyledHeaderContainer,
    StyledKicker,
} from './TNBreakingNewsHeadline.styled'

interface TNBreakingNewsHeadlineProps {
    headline: string
    kicker?: string
}

const DEFAULT_KICKER = 'Breaking'

export const TNBreakingNewsHeadline: FC<TNBreakingNewsHeadlineProps> = ({
    kicker,
    headline,
}) => {
    const displayKicker =
        kicker === undefined || kicker.length === 0 ? DEFAULT_KICKER : kicker

    return (
        <StyledHeaderContainer>
            <StyledKicker>{displayKicker}</StyledKicker>
            <StyledHeader>{headline}</StyledHeader>
        </StyledHeaderContainer>
    )
}
