import styled from '@emotion/styled'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { BreakpointKeys, metrics } from '../../../__styling'
import { StyledLinkGroup } from '../TNFlyoutNav.styled'

export const StyledNavWrapperClass = 'stylednavwrapper'

export const StyledSubLinkGroupWrapper = styled.div<{
    isOpen: boolean
    hideAtBreakpoint?: BreakpointKeys
}>(({ isOpen, hideAtBreakpoint }) => ({
    display: isOpen ? 'grid' : 'none',
    gridTemplateRows: '1fr',
    marginBottom: calcRem(24),

    transition: 'grid-template-rows 0.25s, margin 0.25s',

    ...(hideAtBreakpoint && {
        [breakpoint(hideAtBreakpoint)]: {
            display: 'none',
        },
    }),
}))

export const StyledSubLinkList = styled(StyledLinkGroup)(() => ({
    minHeight: 0,
}))
