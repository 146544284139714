import styled from '@emotion/styled'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { ArrowStyle } from './MessageBannerSmall'

interface StyledMessageBannerProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    connector?: ArrowStyle
}

export const StyledMessageBannerSmall = styled('div')<StyledMessageBannerProps>(
    ({ theme, verticalSpacing }) => ({
        position: 'relative',
        padding: calcRem(theme.margins.sm, 0),
        background: themedValue(theme, {
            thewest: colors.thewest.greyErmine,
            perthnow: colors.perthnow.greyBasalt90,
            sevennews: colors.sevennews.charade,
            fallback: undefined,
        }),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
    }),
)

export const StyledMessageBannerNewBreach = styled(StyledMessageBannerSmall)(() => ({
    width: '100%',
    maxWidth: calcRem(636),
    padding: calcRem(30, 0, 0),
    background: 'none',
    color: colors.black,
    '& div': {
        width: 'auto'
    }
}))

export const StyledMessageBannerWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    fontFamily: theme.fonts.sansSerif,
    [breakpointMax('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}))

export const StyledIconPremium = styled(IconPremium)(() => ({
    width: calcRem(30),
    height: calcRem(30),
    margin: calcRem(0, 14),
}))

export const StyledMessageCTA = styled('div')(({ theme }) => ({
    justifySelf: 'flex-start',
    height: '100%',

    p: {
        margin: 0,
        fontSize: calcRem(16),
    },
}))

export const StyleMessageCTAIconBannerGroup = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '50%',
    [breakpointMax('xs')]: {
        width: '100%',
        justifyContent: 'flex-start',
    },
}))

export const StyledMessageBannerGroup = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: calcRem(48),
    width: `calc(100% - ${calcRem(48)})`,
    borderColor: themedValue(theme, {
        thewest: colors.thewest.westblue,
        fallback: colors.black,
    }),
    [breakpoint('xxs')]: {
        marginLeft: calcRem(58),
    },

    [breakpoint('xs')]: {
        width: '50%',
        borderLeft: `1px solid`,
        marginLeft: 0,
        justifyContent: 'center',
    },
}))

export const StyledSubscriberMessage = styled('p')(({ theme }) => ({
    lineHeight: 1.2,
    fontWeight: 700,
    fontSize: calcRem(15),
}))

export const StyledButton = styled('button')(({ theme }) => ({
    background: 'none',
    border: 'none',
    fontFamily: theme.fonts.sansSerif,
    color: themedValue(theme, {
        thewest: colors.thewest.westblue,
        fallback: colors.black,
    }),
    lineHeight: 1.2,
    fontWeight: 700,
    fontSize: calcRem(15),
    cursor: 'pointer',
}))
