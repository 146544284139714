import { FeatureConfig } from '@news-mono/web-common'
import React from 'react'
import { LinkGroupsWrapper, LinkGroup } from './LinkGroups.styled'
import { getTextLinks } from '../utils'

type TogglableLink = {
    link: string
    forceInternalSSR?: boolean
} & FeatureConfig

type TextLink = {
    name: string
} & TogglableLink

export type LinkGroupsProps = {
    linkGroups: TextLink[][]
    className?: string
}

/**
 * The main column(s) of links.
 */
export const LinkGroups = ({ linkGroups, className }: LinkGroupsProps) => {
    if (
        linkGroups.length === 0 ||
        linkGroups.every((group) => group.length === 0)
    ) {
        return null
    }

    return (
        <LinkGroupsWrapper className={className}>
            {linkGroups.map((group, i) => {
                if (group.length === 0) {
                    return null
                }

                return <LinkGroup key={i}>{getTextLinks(group)}</LinkGroup>
            })}
        </LinkGroupsWrapper>
    )
}
