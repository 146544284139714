import React from 'react'
import {
    TheNightlyHeaderLoginStateProps,
    TheNightlyLoginModalButtonProps,
} from './TheNightlyHeaderLoginModal'
import {
    StyledInformationHeader,
    StyledInformationSecondaryText,
    StyledIconCrossContainer,
} from './TheNightlyHeaderLoginModal.styled'
import { IconSettingsCogTN } from '../../icons/IconSettingsCogTN/IconSettingsCogTN'
import { IconLogoutTN } from '../../icons/IconLogoutTN/IconLogoutTN'
import StyledButton from './components/StyledButton'
import StyledIconExitCross from './components/StyledIconExitCross'
import StyledButtonText from './components/StyledButtonText'
import { tokens } from '@news-mono/design-tokens'
import { StyledContainer } from '../../user-registration/warden'
import { IconBookmark } from '../../icons/IconBookmark/IconBookmark'
import { FeatureToggle } from '../../feature-toggling'

// The Logged In state of the the top information section. While logged in this is to
// display the user's email.
export const LoggedInInformationContainer: React.FC<TheNightlyHeaderLoginStateProps> =
    (props) => {
        const authState = props.authenticationState
        const hide = props.hide

        const userEmail = (
            authState.userEmail ? authState.userEmail : 'nightly@wanews.com.au'
        ).trim()
        const userName = authState.userName.trim()
        const [firstName, lastName] = userName.split(' ')
        let properFirstName = firstName

        // Check if the firstName length is empty, or the userName is equal
        // to the user email.
        if (firstName.length === 0 || userName === userEmail || !lastName) {
            properFirstName = 'there'
        }

        return (
            <>
                <StyledContainer
                    marginBottom={8}
                    addPadding={false}
                    flexDirection={'row'}
                >
                    <StyledInformationHeader>
                        Hi {properFirstName}.
                    </StyledInformationHeader>
                    <StyledIconCrossContainer onClick={hide}>
                        <StyledIconExitCross />
                    </StyledIconCrossContainer>
                </StyledContainer>
                <StyledInformationSecondaryText>
                    {userEmail}
                </StyledInformationSecondaryText>
            </>
        )
    }

// The Logged In state of the middle section (buttons). While the user is logged in this
// is to display a "My account" and a "Log out" button.
export const LoggedInButtons: React.FC<TheNightlyLoginModalButtonProps> = (
    props,
) => (
    <>
        <StyledButton
            id="login-modal-my-account-button"
            marginBottom={12}
            onClick={props.onMyAccountClick}
        >
            <IconSettingsCogTN dimension={24} />
            <StyledButtonText onClick={props.onMyAccountClick}>
                My account
            </StyledButtonText>     
        </StyledButton>
        <FeatureToggle
            feature={'save-article-to-profile'}
            on={() => (
                <StyledButton
                    id="login-modal-saved-publication-button"
                    marginBottom={12}
                    onClick={props.onSavedPublicationClick}
                >
                    <IconBookmark
                        dimension={24}
                        strokeColor={
                            tokens.thenightly.colors.palette.neutral[0]
                        }
                    />
                    <StyledButtonText>Saved</StyledButtonText>
                </StyledButton>
            )}
        />
        <StyledButton
            id="login-modal-log-out-button"
            onClick={props.onLogoutClick}
        >
            <IconLogoutTN dimension={24} />
            <StyledButtonText
                color={tokens.thenightly.colors.palette.utility.error.light}
            >
                Log out
            </StyledButtonText>
        </StyledButton>
    </>
)
