import React, { ReactNode } from 'react'
import {
    Divider,
    FiltersWrapper,
    SearchFiltersContainer,
    TrailingContent,
} from './SearchFiltersPanel.styled'
import { useFeature } from '@news-mono/web-common'
import { SearchFilters } from '../SearchFilters'

export type SearchFiltersPanelProps = {
    trailingContent: ReactNode
    onEvent: (event: any) => void
}

export const SearchFiltersPanel = ({
    trailingContent,
    onEvent,
}: SearchFiltersPanelProps) => {
    const showSearchFilters = useFeature('show-search-filter')

    return (
        <SearchFiltersContainer>
            {showSearchFilters && (
                <FiltersWrapper>
                    <SearchFilters showHeader={true} onEvent={onEvent} />
                    <Divider />
                </FiltersWrapper>
            )}
            <TrailingContent>{trailingContent}</TrailingContent>
        </SearchFiltersContainer>
    )
}
