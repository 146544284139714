import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { IconCross } from '../../../navigation/TNFlyoutNav/assets/IconCross'
import { colors } from '../../../__styling/settings/colors'
import {
    breakpointBetween,
    breakpointMax,
    getComponentTheme,
    metrics,
} from '../../../__styling'
import { zIndex } from '../../../__styling/settings/z-index'

const palette = tokens.thenightly.colors.palette

export interface StickyBreachContainerProps {
    isVisible: boolean
}

export const StickyBreachContainer = styled.div({
    width: '100%',
    marginBlockStart: 'auto',
    background: palette.neutral[100],
    position: 'sticky',
    bottom: 0,
    zIndex: zIndex.thenightly.stickyCreateAccountModal,
})

export const StickyBreachWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    padding: calcRem(
        metrics.thenightly.margins.m2l,
        metrics.thenightly.margins.sm,
    ),
})

/** The divider line. */
export const Divider = styled.span({
    display: 'block',
    width: '100%',
    height: 1,
    margin: calcRem(metrics.thenightly.margins.md, 0),
    background: palette.neutral[60],
})

/** The styles for the breach title */
export const StyledTitle = styled('p')({
    margin: 0,
    fontSize: calcRem(40),
    fontWeight: 600,
    color: palette.neutral[0],
    alignSelf: 'center',
    lineHeight: 1.2,
    paddingBottom: calcRem(metrics.thenightly.margins.md),

    [breakpointBetween('xxs', 'mlg')]: {
        fontSize: calcRem(22),
    },
    [breakpointMax('xxs')]: {
        fontSize: calcRem(20),
    },
})

/** The styles for the breach sub title */
export const StyledText = styled.p<{ padBottom?: boolean }>(
    ({ padBottom = false }) => ({
        margin: 0,
        fontSize: calcRem(16),
        fontWeight: 400,
        color: palette.neutral[20],
        alignSelf: 'stretch',
        textAlign: 'center',
        lineHeight: 1.5,
        paddingBottom: padBottom ? calcRem(metrics.thenightly.margins.md) : 0,
    }),
)

/** The styles for the breach sub title container */
export const StyledContentWrapper = styled.div({
    alignSelf: 'center',
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [breakpointMax('xxs')]: {
        width: '95%',
    },
    [breakpointBetween('xxs', 'mlg')]: {
        width: '60%',
    },
})

export const StyledCloseButton = styled.button({
    outline: 'none',
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
    textUnderlinePosition: 'under',
    alignSelf: 'flex-end',
})

export const StyledIconCross = styled(IconCross)({
    height: calcRem(24),
    width: calcRem(24),
    fill: colors.white,
    '&:hover': {
        cursor: 'pointer',
    },
    margin: calcRem(0, metrics.thenightly.margins.m2l),
})

/** The styles for a text link */
export const StyledTextLink = styled.a({
    textDecoration: 'underline',
    color: palette.neutral[10],
    fontSize: calcRem(16),
    lineHeight: 1.5,
    fontStyle: 'normal',
    fontWeight: 400,
    paddingTop: calcRem(metrics.thenightly.margins.md),
    '&:hover': {
        cursor: 'pointer',
    },
})

export const StyledTextButton = styled.button({
    textDecoration: 'underline',
    color: palette.neutral[10],
    fontSize: calcRem(16),
    lineHeight: 1.5,
    fontStyle: 'normal',
    fontWeight: 400,
    paddingTop: calcRem(metrics.thenightly.margins.md),
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
})

export const StyledCreateAccountButton = styled.button(({ theme }) => {
    const buttonTheme = getComponentTheme(theme, 'buttonTheme')

    return {
        display: 'flex',
        flexGrow: 1,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: buttonTheme.borderRadius,
        gap: buttonTheme.gap,
        marginTop: calcRem(metrics.thenightly.margins.m2l),
        padding: buttonTheme.padding,
        cursor: 'pointer',
        background: palette.utility.error.default,
        color: palette.neutral[0],
        fontSize: calcRem(16),
        fontWeight: 600,
        lineHeight: 1.5,
        fontStyle: 'normal',

        // Remove default border.
        borderStyle: 'none',
        borderWidth: 0,

        ':disabled': {
            cursor: 'not-allowed',
        },
    }
})
