import styled from '@emotion/styled'
import {
    breakpointBetween,
    breakpointMax,
    calcRem,
    fonts,
    metrics,
} from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
const { m2l, xl } = metrics.thenightly.margins

export const Container = styled('div')({
    display: 'flex',
    width: 'fit-content',

    [breakpointMax('mlg')]: {
        marginBlockStart: 0,
        margin: 'auto',
    },

    [breakpointMax('xxs')]: {
        width: '100%',

        justifyContent: 'flex-end',
        flexDirection: 'column',
        height: 'auto',
        marginBottom: 0,
    },
})

export const StyledSubTitle = styled('h3')({
    fontFamily: fonts.thenightly.subheading,
    fontSize: calcRem(25),
    fontWeight: 700,
    lineHeight: calcRem(32),
    letterSpacing: calcRem(-0.55),
    color: tokens.thenightly.colors.text.heading,

    // apply style for tablet
    [breakpointBetween('xxs', 'mlg')]: {
        fontSize: calcRem(22),
        fontWeight: 700,
        lineHeight: calcRem(32),
        letterSpacing: calcRem(-0.48),
    },

    // apply style for anything smaller than a tablet
    [breakpointMax('xxs')]: {
        fontSize: calcRem(20),
        fontWeight: 700,
        lineHeight: calcRem(24),
        letterSpacing: calcRem(-0.44),
    },
})

export const StyledGroup = styled.div({
    paddingTop: calcRem(xl),
})

export const StyledCheckboxGroup = styled.div({
    paddingTop: calcRem(m2l),
})
