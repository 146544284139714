import {
    ContentNavMenuItem,
    HighlightDirection,
} from '@news-mono/component-library'
import { decodeId } from '../hooks'
import { NavReducerState } from './reducer'
import { ScrollProps } from './reducer'
import {
    AD_SCROLL_THRESHOLD,
    HEADER_SCROLL_THRESHOLD_DESKTOP,
    HEADER_SCROLL_THRESHOLD_MOBILE,
    SCROLL_CHANGE_THRESHOLD,
} from './constants'

export const getScrollChange = (
    newScrollPosition: number,
    prevScrollPosition: number,
) => {
    const delta = Math.abs(prevScrollPosition - newScrollPosition)
    const direction =
        newScrollPosition < prevScrollPosition ? 'scroll-up' : 'scroll-down'

    return {
        direction,
        delta,
    }
}

export const isContentNavUsable = (state: NavReducerState) => {
    return (
        state.contentNavMenuItems.length > 0 &&
        ((state.isMobile && state.isMobileEnabled) ||
            (!state.isMobile && state.isDesktopEnabled))
    )
}

export const getContentNavState = (state: NavReducerState) => {
    return state.isMobile && state.scrollDirection === 'scroll-up'
        ? 'content-nav-with-header'
        : 'content-nav'
}

export const getMenuItemIndex = (
    menuItems: ContentNavMenuItem[],
    nodeId: string,
) => {
    const menuItem = menuItems.find(
        (menuItem) => menuItem.heading === decodeId(nodeId),
    )

    return menuItem?.index ?? 0
}

export const getMenuItemsFromNodes = (
    nodes: Element[],
): ContentNavMenuItem[] => {
    const menuItems: ContentNavMenuItem[] = []

    if (nodes.length < 1) {
        return menuItems
    }

    for (const [index, node] of nodes.entries()) {
        const title = node.getAttribute('title')
        const offsetTop = (node as HTMLDivElement).offsetTop

        if (title) {
            menuItems.push({
                heading: decodeId(node.id),
                section: title,
                offsetTop,
                index: index + 1,
            })
        }
    }

    return menuItems
}

export const meetsThresholdToShowAd = ({
    state,
    newScrollPosition,
}: Omit<ScrollProps, 'scrollDelta'>) => {
    return newScrollPosition <= state.adHeight / 2
}

export const isContentNavActive = (state: NavReducerState) => {
    return state.activeContentArea > 0
}

export const scrollDistanceDoesntMeetThreshold = (scrollDelta: number) => {
    return scrollDelta <= SCROLL_CHANGE_THRESHOLD
}
export const doesNotMeetThresholdToShowHeader = ({
    state,
    newScrollPosition,
}: Omit<ScrollProps, 'scrollDelta'>) => {
    const threshold = state.isMobile
        ? HEADER_SCROLL_THRESHOLD_MOBILE
        : HEADER_SCROLL_THRESHOLD_DESKTOP

    return newScrollPosition > threshold
}

export const doesNotMeetThresholdToShowAd = ({
    newScrollPosition,
}: Omit<ScrollProps, 'scrollDelta' | 'state'>) => {
    return newScrollPosition > AD_SCROLL_THRESHOLD
}

export const shouldOpenContentNav = (
    newContentAreaIndex: number,
    state: NavReducerState,
) => {
    return newContentAreaIndex > 0 && !isContentNavActive(state)
}

export const isMenuChangeInSameDirectionAsScroll = (
    newContentAreaIndex: number,
    state: NavReducerState,
) => {
    return (
        (state.activeContentArea > newContentAreaIndex &&
            state.scrollDirection === 'scroll-up') ||
        (state.activeContentArea < newContentAreaIndex &&
            state.scrollDirection === 'scroll-down')
    )
}

export const getHighlightDirection = (
    newIndex: number,
    oldIndex: number,
): HighlightDirection => {
    return newIndex < oldIndex ? 'left' : 'right'
}
