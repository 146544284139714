import styled from '@emotion/styled'
import { zIndex } from '../../../__styling/settings/z-index'
import { breakpoint, colors } from '../../../__styling/'
import { tokens } from '@news-mono/design-tokens'
import { NavDisplayState } from '@news-mono/component-library'

interface StyledAnimatedHeaderNavContainerProps {
    adHeight: number
    navHeight: number
    transitionTime: number
    navDisplayState: NavDisplayState
}

export const StyledAnimatedHeaderNavContainer =
    styled.div<StyledAnimatedHeaderNavContainerProps>(
        ({ adHeight, navHeight, transitionTime, navDisplayState }) => {
            const transition = `all ${transitionTime}ms ease-out`

            return {
                position: 'sticky',
                top: 0,
                zIndex: zIndex.thenightly.stickySiteHeader,
                transform: `translateY(${getHeaderTransformPosition(
                    navDisplayState,
                    adHeight,
                    navHeight,
                )}px)`,

                // Header is opening, slide down from the top of the screen.
                // Account for an ad if there is one.
                '&.header-enter': {
                    transform: `translateY(${-navHeight}px)`,

                    [breakpoint('sm')]: {
                        transform: `translateY(${-navHeight + -adHeight}px)`,
                    },
                },

                '&.header-enter-active': {
                    transform: 'translateY(0%)',
                    transition,

                    [breakpoint('sm')]: {
                        transform: `translateY(${-adHeight}px)`,
                    },
                },

                // Header closing. Slide up to top of screen.
                '&.header-exit': {
                    transform: 'translateY(0%)',

                    [breakpoint('sm')]: {
                        transform: `translateY(${-adHeight}px)`,
                    },
                },

                '&.header-exit-active': {
                    transform: `translateY(${-navHeight}px)`,
                    transition,

                    [breakpoint('sm')]: {
                        transform: `translateY(${
                            isWithAd(navDisplayState)
                                ? 0
                                : -adHeight + -navHeight
                        }px)`,
                    },
                },

                // Ad opening. Slide down from top of screen.
                '&.header-with-ad-enter': {
                    [breakpoint('sm')]: {
                        transform: `translateY(${-adHeight}px)`,
                    },
                },

                '&.header-with-ad-enter-active': {
                    transition,
                    [breakpoint('sm')]: {
                        transform: `translateY(0px)`,
                    },
                },

                // Ad closing. Slide up to top of screen.
                '&.header-with-ad-exit': {
                    transform: 'translateY(0px)',
                },

                '&.header-with-ad-exit-active': {
                    transition,
                    transform: `translateY(${-adHeight}px)`,
                },
            }
        },
    )

export const StyledHeaderNavContainer = styled.div({
    backgroundColor: colors.white,
    borderBottom: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
})

export const StyledNavAdContainer = styled.div({
    backgroundColor: colors.white,
    borderBottom: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
    overflowY: 'hidden',
})

const getHeaderTransformPosition = (
    navDisplayState: NavDisplayState,
    adHeight: number,
    navHeight: number,
) => {
    switch (navDisplayState) {
        case 'header-with-ad':
            return 0
        case 'header':
        case 'content-nav-with-header':
            return -adHeight
        case 'none':
        case 'content-nav':
            return -adHeight + -navHeight
    }
}

const isWithAd = (navDisplayState: NavDisplayState) => {
    return navDisplayState === 'header-with-ad'
}
