import { TheNightlySection } from '@news-mono/common'
import { StaticRoute, StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { metrics } from '@news-mono/component-library'

const createUserSettingPage: StaticRoute<TheNightlySection> = ({ store }) => {
    return {
        kind: 'page',
        heading: 'Manage Account',
        hideHeading: true,
        pageType: 'static',
        adTargeting: {
            pageId: 'user-setting',
            adUnitPath: '',
            ssAdUnits: [],
            topics: [],
        },
        additionalPageProperties: {
            hasVerticalGutter: false,
        },
        section: 'default',
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth: '100%',
                    verticalGutters: false,
                    horizontalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'user-setting',
                            props: {
                                verticalGutters: false,
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const getUserSettingRouteInfo: StaticRoutes<TheNightlySection> = {
    '/manage-account': createUserSettingPage,
}
