import React, { FC } from 'react'
import { StyledNotificationCentreCloseIcon } from './NotificationCentreCloseIcon.styled'

interface NotificationCentreCloseIconProps {
    onClick: () => void
}

export const NotificationCentreCloseIcon: FC<NotificationCentreCloseIconProps> =
    ({ onClick }) => {
        return (
            <StyledNotificationCentreCloseIcon onClick={onClick}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 6L6 18M6 6L18 18"
                        stroke="#161616"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </StyledNotificationCentreCloseIcon>
        )
    }
