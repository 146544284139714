import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { TrendingArticles } from './TrendingArticles'

export const TrendingArticlesRegistration = createRegisterableComponentWithData(
    'trending-articles',
    CurationAndCurationMetadataLoader,
    (_, data, { onEvent }) => {
        return <TrendingArticles data={data} onEvent={onEvent} />
    },
)
