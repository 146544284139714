import { useTheme } from '@emotion/react'
import React from 'react'
import { WestRewardsLogoHorizontal } from '../../../../../logos/TheWestRewardsLogo/TheWestRewardsLogo'
import { calcRem } from '../../../../../__styling'
import {
    StyledCarouselDotsContainer,
    StyledCarouselEmptyDot,
    StyledCarouselFullDot,
    StyledWestRewardsCallToAction,
    StyledWestRewardsCardDescription,
    StyledWestRewardsCardIconWrapper,
    StyledWestRewardsCardLink,
    StyledWestRewardsCardsContainer,
    StyledWestRewardsCardTextWrapper,
    StyledWestRewardsCardTitle,
    StyledWestRewardsCardWrapper,
    StyledWestRewardsLogoGradientBackground,
    StyledWestRewardsMobileCardWrapper,
    StyledWestRewardsMobileCarouselContainer,
} from './WestRewards.styled'
import { WestRewardsDivider } from './WestRewardsDivider'
import {
    WestRewardsDollarIcon,
    WestRewardsNewsletterIcon,
    WestRewardsVideoPodcastIcon,
    WestRewardsWinningIcon,
} from './WestRewardsImages'

export const WestRewards: React.FC = () => {
    const [carouselDotsIndex, setCarouselDotsIndex] = React.useState(0)
    const theme = useTheme()
    return (
        <>
            <WestRewardsDivider />

            <CarouselDots
                number={WestRewardsData.length}
                index={carouselDotsIndex}
            />
            <StyledWestRewardsMobileCarouselContainer
                onScroll={(e) => {
                    const carouselDiv = e.target as HTMLDivElement
                    const itemWidth =
                        carouselDiv.scrollWidth / WestRewardsData.length
                    const displayedSliderIndex = Math.floor(
                        (carouselDiv.scrollLeft + 1) / itemWidth,
                    )
                    setCarouselDotsIndex(displayedSliderIndex)
                }}
            >
                {WestRewardsData.map((item) => (
                    <StyledWestRewardsMobileCardWrapper key={item.title}>
                        <StyledWestRewardsCardIconWrapper>
                            {item.Icon}
                        </StyledWestRewardsCardIconWrapper>
                        <StyledWestRewardsCardTextWrapper>
                            <StyledWestRewardsCardTitle>
                                {item.title}
                            </StyledWestRewardsCardTitle>

                            {!!item.isReward && (
                                <StyledWestRewardsLogoGradientBackground
                                    height={calcRem(21)}
                                    width={calcRem(117)}
                                >
                                    <WestRewardsLogoHorizontal
                                        markFill={
                                            theme.colors.background.primary
                                        }
                                        textFill={
                                            theme.colors.background.primary
                                        }
                                    />
                                </StyledWestRewardsLogoGradientBackground>
                            )}

                            <StyledWestRewardsCardDescription>
                                {item.description}
                            </StyledWestRewardsCardDescription>
                            <StyledWestRewardsCardLink
                                href={item.linkHref}
                                target="_blank"
                            >
                                {item.linkText}
                            </StyledWestRewardsCardLink>
                        </StyledWestRewardsCardTextWrapper>
                    </StyledWestRewardsMobileCardWrapper>
                ))}
            </StyledWestRewardsMobileCarouselContainer>

            <StyledWestRewardsCardsContainer>
                {WestRewardsData.map((item, index) => (
                    <React.Fragment key={index}>
                        <StyledWestRewardsCardWrapper>
                            <StyledWestRewardsCardIconWrapper>
                                {item.Icon}
                            </StyledWestRewardsCardIconWrapper>
                            <StyledWestRewardsCardTextWrapper>
                                <StyledWestRewardsCardTitle>
                                    {item.title}
                                </StyledWestRewardsCardTitle>

                                {!!item.isReward && (
                                    <StyledWestRewardsLogoGradientBackground
                                        height={calcRem(21)}
                                        width={calcRem(117)}
                                    >
                                        <WestRewardsLogoHorizontal
                                            markFill={
                                                theme.colors.background.primary
                                            }
                                            textFill={
                                                theme.colors.background.primary
                                            }
                                        />
                                    </StyledWestRewardsLogoGradientBackground>
                                )}

                                <StyledWestRewardsCardDescription>
                                    {item.description}
                                </StyledWestRewardsCardDescription>
                                <StyledWestRewardsCardLink
                                    href={item.linkHref}
                                    target="_blank"
                                >
                                    {item.linkText}
                                </StyledWestRewardsCardLink>
                            </StyledWestRewardsCardTextWrapper>
                        </StyledWestRewardsCardWrapper>
                    </React.Fragment>
                ))}
            </StyledWestRewardsCardsContainer>
            <StyledWestRewardsCallToAction>
                <WestRewardsLogoHorizontal
                    markFill={theme.colors.brand}
                    gradientId={
                        'rewardsGradient-' +
                        Math.random().toFixed(5).replace('0.', '')
                    }
                />
                Subscribe and get instant access to West Rewards, our rewards
                program.
            </StyledWestRewardsCallToAction>
        </>
    )
}

export interface CarouselDotsProps {
    number: number
    index: number
}

export const CarouselDots: React.FC<CarouselDotsProps> = ({
    number,
    index,
}) => {
    const dotsArray = []

    for (let i = 0; i < number; i++) {
        i == index
            ? dotsArray.push(<StyledCarouselFullDot key={i} />)
            : dotsArray.push(<StyledCarouselEmptyDot key={i} />)
    }

    return (
        <StyledCarouselDotsContainer>{dotsArray}</StyledCarouselDotsContainer>
    )
}

const WestRewardsData = [
    {
        Icon: <WestRewardsVideoPodcastIcon width={150} height={150} />,
        title: 'Video series & podcasts',
        description:
            'Watch and listen to a variety of WA true crime series, video channels and podcasts with commentary on news, politics and current affairs',
        linkText: 'Watch now',
        linkHref: 'https://thewest.com.au/features',
    },
    {
        Icon: <WestRewardsNewsletterIcon width={150} height={150} />,
        title: 'Newsletters',
        description:
            'Breaking news, sport, business and more. Get the news you want delivered straight to your inbox so you never miss a headline.',
        linkText: 'Choose today',
        linkHref: 'https://thewest.com.au/manage-email-preferences',
    },
    {
        Icon: <WestRewardsWinningIcon width={150} height={150} />,
        title: 'Win big!',
        description:
            'Regular chances to win incredible prizes like luxury getaways and sports tickets with our subscriber exclusive competitions',
        linkText: 'Enter now',
        linkHref:
            'https://thewest.com.au/entertainment/competitions/west-rewards',
        isReward: true,
    },
    {
        Icon: <WestRewardsDollarIcon width={150} height={150} />,
        title: 'Subscriber discounts',
        description:
            'Get exclusive discounts on movie tickets, dining and popular attractions.',
        linkText: 'Browse today',
        linkHref: 'https://rewards.thewest.com.au/',
        isReward: true,
    },
]
