import { TheNightlySection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import { getEditionPageMeta } from './edition-page-meta'

export function createSpecificEditionPage(
    config: BaseClientConfig,
    getAdTargeting: GetRouteAdTargeting,
    section: TheNightlySection,
    edition: EditionV4DTO,
): PageType<TheNightlySection> {
    const title = edition.heading ?? edition.seoHead
    const description = edition.teaser

    return {
        kind: 'page',
        heading: edition.title,
        pageType: 'publication',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: getEditionPageMeta(config, edition),
        socialMeta: {
            title: title,
            description: description,
        },
        adTargeting: getAdTargeting('home', section, 'default'),
        section: section,

        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth: '100%',
                    horizontalGutters: false,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                                containerWidth:
                                    metrics.thenightly.siteMetrics
                                        .mainContentWidth,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'nightly-specific-edition-focus',
                                        props: {
                                            edition,
                                            lazyloadImages: true,
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                adType: 'inline',
                                noticePosition: 'below-center',
                                slot: {
                                    id: 'single-editions-page',
                                    size: 'billboardAbove1280leaderboardAbove768MrecBelow',
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
