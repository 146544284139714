import React, { useContext } from 'react'
import { ConfigurationContext } from '@news-mono/web-common'
import {
    StyledDownloadAppCta,
    StyledHeading,
    StyledDescription,
    StyledButtonGroup,
    StyledDescriptionButtonContainer,
    AppleButton,
    GoogleButton,
} from './DownloadAppCta.styled'
import { IconDownloadAppStore, IconDownloadPlayStore } from '../../../icons'

export const DownloadAppCta: React.FC<{ className?: string }> = ({
    className,
}) => {
    const config = useContext(ConfigurationContext)

    return (
        <StyledDownloadAppCta className={className}>
            <StyledHeading>The Nightly App</StyledHeading>
            <StyledDescriptionButtonContainer>
                <StyledDescription>
                    Get the most out of your news with The Nightly app.
                    Available for iOS and Android.
                </StyledDescription>
                <StyledButtonGroup>
                    <AppleButton to={config.appDownloadUrlApple ?? '#'}>
                        <IconDownloadAppStore />
                    </AppleButton>
                    <GoogleButton to={config.appDownloadUrlGoogle ?? '#'}>
                        <IconDownloadPlayStore />
                    </GoogleButton>
                </StyledButtonGroup>
            </StyledDescriptionButtonContainer>
        </StyledDownloadAppCta>
    )
}
