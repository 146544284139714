import { useEffect } from 'react'
import {
    addListener,
    removeListener,
    ScrollProps,
} from '@news-mono/component-library'

export const useScroll = (callback: (e: ScrollProps) => void) => {
    useEffect(() => {
        addListener('scroll', callback)
        return () => {
            removeListener('scroll', callback)
        }
    }, [callback])
}
