import React from 'react'
import { ReactElement } from 'react'
import { BaseTheNightlyButton } from '../NightlyButton'
import { StyledButton, StyledButtonLink } from './DefaultButton.styled'

export type ButtonColorVariant = 'primary' | 'secondary' | 'emphasis'
export type ButtonFillVariant = 'filled' | 'outline' | 'text'

export type DefaultButtonProps = BaseTheNightlyButton<'default'> & {
    color: ButtonColorVariant
    fill: ButtonFillVariant
    icon?: {
        iconPosition: 'left' | 'right'
        IconElement: ReactElement
    }
}

/** A regular button, which can take an optional icon to place to the left or right of text. */
export const DefaultButton = ({
    text,
    isDisabled,
    fill,
    color,
    layout,
    icon,
    action,
}: DefaultButtonProps) => {
    const content = (
        <>
            {icon?.iconPosition === 'left' && icon.IconElement}
            {text}
            {icon?.iconPosition === 'right' && icon.IconElement}
        </>
    )
    const sharedProps = { fill, color, layout }

    if (action.type === 'button') {
        return (
            <StyledButton
                {...sharedProps}
                disabled={isDisabled}
                onClick={action.onClick}
            >
                {content}
            </StyledButton>
        )
    } else {
        const { type, onClick, ...linkProps } = action

        return (
            <StyledButtonLink {...linkProps} {...sharedProps} onClick={onClick}>
                {content}
            </StyledButtonLink>
        )
    }
}
