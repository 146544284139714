import { NavEventArgs, NavItem } from '@news-mono/web-common'
import React from 'react'
import { StyledNavSectionLabel } from './NavSectionLabel.styled'

export interface NavSectionLabelProps {
    navItem: NavItem
    navPos: number
    onClick?: (args: NavEventArgs) => void
}

export const NavSectionLabel: React.FC<NavSectionLabelProps> = ({
    navItem,
    onClick,
    navPos,
}) => {
    return (
        <StyledNavSectionLabel
            onClick={() => {
                onClick &&
                    onClick({
                        navPos,
                        navText: navItem.name,
                        navLink: navItem.link,
                        navParent: parent.name,
                    })
            }}
        >
            {navItem.name}
        </StyledNavSectionLabel>
    )
}

NavSectionLabel.displayName = 'TNFlyoutNavSectionLabel'
