import React, { FC } from 'react'
import { SectionContentContainer } from '../../compositions/TheNightly/SectionContent/SectionContent.styled'
import { containerClassNameIdentifier, encodeId } from '../TNHeaderNav'

interface NavigationAnchorProps {
    section?: string
    heading?: string
}

export const ContentNavAnchor: FC<NavigationAnchorProps> = ({
    section,
    heading,
    children,
}) => {
    if (section !== undefined && heading !== undefined) {
        return (
            <SectionContentContainer
                id={encodeId(heading)}
                className={containerClassNameIdentifier}
                title={section}
            >
                {children}
            </SectionContentContainer>
        )
    }

    return <>{children}</>
}
