import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { breakpoint } from '../../../__styling'

/** Positions the social links. */
export const SocialLinksWrapper = styled.div({
    display: 'flex',
    gap: 24,

    [breakpoint('sm')]: {
        marginLeft: 'auto',
    },
})

/**
 * Styles the social links.
 * For some reason, the height goes to 38 without this.
 */
export const StyledSocialLink = styled(WebLink)({
    height: 32,
})
