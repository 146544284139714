import styled from '@emotion/styled'
import { getComponentTheme } from '../../__styling'

interface StyledNotificationBellContainerProps {
    isApp: boolean
}

export const StyledNotificationBellContainer =
    styled.div<StyledNotificationBellContainerProps>(({ theme, isApp }) => {
        const iconTheme = getComponentTheme(theme, 'notificationCentre').icon
        return {
            position: iconTheme.position,
            cursor: iconTheme.cursor,
            padding: isApp ? iconTheme.appPadding : iconTheme.padding,
            height: iconTheme.height,

            '&:hover': {
                svg: {
                    path: {
                        stroke: iconTheme.hoverStroke,
                    },
                },
            },
        }
    })

export const StyledNewNotificationsBadge = styled.div(({ theme }) => {
    const badgeTheme = getComponentTheme(theme, 'notificationCentre').badge
    return {
        position: badgeTheme.position,
        height: badgeTheme.height,
        width: badgeTheme.width,
        backgroundColor: badgeTheme.backgroundColor,
        borderRadius: badgeTheme.borderRadius,
        right: badgeTheme.right,
        top: badgeTheme.top,
    }
})
