import { cx } from '@emotion/css'
import React from 'react'

export interface IconInfoLargeProps {
    dimension?: number
    className?: string
    strokeColor?: string
    filled?: boolean
}

export const IconInfoLarge: React.FC<IconInfoLargeProps> = ({
    className,
    strokeColor = '#000000',
    dimension = 24,
    filled = false,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={dimension}
        height={dimension}
        viewBox={`0 0 ${dimension} ${dimension}`}
        fill={filled ? strokeColor : 'none'}
        className={cx(className, 'icon-info')}
    >
        <path
            d="M24 32V24M24 16H24.02M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z"
            stroke={strokeColor}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconInfoLarge.displayName = 'IconInfoLarge'
